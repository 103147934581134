import { z } from 'zod'
import { EvaluationRange } from '@/core/domain/resources/enums/EvaluationRange'

export const DashboardScoreModuleGrainSchema = z.object({
  learningGrainId: z.number(),
  learningGrainTitle: z.string(),
  learningGrainOrder: z.number(),
  postQuizScoreScaled: z.number().nullable().optional(),
  postQuizScoreScaledHistory: z.array(z.number()),
  postQuizCountAttempt: z.number().nullable().optional(),
  postQuizEvalRangeName: z.nativeEnum(EvaluationRange.Values).nullable()
})

export type DashboardScoreModuleGrainEntity = z.infer<typeof DashboardScoreModuleGrainSchema>
