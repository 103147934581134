<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useProfileStore } from '@/stores/profile'
import { useUserMenu } from '@/composables/navigation/useUserMenu'
import { default as PButton } from 'primevue/button'
import { default as PAvatar } from 'primevue/avatar'
import { default as PMenu } from 'primevue/menu'
import largeLogoUrl from '@/assets/images/logo-naboo-bleu.svg'
import smallLogoUrl from '@/assets/images/logo-naboo-bleu-small.svg'

// Props
defineProps<{
  isFullyOpened: boolean
}>()

// Store refs
const { getPicture, getFullName, getFirstNameInitial } = storeToRefs(useProfileStore())

// User menu
const { menuAvatar, userMenuItems, toggleUserMenu } = useUserMenu()
</script>

<template>
  <div class='sticky top-0 px-4 pt-5 bg-white z-2'>
    <!-- Logo -->
    <img
      :src="isFullyOpened ? largeLogoUrl : smallLogoUrl"
      :class="isFullyOpened ? 'block w-full h-3rem' : 'block max-w-3rem'"
      :alt="isFullyOpened ? 'Logo naboo' : 'Logo naboo small'"
    />

    <!-- Profil -->
    <p-button
      @click="toggleUserMenu"
      aria-haspopup="true"
      aria-controls="overlay_menu"
      text
      class="p-0 my-5 w-full border-none"
      :class="isFullyOpened ? 'justify-content-start' : 'justify-content-center'"
    >
      <p-avatar
        v-if="getPicture"
        :image="getPicture"
        shape="circle"
      />
      <p-avatar
        v-else
        :label="getFirstNameInitial?.toLocaleUpperCase()"
        shape="circle"
      />
      <span
        v-if="isFullyOpened"
        class='ml-2 white-space-nowrap text-left flex-1 max-w-10rem overflow-hidden text-overflow-ellipsis uppercase'
        :title='getFullName'
      >
        {{ getFullName }}
      </span>
      <i
        v-if="isFullyOpened"
        class="pi pi-chevron-down ml-auto"
      ></i>
    </p-button>
    <p-menu
      ref="menuAvatar"
      id="overlay_menu"
      :model="userMenuItems"
      :popup="true"
    />
  </div>
</template>