<script setup lang="ts">
/*
 *
 * IMPORTS
 *
 * */

// Stores
import { useModulesStore } from '@/stores/learning/learningModules'
import { useLearningQuizStore } from '@/stores/learning/learningQuiz'

// Vue, router
import { computed, nextTick, onMounted, type Ref, ref } from 'vue'

// Types
import type { ILearningModule } from '@/assets/types/learning/LearningModuleTypes'
import type { ILearningGrain } from '@/assets/types/learning/LearningGrainTypes'

// PrimeVue & libs
import { default as PButton } from 'primevue/button'
import { default as PInlineMessage } from 'primevue/inlinemessage'
import { default as PProgressSpinner } from 'primevue/progressspinner'
import draggable from 'vuedraggable'
import { useConfirm } from 'primevue/useconfirm'

// Custom components and types
import QuestionItem from '@/views/quiz/blocks/QuestionItem.vue'
import QuizHeader from '@/views/quiz/blocks/QuizHeader.vue'
import QuestionDetails from '@/views/quiz/blocks/QuestionDetails.vue'
import type { IQuestion } from '@/assets/types/learning/QuizQuestion'
import { LearningGrainResponseDto } from '@/assets/DTO/learning/learningGrain.response.dto'
import type { ILearningQuiz } from '@/assets/types/learning/learningQuiz'
import { LearningQuizQuestion } from '@/assets/DTO/learning/learningQuiz.dto'
import { LearningEnums } from '@/assets/types/learning/enums'
import { deepClone } from '@/assets/utils'
import type { ILearningCourse } from '@/assets/types/learning/LearningCourses'
import { onBeforeRouteLeave } from 'vue-router'

// Utils
const confirm = useConfirm()

// Data, refs
const QuestionListRef = ref<HTMLElement | null>(null)
const learningModule: Ref<ILearningModule> = ref({} as ILearningModule)
const learningGrain = ref({} as ILearningGrain)
const QuizHeaderRef = ref<typeof QuizHeader | null>(null) // Used to get the height of the header
const loading = ref(true)
const quiz: Ref<ILearningQuiz> = ref({} as ILearningQuiz)
const quizCopy: Ref<ILearningQuiz> = ref({} as ILearningQuiz)
const currentDisplayedQuestionIndex: Ref<null | number> = ref(null)
const dragOptions = {
  animation: 150,
  group: 'group1',
  disabled: false,
  ghostClass: 'ghost'
}

const props = defineProps<{
  id: number
  moduleId?: number
  grainId?: number
  taskId?: number
  quizId: number
  learningCourse?: ILearningCourse
}>()

// Computed
const backTo = computed(() => {
  if (props.moduleId) {
    return {
      name: 'learning-course-update-module',
      params: { id: props.id, moduleId: props.moduleId },
      query: { grainId: props.grainId }
    }
  } else if (props.taskId) {
    return { name: 'learning-course-update', params: { id: props.id } }
  }

  return { name: 'learning-courses-list' }
})

const task = computed(() => {
  if (props.taskId) {
    return props.learningCourse?.domain.tasks.find((task) => task.id === props.taskId)
  }
  return undefined
})

const hasUnsavedChanges = computed(() => {
  return JSON.stringify(quiz.value) !== JSON.stringify(quizCopy.value)
})

// Lifecycle
onMounted(async () => {
  if (props.moduleId) {
    learningModule.value = await fetchModuleById(props.moduleId, props.id)

    // Get the grain
    learningModule.value.learningGrains.find((grain) => {
      if (grain.id === props.grainId) {
        learningGrain.value = new LearningGrainResponseDto(grain)
        return true
      }
    })
  }

  quiz.value = await fetchQuiz(props.quizId)
  quizCopy.value = deepClone(quiz.value)

  loading.value = false
})

// Methods
const { fetchModuleById } = useModulesStore()
const { fetchQuiz, updateQuiz, markQuizAsReady } = useLearningQuizStore()

function addQuestion() {
  if (hasUnsavedChanges.value) {
    confirm.require({
      message: 'Vous avez des modifications non sauvegardées. Voulez-vous continuer ?',
      accept: async () => {
        await validateUpdate()
        addQuestionToQuiz()
      },
      reject: () => {
        cancel()
        addQuestionToQuiz()
      }
    })
  } else addQuestionToQuiz()

  function addQuestionToQuiz() {
    quiz.value.questions.push(
      new LearningQuizQuestion({
        order: quiz.value.questions.length + 1,
        answers: [],
        label: 'Nouvelle question' + (quiz.value.questions.length + 1),
        type: LearningEnums.QuestionType.SINGLE_CHOICE,
        id: 0,
        feedbacks: {
          correct: '',
          wrong: ''
        }
      })
    )

    updateQuestionListOrder()

    displayQuestion(quiz.value.questions.length - 1)

    nextTick(() => {
      QuestionListRef.value?.scrollTo({
        top: QuestionListRef.value.scrollHeight,
        behavior: 'smooth'
      })
    })
  }
}

function displayQuestion(index: number) {
  currentDisplayedQuestionIndex.value = index
}

function updateQuestionListOrder() {
  quiz.value.questions.forEach((question, index) => {
    question.order = index + 1
  })
}

function duplicateQuestion(questionToDuplicate: IQuestion) {
  if (hasUnsavedChanges.value) {
    confirm.require({
      message: 'Vous avez des modifications non sauvegardées. Voulez-vous continuer ?',
      accept: async () => {
        await validateUpdate()
        duplicateQuestionToQuiz()
      },
      reject: () => {
        cancel()
        duplicateQuestionToQuiz()
      }
    })
  } else {
    duplicateQuestionToQuiz()
  }

  function duplicateQuestionToQuiz() {
    const newQuestion = new LearningQuizQuestion({
      order: quiz.value.questions.length + 1,
      answers: questionToDuplicate.answers,
      label: questionToDuplicate.label + ' (copie)',
      type: questionToDuplicate.type,
      id: 0,
      feedbacks: {
        correct: questionToDuplicate.feedbacks.correct,
        wrong: questionToDuplicate.feedbacks.wrong
      }
    })

    // add the question after the orginal question
    quiz.value.questions.splice(questionToDuplicate.order, 0, newQuestion)
    updateQuestionListOrder()
    displayQuestion(questionToDuplicate.order)
  }
}

function startEditing(index: number) {
  if (currentDisplayedQuestionIndex.value === index) return

  if (hasUnsavedChanges.value)
    confirm.require({
      message: 'Vous avez des modifications non sauvegardées. Voulez-vous les sauvegarder ?',
      accept: async () => {
        await validateUpdate()
        displayQuestion(index)
      },
      reject: () => {
        cancel()
        displayQuestion(index)
      }
    })
  else displayQuestion(index)
}

function deleteQuestion(index: number) {
  quiz.value.questions.splice(index, 1)
  updateQuestionListOrder()
  currentDisplayedQuestionIndex.value = 0
}

async function validateUpdate() {
  const updatedQuiz = await updateQuiz(quiz.value)

  if (updatedQuiz) {
    quiz.value = updatedQuiz
    quizCopy.value = deepClone(updatedQuiz)
  }
}

async function publishQuiz() {
  confirm.require({
    message: 'Êtes-vous certains de vouloir publier ce quiz ?',
    accept: async () => {
      const readyQuiz = await markQuizAsReady(quiz.value)
      if (readyQuiz) {
        quiz.value = readyQuiz
        quizCopy.value = deepClone(readyQuiz)
      }
    },
    reject: () => {}
  })
}

function cancel() {
  quiz.value = deepClone(quizCopy.value)
  currentDisplayedQuestionIndex.value = null
}

onBeforeRouteLeave(async () => {
  if (hasUnsavedChanges.value) {
    confirm.require({
      message: 'Vous avez des modifications non sauvegardées. Voulez-vous les sauvegarder ?',
      accept: async () => {
        await validateUpdate()
      },
      reject: () => {
        cancel()
      }
    })
  }
})
</script>

<template>
  <div v-if="loading" class="w-full h-full flex justify-content-center align-items-center">
    <p-progress-spinner />
  </div>

  <div class="grid grid-nogutter" v-else>
    <quiz-header
      :back-to="backTo"
      :learningModule="learningModule"
      :learningGrain="learningGrain"
      :learning-course="learningCourse"
      :task="task"
      :isQuizReady="quiz.ready"
      ref="QuizHeaderRef"
      @save="validateUpdate"
      @publish="publishQuiz"
    />

    <div
      class="border-right-1 surface-border surface-b p-5 overflow-auto col-4 flex flex-column"
      :style="`height: calc(100vh + 1rem - ${QuizHeaderRef?.Header.offsetHeight}px)`"
      ref="QuestionListRef"
    >
      <div class="grid grid-nogutter justify-content-between h-fit w-full">
        <h4 class="align-content-center font-bold">Liste des questions</h4>

        <p-button
          label="Ajouter une question"
          icon="pi pi-plus"
          outlined
          size="small"
          @click="addQuestion"
        />
      </div>

      <div class="w-full flex flex-column mt-4 align-items-start">
        <draggable
          class="list-group w-full flex flex-column gap-3"
          item-key="order"
          handle=".handle"
          :component-data="{ tag: 'div', name: 'flip-list', type: 'transition' }"
          v-model="quiz.questions"
          v-bind="dragOptions"
          v-if="quiz.questions.length > 0"
          @end="updateQuestionListOrder()"
        >
          <template #item="{ element, index }: { element: IQuestion; index: number }">
            <div>
              <question-item
                :question="element"
                :isEditing="currentDisplayedQuestionIndex === index"
                :index="index"
                @edit="startEditing(index)"
                @duplicate="
                  (questionToDuplicate: IQuestion) => duplicateQuestion(questionToDuplicate)
                "
                @delete="() => deleteQuestion(index)"
              />
            </div>
          </template>
        </draggable>
        <p-inline-message v-else severity="info">
          Il n'y a pas de questions pour ce quiz. Cliquez sur "Ajouter une question" pour en créer
          une.
        </p-inline-message>
      </div>
    </div>

    <div
      class="p-5 overflow-auto col-8"
      :style="`height: calc(100vh + 1rem - ${QuizHeaderRef?.Header.offsetHeight}px)`"
    >
      <question-details
        v-if="currentDisplayedQuestionIndex !== null"
        v-model:question="quiz.questions[currentDisplayedQuestionIndex]"
        :validate-update="() => validateUpdate()"
        @cancel="cancel"
      />
      <p-inline-message severity="info" v-else>
        Commencer l'édition d'une question pour l'afficher.
      </p-inline-message>
    </div>
  </div>
</template>

<style>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>
