import SchoolRepository from '@/core/infrastructure/repositories/school/core/SchoolRepository'
import { ModuleBase } from '@/presentation/configuration/modules/ModuleBase'
import type ApiService from '@/core/infrastructure/services/ApiService'
import FetchSchoolUseCase from '@/core/useCases/school/core/FetchSchoolUseCase'
import FetchSchoolsForMeUseCase from '@/core/useCases/school/core/FetchSchoolsForMeUseCase'
import FetchAllSchoolsUseCase from '@/core/useCases/school/core/FetchAllSchoolsUseCase'
import FetchSchoolFromDataEducationUseCase from '@/core/useCases/school/core/FetchSchoolFromDataEducationUseCase'
import CreateSchoolUseCase from '@/core/useCases/school/core/CreateSchoolUseCase'
import CreateSchoolPeriodUseCase from '@/core/useCases/school/period/CreateSchoolPeriodUseCase'
import SchoolPeriodRepository from '@/core/infrastructure/repositories/school/period/SchoolPeriodRepository'
import FetchAllSchoolPeriodsUseCase from '@/core/useCases/school/period/FetchAllSchoolPeriodsUseCase'
import SchoolPeriodUserRepository from '@/core/infrastructure/repositories/school/user/SchoolPeriodUserRepository'
import { CreateSchoolPeriodUserUseCase } from '@/core/useCases/school/user/CreateSchoolPeriodUserUseCase'
import SchoolYearRepository from '@/core/infrastructure/repositories/school/year/SchoolYearRepository'
import FetchAllSchoolYearsUseCase from '@/core/useCases/school/year/FetchAllSchoolYearsUseCase'
import SchoolClassroomRepository from '@/core/infrastructure/repositories/school/classroom/SchoolClassroomRepository'
import FetchSchoolClassroomLoginHistoryUseCase
  from '@/core/useCases/dashboard/FetchSchoolClassroomLoginHistoryUseCase'
import { FetchSchoolClassroomUseCase } from '@/core/useCases/school/classroom/FetchSchoolClassroomUseCase'
import { FetchAllSchoolClassroomsUseCase } from '@/core/useCases/school/classroom/FetchAllSchoolClassroomsUseCase'
import FetchAllSchoolPeriodUsersUseCase from '@/core/useCases/school/user/FetchAllSchoolPeriodUsersUseCase'
import { CreateSchoolClassroomUseCase } from '@/core/useCases/school/classroom/CreateSchoolClassroomUseCase'
import PrepareSchoolPeriodUserEditUseCase from '@/core/useCases/school/user/PrepareSchoolPeriodUserEditUseCase'
import DependencyContainer from '@/presentation/configuration/DependencyContainer'
import { UsersRepository } from '@/core/infrastructure/repositories/users/core/UsersRepository'
import { UpdateSchoolPeriodUserUseCase } from '@/core/useCases/school/user/UpdateSchoolPeriodUserUseCase'
import { UpdateSchoolClassroomUsersUseCase } from '@/core/useCases/school/classroom/UpdateSchoolClassroomUsersUseCase'
import { UpdateSchoolClassroomUseCase } from '@/core/useCases/school/classroom/UpdateSchoolClassroomUseCase'
import { DeleteSchoolClassroomUseCase } from '@/core/useCases/school/classroom/DeleteSchoolClassroomUseCase'
import { CreateSchoolMonitoringGroupUseCase } from '@/core/useCases/school/group/CreateSchoolMonitoringGroupUseCase'
import {
  SchoolMonitoringGroupRepository
} from '@/core/infrastructure/repositories/school/group/SchoolMonitoringGroupRepository'
import {
  UpdateSchoolMonitoringGroupUsersUseCase
} from '@/core/useCases/school/group/UpdateSchoolMonitoringGroupUsersUseCase'
import { DeleteSchoolMonitoringGroupUseCase } from '@/core/useCases/school/group/DeleteSchoolMonitoringGroupUseCase'
import { UpdateSchoolUseCase } from '@/core/useCases/school/core/UpdateSchoolUseCase'

export class SchoolModule extends ModuleBase {
  initialize(apiService: ApiService): void {
    this.initializeCore(apiService);
    this.initializePeriod(apiService);
    this.initializeYear(apiService);
    this.initializeClassroom(apiService);
    this.initializeUser(apiService);
    this.initializeMonitoringGroup(apiService);
  }

  private initializeCore(apiService: ApiService): void {
    const schoolRepository = new SchoolRepository(apiService);
    this.registerRepository(SchoolRepository, schoolRepository);

    const fetchSchoolUseCase = new FetchSchoolUseCase(schoolRepository)
    const fetchSchoolForMeUseCase = new FetchSchoolsForMeUseCase(schoolRepository)
    const fetchAllSchoolsUseCase = new FetchAllSchoolsUseCase(schoolRepository)
    const fetchSchoolFromDataEducationUseCase = new FetchSchoolFromDataEducationUseCase(schoolRepository)
    const createSchoolUseCase = new CreateSchoolUseCase(schoolRepository)
    const updateSchoolUseCase = new UpdateSchoolUseCase(schoolRepository)

    this.registerUseCase(FetchSchoolUseCase, fetchSchoolUseCase);
    this.registerUseCase(FetchSchoolsForMeUseCase, fetchSchoolForMeUseCase);
    this.registerUseCase(FetchAllSchoolsUseCase, fetchAllSchoolsUseCase);
    this.registerUseCase(FetchSchoolFromDataEducationUseCase, fetchSchoolFromDataEducationUseCase);
    this.registerUseCase(CreateSchoolUseCase, createSchoolUseCase);
    this.registerUseCase(UpdateSchoolUseCase, updateSchoolUseCase);
  }

  private initializePeriod(apiService: ApiService): void {
    const schoolPeriodRepository = new SchoolPeriodRepository(apiService);
    this.registerRepository(SchoolPeriodRepository, schoolPeriodRepository);

    const fetchAllSchoolPeriodsUseCase = new FetchAllSchoolPeriodsUseCase(schoolPeriodRepository)
    const createSchoolPeriodUseCase = new CreateSchoolPeriodUseCase(schoolPeriodRepository)

    this.registerUseCase(FetchAllSchoolPeriodsUseCase, fetchAllSchoolPeriodsUseCase);
    this.registerUseCase(CreateSchoolPeriodUseCase, createSchoolPeriodUseCase);
  }

  private initializeYear(apiService: ApiService): void {
    const schoolYearRepository = new SchoolYearRepository(apiService)
    this.registerRepository(SchoolYearRepository, schoolYearRepository)

    const fetchAllSchoolYearsUseCase = new FetchAllSchoolYearsUseCase(schoolYearRepository)
    this.registerUseCase(FetchAllSchoolYearsUseCase, fetchAllSchoolYearsUseCase)
  }

  private initializeClassroom(apiService: ApiService): void {
    const schoolClassroomRepository = new SchoolClassroomRepository(apiService)
    this.registerRepository(SchoolClassroomRepository, schoolClassroomRepository)

    const fetchSchoolClassroomLoginHistoryUseCase = new FetchSchoolClassroomLoginHistoryUseCase(schoolClassroomRepository)
    const fetchSchoolClassroomUseCase = new FetchSchoolClassroomUseCase(schoolClassroomRepository)
    const fetchAllSchoolClassroomsUseCase = new FetchAllSchoolClassroomsUseCase(schoolClassroomRepository)
    const createSchoolClassroomUseCase = new CreateSchoolClassroomUseCase(schoolClassroomRepository)
    const updateSchoolClassroomUserUseCase = new UpdateSchoolClassroomUsersUseCase(schoolClassroomRepository)
    const updateSchoolClassroomUseCase = new UpdateSchoolClassroomUseCase(schoolClassroomRepository)
    const deleteSchoolClassroomUseCase = new DeleteSchoolClassroomUseCase(schoolClassroomRepository)

    this.registerUseCase(FetchSchoolClassroomLoginHistoryUseCase, fetchSchoolClassroomLoginHistoryUseCase)
    this.registerUseCase(FetchSchoolClassroomUseCase, fetchSchoolClassroomUseCase)
    this.registerUseCase(FetchAllSchoolClassroomsUseCase, fetchAllSchoolClassroomsUseCase)
    this.registerUseCase(CreateSchoolClassroomUseCase, createSchoolClassroomUseCase)
    this.registerUseCase(UpdateSchoolClassroomUsersUseCase, updateSchoolClassroomUserUseCase)
    this.registerUseCase(UpdateSchoolClassroomUseCase, updateSchoolClassroomUseCase)
    this.registerUseCase(DeleteSchoolClassroomUseCase, deleteSchoolClassroomUseCase)
  }

  private initializeUser(apiService: ApiService): void {
    const schoolPeriodUserRepository = new SchoolPeriodUserRepository(apiService);
    const usersRepository = DependencyContainer.resolve(UsersRepository);
    this.registerRepository(SchoolPeriodUserRepository, schoolPeriodUserRepository);

    const createSchoolPeriodUserUseCase = new CreateSchoolPeriodUserUseCase(schoolPeriodUserRepository);
    const prepareSchoolPeriodUserEditUseCase = new PrepareSchoolPeriodUserEditUseCase(usersRepository);
    const fetchAllSchoolPeriodsUsersCase = new FetchAllSchoolPeriodUsersUseCase(schoolPeriodUserRepository);
    const updateSchoolPeriodUserUseCase = new UpdateSchoolPeriodUserUseCase(schoolPeriodUserRepository);

    this.registerUseCase(CreateSchoolPeriodUserUseCase, createSchoolPeriodUserUseCase);
    this.registerUseCase(PrepareSchoolPeriodUserEditUseCase, prepareSchoolPeriodUserEditUseCase);
    this.registerUseCase(FetchAllSchoolPeriodUsersUseCase, fetchAllSchoolPeriodsUsersCase);
    this.registerUseCase(UpdateSchoolPeriodUserUseCase, updateSchoolPeriodUserUseCase);
  }

  private initializeMonitoringGroup(apiService: ApiService): void {
    const schoolMonitoringGroupRepository = new SchoolMonitoringGroupRepository(apiService)

    this.registerRepository(SchoolMonitoringGroupRepository, schoolMonitoringGroupRepository)

    const createSchoolMonitoringGroupUseCase = new CreateSchoolMonitoringGroupUseCase(schoolMonitoringGroupRepository)
    const updateSchoolMonitoringGroupUsersUseCase = new UpdateSchoolMonitoringGroupUsersUseCase(schoolMonitoringGroupRepository)
    const deleteSchoolMonitoringGroupUseCase = new DeleteSchoolMonitoringGroupUseCase(schoolMonitoringGroupRepository)

    this.registerUseCase(CreateSchoolMonitoringGroupUseCase, createSchoolMonitoringGroupUseCase)
    this.registerUseCase(UpdateSchoolMonitoringGroupUsersUseCase, updateSchoolMonitoringGroupUsersUseCase)
    this.registerUseCase(DeleteSchoolMonitoringGroupUseCase, deleteSchoolMonitoringGroupUseCase)
  }
}