import { z } from 'zod'


/**
 * Schéma pour les préférences d'onboarding de l'utilisateur
 */
export const UserOnboardingPreferencesSchema = z.object({
  isGeneralCompleted: z.boolean(),
  isDiagnosticCompleted: z.boolean(),
  isModuleCompleted: z.boolean()
})

/**
 * Schéma pour les préférences utilisateur
 */
export const UserPreferencesSchema = z.object({
  onboarding: UserOnboardingPreferencesSchema
})

export type UserPreferencesEntity = z.infer<typeof UserPreferencesSchema>;

