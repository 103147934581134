<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useDialog } from 'primevue/usedialog'
import GroupDetailListUser from '@/components/dashboard/DashboardGroupDetailListUserDialog.vue'
import { useDashboardClassroomStore } from '@/stores/dashboard/dashboardClassroom'
import { default as PSkeleton } from 'primevue/skeleton'
import { default as PMessage } from 'primevue/message'

// COMPOSABLES
const dialog = useDialog()
const dashboardClassroomStore = useDashboardClassroomStore()

// STORE REFS
const {
  dashboardSelectedClassroom: classroom,
  dashboardClassroomStoreIsLoading: isLoading,
  dashboardClassroomStoreError: error
} = storeToRefs(dashboardClassroomStore)

// LOCAL STATE
const localLoading = ref(false)
const localError = ref('')

// COMPUTED
const hasError = computed(() =>
  (error.value && error.value.hasError) || localError.value !== ''
)

const errorMessage = computed(() =>
  localError.value || (error.value?.hasError ? error.value.message : '')
)

const isDataLoading = computed(() =>
  isLoading.value || localLoading.value
)

const hasData = computed(() =>
  !isDataLoading.value && !!classroom.value
)

const cards = computed(() => [
  {
    key: 'students',
    title: 'élève(s)',
    value: classroom.value?.students?.length ?? 0,
    icon: 'pi pi-user',
    action: () => openUserListDialog('students')
  },
  {
    key: 'teachers',
    title: 'enseignant(s) référent(s)',
    value: classroom.value?.teachers?.length ?? 0,
    icon: 'pi pi-user',
    action: () => openUserListDialog('teachers')
  }
])

// METHODS
/**
 * Ouvre un dialogue affichant une liste d'utilisateurs
 * @param userType Type d'utilisateurs à afficher ('students' ou 'teachers')
 */
function openUserListDialog(userType: 'students' | 'teachers') {
  try {
    let users = [];
    let header = '';

    if (userType === 'students') {
      if (!classroom.value?.students) {
        throw new Error('La liste des élèves n\'est pas disponible');
      }
      users = classroom.value.students;
      header = 'Liste des élèves';
    } else { // teachers
      if (!classroom.value?.teachers) {
        throw new Error('La liste des enseignants n\'est pas disponible');
      }
      users = classroom.value.teachers;
      header = 'Liste des enseignants référents';
    }

    dialog.open(GroupDetailListUser, {
      props: {
        header,
        modal: true,
        dismissableMask: true,
        draggable: false,
        style: 'width: 50vw',
        breakpoints: {
          '960px': '75vw',
          '640px': '90vw'
        }
      },
      data: { users }
    });
  } catch (err) {
    localError.value = err instanceof Error ? err.message : 'Erreur lors de l\'ouverture de la liste';
    console.error(`Erreur lors de l'ouverture de la liste des ${userType}:`, err);
  }
}

// LIFECYCLE
onMounted(() => {
  // Réinitialiser les erreurs locales au montage
  localError.value = '';
})
</script>

<template>
  <!-- Message d'erreur -->
  <p-message
    v-if="hasError"
    severity="error"
    class="mb-3 w-full"
  >
    {{ errorMessage }}
  </p-message>

  <!-- État de chargement -->
  <div v-if="isDataLoading" class="col-12 grid flex-wrap sm:gap-2 md:gap-4 justify-content-start max-h-min">
    <div v-for="i in 2" :key="i" class="col flex-initial p-3">
      <p-skeleton width="100%" height="60px" />
    </div>
  </div>

  <!-- État vide -->
  <div v-else-if="!hasData" class="col-12 flex justify-content-center align-items-center p-3 bg-white border-1 border-bo-brand-secondary border-round">
    <i class="pi pi-exclamation-circle mr-2 text-yellow-500"></i>
    <span>Aucune donnée disponible</span>
  </div>

  <!-- Contenu principal -->
  <div v-else class="col-12 grid flex-wrap sm:gap-2 md:gap-4 justify-content-start max-h-min">
    <div
      v-for="card in cards"
      :key="`card_${card.key}`"
      class="col flex-initial align-items-start cursor-pointer p-3 bg-white border-1 border-bo-brand-secondary border-round"
      @click="card.action"
    >
      <div>
        <i class="font-bold text-lg" :class="card.icon" />
        <span class="ml-2 font-bold text-lg">{{ card.value }}</span>
      </div>
      <div>
        <span class="text-base">{{ card.title }}</span>
        <i class="pi pi-arrow-right text-primary ml-2 vertical-align-middle" />
      </div>
    </div>
  </div>
</template>