import { LearningQuiz } from '@/core/domain/learning/quiz/LearningQuiz'
import { LearningStatus } from '@/core/domain/resources/enums/LearningStatus'
import { createLearningSupport, type LearningSupport } from '@/core/domain/learning/support/LearningSupport'
import type {
  LearningGrainEntity,
  LearningGrainInputEntity
} from '@/core/infrastructure/schemas/learning/grain/LearningGrainSchema'

export class LearningGrain {
  id: number
  title: string
  status: LearningStatus.Values
  points: number
  order: number
  quizPre?: LearningQuiz | null
  quizPost?: LearningQuiz | null
  learningSupports: LearningSupport[]

  constructor(grain: LearningGrainEntity) {
    this.id = grain.id
    this.title = grain.title
    this.status = grain.status
    this.points = grain.points
    this.order = grain.order

    // Conversion des quiz s'ils existent
    if (grain.quizPre) {
      this.quizPre = new LearningQuiz(grain.quizPre)
    } else {
      this.quizPre = null
    }

    if (grain.quizPost) {
      this.quizPost = new LearningQuiz(grain.quizPost)
    } else {
      this.quizPost = null
    }

    // Conversion des supports
    this.learningSupports = grain.learningSupports.map(support =>
      createLearningSupport(support)
    )
  }

  // Retourne le nombre de supports du grain
  supportsCount(): number {
    return this.learningSupports.length
  }

  // Vérifie si les quiz sont valides
  quizzesAreValid(): boolean {
    const preQuizValid = this.quizPre ? this.quizPre.isValid() : true
    const postQuizValid = this.quizPost ? this.quizPost.isValid() : true
    return preQuizValid && postQuizValid
  }

  // Obtenir les supports triés par ordre
  getSortedSupports(): LearningSupport[] {
    return [...this.learningSupports].sort((a, b) => a.order - b.order)
  }

  // Convertir vers le format d'API pour la mise à jour
  toApiFormat(): Record<string, any> {
    return {
      id: this.id,
      title: this.title,
      status: this.status,
      points: this.points,
      order: this.order,
      learningSupports: this.learningSupports.map(support => support.toApiFormat())
    }
  }

  // Créer un objet de grain pour l'entrée API
  toInputFormat(): LearningGrainInputEntity {
    return {
      id: this.id,
      title: this.title,
      status: this.status,
      points: this.points,
      order: this.order,
      learningSupports: this.learningSupports.map(support => support.toApiFormat())
    }
  }
}