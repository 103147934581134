import type { IDashboardRepository } from '@/core/domain/dashboard/IDashboardRepository'
import type ApiService from '@/core/infrastructure/services/ApiService'
import { DashboardScoreModule } from '@/core/domain/dashboard/DashboardScoreModule'
import {
  DashboardScoreModuleListSchema
} from '@/core/infrastructure/schemas/dashboard/DashboardScoreModuleSchema'
import { DashboardScoreDiagnostic } from '@/core/domain/dashboard/DashboardScoreDiagnostic'
import {
  DashboardScoreDiagnosticListSchema
} from '@/core/infrastructure/schemas/dashboard/DashboardScoreDiagnosticSchema'

export class DashboardRepository implements IDashboardRepository {
  apiService: ApiService

  constructor(apiService: ApiService) {
    this.apiService = apiService
  }

  async fetchDashboardScoreModuleResults(
    schoolId: number,
    schoolPeriodId: number,
    classroomId: number,
    taskId: number
  ): Promise<DashboardScoreModule[]> {
    const url = `/admin/schools/${schoolId}/periods/${schoolPeriodId}/classrooms/${classroomId}/tasks/${taskId}/module`
    const response = await this.apiService.get<DashboardScoreModule[]>(url)

    const parse = DashboardScoreModuleListSchema.safeParse(response)

    if (parse.success) {
      return parse.data.map((item) => new DashboardScoreModule(item))
    }

    return Promise.reject('Parsing error :' + parse.error)
  }

  /**
   * Récupère les scores de module des élèves d'une classe
   */
  async fetchDashboardScoreDiagnosticResults(
    schoolId: number,
    schoolPeriodId: number,
    classroomId: number,
    courseId: number
  ): Promise<DashboardScoreDiagnostic[]> {
    const url = `/admin/schools/${schoolId}/periods/${schoolPeriodId}/classrooms/${classroomId}/courses/${courseId}/diagnostic`
    const result = await this.apiService.get<DashboardScoreDiagnostic[]>(url)


    const parse = DashboardScoreDiagnosticListSchema.safeParse(result)

    if (parse.success) {
      return parse.data.map((item) => new DashboardScoreDiagnostic(item))
    }

    return Promise.reject('Parsing error :' + parse.error)
  }
}
