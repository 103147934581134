import type { ISchoolPeriodUserRepository } from '@/core/domain/school/user/ISchoolPeriodUserRepository'
import type { SchoolPeriodUserInput } from '@/core/domain/school/user/SchoolPeriodUser'

export class CreateSchoolPeriodUserUseCase {
  private schoolPeriodUserRepository: ISchoolPeriodUserRepository;
  constructor(schoolPeriodUserRepository: ISchoolPeriodUserRepository) {
    this.schoolPeriodUserRepository = schoolPeriodUserRepository;
  }

  async execute(schoolId: number, schoolPeriodId: number, userInput: SchoolPeriodUserInput) {
    return await this.schoolPeriodUserRepository.createSchoolPeriodUser(schoolId, schoolPeriodId, userInput);
  }
}