import type ApiService from '@/core/infrastructure/services/ApiService'
import SchoolMonitoringGroup, { SchoolMonitoringGroupInput } from '@/core/domain/school/group/SchoolMonitoringGroup'
import { SchoolMonitoringGroupSchema } from '@/core/infrastructure/schemas/school/group/SchoolMonitoringGroupSchema'
import type { ISchoolMonitoringGroupRepository } from '@/core/domain/school/group/ISchoolMonitoringGroupRepository'

export class SchoolMonitoringGroupRepository implements ISchoolMonitoringGroupRepository {
  private apiService: ApiService

  /**
   * Constructeur du repository
   * @param apiService - Service d'API injecté pour effectuer les appels HTTP
   */
  constructor(apiService: ApiService) {
    this.apiService = apiService
  }

  /**
   * Récupère un groupe de suivi spécifique depuis l'API
   * @param schoolId - Identifiant de l'école
   * @param schoolPeriodId - Identifiant de la période scolaire
   * @param schoolClassroomId - Identifiant de la classe
   * @param schoolMonitoringGroupId - Identifiant du groupe à récupérer
   * @returns Une promesse contenant l'objet SchoolMonitoringGroup
   * @throws Erreur si la validation des données échoue
   */
  async fetchSchoolMonitoringGroup(schoolId: number, schoolPeriodId: number, schoolClassroomId: number, schoolMonitoringGroupId: number) {
    const response = await this.apiService.get<SchoolMonitoringGroup>(
      `admin/schools/${schoolId}/periods/${schoolPeriodId}/classrooms/${schoolClassroomId}/monitoring-groups/${schoolMonitoringGroupId}`
    )

    const parse = SchoolMonitoringGroupSchema.safeParse(response)

    if (parse.success) {
      return new SchoolMonitoringGroup(parse.data)
    }

    return Promise.reject('Parsing error :' + parse.error)
  }

  /**
   * Crée un nouveau groupe de suivi
   * @param schoolId - Identifiant de l'école
   * @param schoolPeriodId - Identifiant de la période scolaire
   * @param schoolClassroomId - Identifiant de la classe
   * @param schoolMonitoringGroup - Objet contenant les données du groupe à créer
   * @returns Une promesse contenant l'objet SchoolMonitoringGroup créé (avec son ID)
   * @throws Erreur si la validation des données échoue
   */
  async createSchoolMonitoringGroup(schoolId: number, schoolPeriodId: number, schoolClassroomId: number) {
    const response = await this.apiService.post<SchoolMonitoringGroup>(
      `admin/schools/${schoolId}/periods/${schoolPeriodId}/classrooms/${schoolClassroomId}/monitoring-groups`
    )

    const parse = SchoolMonitoringGroupSchema.safeParse(response)

    if (parse.success) {
      return new SchoolMonitoringGroup(parse.data)
    }

    return Promise.reject('Parsing error :' + parse.error)
  }

  /**
   * Met à jour un groupe de suivi existant
   * @param schoolId - Identifiant de l'école
   * @param schoolPeriodId - Identifiant de la période scolaire
   * @param schoolClassroomId - Identifiant de la classe
   * @param schoolMonitoringGroupId - Identifiant du groupe à mettre à jour
   * @param schoolMonitoringGroup - Objet contenant les données mises à jour du groupe
   * @returns Une promesse contenant l'objet SchoolMonitoringGroup mis à jour
   * @throws Erreur si la validation des données échoue
   */
  async updateSchoolMonitoringGroupUsers(schoolId: number, schoolPeriodId: number, schoolClassroomId: number, schoolMonitoringGroupId: number, schoolMonitoringGroup: SchoolMonitoringGroupInput) {
    const response = await this.apiService.put<SchoolMonitoringGroup>(
      `admin/schools/${schoolId}/periods/${schoolPeriodId}/classrooms/${schoolClassroomId}/monitoring-groups/${schoolMonitoringGroupId}/users`,
      schoolMonitoringGroup
    )

    const parse = SchoolMonitoringGroupSchema.safeParse(response)

    if (parse.success) {
      return new SchoolMonitoringGroup(parse.data)
    }

    return Promise.reject('Parsing error :' + parse.error)
  }

  /**
   * Supprime un groupe de suivi
   * @param schoolId - Identifiant de l'école
   * @param schoolPeriodId - Identifiant de la période scolaire
   * @param schoolClassroomId - Identifiant de la classe
   * @param groupId - Identifiant du groupe à supprimer
   * @returns Une promesse vide en cas de succès
   */
  async deleteSchoolMonitoringGroup(schoolId: number, schoolPeriodId: number, schoolClassroomId: number,groupId: number) {
    await this.apiService.delete(
      `admin/schools/${schoolId}/periods/${schoolPeriodId}/classrooms/${schoolClassroomId}/monitoring-groups/${groupId}`
    )
  }
}