<script setup lang="ts">
import type { Props } from '@/components/templates/DefaultTemplateProps'
import LateralBar from '@/components/navigation/lateralBar/LateralBar.vue'

const localProps = defineProps<Props>()
</script>

<template>
  <div class="_la-DefaultTemplate flex h-full">
    <div class='' v-if="localProps.withSidebar">
      <lateral-bar/>
    </div>

    <div class="flex-1 overflow-hidden">
      <div class="_la-DefaultTemplate-Content flex flex-column">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
._la-DefaultTemplate {
  max-height: 100vh;
  overflow: hidden;

  ._la-DefaultTemplate-Content {
    height: 100%;
    overflow: hidden;
  }

  &.no-header {
    ._la-DefaultTemplate-Content {
      height: 100vh;
    }
  }
}
</style>
