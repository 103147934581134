import { defineStore } from 'pinia'
import type {
  ILearningModule,
  ILearningModuleInput
} from '@/assets/types/learning/LearningModuleTypes'
import type { Ref } from 'vue'
import { ref } from 'vue'
import nabooApi from '@/services/nabooApi'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import { NabooError } from '@/assets/classes/Error'
import { LearningEnums } from '@/assets/types/learning/enums'
import { LearningModuleResponseDto } from '@/assets/DTO/learning/learningModule.response.dto'
import { useLearningGrainsStore } from '@/stores/learning/learningGrains'
import { LearningGrainResponseDto } from '@/assets/DTO/learning/learningGrain.response.dto'
import { useLearningCoursesStore } from '@/stores/learning/learningCourses'

export const useModulesStore = defineStore('modules', () => {
  // UTILITY FUNCTIONS
  const toast = useToast()
  const router = useRouter()
  const grainsStore = useLearningGrainsStore()
  const coursesStore = useLearningCoursesStore()

  // STATE
  const modulesList: Ref<LearningModuleResponseDto[]> = ref([])

  //MUTATIONS
  function addModuleToList(module: LearningModuleResponseDto) {
    modulesList.value.push(module)
  }

  function setModules(modules: ILearningModule[]) {
    modulesList.value = modules.map((module) => new LearningModuleResponseDto(module))
  }

  function updateModuleData(module: LearningModuleResponseDto) {
    const index = modulesList.value.findIndex((m) => m.id === module.id)

    module.learningGrains = module.learningGrains.filter(
      (grain) => grain.status !== (LearningEnums.Status.ARCHIVED || LearningEnums.Status.DELETED)
    )

    if (index !== -1) {
      modulesList.value[index] = module
    } else {
      addModuleToList(module)
    }
  }

  // ACTIONS
  async function createModule(module: ILearningModuleInput, learningCourseId: number) {
    try {
      const learningModule = await nabooApi.createModule(module, learningCourseId)

      addModuleToList(learningModule)
      coursesStore.addModuleToCourse(learningModule)

      await router.push({
        name: 'learning-course-update-module',
        params: { id: learningCourseId, moduleId: learningModule.id }
      })

      toast.add({
        severity: 'success',
        summary: 'Module créé',
        detail: `Le module ${module.title} a été créé avec succès`,
        life: 3000
      })

      return Promise.resolve(learningModule)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningModules.ts - createModule')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue après la création du module.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: nabooError.name,
        detail: nabooError.message,
        life: 3000
      })

      return Promise.reject(nabooError)
    }
  }

  async function fetchModuleById(moduleId: number, learningCourseId: number) {
    try {
      const module = await nabooApi.getModuleById(moduleId, learningCourseId)

      updateModuleData(module)
      grainsStore.addGrains(
        module.learningGrains.map((grain) => new LearningGrainResponseDto(grain))
      )

      return module
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningModules.ts - getModuleById')
        error.setMessage('Une erreur est survenue lors de la récupération du module.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la récupération du module.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: nabooError.name,
        detail: nabooError.message,
        life: 3000
      })

      return Promise.reject(nabooError)
    }
  }

  async function updateModule(
    module: ILearningModuleInput,
    learningModuleId: number,
    learningCourseId: number
  ) {
    try {
      const response = await nabooApi.updateModule(module, learningModuleId, learningCourseId)
      updateModuleData(response)
      coursesStore.updateModuleInCourse(response)

      toast.add({
        severity: 'success',
        summary: 'Succès',
        detail: 'Le module a été mis à jour avec succès.',
        life: 3000
      })

      return Promise.resolve(response)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningModules.ts - updateModule')
        error.setMessage('Une erreur est survenue lors de la mise à jour du module.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la mise à jour du module.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: nabooError.name,
        detail: nabooError.message,
        life: 3000
      })

      return Promise.reject(nabooError)
    }
  }

  async function updateModuleStatus(
    status: LearningEnums.Status,
    learningModuleId: number,
    learningCouresId: number
  ) {
    const module = getModuleById(learningModuleId)

    if (!module) {
      const nabooError = new NabooError({
        message: "Le module n'a pas été trouvé",
        status: 0,
        name: 'Erreur',
        code: 'ERR_UNKNOWN',
        error: new Error('Module not found')
      })

      toast.add({
        severity: 'error',
        summary: nabooError.name,
        detail: nabooError.message,
        life: 3000
      })

      return Promise.reject(nabooError)
    }

    if (module.activeGrains().length === 0 && status === LearningEnums.Status.ACTIVE) {
      const nabooError = new NabooError({
        message: 'Le module doit avoir au moins un grain actif pour être activé.',
        status: 0,
        name: 'Erreur',
        code: 'ERR_UNKNOWN',
        error: new Error('No active grains in module')
      })

      toast.add({
        severity: 'error',
        summary: nabooError.name,
        detail: nabooError.message,
        life: 3000
      })

      return Promise.reject(nabooError)
    }

    try {
      const response = await nabooApi.updateModuleStatus(status, learningModuleId, learningCouresId)

      updateModuleData(response)
      coursesStore.updateModuleInCourse(response)

      toast.add({
        severity: 'success',
        summary: 'Succès',
        detail: 'Le statut du module a été mis à jour avec succès.',
        life: 3000
      })

      return Promise.resolve(response)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/learning/learningModules.ts - updateStatus')
        error.setMessage('Une erreur est survenue lors de la mise à jour du statut du module.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la mise à jour du statut du module.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: nabooError.name,
        detail: nabooError.message,
        life: 3000
      })

      return Promise.reject(nabooError)
    }
  }

  function getModuleById(moduleId: number) {
    return modulesList.value.find((module) => module.id === moduleId)
  }

  // GETTERS (COMPUTED)

  return {
    modulesList,
    setModules,
    createModule,
    updateModule,
    fetchModuleById,
    updateModuleStatus,
    getModuleById
  }
})
