import ApiService from '@/core/infrastructure/services/ApiService'
import { SchoolModule } from '@/presentation/configuration/modules/SchoolModule'
import { LearningModule } from '@/presentation/configuration/modules/LearningModule'
import { UsersModule } from '@/presentation/configuration/modules/UsersModule'
import { DashboardModule } from '@/presentation/configuration/modules/DashboardModule'


export default class Bootloader {
  public static initialize() {
    console.log('Bootloader initialized')

    // Register services
    const apiService = new ApiService()

    const usersModule = new UsersModule()
    usersModule.initialize(apiService)

    // Register school repositories and use cases
    const schoolModule = new SchoolModule()
    schoolModule.initialize(apiService)

    // Register learning repositories and use cases
    const leaningModule = new LearningModule()
    leaningModule.initialize(apiService)

    // Register dashboard repositories and use cases
    const dashboardModule = new DashboardModule()
    dashboardModule.initialize(apiService)

  }
}
