import { z } from 'zod';

export const SchoolYearSchema = z.object({
  schoolYearId: z.number(),
  startDate: z.string(),
  endDate: z.string(),
  name: z.string(),
  isCurrent: z.boolean(),
});

export const SchoolYearListSchema = z.array(SchoolYearSchema);

export type SchoolYearEntity = z.infer<typeof SchoolYearSchema>;
export type SchoolYearListEntity = z.infer<typeof SchoolYearListSchema>;

