import { z } from 'zod'
import { LearningSupportType } from '@/core/domain/resources/enums/LearningSupport'

const BaseLearningSupportSchema = z.object({
  id: z.number(),
  type: z.nativeEnum(LearningSupportType.Values),
  order: z.number(),
  estimatedTime: z.number(),
})

// Schema pour le support de type GENIALLY
const GeniallyLearningSupportSchema = BaseLearningSupportSchema.extend({
  type: z.literal(LearningSupportType.Values.GENIALLY),
  url: z.string(),
})

export const LearningSupportSchema = z.discriminatedUnion('type', [
  GeniallyLearningSupportSchema,
])

// Liste de supports
export const LearningSupportListSchema = z.array(LearningSupportSchema)

// Types dérivés des schemas
export type LearningSupportEntity = z.infer<typeof LearningSupportSchema>
export type LearningSupportListEntity = z.infer<typeof LearningSupportListSchema>

export type GeniallyLearningSupportEntity = z.infer<typeof GeniallyLearningSupportSchema>

// Type guards pour les différents types de supports
export const isGeniallySupport = (support: LearningSupportEntity): support is GeniallyLearningSupportEntity => {
  return support.type === LearningSupportType.Values.GENIALLY
}