<script lang='ts' setup>
import { computed, onMounted, type Ref, ref, watch, watchEffect } from 'vue'
import { storeToRefs } from 'pinia'
import { useSchoolStore } from '@/stores/school/school'
import BaseHeader from '@/components/base/BaseHeader.vue'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import type SchoolPeriod from '@/core/domain/school/period/SchoolPeriod'
import { default as PDropdown } from 'primevue/dropdown'
import { default as PTag } from 'primevue/tag'
import { default as PProgressSpinner } from 'primevue/progressspinner'
import { default as PMessage } from 'primevue/message'
import { default as PInlineMessage } from 'primevue/inlinemessage'
import { useSchoolPeriodStore } from '@/stores/school/schoolPeriod'
import type SchoolYear from '@/core/domain/school/year/SchoolYear'
import { DateTime } from 'luxon'

// Types
type SchoolPeriodOption = {
  label: string
  value: SchoolPeriod
}

// Composables
const route = useRoute()
const schoolStore = useSchoolStore()
const schoolPeriodStore = useSchoolPeriodStore()

// Store refs
const { schoolStoreLoading, schoolStoreError } = storeToRefs(schoolStore)
const {
  schoolPeriods,
  selectedSchoolPeriod,
  schoolPeriodStoreLoading,
  schoolPeriodStoreError,
  sortedSchoolPeriods
} = storeToRefs(schoolPeriodStore)

// Refs
const componentLoading: Ref<boolean> = ref(true)
const schoolsLoaded: Ref<boolean> = ref(false)
const periodsLoaded: Ref<boolean> = ref(false)
const periodsAttempted: Ref<boolean> = ref(false)  // Pour suivre si nous avons tenté de charger les périodes
const localError: Ref<string | null> = ref(null)
const currentSchoolId: Ref<number | null> = ref(null)
const currentSchoolSlug: Ref<string | null> = ref(null) // Pour suivre le slug de l'école actuelle

// Methods
const getYearStatus = (schoolYear: SchoolYear) => {
  const now = DateTime.now()
  const startDate = DateTime.fromISO(schoolYear.startDate)
  const endDate = DateTime.fromISO(schoolYear.endDate)

  if (now < startDate) {
    return 'A venir'
  }

  if (now > endDate) {
    return 'Terminée'
  }

  return 'Active'
}

const onSchoolPeriodChange = (schoolPeriod: SchoolPeriod) => {
  schoolPeriodStore.selectSchoolPeriod(schoolPeriod)
}

/**
 * Charge les données des écoles et des périodes
 */
const loadData = async (forceRefresh = false) => {
  try {
    componentLoading.value = true
    localError.value = null

    // Charger les écoles si nécessaire
    if (!schoolsLoaded.value || forceRefresh) {
      await loadSchools()
    }

    // Charger les périodes si nous sommes sur une page d'école
    if (route.name === 'school' && route.params.schoolSlug) {
      // Si l'école a changé ou si c'est notre première tentative
      if (currentSchoolSlug.value !== route.params.schoolSlug || !periodsAttempted.value || forceRefresh) {
        await loadPeriodsForCurrentSchool(forceRefresh)
        periodsAttempted.value = true
        currentSchoolSlug.value = route.params.schoolSlug as string
      }
    }

  } catch (err) {
    console.error('Erreur lors du chargement des données:', err)
    localError.value = err instanceof Error
      ? err.message
      : 'Une erreur est survenue lors du chargement des données'
  } finally {
    componentLoading.value = false
  }
}

/**
 * Charge la liste des écoles
 */
const loadSchools = async () => {
  try {
    // Vérifier si les écoles doivent être rechargées
    if (schoolStore.allSchoolCount === 0 ||
      !schoolStore.schoolStoreLastUpdated) {
      await schoolStore.fetchAllSchools()
    }

    schoolsLoaded.value = true
  } catch (err) {
    console.error('Erreur lors du chargement des écoles:', err)
    throw err
  }
}

/**
 * Charge les périodes pour l'école courante
 */
const loadPeriodsForCurrentSchool = async (forceRefresh = false) => {
  try {
    if (!route.params.schoolSlug) return

    // Récupérer l'école actuelle
    const school = schoolStore.getSchoolBySlug(route.params.schoolSlug as string)
    if (!school) {
      throw new Error(`École avec le slug ${route.params.schoolSlug} non trouvée`)
    }

    // Si nous n'avons jamais chargé cette école ou si l'école a changé
    const isNewSchool = currentSchoolId.value !== school.id

    // Mettre à jour l'ID de l'école courante
    currentSchoolId.value = school.id

    // Chargement des périodes si nécessaire
    if (isNewSchool || !periodsLoaded.value || forceRefresh || schoolPeriodStore.needsFreshData) {

      // Reset du store avant le chargement pour une nouvelle école
      if (isNewSchool) {
        schoolPeriodStore.reset()
      }

      await schoolPeriodStore.fetchAllSchoolPeriodsForSchool(school.id, forceRefresh)

      // Marquer comme chargé même si le résultat est vide
      periodsLoaded.value = true
    }
  } catch (err) {
    console.error('Erreur lors du chargement des périodes scolaires:', err)
    throw err
  }
}

// Computed
const loading = computed(() => {
  return componentLoading.value ||
    schoolStoreLoading.value.fetchAll ||
    schoolPeriodStoreLoading.value.fetchAll
})

const hasError = computed(() => {
  return localError.value !== null ||
    schoolStoreError.value.hasError ||
    (schoolPeriodStoreError?.value.hasError ?? false)
})

const errorMessage = computed(() => {
  return localError.value ||
    schoolStoreError.value.message ||
    (schoolPeriodStoreError?.value.message ?? '')
})

const displayBreadcrumbs = computed(() => {
  if (typeof route.meta.showBreadCrumbs === 'boolean')
    return route.meta.showBreadCrumbs
  return false
})

type Breadcrumb = {
  name: string
  link: boolean
  title: string
  icon: string
}

const breadcrumbs = computed<Breadcrumb[]>(() => {
  return [
    {
      name: 'schools-list',
      link: true,
      title: 'Liste des établissements',
      icon: 'pi pi-home'
    },
    {
      name: route.name as string,
      link: false,
      title: `${routeTitle.value}`,
      icon: 'pi pi-chevron-right'
    },
  ]
})

const routeTitle = computed(() => {
  if (route.name === 'school') {
    const school = schoolStore.getSchoolBySlug(route.params.schoolSlug as string)
    if (school)
      return `${school.name} (${school.zipCode} ${school.city})`
  }

  if (route.name === 'schools-create')
    return 'Créer un établissement'

  return 'Établissements'
})

const displayTitle = computed(() => {
  if (route.name === 'school') {
    const school = schoolStore.getSchoolBySlug(route.params.schoolSlug as string)
    if (school)
      return `${school.name} (${school.zipCode} ${school.city})`
    return 'Chargement de l\'établissement...'
  }

  if (route.name === 'schools-create')
    return 'Créer un établissement'

  return 'Établissements'
})

const displaySchoolYearDropdown = computed(() => {
  // Display the dropdown only if the user is on the school page
  return route.name === 'school'
})

const schoolPeriodsOptions = computed(() => {
  return sortedSchoolPeriods.value.map((schoolPeriod) => {
    return {
      label: schoolPeriod.schoolYear.name,
      value: schoolPeriod
    }
  })
})

const hasPeriods = computed(() => {
  return schoolPeriods.value.length > 0
})

// LIFECYCLE
onMounted(async () => {
  try {
    await loadData(false)
  } catch (err) {
    console.error("Erreur lors du chargement initial:", err)
  }
})

// Route Guards
onBeforeRouteUpdate(async (to, from, next) => {
  // Si nous changeons de page d'école (slug différent) ou type de page
  if (to.name !== from.name ||
    (to.name === 'school' && from.name === 'school' &&
      to.params.schoolSlug !== from.params.schoolSlug)) {
    componentLoading.value = true

    // Réinitialiser l'état des périodes si nous changeons d'école
    if (to.name === 'school' && from.name === 'school' &&
      to.params.schoolSlug !== from.params.schoolSlug) {
      periodsLoaded.value = false
      periodsAttempted.value = false
      // Ajouté: forcer le rechargement des données pour la nouvelle école
      currentSchoolSlug.value = null
    }
  }

  next()

  // Charger les données après la navigation
  if (to.name === 'school') {
    try {
      if (to.params.schoolSlug !== currentSchoolSlug.value || !periodsAttempted.value) {
        await loadPeriodsForCurrentSchool(true) // Force refresh pour être sûr
        periodsAttempted.value = true
        currentSchoolSlug.value = to.params.schoolSlug as string
      }
    } catch (err) {
      console.error("Erreur lors du chargement des périodes après navigation:", err)
      localError.value = "Erreur lors du chargement des données de l'établissement"
    }
  }

  componentLoading.value = false
})

// Surveiller explicitement le changement d'école
watch(() => route.params.schoolSlug, async (newSlug, oldSlug) => {
  if (newSlug && newSlug !== oldSlug && route.name === 'school') {
    periodsLoaded.value = false
    periodsAttempted.value = false
    currentSchoolSlug.value = null
    await loadPeriodsForCurrentSchool(true)
    periodsAttempted.value = true
    currentSchoolSlug.value = newSlug as string
  }
}, { immediate: true })

// Surveiller les changements de route pour mettre à jour l'interface
watchEffect(() => {
  // Réinitialiser les états si nécessaire quand la route change radicalement
  if (route.name === 'schools-list' && !schoolsLoaded.value) {
    loadSchools()
  }
})
</script>

<template>
  <base-header
    :title='displayTitle'
    :display-breadcrumbs='displayBreadcrumbs'
    :breadcrumbs='breadcrumbs'
    :has-widget='displaySchoolYearDropdown'
  >
    <template #widgets>
      <!-- Widget de sélection de période scolaire -->
      <template v-if="loading && displaySchoolYearDropdown">
        <div class="flex align-items-center gap-2">
          <p-progress-spinner style="width: 20px; height: 20px;" strokeWidth="4" />
          <span>Chargement des périodes...</span>
        </div>
      </template>

      <template v-else-if="displaySchoolYearDropdown">
        <h5 class='font-semibold'>Période scolaire</h5>
        <p-dropdown
          :options='schoolPeriodsOptions'
          :model-value='selectedSchoolPeriod'
          @update:model-value='(option: SchoolPeriodOption) => onSchoolPeriodChange(option.value)'
          v-if='hasPeriods'
          class="min-w-min w-full md:w-15rem"
          placeholder="Sélectionner une période"
        >
          <!--  Value template-->
          <template #value="slotProps : { value: SchoolPeriod }">
            <div v-if="slotProps.value" class="flex align-items-center gap-2">
              <div>{{ slotProps.value.schoolYear.name }}</div>
              <p-tag
                class='align-self-start'
                :value='getYearStatus(slotProps.value.schoolYear)'
                :severity='getYearStatus(slotProps.value.schoolYear) === "Active" ? "success" : "info"'
              />
            </div>
            <span v-else>Sélectionner une période</span>
          </template>

          <!-- Option template-->
          <template #option="slotProps : {option : SchoolPeriodOption}">
            <div class="flex align-items-center gap-2">
              <div>{{ slotProps.option.label }}</div>
              <p-tag
                class='align-self-start'
                :value='getYearStatus(slotProps.option.value.schoolYear)'
                :severity='getYearStatus(slotProps.option.value.schoolYear) === "Active" ? "success" : "info"'
              />
            </div>
          </template>
        </p-dropdown>
        <p-inline-message
          v-if="!hasPeriods"
          severity="info"
          class="w-full mt-2 "
          :closable="false"
        >
          Aucune période n'est associée.
        </p-inline-message>
      </template>
    </template>
  </base-header>

  <!-- Contenu principal -->
  <div class="col-12 p-5 pt-2 bg-bo-brand-tertiary flex-1 overflow-y-auto gap-5 flex flex-column">
    <!-- Affichage des erreurs -->
    <p-message v-if="hasError" severity="error" :closable="false" class="mb-3">
      {{ errorMessage }}
    </p-message>

    <!-- État de chargement principal -->
    <div v-if="loading && !hasError" class="flex flex-column align-items-center justify-content-center p-5">
      <p-progress-spinner style="width: 50px; height: 50px;" strokeWidth="5" />
      <p class="mt-3 text-lg">Chargement des données...</p>
    </div>

    <!-- Contenu de la page -->
    <router-view v-else :loading='loading' />
  </div>
</template>

<style scoped>
/* Animations pour les transitions de chargement */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Ajustements responsifs */
@media screen and (max-width: 768px) {
  :deep(.p-dropdown) {
    width: 100% !important;
  }
}
</style>