export namespace UserGender {
  export enum Values {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER',
    UNSPECIFIED = 'UNSPECIFIED'
  }

  export function label(gender: Values) {
    switch (gender) {
      case Values.MALE:
        return 'Masculin'
      case Values.FEMALE:
        return 'Féminin'
      case Values.OTHER:
        return 'Autre'
      case Values.UNSPECIFIED:
        return 'Non spécifié'
      default:
        return 'Non défini'
    }
  }
}