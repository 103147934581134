import type {
  DashboardScoreDiagnosticEntity
} from '@/core/infrastructure/schemas/dashboard/DashboardScoreDiagnosticSchema'
import { DashboardScoreDiagnosticTask } from '@/core/domain/dashboard/DashboardScoreDiagnosticTask'


export class DashboardScoreDiagnostic {
  readonly identifier: string
  readonly firstname: string
  readonly lastname: string
  readonly periodId: number
  readonly schoolYearName: string
  readonly classroomId: number
  readonly groupId: number
  readonly groupName: string
  readonly teacherId: number
  readonly teacherFirstname: string
  readonly teacherLastname: string
  readonly tasks: DashboardScoreDiagnosticTask[]

  constructor(scoreDiagnostic: DashboardScoreDiagnosticEntity) {
    this.identifier = scoreDiagnostic.identifier
    this.firstname = scoreDiagnostic.firstname
    this.lastname = scoreDiagnostic.lastname
    this.periodId = scoreDiagnostic.periodId
    this.schoolYearName = scoreDiagnostic.schoolYearName
    this.classroomId = scoreDiagnostic.classroomId
    this.groupId = scoreDiagnostic.groupId
    this.groupName = scoreDiagnostic.groupName
    this.teacherId = scoreDiagnostic.teacherId
    this.teacherFirstname = scoreDiagnostic.teacherFirstname
    this.teacherLastname = scoreDiagnostic.teacherLastname
    this.tasks = scoreDiagnostic.tasks.map((task) => new DashboardScoreDiagnosticTask(task))
  }
}