import type ApiService from '@/core/infrastructure/services/ApiService'
import type { ISchoolPeriodUserRepository } from '@/core/domain/school/user/ISchoolPeriodUserRepository'
import { SchoolPeriodUser, SchoolPeriodUserInput } from '@/core/domain/school/user/SchoolPeriodUser'
import {
  SchoolPeriodUserListSchema,
  SchoolPeriodUserSchema
} from '@/core/infrastructure/schemas/school/user/SchoolPeriodUserSchema'

export default class SchoolPeriodUserRepository implements ISchoolPeriodUserRepository {
  private apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  async fetchSchoolPeriodUser(schoolId: number, schoolPeriodId: number, userIdentifier: string) {
    const response = await this.apiService.get(
      `admin/schools/${schoolId}/periods/${schoolPeriodId}/users/${userIdentifier}`
    );

    const parse = SchoolPeriodUserSchema.safeParse(response);

    if (parse.success) {
      return new SchoolPeriodUser(parse.data);
    }

    return Promise.reject('Parsing error: ' + parse.error);
  }

  async fetchAllSchoolPeriodUsers(schoolId: number, schoolPeriodId: number) {
    const response = await this.apiService.get<SchoolPeriodUser[]>(`admin/schools/${schoolId}/periods/${schoolPeriodId}/users/`);

    const parse = SchoolPeriodUserListSchema.safeParse(response);

    if (parse.success) {
      return parse.data.map((schoolPeriodUser) => new SchoolPeriodUser(schoolPeriodUser));
    }

    return Promise.reject('Parsing error' + parse.error);
  }

  async createSchoolPeriodUser(schoolId: number, schoolPeriodId: number, userInput: SchoolPeriodUserInput) {
    const response = await this.apiService.post<SchoolPeriodUser>(`admin/schools/${schoolId}/periods/${schoolPeriodId}/users/`, userInput);

    const parse = SchoolPeriodUserSchema.safeParse(response);

    if (parse.success) {
      return new SchoolPeriodUser(parse.data);
    }

    return Promise.reject('Parsing error' + parse.error);
  }

  async updateSchoolPeriodUser(schoolId: number, schoolPeriodId: number, userInput: SchoolPeriodUserInput) {
    const response = await this.apiService.put<SchoolPeriodUser>(`admin/schools/${schoolId}/periods/${schoolPeriodId}/users/${userInput.userIdentifier}`, userInput);

    const parse = SchoolPeriodUserSchema.safeParse(response);

    if (parse.success) {
      return new SchoolPeriodUser(parse.data);
    }

    return Promise.reject('Parsing error' + parse.error);
  }
}