<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import type { SchoolMe } from '@/core/domain/school/core/School'
import type SchoolClassroom from '@/core/domain/school/classroom/SchoolClassroom'
import { computed } from 'vue'
import BaseCard from '@/components/base/BaseCard.vue'
import { useRouter } from 'vue-router'
import { useDashboardStore } from '@/stores/dashboard/dashboard'
import { useDashboardClassroomStore } from '@/stores/dashboard/dashboardClassroom'

// COMPOSABLES & SEVICES
const router = useRouter()

// PROPS
const props = defineProps<{
  school: SchoolMe
  classroom: SchoolClassroom | null
}>()

// STORE ACTIONS
const { setDashboardSelectedSchool } = useDashboardStore()
const { setDashboardSelectedClassroom } = useDashboardClassroomStore()

// ACTIONS
const showClassroom = () => {
  if (!props.school || !props.classroom) return

  // Set selected school and classroom
  setDashboardSelectedSchool(props.school)
  setDashboardSelectedClassroom(props.classroom)

  // Redirect to school page
  router.push({
    name: 'dashboard-group',
    params: {
      schoolId: props.school.id, classroomId: props.classroom.schoolClassroomId
    },
  })
}

// COMPUTED
const count = computed(() => {
  return {
    students: props.classroom?.students?.length || 0,
    teachers: props.classroom?.teachers?.length || 0
  }
})
</script>

<template>
  <div class='col-6 lg:col-3'>
  <base-card
    v-if="school && classroom"
    class='h-full border-1 border-bo-brand-secondary border-round-xl p-4 overflow-hidden flex flex-column'
    :title="classroom.name"
    has-footer
  >
    <template #content>
      <p class='w-full'>
        <i class="pi pi-home" />
        {{ `${school.name} (${school.city})` }}
      </p>

      <p class='w-full'>
        <i class="pi pi-users" />
        {{ count.students }} élève(s)
      </p>

      <p class='w-full'>
        <FontAwesomeIcon icon="chalkboard-teacher" />
        {{ count.teachers }} enseignant(s) référent(s)
      </p>

    </template>

    <template #footer>
      <a
        class="bo-active-color text-right no-underline cursor-pointer"
        @click="showClassroom"
      >
        Afficher la classe
      </a>
    </template>
  </base-card>
  <base-card
    v-else
    title='Aucune classe'
    without-content
  />
  </div>
</template>

