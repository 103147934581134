<script setup lang="ts">
/*
 *
 * IMPORTS
 *
 * */

//PrimeVue
import { default as PButton } from 'primevue/button'
import { default as PDataTable } from 'primevue/datatable'
import { default as PColumn } from 'primevue/column'
import { default as PInputText } from 'primevue/inputtext'
import { default as PIconField } from 'primevue/iconfield'
import { default as PInputIcon } from 'primevue/inputicon'
import { default as PButtonGroup } from 'primevue/buttongroup'
import { default as PTag } from 'primevue/tag'

// Custom components and types
import type { IDomain } from '@/assets/types/Referential'
import LearningHeader from './blocks/LearningHeader.vue'

//Vue, Vue Router etc
import { onMounted, type Ref, ref } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

//Store
import { useLearningCoursesStore } from '@/stores/learning/learningCourses'
import { useReferentialStore } from '@/stores/learning/referential'
import type { ILearningModule } from '@/assets/types/learning/LearningModuleTypes'
import { LearningEnums } from '@/assets/types/learning/enums'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from 'primevue/useconfirm'
import type { ILearningCourse } from '@/assets/types/learning/LearningCourses'

// Utility
const router = useRouter()
const confirm = useConfirm()
const { fetchAllAxises } = useReferentialStore()
const { fetchAllCourses, updateLearningCourseStatus } = useLearningCoursesStore()
const { learningCourses } = storeToRefs(useLearningCoursesStore())
const { getAxisTitleFromDomainId } = useReferentialStore()

interface Filter {
  value: any
  matchMode: string
}

interface Filters {
  [key: string]: Filter
}

const filters: Ref<Filters> = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
})

// Lifecycle hooks
onMounted(async () => {
  await fetchAllAxises()
  await fetchAllCourses()
})

// Methods
function createLearningCourse() {
  router.push({ name: 'learning-courses-add' })
}

function resetFilters() {
  for (let valueKey in filters.value) {
    filters.value[valueKey].value = null
  }
}

async function archiveCourse(id: number) {
  confirm.require({
    message: 'Êtes-vous sûr de vouloir archiver ce parcours ?',
    accept: async () => await updateLearningCourseStatus(id, LearningEnums.Status.ARCHIVED)
  })
}
</script>

<template>
  <div class="flex flex-column overflow-y-auto h-full">
    <learning-header
      title="Tous les parcours"
      :primary-button="{
        label: 'Créer un parcours',
        onClick: createLearningCourse,
        icon: 'pi pi-plus'
      }"
    />

    <div class="w-full px-5">
      <p-data-table class="mt-6" :value="learningCourses" :filters="filters">
        <template #header>
          <div class="flex justify-content-between">
            <p-button
              label="Effacer les filtres"
              icon="pi pi-filter-slash"
              size="small"
              @click="resetFilters"
              outlined
            />
            <p-icon-field iconPosition="left">
              <p-input-icon class="pi pi-search"></p-input-icon>
              <p-input-text
                v-model="filters['global'].value"
                size="small"
                placeholder="Rechercher"
              />
            </p-icon-field>
          </div>
        </template>
        <template #empty><p>Aucun parcours trouvé.</p></template>
        <template #loading><p>Chargement des données...</p></template>

        <p-column header="Nom">
          <template #body="{ data }: { data: ILearningCourse }">
            <p>{{ data.title }}</p>
            <p-tag severity="secondary">Exploration {{ data.name }}</p-tag>
          </template>
        </p-column>
        <p-column field="domain" header="Domaine associé">
          <template #body="{ data }: { data: { domain: IDomain } }">
            <p>{{ data.domain.title }}</p>
          </template>
        </p-column>
        <p-column field="domain" header="Axe">
          <template #body="{ data }: { data: { domain: IDomain } }">
            <p>{{ getAxisTitleFromDomainId(data.domain.id) }}</p>
          </template>
        </p-column>
        <p-column field="learningModules" header="Modules">
          <template #body="{ data }: { data: { learningModules: ILearningModule[] } }">
            <span>{{ data.learningModules.length }}</span>
          </template>
        </p-column>
        <p-column field="status" header="état" style="width: 8rem">
          <template #body="{ data }: { data: { status: LearningEnums.Status } }">
            <p-tag
              :value="LearningEnums.statusLabel(data.status)"
              :severity="LearningEnums.statusColor(data.status)"
            />
          </template>
        </p-column>
        <p-column field="id" header="Actions" style="width: 8rem">
          <template #body="{ data }: { data: { id: number } }">
            <p-button-group>
              <p-button
                icon="pi pi-pencil"
                severity="secondary"
                text
                class="mr-2"
                @click="
                  () => router.push({ name: 'learning-course-update', params: { id: data.id } })
                "
              />
              <p-button
                icon="pi pi-inbox"
                severity="secondary"
                text
                class="mr-2"
                @click="() => archiveCourse(data.id)"
              />
            </p-button-group>
          </template>
        </p-column>
      </p-data-table>
    </div>
  </div>
</template>
