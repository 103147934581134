import type ApiService from '@/core/infrastructure/services/ApiService'
import { ModuleBase } from '@/presentation/configuration/modules/ModuleBase'
import { DashboardRepository } from '@/core/infrastructure/repositories/dashboard/DashboardRepository'
import {
  FetchDashboardClassroomScoreModuleResults
} from '@/core/useCases/dashboard/FetchDashboardClassroomScoreModuleResults'
import {
  FetchDashboardClassroomScoreDiagnosticResults
} from '@/core/useCases/dashboard/FetchDashboardClassroomScoreDiagnosticResults'

export class DashboardModule extends ModuleBase {
  initialize(apiService: ApiService) {
    this.initializeDashboard(apiService)
  }

  private initializeDashboard(apiService: ApiService): void {
    const dashboardRepository = new DashboardRepository(apiService)
    this.registerRepository(DashboardRepository, dashboardRepository)

    const fetchDashboardClassroomScoreModuleResults = new FetchDashboardClassroomScoreModuleResults(dashboardRepository)
    const fetchDashboardClassroomScoreDiagnosticResults = new FetchDashboardClassroomScoreDiagnosticResults(dashboardRepository)
    this.registerUseCase(FetchDashboardClassroomScoreModuleResults, fetchDashboardClassroomScoreModuleResults)
    this.registerUseCase(FetchDashboardClassroomScoreDiagnosticResults, fetchDashboardClassroomScoreDiagnosticResults)
  }
}