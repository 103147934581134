import { ModuleBase } from '@/presentation/configuration/modules/ModuleBase'
import type ApiService from '@/core/infrastructure/services/ApiService'
import { UsersRepository } from '@/core/infrastructure/repositories/users/core/UsersRepository'


export class UsersModule extends ModuleBase {
  initialize(apiService: ApiService): void {
    this.initializeCore(apiService);
  }

  private initializeCore(apiService: ApiService): void {
    const usersRepository = new UsersRepository(apiService);

    this.registerRepository(UsersRepository, usersRepository);
  }
}