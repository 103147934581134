<script setup lang="ts">
import { ref, computed, nextTick } from 'vue'
import { storeToRefs } from 'pinia'
import { useProfileStore } from '@/stores/profile'
import NavHeader from './NavHeader.vue'
import NavMenu from './NavMenu.vue'
import { default as PButton } from 'primevue/button'

// Store
const profileStore = useProfileStore()
const { isMenuOpened } = storeToRefs(profileStore)

// Local state
const isFullyOpened = ref(isMenuOpened.value)

// Methods
function toggleMenu() {
  if (!isMenuOpened.value) {
    nextTick(() => { isFullyOpened.value = true })
  } else {
    nextTick(() => { isFullyOpened.value = false })
  }
  profileStore.toggleMenuOpen()
}
</script>

<template>
  <div
    class="_m-Lateral-Menu relative overflow-y-auto border-right-1 border-bo-brand-secondary"
    :class="isMenuOpened ? 'opened' : ['closed', 'flex', 'flex-column', 'align-items-center']"
  >
    <!-- En-tête : logo et profil -->
    <NavHeader :is-fully-opened="isFullyOpened" />

    <!-- Menu de navigation -->
    <NavMenu :is-fully-opened="isFullyOpened" />

    <!-- Bouton de bascule du menu -->
    <p-button
      @click="toggleMenu()"
      icon="pi pi-arrow-right"
      text
      rounded
      aria-label="toggle menu"
      class="absolute border-none right-0 bottom-0 _m-Lateral-Menu-Toggle"
    />
  </div>
</template>

<style scoped lang="scss">
._m-Lateral-Menu {
  height: 100vh;
  transition: all ease 0.3s;

  &.opened {
    width: 250px;
    .p-button._m-Lateral-Menu-Toggle {
      position: relative;
      transform: rotateZ(180deg);
    }
  }

  &.closed {
    max-width: 105px;
  }
}
</style>