import { z } from 'zod'
import {
  DashboardScoreModuleGrainSchema
} from '@/core/infrastructure/schemas/dashboard/DashboardScoreModuleGrainSchema'
import { EvaluationRange } from '@/core/domain/resources/enums/EvaluationRange'

export const DashboardScoreModuleSchema = z.object({
  identifier: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  teacherFirstname: z.string().nullable(),
  teacherLastname: z.string().nullable(),
  taskId: z.number(),
  taskTitle: z.string(),
  taskOrder: z.number(),
  diagScorePercentRounded: z.number().nullable(),
  diagEvalRangeName: z.nativeEnum(EvaluationRange.Values).nullable(),
  moduleScorePercentRounded: z.number().nullable(),
  moduleEvalRangeName: z.string().nullable(),
  diagnosticModuleVariation: z.number().nullable(),
  learningGrains: z.array(DashboardScoreModuleGrainSchema)
})

export const DashboardScoreModuleListSchema = z.array(DashboardScoreModuleSchema)

export type DashboardScoreModuleEntity = z.infer<typeof DashboardScoreModuleSchema>
export type DashboardScoreModuleListEntity = z.infer<typeof DashboardScoreModuleListSchema>