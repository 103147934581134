<script setup lang='ts'>
import SchoolPeriodsItem from '@/components/schools/SchoolPeriodsItem.vue'
import type SchoolYear from '@/core/domain/school/year/SchoolYear'
import { useSchoolYearStore } from '@/stores/school/schoolYear'
import { storeToRefs } from 'pinia'
import { computed, inject, onMounted, ref } from 'vue'
import type { DialogRefWrapper } from '@/assets/types/primevue/dialog'
import School from '@/core/domain/school/core/School'
import type SchoolPeriod from '@/core/domain/school/period/SchoolPeriod'
import { useToast } from 'primevue/usetoast'
import { useSchoolPeriodStore } from '@/stores/school/schoolPeriod'
import { default as PProgressSpinner } from 'primevue/progressspinner'
import { default as PInputText } from 'primevue/inputtext'

interface DialogData {
  school: School
  schoolPeriods: SchoolPeriod[]
}

// COMPOSABLES & STORES
const toast = useToast()
const schoolYearStore = useSchoolYearStore()
const schoolPeriodStore = useSchoolPeriodStore()

// STORE REFS
const { schoolYearList, schoolYearStoreIsLoading } = storeToRefs(schoolYearStore)
const {
  schoolPeriods,
  schoolPeriodStoreIsLoading,
} = storeToRefs(schoolPeriodStore)

// LOCAL STATE
const dialogRef: DialogRefWrapper<DialogData> | undefined = inject('dialogRef')
const localLoading = ref(false)
const localError = ref('')
const searchTerm = ref('')

// COMPUTED
const school = computed(() => dialogRef?.value.data.school)

const isLoading = computed(() =>
  localLoading.value ||
  schoolYearStoreIsLoading.value ||
  schoolPeriodStoreIsLoading.value
)

const filteredSchoolYears = computed(() => {
  if (!searchTerm.value.trim()) {
    return schoolYearList.value
  }

  const search = searchTerm.value.toLowerCase()
  return schoolYearList.value.filter(year =>
    year.name.toLowerCase().includes(search)
  )
})

const availableSchoolYears = computed(() => {
  // Filtrer les années scolaires qui ne sont pas déjà associées à l'école
  const existingYearIds = new Set(
    schoolPeriods.value.map(period => period.schoolYear.schoolYearId)
  )

  return filteredSchoolYears.value.filter(year =>
    !existingYearIds.has(year.schoolYearId)
  )
})

const hasNoAvailableYears = computed(() =>
  availableSchoolYears.value.length === 0 && !isLoading.value
)

// METHODS
/**
 * Crée une période scolaire pour l'année sélectionnée
 */
const createPeriod = async (schoolYear: SchoolYear) => {
  if (!school.value) return

  try {
    localLoading.value = true
    localError.value = ''

    await schoolPeriodStore.createSchoolPeriod({
      schoolId: school.value.id,
      schoolYearId: schoolYear.schoolYearId
    })

    dialogRef?.value.close()
  } catch (err) {
    console.error(err)
    localError.value = err instanceof Error
      ? err.message
      : 'Une erreur est survenue lors de l\'association de la période scolaire'

    toast.add({
      severity: 'error',
      summary: 'Erreur',
      detail: localError.value
    })
  } finally {
    localLoading.value = false
  }
}

/**
 * Ferme la boîte de dialogue
 */
const closeDialog = () => {
  dialogRef?.value.close()
}

// LIFECYCLE
onMounted(async () => {
  try {
    localLoading.value = true
    // Charger les années scolaires si nécessaire
    if (schoolYearList.value.length === 0) {
      await schoolYearStore.fetchAllSchoolYears()
    }
  } catch (err) {
    localError.value = err instanceof Error
      ? err.message
      : 'Erreur lors du chargement des années scolaires'
  } finally {
    localLoading.value = false
  }
})
</script>

<template>
  <div class="p-3">
    <!-- Message d'erreur -->

    <!-- Pas d'années disponibles -->
    <div v-if="hasNoAvailableYears" class="flex flex-column align-items-center gap-3 py-5">
      <i class="pi pi-calendar-times text-4xl text-500"></i>
      <p class="text-center">
        Toutes les années scolaires sont déjà associées à cet établissement.<br>
        Vous devez d'abord créer de nouvelles années scolaires.
      </p>
    </div>

    <!-- Recherche -->
    <div v-else-if="availableSchoolYears.length > 3" class="mb-4">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-search" />
        <p-input-text
          v-model="searchTerm"
          placeholder="Rechercher une année scolaire"
          class="w-full"
        />
      </span>
    </div>

    <!-- État de chargement -->
    <div v-if="isLoading" class="flex justify-content-center py-5">
      <p-progress-spinner strokeWidth="4" />
    </div>

    <!-- Liste des années scolaires -->
    <div v-else-if="availableSchoolYears.length > 0" class="grid w-full">
      <div
        v-for="(schoolYear, index) in availableSchoolYears"
        :key="schoolYear.schoolYearId || index"
        class="col-12 md:col-6 lg:col-4 p-2"
      >
        <school-periods-item
          :school-year="schoolYear"
          :school="school"
          :school-periods="schoolPeriods"
          :show-create-button="true"
          @create-school-period="createPeriod(schoolYear)"
        />
      </div>
    </div>

    <!-- Résultat de recherche vide -->
    <div v-else-if="searchTerm" class="flex flex-column align-items-center gap-3 py-5">
      <i class="pi pi-search text-4xl text-500"></i>
      <p>Aucune année scolaire ne correspond à votre recherche.</p>
      <p>Essayez avec un autre terme.</p>
    </div>
  </div>
</template>