<script lang='ts' setup>

import BaseBlock from '@/components/base/BaseBlock.vue'

import { default as PButton } from 'primevue/button'
import { storeToRefs } from 'pinia'
import { useSchoolStore } from '@/stores/school/school'
import SchoolsListItem from '@/components/schools/SchoolsListItem.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const { allSchools } = storeToRefs(useSchoolStore())

const createSchool = () => {
  router.push({ name: 'schools-create' })
}
</script>

<template>
  <base-block :accordion='false' title='Liste des établissements'>
      <p-button label='Créer un établissement' icon='pi pi-plus' severity='primary' size='small' @click='createSchool' />

      <div v-if='allSchools.length > 0' class='grid  w-full'>
        <template
          v-for='school in allSchools'
          :key='school.id'
        >
          <div class='col-6 lg:col-3'>
            <schools-list-item :school='school'  />
          </div>

        </template>
      </div>
    <p class='' v-else>
      Il n’y a aucun établissement.
    </p>
  </base-block>
</template>