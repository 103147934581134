import { defineStore } from 'pinia'
import { computed, type ComputedRef, ref } from 'vue'
import DependencyContainer from '@/presentation/configuration/DependencyContainer'
import School, { SchoolInput, SchoolMe } from '@/core/domain/school/core/School'
import FetchAllSchoolsUseCase from '@/core/useCases/school/core/FetchAllSchoolsUseCase'
import FetchSchoolsForMeUseCase from '@/core/useCases/school/core/FetchSchoolsForMeUseCase'
import FetchSchoolFromDataEducationUseCase from '@/core/useCases/school/core/FetchSchoolFromDataEducationUseCase'
import CreateSchoolUseCase from '@/core/useCases/school/core/CreateSchoolUseCase'
import { createStoreBase } from '@/stores/resources/storeBase'
import { UpdateSchoolUseCase } from '@/core/useCases/school/core/UpdateSchoolUseCase'

export const useSchoolStore = defineStore('School', () => {
  // BASE COMMUNE
  const baseStore = createStoreBase(['fetchAll', 'fetchForMe', 'fetchFromDataEducation', 'create', 'update', 'delete']);

  // ÉTAT
  const schoolList = ref<SchoolMe[]>([]);
  const allSchools = ref<School[]>([]);
  const selectedSchool = ref<School | null>(null);

  // ACTIONS PUBLIQUES
  /**
   * Récupère tous les établissements (pour l'administration)
   */
  async function fetchAllSchools(): Promise<School[]> {
    try {
      baseStore.loading.value.fetchAll = true;
      baseStore.resetError();

      const schools = await DependencyContainer.resolve(FetchAllSchoolsUseCase).execute();
      allSchools.value = schools;
      baseStore.lastUpdated.value = new Date();

      return schools;
    } catch (err) {
      baseStore.setError(err);
      return Promise.reject(err);
    } finally {
      baseStore.loading.value.fetchAll = false;
    }
  }

  /**
   * Récupère les établissements pour l'utilisateur actuel
   */
  async function fetchSchoolsForMe(): Promise<SchoolMe[]> {
    try {
      baseStore.loading.value.fetchForMe = true;
      baseStore.resetError();

      const schools = await DependencyContainer.resolve(FetchSchoolsForMeUseCase).execute();
      schoolList.value = schools;
      baseStore.lastUpdated.value = new Date();

      return schools;
    } catch (err) {
      baseStore.setError(err);
      return Promise.reject(err);
    } finally {
      baseStore.loading.value.fetchForMe = false;
    }
  }

  /**
   * Récupère les données d'un établissement depuis l'API de l'éducation nationale
   * @param codeUai Code UAI de l'établissement
   */
  async function fetchSchoolFromDataEducation(codeUai: string): Promise<SchoolInput> {
    try {
      baseStore.loading.value.fetchFromDataEducation = true;
      baseStore.resetError();

      const result = await DependencyContainer.resolve(FetchSchoolFromDataEducationUseCase).execute(codeUai);

      if (result) {
        baseStore.showSuccessToast(
          'Établissement trouvé',
          'L\'établissement a bien été trouvé dans la base de données de l\'éducation nationale.'
        );
      }

      return result;
    } catch (err) {
      baseStore.setError(err);
      return Promise.reject(err);
    } finally {
      baseStore.loading.value.fetchFromDataEducation = false;
    }
  }

  /**
   * Crée un nouvel établissement
   * @param school Données de l'établissement à créer
   * @return L'établissement créé
   */
  async function createSchool(school: SchoolInput): Promise<School> {
    try {
      baseStore.loading.value.create = true;
      baseStore.resetError();

      const newSchool = await DependencyContainer.resolve(CreateSchoolUseCase).execute(school);

      if (newSchool) {
        allSchools.value.push(newSchool);
        baseStore.lastUpdated.value = new Date();

        baseStore.showSuccessToast(
          'Établissement créé',
          'L\'établissement a bien été créé.'
        );
      }

      return newSchool;
    } catch (err) {
      baseStore.setError(err);
      return Promise.reject(err);
    } finally {
      baseStore.loading.value.create = false;
    }
  }

  /**
   * Met à jour un établissement existant
   * @param id Identifiant de l'établissement à mettre à jour
   * @param school Données de l'établissement à mettre à jour
   */
  async function updateSchool(id: number, school: SchoolInput): Promise<School> {
    try {
      baseStore.loading.value.update = true;
      baseStore.resetError();

      // Supposons que vous avez un cas d'utilisation UpdateSchoolUseCase
      const updatedSchool = await DependencyContainer.resolve(UpdateSchoolUseCase).execute(id, school);

      if (updatedSchool) {
        // Mettre à jour l'établissement dans les listes
        const allIndex = allSchools.value.findIndex(s => s.id === id);
        if (allIndex !== -1) {
          allSchools.value[allIndex] = updatedSchool;
        }

        const meIndex = schoolList.value.findIndex(s => s.id === id);
        if (meIndex !== -1 && 'classrooms' in updatedSchool) {
          schoolList.value[meIndex] = updatedSchool as SchoolMe;
        }

        baseStore.lastUpdated.value = new Date();

        baseStore.showSuccessToast(
          'Établissement mis à jour',
          'L\'établissement a bien été mis à jour.'
        );
      }

      return updatedSchool;
    } catch (err) {
      baseStore.setError(err);
      return Promise.reject(err);
    } finally {
      baseStore.loading.value.update = false;
    }
  }

  /**
   * Supprime un établissement
   * @param id Identifiant de l'établissement à supprimer
   */
  // async function deleteSchool(id: number): Promise<void> {
  //   try {
  //     loading.value.delete = true;
  //     resetError();
  //
  //     // Supposons que vous avez un cas d'utilisation DeleteSchoolUseCase
  //     await DependencyContainer.resolve(DeleteSchoolUseCase).execute(id);
  //
  //     // Supprimer l'établissement des listes
  //     allSchools.value = allSchools.value.filter(s => s.id !== id);
  //     schoolList.value = schoolList.value.filter(s => s.id !== id);
  //
  //     if (selectedSchool.value?.id === id) {
  //       selectedSchool.value = null;
  //     }
  //
  //     lastUpdated.value = new Date();
  //
  //     showSuccessToast(
  //       'Établissement supprimé',
  //       'L\'établissement a bien été supprimé.'
  //     );
  //
  //   } catch (err) {
  //     setError(err);
  //     return Promise.reject(err);
  //   } finally {
  //     loading.value.delete = false;
  //   }
  // }

  /**
   * Sélectionne un établissement comme établissement courant
   * @param school Établissement à sélectionner
   */
  function selectSchool(school: School | null): void {
    selectedSchool.value = school;
  }

  /**
   * Réinitialise le store
   */
  function reset(): void {
    schoolList.value = [];
    allSchools.value = [];
    selectedSchool.value = null;
    baseStore.resetError();

    baseStore.loading.value = {
      fetchAll: false,
      fetchForMe: false,
      fetchFromDataEducation: false,
      create: false,
      update: false,
      delete: false
    };
  }

  // GETTERS (COMPUTED)
  const getSchoolById: ComputedRef<(id: number) => SchoolMe | undefined> = computed(() => {
    return (id: number) => schoolList.value.find(school => school.id === id);
  });

  const getSchoolBySlug: ComputedRef<(slug: string) => School | undefined> = computed(() => {
    return (slug: string) => allSchools.value.find(school => school.slug === slug);
  });

  const getSchoolByCodeUai: ComputedRef<(codeUai: string) => SchoolMe | undefined> = computed(() => {
    return (codeUai: string) => schoolList.value.find(school => school.codeUai === codeUai);
  });

  const isLoading: ComputedRef<boolean> = computed(() =>
    Object.values(baseStore.loading.value).some(Boolean)
  );

  const schoolCount: ComputedRef<number> = computed(() =>
    schoolList.value.length
  );

  const allSchoolCount: ComputedRef<number> = computed(() =>
    allSchools.value.length
  );

  return {
    // État
    schoolList,
    allSchools,
    selectedSchool,
    schoolStoreError: baseStore.error,
    schoolStoreLoading: baseStore.loading,
    schoolStoreLastUpdated: baseStore.lastUpdated,

    // Actions
    fetchSchoolsForMe,
    fetchAllSchools,
    fetchSchoolFromDataEducation,
    createSchool,
    updateSchool,
    selectSchool,
    reset,

    // Getters
    getSchoolById,
    getSchoolBySlug,
    getSchoolByCodeUai,
    schoolStoreIsLoading: isLoading,
    schoolCount,
    allSchoolCount,

  };
});