import { ModuleBase } from '@/presentation/configuration/modules/ModuleBase'
import type ApiService from '@/core/infrastructure/services/ApiService'
import { LearningCourseRepository } from '@/core/infrastructure/repositories/learning/course/LearningCourseRepository'
import { FetchAllLearningCoursesUseCase } from '@/core/useCases/learning/course/FetchAllLearningCoursesUseCase'
import { FetchLearningCourseUseCase } from '@/core/useCases/learning/course/FetchLearningCourseUseCase'

export class LearningModule extends ModuleBase {
  initialize(apiService: ApiService): void {
    this.initializeCourse(apiService)
  }

  private initializeCourse(apiService: ApiService): void {
    const learningCourseRepository = new LearningCourseRepository(apiService)

    this.registerRepository(LearningCourseRepository, learningCourseRepository)

    const fetchAllLearningCoursesUseCase = new FetchAllLearningCoursesUseCase(learningCourseRepository)
    const fetchLearningCourseUseCase = new FetchLearningCourseUseCase(learningCourseRepository)

    this.registerUseCase(FetchAllLearningCoursesUseCase, fetchAllLearningCoursesUseCase)
    this.registerUseCase(FetchLearningCourseUseCase, fetchLearningCourseUseCase)
  }
}