import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { NabooError } from '@/assets/classes/Error'
import type SchoolYear from '@/core/domain/school/year/SchoolYear'
import DependencyContainer from '@/presentation/configuration/DependencyContainer'
import FetchAllSchoolYearsUseCase from '@/core/useCases/school/year/FetchAllSchoolYearsUseCase'
import { useToast } from 'primevue/usetoast'

// Types pour une meilleure gestion des états
type LoadingState = {
  fetchAll: boolean;
  create: boolean;
  update: boolean;
  [key: string]: boolean;
};

type ErrorState = {
  hasError: boolean;
  message: string;
  code?: string;
  details?: any;
};

export const useSchoolYearStore = defineStore('SchoolYear', () => {
  // SERVICES
  const toast = useToast();

  // ÉTAT
  const schoolYearList = ref<SchoolYear[]>([]);
  const selectedSchoolYear = ref<SchoolYear | null>(null);
  const loading = ref<LoadingState>({
    fetchAll: false,
    create: false,
    update: false
  });
  const error = ref<ErrorState>({
    hasError: false,
    message: '',
    code: undefined,
    details: undefined
  });
  const lastUpdated = ref<Date | null>(null);

  // MÉTHODES PRIVÉES
  /**
   * Réinitialise l'état d'erreur
   */
  function resetError(): void {
    error.value = {
      hasError: false,
      message: '',
      code: undefined,
      details: undefined
    };
  }

  /**
   * Configure l'état d'erreur
   */
  function setError(err: any): void {
    error.value.hasError = true;

    if (err instanceof NabooError) {
      error.value.message = err.message;
      error.value.code = err.getCode ? err.getCode() : undefined;
      error.value.details = err.getErrorDetails ? err.getErrorDetails() : undefined;
    } else if (err instanceof Error) {
      error.value.message = err.message;
    } else {
      error.value.message = 'Une erreur inconnue est survenue';
    }
  }

  /**
   * Affiche un toast de succès
   */
  function showSuccessToast(summary: string, detail: string): void {
    toast.add({
      severity: 'success',
      summary,
      detail,
      life: 5000
    });
  }

  // ACTIONS PUBLIQUES
  /**
   * Récupère toutes les années scolaires
   * @param forceRefresh Force le rechargement même si les données sont déjà présentes
   */
  async function fetchAllSchoolYears(forceRefresh = false): Promise<SchoolYear[]> {
    // Éviter le rechargement inutile si les données sont déjà là et récentes
    if (
      !forceRefresh &&
      schoolYearList.value.length > 0 &&
      lastUpdated.value &&
      Date.now() - lastUpdated.value.getTime() < 5 * 60 * 1000 // 5 minutes
    ) {
      return schoolYearList.value;
    }

    try {
      loading.value.fetchAll = true;
      resetError();

      const response = await DependencyContainer.resolve(FetchAllSchoolYearsUseCase).execute();
      schoolYearList.value = response;
      lastUpdated.value = new Date();

      // Sélectionner automatiquement l'année courante si aucune n'est sélectionnée
      if (!selectedSchoolYear.value) {
        const currentYear = response.find((schoolYear) => schoolYear.isCurrent);
        if (currentYear) {
          selectedSchoolYear.value = currentYear;
        }
      }

      return response;
    } catch (err) {
      setError(err);
      return Promise.reject(err);
    } finally {
      loading.value.fetchAll = false;
    }
  }

  /**
   * Sélectionne une année scolaire
   * @param schoolYear Année scolaire à sélectionner
   */
  function selectSchoolYear(schoolYear: SchoolYear | null): void {
    selectedSchoolYear.value = schoolYear;
  }

  /**
   * Réinitialise le store
   */
  function reset(): void {
    schoolYearList.value = [];
    selectedSchoolYear.value = null;
    lastUpdated.value = null;
    resetError();

    loading.value = {
      fetchAll: false,
      create: false,
      update: false
    };
  }

  // GETTERS
  /**
   * Année scolaire courante (basée sur la date actuelle)
   */
  const getCurrentSchoolYear = computed(() => {
    return schoolYearList.value.find((schoolYear) => schoolYear.isCurrent);
  });

  /**
   * Indique si des données sont en cours de chargement
   */
  const isLoading = computed(() =>
    Object.values(loading.value).some(Boolean)
  );

  /**
   * Indique si les données doivent être rechargées
   */
  const needsFreshData = computed(() => {
    if (!lastUpdated.value) return true;

    // Considérer les données comme périmées après 5 minutes
    const fiveMinutesInMs = 5 * 60 * 1000;
    return Date.now() - lastUpdated.value.getTime() > fiveMinutesInMs;
  });

  /**
   * Récupère une année scolaire par son ID
   */
  const getSchoolYearById = computed(() => {
    return (id: number) => schoolYearList.value.find(year => year.schoolYearId === id);
  });

  /**
   * Années scolaires triées par date de début (plus récentes en premier)
   */
  const sortedSchoolYears = computed(() => {
    return [...schoolYearList.value].sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      return dateB.getTime() - dateA.getTime();
    });
  });

  /**
   * Années scolaires à venir (date de début future)
   */
  const upcomingSchoolYears = computed(() => {
    const now = new Date();
    return schoolYearList.value.filter(year => {
      const startDate = new Date(year.startDate);
      return startDate > now;
    });
  });

  /**
   * Années scolaires actives (date actuelle entre début et fin)
   */
  const activeSchoolYears = computed(() => {
    const now = new Date();
    return schoolYearList.value.filter(year => {
      const startDate = new Date(year.startDate);
      const endDate = new Date(year.endDate);
      return startDate <= now && endDate >= now;
    });
  });

  /**
   * Années scolaires passées (date de fin passée)
   */
  const pastSchoolYears = computed(() => {
    const now = new Date();
    return schoolYearList.value.filter(year => {
      const endDate = new Date(year.endDate);
      return endDate < now;
    });
  });

  /**
   * Nombre d'années scolaires
   */
  const yearCount = computed(() => schoolYearList.value.length);

  return {
    // État
    schoolYearList,
    selectedSchoolYear,
    schoolYearStoreLoading: loading,  // Renommé selon la convention demandée
    error,
    lastUpdated,

    // Actions
    fetchAllSchoolYears,
    selectSchoolYear,
    reset,

    // Getters
    getCurrentSchoolYear,
    schoolYearStoreIsLoading: isLoading,  // Renommé selon la convention demandée
    needsFreshData,
    getSchoolYearById,
    sortedSchoolYears,
    upcomingSchoolYears,
    activeSchoolYears,
    pastSchoolYears,
    yearCount
  };
});