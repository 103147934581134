import { z } from 'zod'
import { LearningStatus } from '@/core/domain/resources/enums/LearningStatus'
import { LearningModuleListSchema } from '@/core/infrastructure/schemas/learning/module/LearningModuleSchema'

// Domain associé à un cours
const LearningDomainSchema = z.object({
  id: z.number(),
  title: z.string(),
  order: z.number(),
  tasks: z.array(z.any()).optional()
})

// Schema de base pour un cours d'apprentissage
export const LearningCourseSchema = z.object({
  id: z.number(),
  title: z.string(),
  status: z.nativeEnum(LearningStatus.Values),
  order: z.number(),
  name: z.string(),
  domain: LearningDomainSchema.optional().nullable(),
  domainId: z.number().optional(),
  learningModules: LearningModuleListSchema.default([]),
  createdBy: z.string().optional(),
  createdAt: z.string().datetime().optional(),
  modifiedBy: z.string().optional(),
  modifiedAt: z.string().datetime().optional()
})

// Liste de cours
export const LearningCourseListSchema = z.array(LearningCourseSchema)

// Types dérivés des schemas
export type LearningDomainEntity = z.infer<typeof LearningDomainSchema>
export type LearningCourseEntity = z.infer<typeof LearningCourseSchema>
export type LearningCourseListEntity = z.infer<typeof LearningCourseListSchema>