import type { LearningCourse } from '@/core/domain/learning/course/LearningCourse'
import type { ILearningCourseRepository } from '@/core/domain/learning/course/ILearningCourseRepository'
import type { ILearningCourseUrlOptions } from '@/core/domain/learning/course/ILearningCourseUrlOptions'

export class FetchAllLearningCoursesUseCase {
  private learningCourseRepository: ILearningCourseRepository;

  constructor(learningCourseRepository: ILearningCourseRepository) {
    this.learningCourseRepository = learningCourseRepository;
  }

  async execute(options?: ILearningCourseUrlOptions): Promise<LearningCourse[]> {
    return this.learningCourseRepository.fetchAllLearningCourses(options)
  }
}