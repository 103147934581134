<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useNavigation } from '@/composables/navigation/useNavigation'
import { useAuthStore } from '@/stores/auth'
import NavMenuItem from './NavMenuItem.vue'

// Props
defineProps<{
  isFullyOpened: boolean
}>()

// Store refs
const { isTeacher } = storeToRefs(useAuthStore())

// Menu data
const { menuItems, currentGroup } = useNavigation()
</script>

<template>
  <div
    class="_m-Lateral-Menu-Nav flex flex-column gap-4 overflow-y-auto px-4"
    :class="{ 'align-items-center': !isFullyOpened }"
  >
    <NavMenuItem
      v-for="(item, i) in menuItems"
      :key="`link_${i}`"
      :item="item"
      :is-teacher="isTeacher"
      :is-fully-opened="isFullyOpened"
      :is-selected="currentGroup === item.group"
    />
  </div>
</template>

<style scoped lang="scss">
._m-Lateral-Menu-Nav {
  ._m-Lateral-Menu-Nav__Item {
    border-radius: 6px;
    transition: all ease 0.3s;

    &:hover {
      cursor: pointer;

      i {
        color: var(--blue-400);
        background-color: var(--gray-100);
      }
    }

    i {
      color: var(--gray-500);
      background-color: transparent;
      border-radius: 6px;
      transition: all ease 0.3s;
    }

    //state of component
    &.selected {
      i {
        color: var(--blue-400);
        background-color: var(--blue-100);
      }
    }

    &.has-children {
      .selected {
        color: var(--blue-400);
        font-weight: 700
      }
    }
  }
}
</style>