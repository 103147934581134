import { defineStore, storeToRefs } from 'pinia'
import { useProfileStore } from '@/stores/profile'
import { computed, type ComputedRef } from 'vue'
import * as Sentry from '@sentry/vue'
import { useAuth0 } from '@auth0/auth0-vue'
import { NabooError } from '@/assets/classes/Error'
import { useSchoolYearStore } from '@/stores/school/schoolYear'
import { Role } from '@/assets/types/UserTypes'

export const useAuthStore = defineStore('Auth', () => {
  // UTILITY FUNCTIONS
  const {
    checkSession,
    loginWithRedirect,
    logout,
    idTokenClaims,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    user: userAuth0
  } = useAuth0()
  const { getMe } = useProfileStore()
  const { fetchAllSchoolYears } = useSchoolYearStore()
  const { me } = storeToRefs(useProfileStore())

  // ACTIONS
  function $reset() {
    // Do nothing
  }

  function buildLogoutOidcUrl() {
    let baseUrl = `https://${__AUTH0_DOMAIN__}/oidc/logout`
    baseUrl += `?id_token_hint=${idTokenClaims.value?.__raw}`
    baseUrl += `&post_logout_redirect_uri=${window.location.origin}`
    return baseUrl
  }

  /**
   * Execute the login process after the response of Auth0
   */
  async function initLogin() {
    try {
      await Promise.all([getMe(), fetchAllSchoolYears()])
      if (!isAllowedToLogIn.value) {
        return Promise.reject(
          new NabooError({
            message: "Vous n'êtes pas autorisé à vous connecter.",
            status: 403,
            name: 'Forbidden',
            code: 'ERR_FORBIDDEN',
            errorDetails: null,
            error: new Error("Vous n'êtes pas autorisé à vous connecter.")
          })
        )
      }
      Sentry.setUser({ id: me.value?.identifier })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function initLogout() {
    try {
      $reset()
      if (!isAuthenticated.value) return Promise.resolve()
      await logout({
        openUrl() {
          window.location.replace(buildLogoutOidcUrl())
        }
      })
    } catch (error) {
      Sentry.captureException(error)
      return Promise.reject(error)
    } finally {
      localStorage.clear()
      Sentry.setUser(null)
    }
    return Promise.resolve()
  }

  const getRoles: ComputedRef<Role.Values[]> = computed(
    () => (idTokenClaims.value?.[`${__AUTH0_DOMAIN__}/roles`]) ?? []
  )

  // GETTERS (COMPUTED)

  /**
   * Check if the user is an admin
   */
  const isAdmin = computed(() => {
    return getRoles.value.includes(Role.Values.ADMIN)
  })

  /**
   * Check if the user is an educational engineer
   */
  const isEduEngineer = computed(() => {
    return getRoles.value.includes(Role.Values.EDU_ENGINEER)
  })

  /**
   * Check if the user is a perdir
   */
  const isPerdir = computed(() => {
    return getRoles.value.includes(Role.Values.PERDIR)
  })

  /**
   * Check if the user is a cpe
   */
  const isCpe = computed(() => {
    return getRoles.value.includes(Role.Values.CPE)
  })

  /**
   * Check if the user is a teacher
   */
  const isTeacher = computed(() => {
    return getRoles.value.includes(Role.Values.TEACHER)
  })

  /**
   * Check if the user is allowed to log in
   */
  const isAllowedToLogIn = computed(() => {
    const rolesWhitelist = [
      Role.Values.ADMIN.toString(),
      Role.Values.EDU_ENGINEER.toString(),
      Role.Values.PERDIR.toString(),
      Role.Values.CPE.toString(),
      Role.Values.TEACHER.toString()
    ]
    return getRoles.value.some((role) => rolesWhitelist.includes(role))
  })

  return {
    initLogin,
    initLogout,
    checkSession,
    loginWithRedirect,
    getAccessTokenSilently,
    isAuthenticated,
    idTokenClaims,
    isLoading,
    userAuth0,
    getRoles,
    isAdmin,
    isEduEngineer,
    isTeacher,
    isPerdir,
    isCpe,
    isAllowedToLogIn
  }
})

export type AuthStore = ReturnType<typeof useAuthStore>
