import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import DependencyContainer from '@/presentation/configuration/DependencyContainer'
import { createStoreBase } from '@/stores/resources/storeBase'
import { useToast } from 'primevue/usetoast'
import SchoolClassroom, {
  SchoolClassroomInput,
  type SchoolClassroomListItem
} from '@/core/domain/school/classroom/SchoolClassroom'
import { FetchSchoolClassroomUseCase } from '@/core/useCases/school/classroom/FetchSchoolClassroomUseCase'
import { FetchAllSchoolClassroomsUseCase } from '@/core/useCases/school/classroom/FetchAllSchoolClassroomsUseCase'
import { CreateSchoolClassroomUseCase } from '@/core/useCases/school/classroom/CreateSchoolClassroomUseCase'
import { UpdateSchoolClassroomUsersUseCase } from '@/core/useCases/school/classroom/UpdateSchoolClassroomUsersUseCase'
import { UpdateSchoolClassroomUseCase } from '@/core/useCases/school/classroom/UpdateSchoolClassroomUseCase'
import { DeleteSchoolClassroomUseCase } from '@/core/useCases/school/classroom/DeleteSchoolClassroomUseCase'
import { useStoreEvents } from '@/stores/utils/storeEventBus'

export const useSchoolClassroomStore = defineStore('SchoolClassroom', () => {
  // Base commune
  const storeBase = createStoreBase(['fetchClassrooms', 'fetchClassroom', 'createClassroom', 'updateClassroom', 'deleteClassroom', 'updateClassroomUsers'])

  // Toasts
  const toast = useToast()
  const { lastEvent } = useStoreEvents()

  // État
  const schoolClassrooms = ref<SchoolClassroomListItem[]>([])
  const selectedClassroom = ref<SchoolClassroom | null>(null)
  const currentSchoolId = ref<number | null>(null)
  const currentSchoolPeriodId = ref<number | null>(null)
  const expandedClassroomIds = ref<number[]>([])
  const expandingClassrooms = ref<Map<number, {
    id: number,
    isLoading: boolean,
    details: SchoolClassroom | null
  }>>(new Map())

  // Méthodes

  /**
   * Met à jour l'état d'expansion d'une classe
   * @param classroomId ID de la classe
   * @param expanded État d'expansion
   */
  function setClassroomExpanded(classroomId: number, expanded: boolean) {
    if (expanded) {
      if (!expandedClassroomIds.value.includes(classroomId)) {
        expandedClassroomIds.value.push(classroomId)
      }
    } else {
      const index = expandedClassroomIds.value.indexOf(classroomId)
      if (index >= 0) {
        expandedClassroomIds.value.splice(index, 1)
      }
    }
  }

  function handleRowCollapse(classroomId: number) {
    const index = expandedClassroomIds.value.indexOf(classroomId)
    if (index >= 0) {
      expandedClassroomIds.value.splice(index, 1)
    }
  }

  async function loadClassroomDetails(schoolId: number, schoolPeriodId: number, classroomId: number, fromAPI: boolean = false): Promise<SchoolClassroom | null> {
    // Si on a déjà les détails et qu'ils sont chargés
    const expandingClassroom = expandingClassrooms.value.get(classroomId)
    if (expandingClassroom && expandingClassroom.details) {
      if (!fromAPI) {
        return expandingClassroom.details
      } else {

        expandingClassroom.isLoading = true

        expandingClassroom.details = await fetchSchoolClassroom(schoolId, schoolPeriodId, classroomId)
        expandingClassroom.isLoading = false
      }
    }

    // Mettre à jour l'état de chargement
    expandingClassrooms.value.set(classroomId, {
      id: classroomId,
      isLoading: true,
      details: null
    })

    try {
      const classroomDetails = await fetchSchoolClassroom(schoolId, schoolPeriodId, classroomId)

      // Mettre à jour avec les détails
      expandingClassrooms.value.set(classroomId, {
        id: classroomId,
        isLoading: false,
        details: classroomDetails
      })

      return classroomDetails
    } catch (err) {
      // Gérer l'erreur...
      expandingClassrooms.value.set(classroomId, {
        id: classroomId,
        isLoading: false,
        details: null
      })
      throw err
    }
  }

  /**
   * Récupère toutes les classes d'une école pour une période donnée
   * @param schoolId ID de l'école
   * @param schoolPeriodId ID de la période scolaire
   * @param forceRefresh Force le rechargement même si les données sont déjà présentes
   */
  async function fetchAllSchoolClassroomsForSchoolPeriod(
    schoolId: number,
    schoolPeriodId: number,
    forceRefresh = false
  ): Promise<SchoolClassroomListItem[]> {
    // Éviter le rechargement inutile si les données sont déjà là pour la même période
    if (
      !forceRefresh &&
      schoolClassrooms.value.length > 0 &&
      currentSchoolId.value === schoolId &&
      currentSchoolPeriodId.value === schoolPeriodId &&
      storeBase.lastUpdated.value &&
      Date.now() - storeBase.lastUpdated.value.getTime() < 5 * 60 * 1000 // 5 minutes
    ) {
      return schoolClassrooms.value
    }

    try {
      storeBase.loading.value.fetchClassrooms = true
      storeBase.resetError()

      currentSchoolId.value = schoolId
      currentSchoolPeriodId.value = schoolPeriodId

      const response = await DependencyContainer.resolve(FetchAllSchoolClassroomsUseCase).execute(schoolId, schoolPeriodId)

      schoolClassrooms.value = response
      storeBase.updateLastUpdated()

      return response
    } catch (err) {
      storeBase.setError(err)
      return Promise.reject(err)
    } finally {
      storeBase.loading.value.fetchClassrooms = false
    }
  }

  /**
   * Récupère les détails d'une classe spécifique
   * @param schoolId ID de l'école
   * @param schoolPeriodId ID de la période scolaire
   * @param classroomId ID de la classe
   */
  async function fetchSchoolClassroom(
    schoolId: number,
    schoolPeriodId: number,
    classroomId: number
  ): Promise<SchoolClassroom> {
    try {
      storeBase.loading.value.fetchClassroom = true
      storeBase.resetError()

      const response = await DependencyContainer.resolve(FetchSchoolClassroomUseCase).execute(schoolId, schoolPeriodId, classroomId)

      selectedClassroom.value = response
      return response
    } catch (err) {
      storeBase.setError(err)
      return Promise.reject(err)
    } finally {
      storeBase.loading.value.fetchClassroom = false
    }
  }

  /**
   * Crée une nouvelle classe
   * @param schoolId ID de l'école
   * @param classroomData Données de la classe à créer
   */
  async function createSchoolClassroom(
    schoolId: number,
    classroomData: SchoolClassroomInput
  ): Promise<SchoolClassroomListItem> {
    try {
      storeBase.loading.value.createClassroom = true
      storeBase.resetError()

      const response = await DependencyContainer.resolve(CreateSchoolClassroomUseCase).execute(schoolId, classroomData)

      schoolClassrooms.value.push(response)
      storeBase.updateLastUpdated()

      toast.add({
        severity: 'success',
        summary: 'Succès',
        detail: 'La classe a été créée avec succès',
        life: 3000
      })

      return response
    } catch (err) {
      storeBase.setError(err)
      return Promise.reject(err)
    } finally {
      storeBase.loading.value.createClassroom = false
    }
  }
  /**
   * Met à jour les utilisateurs d'une classe
   * @param schoolId ID de l'école
   * @param schoolPeriodId ID de la période scolaire
   * @param classroomId ID de la classe
   * @param users Liste des utilisateurs à ajouter ou supprimer
   */
  async function updateSchoolClassroomUsers(
    schoolId: number,
    schoolPeriodId: number,
    classroomId: number,
    users: number[]
  ): Promise<SchoolClassroomListItem> {
    try {
      storeBase.loading.value.updateClassroom = true
      storeBase.resetError()

      const classroomIndex = schoolClassrooms.value.findIndex(c => c.schoolClassroomId === classroomId)

      const response = await DependencyContainer.resolve(UpdateSchoolClassroomUsersUseCase).execute(schoolId, schoolPeriodId, classroomId, users)

      if (classroomIndex !== -1) {
        schoolClassrooms.value[classroomIndex] = response
      }

      storeBase.updateLastUpdated()
      toast.add({
        severity: 'success',
        summary: 'Succès',
        detail: 'Les utilisateurs de la classe ont été mis à jour avec succès',
        life: 3000
      })
      return response

    } catch (err) {
      storeBase.setError(err)
      return Promise.reject(err)
    } finally {
      storeBase.loading.value.updateClassroomUsers = false
    }
  }

  /**
   * Met à jour une classe existante
   * @param schoolId ID de l'école
   * @param schoolPeriodId ID de la période scolaire
   * @param classroomData Nouvelles données de la classe
   */
  async function updateSchoolClassroom(
    schoolId: number,
    schoolPeriodId: number,
    classroomData: SchoolClassroomInput
  ): Promise<SchoolClassroomListItem> {
    try {
      storeBase.loading.value.updateClassroom = true
      storeBase.resetError()

      const response = await DependencyContainer.resolve(UpdateSchoolClassroomUseCase).execute(schoolId, schoolPeriodId, classroomData)

      const classroomToUpdate = schoolClassrooms.value.find(c => c.schoolClassroomId === classroomData.schoolClassroomId)
      if (classroomToUpdate) {
        Object.assign(classroomToUpdate, response)
      }
      storeBase.updateLastUpdated()

      toast.add({
        severity: 'success',
        summary: 'Succès',
        detail: 'La classe a été mise à jour avec succès',
        life: 3000
      })

      return response
    } catch (err) {
      storeBase.setError(err)
      return Promise.reject(err)
    } finally {
      storeBase.loading.value.updateClassroom = false
    }
  }

  /**
   * Supprime une classe
   * @param schoolId ID de l'école
   * @param schoolPeriodId ID de la période scolaire
   * @param classroomId ID de la classe à supprimer
   */
  async function deleteSchoolClassroom(
    schoolId: number,
    schoolPeriodId: number,
    classroomId: number
  ): Promise<boolean> {
    try {
      storeBase.loading.value.deleteClassroom = true
      storeBase.resetError()

      const classroomIndex = schoolClassrooms.value.findIndex(c => c.schoolClassroomId === classroomId)

      if (classroomIndex === -1) {
        return Promise.reject(new Error('Classe non trouvée'))
      }

      await DependencyContainer.resolve(DeleteSchoolClassroomUseCase).execute(schoolId, schoolPeriodId, classroomId)

      schoolClassrooms.value.splice(classroomIndex, 1)
      storeBase.updateLastUpdated()

      toast.add({
        severity: 'success',
        summary: 'Succès',
        detail: 'La classe a été supprimée avec succès',
        life: 3000
      })

      return true
    } catch (err) {
      storeBase.setError(err)
      return Promise.reject(err)
    } finally {
      storeBase.loading.value.deleteClassroom = false
    }
  }

  async function handleGroupUpdate(schoolId: number, schoolPeriodId: number, schoolClassroomId: number) {
    // Recharger les classes pour mettre à jour les compteurs
    await fetchAllSchoolClassroomsForSchoolPeriod(schoolId, schoolPeriodId, true)

    // Si la classe est développée, recharger aussi ses détails
    if (expandedClassroomIds.value.includes(schoolClassroomId)) {
      await loadClassroomDetails(schoolId, schoolPeriodId, schoolClassroomId, true)
    }
  }

  /**
   * Réinitialise le store à son état initial
   */
  function reset(): void {
    schoolClassrooms.value = []
    selectedClassroom.value = null
    currentSchoolId.value = null
    currentSchoolPeriodId.value = null
    storeBase.resetBase()
  }

  // Getters
  /**
   * Indique si les données doivent être rechargées
   */
  const needsFreshData = computed(() => {
    if (!storeBase.lastUpdated.value) return true

    // Considérer les données comme périmées après 5 minutes
    const fiveMinutesInMs = 5 * 60 * 1000
    return Date.now() - storeBase.lastUpdated.value.getTime() > fiveMinutesInMs
  })

  /**
   * Récupère une classe par son ID
   */
  const getClassroomById = computed(() => {
    return (id: number) => schoolClassrooms.value.find(c => c.schoolClassroomId === id)
  })

  /**
   * Nombre total de classes
   */
  const classroomCount = computed(() => schoolClassrooms.value.length)


  watch(lastEvent, async (newEvent) => {
    if (!newEvent) return;

    // Vérifier si c'est un événement de mise à jour de groupe
    if (newEvent.name === 'group-updated') {
      const { schoolId, schoolPeriodId, schoolClassroomId } = newEvent.payload;

      await handleGroupUpdate(schoolId, schoolPeriodId, schoolClassroomId)
    }
  }, { deep: true });

  return {
    // État
    schoolClassrooms,
    selectedClassroom,
    currentSchoolId,
    currentSchoolPeriodId,
    expandedClassroomIds,
    expandingClassrooms,
    schoolClassroomStoreLoading: storeBase.loading,
    schoolClassroomStoreError: storeBase.error,
    schoolClassroomStoreLastUpdated: storeBase.lastUpdated,

    // Actions
    fetchSchoolClassrooms: fetchAllSchoolClassroomsForSchoolPeriod,
    fetchSchoolClassroom,
    createSchoolClassroom,
    updateSchoolClassroomUsers,
    updateSchoolClassroom,
    deleteSchoolClassroom,
    setClassroomExpanded,
    loadClassroomDetails,
    handleRowCollapse,
    reset,

    // Getters
    needsFreshData,
    getClassroomById,
    classroomCount,
    schoolClassroomStoreIsLoading: storeBase.isLoading
  }
})