import { z } from 'zod'
import { LearningStatus } from '@/core/domain/resources/enums/LearningStatus'
import { LearningGrainListSchema } from '@/core/infrastructure/schemas/learning/grain/LearningGrainSchema'
import { LearningQuizSchema } from '@/core/infrastructure/schemas/learning/quiz/LearningQuizSchema'
import { orderBy } from 'lodash'

// Task associée à un module
const ModuleTaskSchema = z.object({
  id: z.number(),
  title: z.string(),
  order: z.number(),
  quiz: LearningQuizSchema.optional().nullable()
})

// Schema de base pour un module d'apprentissage
export const LearningModuleSchema = z.object({
  id: z.number(),
  title: z.string(),
  status: z.nativeEnum(LearningStatus.Values),
  order: z.number(),
  task: ModuleTaskSchema,
  learningCourseId: z.number(),
  learningCourseName: z.string(),
  learningGrains: LearningGrainListSchema.default([]),
  createdBy: z.string().optional(),
  createdAt: z.string().datetime().optional(),
  modifiedBy: z.string().optional(),
  modifiedAt: z.string().datetime().optional()
})

// Liste de modules
export const LearningModuleListSchema = z.array(LearningModuleSchema)

// Entrée pour la création ou la mise à jour d'un module
export const LearningModuleInputSchema = z.object({
  id: z.number(),
  title: z.string(),
  order: z.number(),
  status: z.nativeEnum(LearningStatus.Values),
  taskId: z.number().optional(),
  learningGrains: z.array(z.any()).optional() // Plus générique pour les entrées
})

// Types dérivés des schemas
export type ModuleTaskEntity = z.infer<typeof ModuleTaskSchema>
export type LearningModuleEntity = z.infer<typeof LearningModuleSchema>
export type LearningModuleListEntity = z.infer<typeof LearningModuleListSchema>
export type LearningModuleInputEntity = z.infer<typeof LearningModuleInputSchema>