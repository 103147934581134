<script setup lang="ts">
import { useLearningCoursesStore } from '@/stores/learning/learningCourses'
import { computed, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import DashboardClassroomCoursesListItem from '@/components/dashboard/DashboardClassroomCoursesListItem.vue'
import BaseBlock from '@/components/base/BaseBlock.vue'

// STORES REFS
const { learningCourses } = storeToRefs(useLearningCoursesStore())

// PROPS
// Inherit from router
const props = defineProps<{
  schoolId: number
  classroomId: number
}>()

// ACTIONS
const { fetchAllCourses } = useLearningCoursesStore()

// COMPUTED
/**
 * Get active learning courses
 * @returns {LearningCourse[]}
 */
const activeLearningCourses = computed(() => {
  return learningCourses.value.filter((course) => course.isActive())
})

// LYFECYCLE HOOKS
onMounted(async () => {
  await fetchAllCourses()
})
</script>

<template>
  <div class="flex flex-column grid col-12">
    <base-block title="Liste des parcours" >
      <div
        class='grid gap-3 p-3 w-full'
      >
        <dashboard-classroom-courses-list-item
          v-for="course in activeLearningCourses"
          :key="`course_${course.id}`"
          :learningCourse="course"
          :school-id="props.schoolId"
          :classroom-id="props.classroomId"
        />
      </div>

    </base-block>
  </div>
</template>
