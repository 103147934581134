<script setup lang='ts'>
// Vue, Vue Router, Pinia
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

// Stores
import { useSchoolPeriodStore } from '@/stores/school/schoolPeriod'
import { storeToRefs } from 'pinia'
import type SchoolYear from '@/core/domain/school/year/SchoolYear'
import { DateTime } from 'luxon'
import type SchoolPeriod from '@/core/domain/school/period/SchoolPeriod'

// Components

// Types
// @TODO : Move to a global types file
type Breadcrumb = {
  name: string
  link: boolean
  title: string
  icon: string
}

/*
*
*  SERVICES
*
* */
const route = useRoute()

// Data, refs
const Header = ref<HTMLElement | null>(null)

// Props
const props = defineProps<{
  title: string
  subtitle?: string
  breadcrumbs?: Breadcrumb[]
  displayBreadcrumbs: boolean
  hasWidget?: boolean
}>()

// Methods

</script>

<template>
  <div class="col-12 grid gap-2 grid-nogutter p-5 border-300 bg-bo-brand-tertiary" ref="Header">
    <div class='flex-1'>
      <!--  Breadcrumbs -->
      <div class="col-12 justify-content-start align-items-center flex gap-2" v-if='displayBreadcrumbs'>
        <template
          v-for="(breadcrumb, index) in breadcrumbs"
          :key="index"
        >
          <component
            :is='breadcrumb.link ? "router-link" : "p"'
            :to="{ name: breadcrumb.name }"
            :class="[breadcrumb.link ? 'bo-secondary' : 'bo-primary']"
            class='no-underline max-w-15rem overflow-hidden text-overflow-ellipsis white-space-nowrap'
            :title='breadcrumb.title'
          >
            <i :class='breadcrumb.icon' />
            {{ breadcrumb.title }}
          </component>
        </template>

      </div>

      <h3 class="brand-heading uppercase text-left col-12">{{ title }}</h3>

      <h4 v-if='route.name === "dashboard-group-progress"' class=" brand-heading medium uppercase py-1 align-self-start" rounded
      >{{ subtitle }}
      </h4>
    </div>

    <div class='ml-auto' v-if='hasWidget'>
      <slot name='widgets' />
    </div>
  </div>
</template>