<script setup lang="ts">
import SchoolDetails from '@/components/schools/SchoolDetails.vue'
import SchoolPeriods from '@/components/schools/SchoolPeriods.vue'
import SchoolPeriodUsers from '@/components/schools/SchoolPeriodUsers.vue'
import { default as PTabMenu } from 'primevue/tabmenu'
import { default as PProgressSpinner } from 'primevue/progressspinner'
import { default as PMessage } from 'primevue/message'
import { default as PButton } from 'primevue/button'
import { useSchoolStore } from '@/stores/school/school'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import SchoolPeriodClassrooms from '@/components/schools/SchoolClassrooms.vue'

// COMPOSABLES
const router = useRouter()
const route = useRoute()
const schoolStore = useSchoolStore()

// STORE REFS
const { selectedSchool, schoolStoreError } = storeToRefs(schoolStore)

// LOCAL REFS
const activeTab = ref('Informations générales')
const localLoading = ref(false)
const localError = ref('')

// TAB CONFIGURATION
const tabs = {
  INFO: 'Informations générales',
  PERIODS: 'Périodes scolaires',
  USERS: 'Gestion des utilisateurs',
  CLASSES: 'Gestion des classes et groupes',
}

// PROPS
const props = defineProps<{
  schoolSlug: string
  loading?: boolean
}>()

const items = ref([
  createTabItem(tabs.INFO),
  createTabItem(tabs.PERIODS),
  createTabItem(tabs.USERS),
  createTabItem(tabs.CLASSES),
])

// METHODS
/**
 * Gère le changement d'onglet et met à jour l'URL
 */
const onTabChange = async (tabName: string) => {
  activeTab.value = tabName
  await router.push({ query: { ...route.query, tab: tabName } })
}

/**
 * Crée un élément de menu pour les onglets
 */
function createTabItem(label: string) {
  return {
    label,
    command: () => onTabChange(label)
  }
}

/**
 * Initialise l'onglet à partir des paramètres de l'URL
 */
function initializeTab() {
  // Mettre à jour l'onglet sélectionné en fonction de l'URL
  if (route.query.tab && typeof route.query.tab === 'string') {
    activeTab.value = route.query.tab
  } else {
    router.replace({ query: { ...route.query, tab: activeTab.value } })
  }
}

// COMPUTED
/**
 * Récupère l'école active depuis le store
 */
const school = computed(() => schoolStore.getSchoolBySlug(props.schoolSlug))

/**
 * Composant à afficher en fonction de l'onglet actif
 */
const tabDisplay = computed(() => {
  switch (activeTab.value) {
    case tabs.INFO:
      return SchoolDetails
    case tabs.PERIODS:
      return SchoolPeriods
    case tabs.USERS:
      return SchoolPeriodUsers
    case tabs.CLASSES:
      return SchoolPeriodClassrooms
    default:
      return 'div'
  }
})

/**
 * Index de l'onglet actif pour le menu
 */
const activeTabIndex = computed(() => {
  return items.value.findIndex(item => item.label === activeTab.value)
})

/**
 * État de chargement global, utilise le prop loading du parent
 */
const isLoading = computed(() =>
  localLoading.value || props.loading
)

/**
 * Message d'erreur global (combine les erreurs locales et du store)
 */
const errorMessage = computed(() => {
  if (localError.value) return localError.value
  if (schoolStoreError.value.hasError) return schoolStoreError.value.message
  return ''
})

/**
 * Vérifie si une école est sélectionnée et si elle correspond au slug
 */
const isCorrectSchoolSelected = computed(() => {
  return selectedSchool.value?.slug === props.schoolSlug
})

// LIFECYCLE
onMounted(() => {
  // Initialiser l'onglet et vérifier que tout est bien configuré
  initializeTab()

  // Sélectionner l'école si elle n'est pas déjà sélectionnée
  if (school.value && !isCorrectSchoolSelected.value) {
    schoolStore.selectSchool(school.value)
  }
})

// WATCHERS
// Observer les changements de slug d'école pour mettre à jour l'école sélectionnée
watch(() => props.schoolSlug, (newSlug) => {
  // Mettre à jour l'école sélectionnée si nécessaire
  const newSchool = schoolStore.getSchoolBySlug(newSlug)
  if (newSchool && !isCorrectSchoolSelected.value) {
    schoolStore.selectSchool(newSchool)
  }

  // Réinitialiser l'onglet
  initializeTab()
})
</script>

<template>
  <div class="flex flex-column gap-3">
    <div>
      <p-tab-menu :model="items" :activeIndex="activeTabIndex" />
    </div>

    <!-- Message d'erreur local -->
    <p-message
      v-if="errorMessage"
      severity="error"
      class="w-full"
    >
      {{ errorMessage }}
    </p-message>

    <!-- Affichage pendant le chargement -->
    <div v-if="isLoading" class="flex justify-content-center p-4">
      <p-progress-spinner strokeWidth="4" />
    </div>

    <!-- Affichage de l'école -->
    <template v-else-if="school">
      <component
        :is="tabDisplay"
        :school="selectedSchool"
        class="mt-3"
      />
    </template>

    <!-- Message si l'école n'est pas trouvée -->
    <div v-else class="p-4 surface-200 border-round">
      <p>Établissement non trouvé</p>
      <p-button
        label="Retour à la liste"
        icon="pi pi-arrow-left"
        class="mt-3"
        @click="router.push({ name: 'schools-list' })"
      />
    </div>
  </div>
</template>