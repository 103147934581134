import { z } from 'zod';
import { Degree } from '@/assets/types/school/ISchoolClassroom'
import { ClassroomType } from '@/core/domain/resources/enums/ClassroomType'
import { SchoolPeriodUserSchema } from '@/core/infrastructure/schemas/school/user/SchoolPeriodUserSchema'
import { SchoolMonitoringGroupSchema } from '@/core/infrastructure/schemas/school/group/SchoolMonitoringGroupSchema'

export const SchoolClassroomSchema = z.object({
  schoolClassroomId: z.number(),
  schoolPeriodId: z.number(),
  name: z.string(),
  degree: z.nativeEnum(Degree.Values),
  users: z.array(SchoolPeriodUserSchema),
  type: z.nativeEnum(ClassroomType.Values),
  groups: z.array(SchoolMonitoringGroupSchema)
});

export const SchoolClassroomsListItemSchema = z.object({
  schoolClassroomId: z.number(),
  schoolPeriodId: z.number(),
  name: z.string(),
  degree: z.nativeEnum(Degree.Values),
  type: z.nativeEnum(ClassroomType.Values),
  countStudents: z.number(),
  countTeachers: z.number(),
  countGroups: z.number(),
  countStudentsWithoutGroup: z.number(),
})

export const SchoolClassroomInputSchema = z.object({
  schoolClassroomId: z.number().optional(),
  schoolPeriodId: z.number(),
  name: z.string(),
  degree: z.nativeEnum(Degree.Values),
  type: z.nativeEnum(ClassroomType.Values),
  users: z.array(SchoolPeriodUserSchema).optional(),
})

export const SchoolClassroomAssociateUserSchema = z.object({
  schoolClassroomId: z.number(),
  users: z.array(SchoolPeriodUserSchema)
})

export const SchoolClassroomsListISchema = z.array(SchoolClassroomsListItemSchema);

export type SchoolClassroomEntity = z.infer<typeof SchoolClassroomSchema>;
export type SchoolClassroomsListItemEntity = z.infer<typeof SchoolClassroomsListItemSchema>;
export type SchoolClassroomInputEntity = z.infer<typeof SchoolClassroomInputSchema>;
export type SchoolClassroomAssociateUserEntity = z.infer<typeof SchoolClassroomAssociateUserSchema>;