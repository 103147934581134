import type SchoolYear from '@/core/domain/school/year/SchoolYear'
import type ISchoolYearRepository from '@/core/domain/school/year/ISchoolYearRepository'


export default class FetchAllSchoolYearsUseCase {
  private schoolYearRepository: ISchoolYearRepository;

  constructor(schoolYearRepository: ISchoolYearRepository) {
    this.schoolYearRepository = schoolYearRepository;
  }

  async execute(): Promise<SchoolYear[]> {
    return await this.schoolYearRepository.fetchAllSchoolYears();
  }
}