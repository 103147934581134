<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import PChart from 'primevue/chart'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Chart, type ChartOptions } from 'chart.js'
import { storeToRefs } from 'pinia'
import { FilterMatchMode } from 'primevue/api'
import DashboardBaseBlock from '@/views/dashboard/blocks/DashboardBaseBlock.vue'
import { EvaluationRange } from '@/assets/types/enums'
import { useNabooContent } from '@/assets/composables/useNabooContent'
import { useDashboardClassroomResultStore } from '@/stores/dashboard/dashboardClassroomResult'
import { default as PProgressSpinner } from 'primevue/progressspinner'
import { default as PMessage } from 'primevue/message'

// Interface data
interface IScoreDiagnosticData {
  labels: string[]
  datasets: {
    label: 'Défi' | 'Efforts à faire' | 'Force'
    backgroundColor: string
    data: number[]
  }[]
}

// Référencer les plugins de Chart.js
Chart.register(ChartDataLabels)

// Stores
const dashboardClassroomResultStore = useDashboardClassroomResultStore()
const { scoreDiagnostic, dashboardClassroomResultStoreLoading } = storeToRefs(dashboardClassroomResultStore)

// Variables, props
const props = defineProps<{
  schoolId: number
  classroomId: number
  courseId: number
}>()

// Local state
const loading = ref(false)
const error = ref<string | null>(null)
const dataInitialized = ref(false)

// Composables
const { openDiagnosticDialog } = useNabooContent()

// Méthodes
const { fetchSchoolClassroomScoreDiagnostic } = dashboardClassroomResultStore

/**
 * Charge les données de diagnostic
 */
const loadDiagnosticData = async () => {
  // Si déjà initialisé et pas d'erreur, ne pas recharger
  if (dataInitialized.value && !error.value) return

  try {
    loading.value = true
    error.value = null

    await fetchSchoolClassroomScoreDiagnostic(props.courseId)

    // Marquer comme initialisé après le chargement réussi
    dataInitialized.value = true
  } catch (err) {
    console.error('Erreur lors du chargement des diagnostics:', err)
    error.value = err instanceof Error
      ? err.message
      : 'Une erreur est survenue lors du chargement des diagnostics'
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  await loadDiagnosticData()
})

// Observer les changements de props pour recharger si nécessaire
watch(
  [() => props.schoolId, () => props.classroomId, () => props.courseId],
  async ([newSchoolId, newClassroomId, newCourseId], [oldSchoolId, oldClassroomId, oldCourseId]) => {
    if (newSchoolId !== oldSchoolId || newClassroomId !== oldClassroomId || newCourseId !== oldCourseId) {
      // Réinitialiser pour permettre le rechargement
      dataInitialized.value = false
      await loadDiagnosticData()
    }
  }
)

// Computed pour la source de données
const dataSource = computed(() => {
  return scoreDiagnostic.value?.map((student) => {
    return {
      ...student,
      teacherFullname: student.teacherFirstname
        ? `${student.teacherFirstname} ${student.teacherLastname}`
        : undefined
    }
  }) || []
})

const teachersOptions = computed(() => {
  const teachers = [
    ...new Set(
      dataSource.value
        ?.filter((student) => student.teacherFullname)
        ?.map((student) => student.teacherFullname)
    )
  ]?.map((teacher) => {
    return {
      label: teacher,
      value: teacher
    }
  })

  return teachers ? [{ label: 'Tous les enseignants', value: null }, ...teachers] : []
})

// Computed method for chart avec gestion d'erreur améliorée
const transformDataForChart = computed(() => {
  const data = scoreDiagnostic.value || []

  // Initialisation des tâches regroupées
  const groupedTasks: IScoreDiagnosticData = {
    labels: [],
    datasets: [
      {
        label: 'Défi',
        backgroundColor: '#FF6F61', // Rouge
        data: []
      },
      {
        label: 'Efforts à faire',
        backgroundColor: '#FBA86F', // Jaune
        data: []
      },
      {
        label: 'Force',
        backgroundColor: '#32CD32', // Vert
        data: []
      }
    ]
  }

  // Vérifier si nous avons des données à traiter
  if (data.length === 0) {
    return groupedTasks;
  }

  // Map pour compter les scores par évaluation pour chaque tâche
  const taskScores = new Map<number, { defi: number; effortsAFaire: number; force: number }>()

  // Parcours des étudiants et regroupement par tâche
  data.forEach((student) => {
    if (!student.tasks) return;

    student.tasks.forEach((task) => {
      if (!taskScores.has(task.taskId)) {
        taskScores.set(task.taskId, { defi: 0, effortsAFaire: 0, force: 0 })
      }

      const taskScore = taskScores.get(task.taskId)
      if (taskScore) {
        // Catégorisation selon le diagEvalRangeName
        if (task.diagEvalRangeName === EvaluationRange.Values.DEFI) {
          taskScore.defi += 1
        } else if (task.diagEvalRangeName === EvaluationRange.Values.EFFORT_A_FAIRE) {
          taskScore.effortsAFaire += 1
        } else if (task.diagEvalRangeName === EvaluationRange.Values.FORCE) {
          taskScore.force += 1
        }
      }
    })
  })

  // Construction des labels et des datasets
  taskScores.forEach((score, taskId) => {
    const taskTitle = data[0]?.tasks?.find(task => task.taskId === taskId)?.taskTitle
    const taskLabel = taskTitle || `Tâche ${taskId}`

    // Ajout du label (titre de la tâche)
    groupedTasks.labels.push(taskLabel)

    // Ajout des données aux datasets
    groupedTasks.datasets[0].data.push(score.defi) // Défi
    groupedTasks.datasets[1].data.push(score.effortsAFaire) // Efforts à faire
    groupedTasks.datasets[2].data.push(score.force) // Force
  })

  return groupedTasks
})

// Vérifie si le graphique a des données à afficher
const hasChartData = computed(() => {
  const data = transformDataForChart.value
  return data.labels.length > 0 &&
    data.datasets.some(dataset => dataset.data.some(value => value > 0))
})

// Configuration du graphique avec meilleure expérience utilisateur
const chartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'right',
      labels: {
        color: '#495057',
        font: {
          size: 14
        }
      }
    },
    tooltip: {
      enabled: true,
      callbacks: {
        // Formater les tooltips pour plus de clarté
        label: (context) => {
          const label = context.dataset.label || '';
          const value = context.parsed.y;
          return `${label}: ${value} élève${value > 1 ? 's' : ''}`;
        }
      }
    },
    datalabels: {
      anchor: 'end',
      align: 'end',
      color: '#000',
      font: {
        size: 12,
        weight: 'bold'
      },
      // N'afficher les valeurs que si > 0
      formatter: (value) => value > 0 ? value : '',
      display: (context) => context.dataset.data[context.dataIndex] > 0
    }
  },
  scales: {
    x: {
      ticks: {
        color: '#495057',
        font: {
          size: 14
        },
        callback: function (value: any, index: number, ticks: any) {
          const label = this.getLabelForValue(value)
          // Tronquer les labels trop longs
          return label.length > 20 ? label.substring(0, 17) + '...' : label
        }
      },
      grid: {
        display: false
      },
      barPercentage: 0.8 // Ajuste l'espacement entre les groupes
    },
    y: {
      beginAtZero: true,
      ticks: {
        color: '#495057',
        font: {
          size: 14
        },
        // Ajuste les intervalles en fonction du max
        stepSize: (context) => {
          const max = Math.max(...context.chart.data.datasets.flatMap(d => d.data));
          return max <= 5 ? 1 : max <= 20 ? 2 : 5;
        }
      },
      grid: {
        color: '#E5E5E5'
      }
    }
  },
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 10,
      bottom: 10
    }
  }
}

// Déterminer si nous sommes en cours de chargement
const isLoading = computed(() => {
  return loading.value || dashboardClassroomResultStoreLoading.value.fetchScoreDiagnostic
})

// Déterminer s'il n'y a pas de données
const isEmpty = computed(() => {
  return !isLoading.value &&
    (!scoreDiagnostic.value || scoreDiagnostic.value.length === 0 || !hasChartData.value)
})
</script>

<template>
  <dashboard-base-block
    title="Diagnostic"
    :action='() => openDiagnosticDialog(courseId)'
    accordion
    :active-index='-1'
    class="dashboard-diagnostic-block"
  >
    <!-- Affichage en cas d'erreur -->
    <p-message
      v-if="error"
      severity="error"
      class="mb-3 w-full"
    >
      {{ error }}
    </p-message>

    <!-- Affichage en cas de chargement -->
    <div v-else-if="isLoading" class="flex align-items-center justify-content-center p-5">
      <p-progress-spinner
        style="width: 50px; height: 50px;"
        strokeWidth="5"
        fill="#EEEEEE"
        animationDuration=".5s"
      />
    </div>

    <!-- Cas où il n'y a pas de données -->
    <div
      v-else-if="isEmpty"
      class="flex flex-column align-items-center justify-content-center p-4 bg-gray-50 border-round-md"
    >
      <i class="pi pi-chart-bar text-4xl text-500 mb-3"></i>
      <h3 class="mt-0 mb-2">Aucune donnée disponible</h3>
      <p class="text-center text-700">
        Aucun résultat de diagnostic n'est disponible pour ce groupe actuellement.
      </p>
    </div>

    <!-- Contenu principal -->
    <div
      v-else
      class="border-1 border-bo-brand-secondary p-4 flex flex-column gap-4 border-round-2xl col-10"
    >
      <h4 class="heading medium">Répartition des élèves par intervalles de scores</h4>
      <div class="chart-container" style="position: relative; height: 400px;">
        <p-chart
          type="bar"
          :data="transformDataForChart"
          :options="chartOptions"
        />
      </div>

      <!-- Légende expliquant les codes couleurs -->
      <div class="mt-3 p-3 bg-gray-50 border-round-md">
        <div class="flex flex-wrap gap-4 justify-content-start">
          <div class="flex align-items-center gap-2">
            <div class="color-indicator" style="background-color: #FF6F61;"></div>
            <span class="text-sm">Défi: score &lt; 40%</span>
          </div>
          <div class="flex align-items-center gap-2">
            <div class="color-indicator" style="background-color: #FBA86F;"></div>
            <span class="text-sm">Efforts à faire: score entre 40% et 70%</span>
          </div>
          <div class="flex align-items-center gap-2">
            <div class="color-indicator" style="background-color: #32CD32;"></div>
            <span class="text-sm">Force: score &gt; 70%</span>
          </div>
        </div>
      </div>
    </div>
  </dashboard-base-block>
</template>

<style scoped>
.chart-container {
  min-height: 300px;
  width: 100%;
}

.color-indicator {
  width: 16px;
  height: 16px;
  border-radius: 3px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .chart-container {
    height: 250px;
  }
}
</style>