/**
 * School period domain class
 */
import type {
  SchoolPeriodEntity,
  SchoolPeriodSchema
} from '@/core/infrastructure/schemas/school/period/SchoolPeriodSchema'
import type SchoolYear from '@/core/domain/school/year/SchoolYear'


export default class SchoolPeriod {
  readonly schoolPeriodId: number
  readonly schoolYear: SchoolYear
  readonly schoolId: number
  readonly countStudents: number
  readonly countTeachers: number
  readonly countClassrooms: number


  constructor(period: SchoolPeriodEntity) {
    this.schoolPeriodId = period.schoolPeriodId
    this.schoolYear = period.schoolYear
    this.schoolId = period.schoolId
    this.countStudents = period.countStudents
    this.countTeachers = period.countTeachers
    this.countClassrooms = period.countClassrooms
  }
}

export class SchoolPeriodInput {
  readonly schoolId: number
  readonly schoolYearId: number

  constructor(schoolId: number, schoolYearId: number) {
    this.schoolId = schoolId
    this.schoolYearId = schoolYearId
  }
}