import type {
  AnswerEntity,
  ContentItemEntity
} from '@/core/infrastructure/schemas/learning/quiz/LearningQuizQuestionAnswerSchema'
import { LearningQuizQuestionType } from '@/core/domain/resources/enums/LearningQuizQuestion'

export abstract class QuestionAnswer {
  id: number
  type: LearningQuizQuestionType.Values

  protected constructor(answer: AnswerEntity) {
    this.id = answer.id
    this.type = answer.type
  }

  abstract isValid(): boolean
}

// Classes pour les différents types de réponses
export class ChoiceAnswer extends QuestionAnswer {
  label: string
  isCorrect: boolean

  constructor(answer: Extract<AnswerEntity, { label: string; isCorrect: boolean }>) {
    super(answer)
    this.label = answer.label
    this.isCorrect = answer.isCorrect
  }

  isValid(): boolean {
    return !!this.label
  }
}

export class TrueFalseAnswer extends QuestionAnswer {
  label: string
  isCorrect: boolean

  constructor(answer: Extract<AnswerEntity, { label: string; isCorrect: boolean }>) {
    super(answer)
    this.label = answer.label
    this.isCorrect = answer.isCorrect
  }

  isValid(): boolean {
    return (this.label === 'Vrai' || this.label === 'Faux')
  }
}

export class MatchingAnswer extends QuestionAnswer {
  left: ContentItemEntity
  right: ContentItemEntity

  constructor(answer: Extract<AnswerEntity, { left: ContentItemEntity; right: ContentItemEntity }>) {
    super(answer)
    this.left = answer.left
    this.right = answer.right
  }

  isValid(): boolean {
    return !!this.left && !!this.left.type && !!this.left.value &&
      !!this.right && !!this.right.type && !!this.right.value
  }
}

export class SequencingAnswer extends QuestionAnswer {
  data: ContentItemEntity
  order: number

  constructor(answer: Extract<AnswerEntity, { data: ContentItemEntity; order: number }>) {
    super(answer)
    this.data = answer.data
    this.order = answer.order
  }

  isValid(): boolean {
    return !!this.data && !!this.data.type && !!this.data.value
  }
}
