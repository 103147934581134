import type {
  LearningCourseEntity,
  LearningDomainEntity
} from '@/core/infrastructure/schemas/learning/course/LearningCourseSchema'
import { LearningModule } from '@/core/domain/learning/module/LearningModule'
import { LearningStatus } from '@/core/domain/resources/enums/LearningStatus'

// Classe pour le domaine associé à un cours
export class LearningDomain {
  id: number
  title: string
  order: number
  tasks?: any[]

  constructor(domain: LearningDomainEntity) {
    this.id = domain.id
    this.title = domain.title
    this.order = domain.order
    this.tasks = domain.tasks
  }
}

export class LearningCourse {
  id: number
  title: string
  status: LearningStatus.Values
  order: number
  name: string
  domain?: LearningDomain | null
  domainId?: number
  learningModules: LearningModule[]
  createdBy?: string
  createdAt?: Date
  modifiedBy?: string
  modifiedAt?: Date

  constructor(course: LearningCourseEntity) {
    this.id = course.id
    this.title = course.title
    this.status = course.status
    this.order = course.order
    this.name = course.name
    this.domainId = course.domainId

    if (course.domain) {
      this.domain = new LearningDomain(course.domain)
    } else {
      this.domain = null
    }

    // Conversion des modules
    this.learningModules = course.learningModules.map(module =>
      new LearningModule(module)
    )

    this.createdBy = course.createdBy

    // Conversion des dates si elles existent
    if (course.createdAt) {
      this.createdAt = new Date(course.createdAt)
    }

    this.modifiedBy = course.modifiedBy

    if (course.modifiedAt) {
      this.modifiedAt = new Date(course.modifiedAt)
    }
  }

  // Retourne le nombre de modules du cours
  modulesCount(): number {
    return this.learningModules.length
  }

  // Retourne les modules actifs du cours
  get activeModules(): LearningModule[] {
    return this.learningModules.filter(module =>
      module.status === LearningStatus.Values.ACTIVE
    )
  }

  // Vérifie si le cours est valide
  isValid(): boolean {
    return this.title !== '' &&
      this.domain !== null &&
      this.activeModules.length > 0
  }

  // Vérifie si le cours est actif
  isActive(): boolean {
    return this.status === LearningStatus.Values.ACTIVE
  }

  // Obtenir les modules triés par ordre
  getSortedModules(): LearningModule[] {
    return [...this.learningModules].sort((a, b) => a.order - b.order)
  }

  // Convertir vers le format d'API pour la mise à jour
  toApiFormat(): Record<string, any> {
    return {
      id: this.id,
      title: this.title,
      status: this.status,
      order: this.order,
      name: this.name,
      domainId: this.domainId
    }
  }
}