import type {
  DashboardScoreModuleGrainEntity
} from '@/core/infrastructure/schemas/dashboard/DashboardScoreModuleGrainSchema'
import { EvaluationRange } from '@/core/domain/resources/enums/EvaluationRange'

export class DashboardScoreModuleGrain {
  readonly learningGrainId: number
  readonly learningGrainTitle: string
  readonly learningGrainOrder: number
  readonly postQuizScoreScaled?: number | null
  readonly postQuizScoreScaledHistory: number[]
  readonly postQuizCountAttempt?: number | null
  readonly postQuizEvalRangeName: EvaluationRange.Values


  constructor(dashboardGrain: DashboardScoreModuleGrainEntity) {
    this.learningGrainId = dashboardGrain.learningGrainId
    this.learningGrainTitle = dashboardGrain.learningGrainTitle
    this.learningGrainOrder = dashboardGrain.learningGrainOrder
    this.postQuizScoreScaled = dashboardGrain.postQuizScoreScaled
    this.postQuizScoreScaledHistory = dashboardGrain.postQuizScoreScaledHistory
    this.postQuizCountAttempt = dashboardGrain.postQuizCountAttempt
    this.postQuizEvalRangeName = dashboardGrain.postQuizEvalRangeName
  }
}