// stores/eventBus.ts
import { ref } from 'vue'

// Définir les types d'événements et leurs payloads
export interface StoreEvents {
  'group-updated': {
    schoolId: number;
    schoolPeriodId: number;
    schoolClassroomId: number;
    groupId: number;
  };
  'classroom-updated': {
    schoolId: number;
    schoolPeriodId: number;
    classroomId: number;
  };
  // Ajoutez d'autres événements selon vos besoins
}

// Type pour un événement enregistré avec discriminant
export type StoredEvent =
  | { name: 'group-updated'; timestamp: number; payload: StoreEvents['group-updated'] }
  | { name: 'classroom-updated'; timestamp: number; payload: StoreEvents['classroom-updated'] };

// Référence pour le dernier événement
export const lastEvent = ref<StoredEvent | null>(null);

// Fonction pour émettre un événement
export function emitStoreEvent<K extends keyof StoreEvents>(
  eventName: K,
  payload: StoreEvents[K]
): void {
  lastEvent.value = {
    name: eventName,
    timestamp: Date.now(),
    payload
  } as StoredEvent; // Le cast est sûr car nous contrôlons les types d'entrée
}

// Hook pour utiliser les événements
export function useStoreEvents() {
  return {
    lastEvent,
    emit: emitStoreEvent
  };
}