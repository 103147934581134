import type ISchoolRepository from '@/core/domain/school/core/ISchoolRepository'
import type School from '@/core/domain/school/core/School'


export default class FetchSchoolUseCase {
  private schoolRepository: ISchoolRepository;

  constructor(schoolRepository: ISchoolRepository) {
    this.schoolRepository = schoolRepository;
  }

  async execute(): Promise<School[]> {
    return await this.schoolRepository.fetchSchoolsForMe();
  }
}