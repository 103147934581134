<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { default as PDivider } from 'primevue/divider'
import { default as PChart } from 'primevue/chart'
import { default as PTabView } from 'primevue/tabview'
import { default as PTabPanel } from 'primevue/tabpanel'

import type { TooltipItem } from 'chart.js'
import { useDashboardClassroomStore } from '@/stores/dashboard/dashboardClassroom'
import BaseBlock from '@/components/base/BaseBlock.vue'

// PROPS
const props = defineProps<{
  schoolId: number
  classroomId: number
}>()

// STORES REFS
const { dashboardClassroomLoginHistory } = storeToRefs(useDashboardClassroomStore())

// LOCAL REFS
const chartData = ref()
const chartOptions = ref()

// COMPUTED
const setChartData = computed(() => {
  return {
    labels: [
      `Pas connecté depuis plus de 14 jours : ${dashboardClassroomLoginHistory.value?.moreThan14Days?.length}`,
      `Connecté il y a plus de 7 jours et moins de 14 jours : ${dashboardClassroomLoginHistory.value?.between7And14Days?.length}`,
      `Connecté(s) les 7 derniers jours : ${dashboardClassroomLoginHistory.value?.lessThan7Days?.length}`
    ],
    datasets: [
      {
        name: 'lessThan7Days',
        data: [
          dashboardClassroomLoginHistory.value?.moreThan14Days?.length,
          dashboardClassroomLoginHistory.value?.between7And14Days?.length,
          dashboardClassroomLoginHistory.value?.lessThan7Days?.length
        ],
        backgroundColor: ['#FF6259', '#EEC137', '#4CD07D'],
        hoverBackgroundColor: ['#FF6259', '#EEC137', '#4CD07D'],
        hoverOffset: 4
      }
    ]
  }
})

const setChartOptions = computed(() => {
  return {
    responsive: true, // Le graphique est responsive
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(
          '--bo-brand-secondary'
        ),
        titleColor: '#000000',
        bodyColor: '#000000',
        footerColor: '#000000',
        borderColor: getComputedStyle(document.documentElement).getPropertyValue(
          '--bo-brand-tertiary'
        ),
        borderWidth: 1,
        displayColors: false,
        callbacks: {
          title: function (tooltipItems: TooltipItem<'pie'>[]) {
            return tooltipItems[0].label
          },
          label: function () {
            return ''
          }
        }
      },
      legend: {
        display: true,
        align: 'start',
        position: 'left',
        fullSize: false,
        labels: {
          usePointStyle: true
        }
      }
    }
  }
})

// STORE METHODS
const { fetchDashboardClassroomLoginHistory, reset } = useDashboardClassroomStore()

// METHODS


// WATCHERS


// LIFECYCLE HOOKS
onMounted(async () => {
  await fetchDashboardClassroomLoginHistory()
  chartData.value = setChartData.value
  chartOptions.value = setChartOptions.value
})

onUnmounted(() => {
  reset()
})
</script>

<template>
  <div class="flex flex-column grid col-12">
    <base-block title="Assiduité des élèves">
      <!-- Chart -->

      <div class="col-5 flex align-items-start justify-content-start">
        <p-chart type="pie" :data="chartData" :options="chartOptions" class="w-full" />
      </div>
      <!-- Divider -->

      <p-divider layout="vertical" class="col-fixed w-auto" />
      <!-- List -->

      <div class="col">
        <p-tab-view>
          <p-tab-panel header="Plus de 14 jours">
            <ul class="list-disc overflow-x-auto" :class="$style['MultiColumnList']">
              <li
                v-for="student in dashboardClassroomLoginHistory?.moreThan14Days"
                :key="student.identifier"
                class="flex-none w-6"
              >
                {{ student.fullName }}
              </li>
            </ul>
          </p-tab-panel>
          <p-tab-panel header="Entre 7 et 14 jours">
            <ul class="list-disc">
              <li
                v-for="student in dashboardClassroomLoginHistory?.between7And14Days"
                :key="student.identifier"
                class="flex-none w-6"
              >
                {{ student.fullName }}
              </li>
            </ul>
          </p-tab-panel>
          <p-tab-panel header="Moins de 7 jours">
            <ul class="list-disc">
              <li
                v-for="student in dashboardClassroomLoginHistory?.lessThan7Days"
                :key="student.identifier"
                class="flex-none w-6"
              >
                {{ student.fullName }}
              </li>
            </ul>
          </p-tab-panel>
        </p-tab-view>
      </div>
    </base-block>
  </div>
</template>

<style module>
.MultiColumnList {
  column-count: 3;
  column-gap: 5px;
  max-height: 200px; /* Limite la hauteur de la liste */
}
</style>
