import type ISchoolRepository from '@/core/domain/school/core/ISchoolRepository'
import School, { SchoolInput, SchoolMe } from '@/core/domain/school/core/School'
import type ApiService from '@/core/infrastructure/services/ApiService'
import {
  SchoolInputListSchema,
  SchoolMeListSchema,
  SchoolSchema,
  SchoolsListSchema
} from '@/core/infrastructure/schemas/school/core/SchoolSchema'

export default class SchoolRepository implements ISchoolRepository {
  private apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  async fetchSchool(schoolId: number): Promise<School> {
    const response = await this.apiService.get<School>(`admin/schools/${schoolId}`);

    const parse = SchoolSchema.safeParse(response);

    if (parse.success) {
      return new School(parse.data);
    }

    return Promise.reject('Parsing error');
  }

  async fetchSchoolsForMe(): Promise<SchoolMe[]> {
    const response = await this.apiService.get<SchoolMe[]>('admin/schools/me');

    console.log(response);

    const parse = SchoolMeListSchema.safeParse(response);
    console.log(parse);

    if (parse.success) {
      return parse.data.map((school) => new SchoolMe(school));
    }

    return Promise.reject('Parsing error');
  }

  async fetchAllSchools(): Promise<School[]> {
    const response = await this.apiService.get<School[]>('admin/schools');

    console.log(response);

    const parse = SchoolsListSchema.safeParse(response);
    console.log(parse);

    if (parse.success) {
      return parse.data.map((school) => new School(school));
    }

    return Promise.reject('Parsing error');
  }

  async fetchSchoolFromDataEducationByUai(codeUai: string): Promise<SchoolInput> {
    const response = await this.apiService.get<SchoolInput[]>(`admin/schools/directory/${codeUai}`);

    console.log(response);

    const parse = SchoolInputListSchema.safeParse(response);
    console.log(parse);


    if (parse.success) {
      if (parse.data.length > 0) {
        return new SchoolInput(parse.data[0]);
      }

      return Promise.reject('No school found');
    }

    return Promise.reject('Parsing error');
  }

  async createSchool(school: SchoolInput): Promise<School> {
    const response = await this.apiService.post<School>('admin/schools', school.schoolInput);

    console.log(response)

    const parse = SchoolSchema.safeParse(response);
    console.log(parse);

    if (parse.success) {
      return new School(parse.data);
    }

    return Promise.reject('Parsing error');
  }


  async updateSchool(schoolId: number, school: SchoolInput): Promise<School> {
    const response = this.apiService.put<School>(`admin/schools/${schoolId}`, school.schoolInput);

    const parse = SchoolSchema.safeParse(response);

    if (parse.success) {
      return new School(parse.data);
    }

    return Promise.reject('Parsing error');
  }
}