<script setup lang="ts">
/*
 *
 * IMPORTS
 *
 * */
// Vue, Vue Router etc
import { onMounted, ref } from 'vue'

// Stores
import { useReferentialStore } from '@/stores/learning/referential'
import { useLearningCoursesStore } from '@/stores/learning/learningCourses'

// Custom components and types
import LearningHeader from '@/views/learningCourses/blocks/LearningHeader.vue'
import LearningModulesList from '@/views/learningCourses/blocks/LearningModulesList.vue'
import LearningCourseTasks from '@/views/learningCourses/blocks/LearningCourseTasks.vue'
import type { ILearningCourse } from '@/assets/types/learning/LearningCourses'
import { LearningEnums } from '@/assets/types/learning/enums'

// Utility

// Data
const loading = ref(true)

// Props
const props = defineProps<{
  learningCourse: ILearningCourse
  axis: string
}>()

// Lifecycle hooks
onMounted(async () => {
  loading.value = false
})

//Computed

// Methods
const { getAxisTitleFromDomainId } = useReferentialStore()
const { updateLearningCourseStatus } = useLearningCoursesStore()

async function publishCourse() {
  await updateLearningCourseStatus(
    props.learningCourse.id,
    LearningEnums.Status.ACTIVE
  )
}
</script>

<template>
  <div class="flex flex-column overflow-y-auto max-h-full pb-5" v-if="!loading">
    <learning-header
      :status="learningCourse.status"
      :title="`Parcours : ${learningCourse.title}`"
      :back-to="{
        label: 'Retour à la liste des parcours',
        route: { name: 'learning-courses-list' }
      }"
      :primary-button="{
        label: 'Publier le parcours',
        onClick: () => publishCourse()
      }"
    />

    <div class="w-full px-5 grid grid-nogutter gap-5">
      <div class="col-12">
        <div class="font-bold text-color-secondary">Axe</div>
        <div class="text-color-secondary mt-1">
          {{ getAxisTitleFromDomainId(learningCourse.domain.id) }}
        </div>
      </div>

      <div class="col-12">
        <div class="font-bold text-color-secondary">Domaine</div>
        <div class="text-color-secondary mt-1">
          {{ learningCourse.domain.title }}
        </div>
      </div>

      <learning-course-tasks
        :tasks="learningCourse.domain.tasks"
        :learning-course-id="learningCourse.id"
      />

      <learning-modules-list
        :modules-list="learningCourse.learningModules"
        :learning-course-id="learningCourse.id"
      />
    </div>
  </div>
</template>
