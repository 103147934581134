import DependencyContainer from '@/presentation/configuration/DependencyContainer'
import FetchAllSchoolPeriodsUseCase from '@/core/useCases/school/period/FetchAllSchoolPeriodsUseCase'
import SchoolPeriod, { SchoolPeriodInput } from '@/core/domain/school/period/SchoolPeriod'
import { computed, ref } from 'vue'
import CreateSchoolPeriodUseCase from '@/core/useCases/school/period/CreateSchoolPeriodUseCase'
import { defineStore } from 'pinia'
import { useToast } from 'primevue/usetoast'
import { NabooError } from '@/assets/classes/Error'
import { createStoreBase, type ErrorState, type LoadingState } from '@/stores/resources/storeBase'


export const useSchoolPeriodStore = defineStore('SchoolPeriod', () => {
  const storeBase = createStoreBase(['fetchAll', 'create']);

  // SERVICES
  const toast = useToast();

  // ÉTAT
  const schoolPeriods = ref<SchoolPeriod[]>([]);
  const selectedSchoolPeriod = ref<SchoolPeriod | null>(null);
  const currentSchoolId = ref<number | null>(null);

  // MÉTHODES PRIVÉES
  /**
   * Réinitialise l'état d'erreur
   */
  function resetError(): void {
    storeBase.error.value = {
      hasError: false,
      message: '',
      code: undefined,
      details: undefined
    };
  }

  /**
   * Configure l'état d'erreur
   */
  function setError(err: any): void {
    storeBase.error.value.hasError = true;

    if (err instanceof NabooError) {
      storeBase.error.value.message = err.message;
      storeBase.error.value.code = err.getCode ? err.getCode() : undefined;
      storeBase.error.value.details = err.getErrorDetails ? err.getErrorDetails() : undefined;
    } else if (err instanceof Error) {
      storeBase.error.value.message = err.message;
    } else {
      storeBase.error.value.message = 'Une erreur inconnue est survenue';
    }
  }

  // ACTIONS PUBLIQUES
  /**
   * Récupère toutes les périodes scolaires pour un établissement
   * @param schoolId Identifiant de l'établissement
   * @param forceRefresh Force le rechargement même si les données sont déjà présentes
   */
  async function fetchAllSchoolPeriodsForSchool(
    schoolId: number,
    forceRefresh = false
  ): Promise<SchoolPeriod[]> {
    // Éviter le rechargement inutile si les données sont déjà là pour le même établissement
    if (
      !forceRefresh &&
      schoolPeriods.value.length > 0 &&
      currentSchoolId.value === schoolId &&
      storeBase.lastUpdated.value &&
      Date.now() - storeBase.lastUpdated.value.getTime() < 5 * 60 * 1000 // 5 minutes
    ) {
      return schoolPeriods.value;
    }

    try {
      storeBase.loading.value.fetchAll = true;
      resetError();
      currentSchoolId.value = schoolId;

      console.log('Fetching all school periods for school ID:', schoolId);
      const response = await DependencyContainer.resolve(FetchAllSchoolPeriodsUseCase).execute(schoolId);
      schoolPeriods.value = response;
      storeBase.lastUpdated.value = new Date();

      // Sélectionner automatiquement la période courante si aucune n'est sélectionnée
      if (!selectedSchoolPeriod.value) {
        const currentPeriod = response.find((schoolPeriod) => schoolPeriod.schoolYear.isCurrent);
        if (currentPeriod) {
          selectedSchoolPeriod.value = currentPeriod;
        } else if (response.length > 0) {
          // Si pas de période courante, prendre la plus récente
          selectedSchoolPeriod.value = response.sort((a, b) => {
            const dateA = new Date(a.schoolYear.startDate);
            const dateB = new Date(b.schoolYear.startDate);
            return dateB.getTime() - dateA.getTime();
          })[0];
        }
      }

      return response;
    } catch (err) {
      setError(err);
      return Promise.reject(err);
    } finally {
      storeBase.loading.value.fetchAll = false;
    }
  }

  /**
   * Crée une nouvelle période scolaire
   * @param schoolPeriodInput Données de la période scolaire à créer
   */
  async function createSchoolPeriod(schoolPeriodInput: SchoolPeriodInput): Promise<SchoolPeriod> {
    try {
      storeBase.loading.value.create = true;
      resetError();

      const response = await DependencyContainer.resolve(CreateSchoolPeriodUseCase).execute(schoolPeriodInput);

      schoolPeriods.value.push(response);
      storeBase.lastUpdated.value = new Date();

      storeBase.showSuccessToast(
        'Période scolaire créée',
        'La période scolaire a bien été créée.'
      );

      return response;
    } catch (err) {
      setError(err);
      return Promise.reject(err);
    } finally {
      storeBase.loading.value.create = false;
    }
  }

  /**
   * Sélectionne une période scolaire
   * @param schoolPeriod Période scolaire à sélectionner
   */
  function selectSchoolPeriod(schoolPeriod: SchoolPeriod | null): void {
    selectedSchoolPeriod.value = schoolPeriod;
  }

  /**
   * Réinitialise le store
   */
  function reset(): void {
    schoolPeriods.value = [];
    selectedSchoolPeriod.value = null;
    currentSchoolId.value = null;
    storeBase.lastUpdated.value = null;
    resetError();

    storeBase.loading.value = {
      fetchAll: false,
      create: false
    };
  }

  // GETTERS
  /**
   * Période scolaire courante (basée sur la date actuelle)
   */
  const getCurrentSchoolPeriod = computed(() => {
    return schoolPeriods.value.find((schoolPeriod) => schoolPeriod.schoolYear.isCurrent);
  });

  /**
   * Indique si des données sont en cours de chargement
   */
  const isLoading = computed(() =>
    Object.values(storeBase.loading.value).some(Boolean)
  );

  /**
   * Indique si les données doivent être rechargées
   */
  const needsFreshData = computed(() => {
    if (!storeBase.lastUpdated.value) return true;

    // Considérer les données comme périmées après 5 minutes
    const fiveMinutesInMs = 5 * 60 * 1000;
    return Date.now() - storeBase.lastUpdated.value.getTime() > fiveMinutesInMs;
  });

  /**
   * Récupère une période scolaire par son ID
   */
  const getSchoolPeriodById = computed(() => {
    return (id: number) => schoolPeriods.value.find(period => period.schoolPeriodId === id);
  });

  /**
   * Périodes scolaires triées par date de début (plus récentes en premier)
   */
  const sortedSchoolPeriods = computed(() => {
    return [...schoolPeriods.value].sort((a, b) => {
      const dateA = new Date(a.schoolYear.startDate);
      const dateB = new Date(b.schoolYear.startDate);
      return dateB.getTime() - dateA.getTime();
    });
  });

  /**
   * Nombre de périodes scolaires
   */
  const periodCount = computed(() => schoolPeriods.value.length);

  return {
    // État
    schoolPeriods,
    selectedSchoolPeriod,
    schoolPeriodStoreLoading: storeBase.loading,
    schoolPeriodStoreError: storeBase.error,
    schoolPeriodStoreLastUpdated: storeBase.lastUpdated,

    // Actions
    fetchAllSchoolPeriodsForSchool,
    createSchoolPeriod,
    selectSchoolPeriod,
    reset,

    // Getters
    getCurrentSchoolPeriod,
    schoolPeriodStoreIsLoading: isLoading,
    needsFreshData,
    getSchoolPeriodById,
    sortedSchoolPeriods,
    periodCount
  };
});