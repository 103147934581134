import type { UserEntity } from '@/core/infrastructure/schemas/users/core/UserSchema'
import { UserGender } from '@/core/domain/resources/enums/UserGender'
import { UserRole } from '@/core/domain/resources/enums/UserRole'


/**
 * Classe représentant un utilisateur dans le système
 */
export class User {
  readonly identifier: string
  readonly firstname: string
  readonly lastname: string
  readonly nickname?: string
  readonly username?: string
  readonly email: string
  readonly isEmailVerified: boolean
  readonly gender: UserGender.Values
  readonly roles: UserRole.Values[]
  readonly birthdate: Date
  readonly picture?: string
  readonly isBlocked: boolean
  readonly hasAnsweredTrackingConsentBanner: boolean
  readonly hasConsentedForTracking: boolean
  readonly isRegisterProcessDone: boolean
  readonly preferences: {
    onboarding: {
      isGeneralCompleted: boolean
      isDiagnosticCompleted: boolean
      isModuleCompleted: boolean
    }
  }
  readonly createdAt: string

  /**
   * Crée une nouvelle instance d'utilisateur
   * @param user Données de l'entité utilisateur
   */
  constructor(user: UserEntity) {
    this.identifier = user.identifier
    this.firstname = user.firstname
    this.lastname = user.lastname
    this.nickname = user.nickname
    this.username = user.username
    this.email = user.email
    this.isEmailVerified = user.isEmailVerified
    this.gender = user.gender
    this.roles = user.roles
    this.birthdate = new Date(user.birthdate)
    this.picture = user.picture
    this.isBlocked = user.isBlocked
    this.hasAnsweredTrackingConsentBanner = user.hasAnsweredTrackingConsentBanner
    this.hasConsentedForTracking = user.hasConsentedForTracking
    this.isRegisterProcessDone = user.isRegisterProcessDone
    this.preferences = user.preferences
    this.createdAt = new Date(user.createdAt).toISOString()
  }

  /**
   * Retourne le nom complet de l'utilisateur
   */
  get fullName(): string {
    return `${this.firstname} ${this.lastname}`
  }

  /**
   * Vérifie si l'utilisateur a un rôle spécifique
   * @param role Rôle à vérifier
   * @returns Vrai si l'utilisateur a le rôle spécifié
   */
  hasRole(role: UserRole.Values): boolean {
    return this.roles.includes(role)
  }

  /**
   * Vérifie si l'utilisateur est administrateur
   * @returns Vrai si l'utilisateur est administrateur
   */
  isAdmin(): boolean {
    return this.hasRole(UserRole.Values.ADMIN)
  }

  /**
   * Vérifie si l'utilisateur est enseignant
   * @returns Vrai si l'utilisateur est enseignant
   */
  isTeacher(): boolean {
    return this.hasRole(UserRole.Values.TEACHER)
  }

  /**
   * Vérifie si l'utilisateur est étudiant
   * @returns Vrai si l'utilisateur est étudiant
   */
  isStudent(): boolean {
    return this.hasRole(UserRole.Values.STUDENT)
  }

  /**
   * Vérifie si l'utilisateur est actif (non bloqué)
   * @returns Vrai si l'utilisateur est actif
   */
  isActive(): boolean {
    return !this.isBlocked
  }

  /**
   * Vérifie si l'utilisateur a terminé le processus d'onboarding
   * @returns Vrai si l'utilisateur a terminé l'onboarding
   */
  hasCompletedOnboarding(): boolean {
    return (
      this.preferences?.onboarding?.isGeneralCompleted &&
      this.preferences?.onboarding?.isDiagnosticCompleted &&
      this.preferences?.onboarding?.isModuleCompleted
    )
  }
}

// /**
//  * Classe pour gérer les données d'entrée lors de la création d'un utilisateur
//  */
// export class UserInput {
//   firstname: string = ''
//   lastname: string = ''
//   nickname?: string
//   username: string = ''
//   email: string = ''
//   isEmailVerified: boolean = false
//   gender: keyof typeof GenderEnum = 'MALE'
//   roles: Array<keyof typeof RoleEnum> = ['USER']
//   birthdate: string = ''
//   picture?: string
//   isBlocked: boolean = false
//   hasAnsweredTrackingConsentBanner: boolean = false
//   hasConsentedForTracking: boolean = false
//   isRegisterProcessDone: boolean = false
//   preferences: {
//     onboarding: {
//       isGeneralCompleted: boolean
//       isDiagnosticCompleted: boolean
//       isModuleCompleted: boolean
//     }
//   } = {
//     onboarding: {
//       isGeneralCompleted: false,
//       isDiagnosticCompleted: false,
//       isModuleCompleted: false
//     }
//   }
//
//   /**
//    * Crée une nouvelle instance de données d'entrée pour un utilisateur
//    * @param data Données d'entrée optionnelles
//    */
//   constructor(data?: UserCreateEntity) {
//     if (data) {
//       this.firstname = data.firstname
//       this.lastname = data.lastname
//       this.nickname = data.nickname
//       this.username = data.username
//       this.email = data.email
//       this.isEmailVerified = data.isEmailVerified
//       this.gender = data.gender as keyof typeof GenderEnum
//       this.roles = data.roles as Array<keyof typeof RoleEnum>
//       this.birthdate = data.birthdate
//       this.picture = data.picture
//
//       if (data.hasAnsweredTrackingConsentBanner !== undefined) {
//         this.hasAnsweredTrackingConsentBanner = data.hasAnsweredTrackingConsentBanner
//       }
//
//       if (data.hasConsentedForTracking !== undefined) {
//         this.hasConsentedForTracking = data.hasConsentedForTracking
//       }
//
//       if (data.isRegisterProcessDone !== undefined) {
//         this.isRegisterProcessDone = data.isRegisterProcessDone
//       }
//
//       if (data.preferences) {
//         this.preferences = data.preferences
//       }
//     }
//   }
//
//   /**
//    * Vérifie si les données d'entrée sont valides
//    * @returns Vrai si les données sont valides
//    */
//   isValid(): boolean {
//     return (
//       !!this.firstname &&
//       !!this.lastname &&
//       !!this.username &&
//       !!this.email &&
//       !!this.birthdate &&
//       this.roles.length > 0
//     )
//   }
//
//   /**
//    * Convertit les données d'entrée en objet pour l'API
//    * @returns Un objet représentant les données d'entrée
//    */
//   toRaw(): UserCreateEntity {
//     const raw: UserCreateEntity = {
//       firstname: this.firstname,
//       lastname: this.lastname,
//       username: this.username,
//       email: this.email,
//       isEmailVerified: this.isEmailVerified,
//       gender: this.gender as any,
//       roles: this.roles as any,
//       birthdate: this.birthdate,
//       hasAnsweredTrackingConsentBanner: this.hasAnsweredTrackingConsentBanner,
//       hasConsentedForTracking: this.hasConsentedForTracking,
//       isRegisterProcessDone: this.isRegisterProcessDone,
//       preferences: this.preferences
//     }
//
//     if (this.nickname) {
//       raw.nickname = this.nickname
//     }
//
//     if (this.picture) {
//       raw.picture = this.picture
//     }
//
//     return raw
//   }
//
//   /**
//    * Crée une instance à partir d'un objet brut
//    * @param data Données brutes
//    * @returns Une nouvelle instance avec les données fournies
//    */
//   static fromRaw(data: Partial<UserCreateEntity>): UserInput {
//     return new UserInput(data as UserCreateEntity)
//   }
// }