import type ISchoolRepository from '@/core/domain/school/core/ISchoolRepository'
import School, { SchoolInput } from '@/core/domain/school/core/School'


export default class FetchSchoolFromDataEducationUseCase {
  private schoolRepository: ISchoolRepository;

  constructor(schoolRepository: ISchoolRepository) {
    this.schoolRepository = schoolRepository;
  }

  async execute(codeUai: string): Promise<SchoolInput> {
    return await this.schoolRepository.fetchSchoolFromDataEducationByUai(codeUai);
  }
}