import { nativeEnum, z } from 'zod'
import { EvaluationRange } from '@/core/domain/resources/enums/EvaluationRange'

export const DashboardScoreDiagnosticTaskSchema = z.object({
  taskId: z.number(),
  taskTitle: z.string(),
  taskOrder: z.number(),
  diagScorePercentRounded: z.number().nullable(),
  diagEvalRangeName: nativeEnum(EvaluationRange.Values).nullable()
})

export type DashboardScoreDiagnosticTaskEntity = z.infer<typeof DashboardScoreDiagnosticTaskSchema>