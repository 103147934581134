import type { ISchoolPeriodUserRepository } from '@/core/domain/school/user/ISchoolPeriodUserRepository'
import type { SchoolPeriodUser } from '@/core/domain/school/user/SchoolPeriodUser'


export default class FetchAllSchoolPeriodUsersUseCase {
  private schoolPeriodUserRepository: ISchoolPeriodUserRepository;

  constructor(schoolPeriodRepository: ISchoolPeriodUserRepository) {
    this.schoolPeriodUserRepository = schoolPeriodRepository;
  }

  async execute(schoolId: number, schoolPeriodId: number): Promise<SchoolPeriodUser[]> {
    return await this.schoolPeriodUserRepository.fetchAllSchoolPeriodUsers(schoolId, schoolPeriodId);
  }
}