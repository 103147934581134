import type { ISchoolMonitoringGroupRepository } from '@/core/domain/school/group/ISchoolMonitoringGroupRepository'

export class DeleteSchoolMonitoringGroupUseCase {
  private schoolMonitoringGroupRepository: ISchoolMonitoringGroupRepository;

  /**
   * Constructeur du cas d'utilisation
   * @param schoolMonitoringGroupRepository - Repository pour gérer les groupes de suivi scolaire
   */
  constructor(schoolMonitoringGroupRepository: ISchoolMonitoringGroupRepository) {
    this.schoolMonitoringGroupRepository = schoolMonitoringGroupRepository;
  }

  /**
   * Supprime un groupe de suivi scolaire
   * @param schoolId - Identifiant de l'école
   * @param schoolPeriodId - Identifiant de la période scolaire
   * @param schoolClassroomId - Identifiant de la classe
   * @param schoolMonitoringGroupId - Identifiant du groupe à supprimer
   * @returns Une promesse contenant l'objet SchoolMonitoringGroup supprimé
   */
  async execute(
    schoolId: number,
    schoolPeriodId: number,
    schoolClassroomId: number,
    schoolMonitoringGroupId: number
  ) {

    try {
      await this.schoolMonitoringGroupRepository.deleteSchoolMonitoringGroup(
        schoolId,
        schoolPeriodId,
        schoolClassroomId,
        schoolMonitoringGroupId
      );
      return Promise.resolve();
    }
    catch (error) {
      return Promise.reject('Error: ' + error);
    }
  }
}