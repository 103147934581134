<script setup lang="ts">
import { onMounted, type Ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useNabooContentStore } from '@/stores/content/nabooContent'
import { storeToRefs } from 'pinia'
import DashboardBaseBlock from '@/views/dashboard/blocks/DashboardBaseBlock.vue'
import NabooContentLearningCourses from '@/views/nabooContent/blocks/NabooContentLearningCourses.vue'
import { NabooContentAxisDto, type NabooContentLearningCourseDto } from '@/assets/DTO/nabooContent/nabooContent.dto'

// SERVICES
const toast = useToast()

// STORES
const { fetchNabooContent } = useNabooContentStore()
const { listAxis } = storeToRefs(useNabooContentStore())

onMounted(async () => {
  try {
    await fetchNabooContent()
  } catch (error) {
    if (error instanceof Error) {
      toast.add({ severity: 'error', summary: 'Erreur', detail: error.message })
    } else {
      toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: 'Une erreur est survenue, merci de contacter le support'
      })
    }
  }
})
</script>

<template>
  <div class="col-12 grid grid-nogutter p-5 py-3 border-bottom-1 border-300">
    <div class="col justify-content-start flex flex-column gap-2">
      <h3 class="uppercase text-left">Liste des contenus Naboo</h3>
    </div>
  </div>

  <div
    class="col-12 p-5 bg-bo-brand-tertiary flex-grow-1 overflow-y-auto gap-5 flex flex-column h-full"
  >
    <dashboard-base-block
      v-for="axis in listAxis"
      :key="`axis_${axis.id}`"
      :title="axis.title"
      :accordion="true"
    >
      <naboo-content-learning-courses
        :learning-courses="axis.learningCourses"
      />
    </dashboard-base-block>
  </div>
</template>
