import { z } from "zod";
import {
  SchoolClassroomLoginHistoryUserSchema
} from '@/core/infrastructure/schemas/school/user/SchoolClassroomLoginHistoryUserSchema'

// Schéma pour l'objet principal qui contient les trois catégories d'utilisateurs
export const SchoolClassroomLoginHistorySchema = z.object({
  lessThan7Days: z.array(SchoolClassroomLoginHistoryUserSchema),
  between7And14Days: z.array(SchoolClassroomLoginHistoryUserSchema),
  moreThan14Days: z.array(SchoolClassroomLoginHistoryUserSchema)
});

// Pour exporter le schéma
export type SchoolClassroomLoginHistoryEntity = z.infer<typeof SchoolClassroomLoginHistorySchema>;