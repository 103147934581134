import { ref } from 'vue'
import type { MenuItem } from 'primevue/menuitem'
import { useAuthStore } from '@/stores/auth'

export function useUserMenu() {
  // Store
  const authStore = useAuthStore()

  // Refs
  const menuAvatar = ref()

  // Menu items
  const userMenuItems: MenuItem[] = [
    {
      label: 'Se déconnecter',
      icon: 'pi pi-sign-out',
      command: () => handleLogout()
    }
  ]

  /**
   * Gère la déconnexion de l'utilisateur
   */
  async function handleLogout() {
    try {
      await authStore.initLogout()
    } catch (err) {
      console.error('Erreur lors de la déconnexion:', err)
    }
  }

  /**
   * Affiche le menu de l'avatar
   */
  function toggleUserMenu(event: Event) {
    menuAvatar.value.toggle(event)
  }

  return {
    menuAvatar,
    userMenuItems,
    toggleUserMenu
  }
}