<script setup lang="ts">
import { computed, ref } from 'vue'
import { default as PTable } from 'primevue/datatable'
import { default as PColumn } from 'primevue/column'
import { default as PButton } from 'primevue/button'
import { default as PProgressSpinner } from 'primevue/progressspinner'
import { useToast } from 'primevue/usetoast'
import type SchoolClassroom from '@/core/domain/school/classroom/SchoolClassroom'
import type SchoolMonitoringGroup from '@/core/domain/school/group/SchoolMonitoringGroup'
import { useDialog } from 'primevue/usedialog'
import SchoolMonitoringGroupUpdate from '@/components/schools/SchoolMonitoringGroupUpdate.vue'
import { useSchoolMonitoringGroupStore } from '@/stores/school/schoolMonitoringGroup'
import { useConfirm } from 'primevue/useconfirm'

// Stores
const monitoringGroupStore = useSchoolMonitoringGroupStore()


// Props
const props = defineProps<{
  classroom: SchoolClassroom | null;
  loading: boolean;
  schoolId: number;
  schoolPeriodId: number;
}>()

// Composables
const toast = useToast()
const dialog = useDialog()
const confirm = useConfirm()

// Local state
const localLoading = ref(false)

// Computed
const hasGroups = computed(() => {
  return props.classroom?.groups && props.classroom.groups.length > 0;
})

// Methods
/**
 * Ouvre le formulaire de création d'un groupe
 */
async function openCreateGroupForm() {
  if (!props.classroom)
    return

  try {
    const newGroup = await monitoringGroupStore.createSchoolMonitoringGroup(
      props.schoolId,
       props.schoolPeriodId,
       props.classroom?.schoolClassroomId
    )

    if (newGroup) {
      toast.add({
        severity: 'success',
        summary: 'Groupe créé',
        detail: `Le groupe ${newGroup.name} a été créé avec succès.`,
        life: 3000
      })
    }

    manageGroupMembers(newGroup)
  } catch (error) {
    toast.add({
      severity: 'error',
      summary: 'Erreur',
      detail: `Une erreur est survenue lors de la création du groupe : ${error.message}`,
      life: 3000
    })
  } finally {
    localLoading.value = false
  }
}

/**
 * Ouvre la page de gestion des membres d'un groupe
 * @param schoolMonitoringGroup Identifiant du groupe
 */
function manageGroupMembers(schoolMonitoringGroup: SchoolMonitoringGroup) {
  dialog.open(SchoolMonitoringGroupUpdate, {
    props: {
      header: `Gérer les membres du groupe ${schoolMonitoringGroup.name}`,
      dismissableMask: true,
      draggable: false,
      modal: true,
      style: {
        width: '75vw',
      },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
    },
    data: {
      schoolId: props.schoolId,
      schoolPeriodId: props.schoolPeriodId,
      schoolClassroomId: props.classroom?.schoolClassroomId,
      groupId: schoolMonitoringGroup.groupId,
      groupData: schoolMonitoringGroup,
    }
  })
}

/**
 * Ouvre les statistiques d'un groupe
 * @param groupId Identifiant du groupe
 */
function openGroupStats(groupId: number) {
  toast.add({
    severity: 'info',
    summary: 'Action',
    detail: `Statistiques du groupe ${groupId} (à implémenter)`,
    life: 3000
  })
}

/**
 * Confirme la suppression d'un groupe
 * @param groupId Identifiant du groupe
 */
function confirmDeleteGroup(groupId: number) {
  if (!props.classroom)
    return

  const params = {
    schoolId: props.schoolId,
    schoolPeriodId: props.schoolPeriodId,
    schoolClassroomId: props.classroom.schoolClassroomId,
    groupId
  }

confirm.require({
    message: 'Êtes-vous sûr de vouloir supprimer ce groupe ?',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      monitoringGroupStore.deleteSchoolMonitoringGroup(
        params.schoolId,
        params.schoolPeriodId,
        params.schoolClassroomId,
        params.groupId
      )
    },
    reject: () => {
      toast.add({
        severity: 'info',
        summary: 'Annulé',
        detail: 'Suppression annulée',
        life: 3000
      })
    }
  })
}
</script>

<template>
  <div class="p-3">
    <!-- État de chargement -->
    <div v-if="loading || localLoading" class="flex justify-content-center py-3">
      <p-progress-spinner style="width: 2rem; height: 2rem" />
      <span class="ml-2">Chargement des groupes...</span>
    </div>

    <!-- Contenu principal -->
    <div v-else>
      <!-- Groupes disponibles -->
      <div v-if="hasGroups">
        <p-table :value="classroom?.groups" class="p-datatable-sm" responsiveLayout="scroll">
          <p-column field="name" header="Nom du groupe">
            <template #body="{ data }: { data: SchoolMonitoringGroup}">
              {{ data.name }}
            </template>
          </p-column>
          
          <p-column field="students" header="Élèves" headerClass="w-8rem" bodyClass="text-center">
            <template #body="{ data }: { data: SchoolMonitoringGroup}">
              {{ data.students.length }}
            </template>
          </p-column>
          
          <p-column field="teacher" header="Enseignant référent">
            <template #body="{ data }: { data: SchoolMonitoringGroup }">
              {{ data.teacher?.fullName }}
            </template>
          </p-column>
          
          <p-column header="Actions" headerClass="w-10rem" bodyClass="text-center">
            <template #body="{ data }: { data: SchoolMonitoringGroup }">
              <div class="flex justify-content-center gap-2">
                <p-button
                  icon="pi pi-user"
                  text
                  size="small"
                  tooltip="Gérer les membres"
                  @click="manageGroupMembers(data)"
                />
                <p-button
                  icon="pi pi-chart-bar"
                  text
                  size="small"
                  tooltip="Statistiques"
                  @click="openGroupStats(data.groupId)"
                />
                <p-button
                  icon="pi pi-trash"
                  text
                  size="small"
                  severity="danger"
                  tooltip="Supprimer"
                  @click="confirmDeleteGroup(data.groupId)"
                />
              </div>
            </template>
          </p-column>
        </p-table>
      </div>
      
      <!-- Aucun groupe -->
      <div v-else class="text-center p-4">
        <p>Aucun groupe défini pour cette classe.</p>
        <p-button
          label="Créer un groupe"
          icon="pi pi-plus"
          size="small"
          class="mt-2"
          @click="openCreateGroupForm"
        />
      </div>
    </div>

    <!-- Bouton de création de groupe (si des groupes existent déjà) -->
    <div v-if="hasGroups" class="flex justify-content-end mt-3">
      <p-button
        label="Ajouter un groupe"
        icon="pi pi-plus"
        size="small"
        @click="openCreateGroupForm"
      />
    </div>
  </div>
</template>
