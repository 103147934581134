<script setup lang='ts'>
// Vue, Vue Router, Pinia
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'

// Stores
import { useDashboardStore } from '@/stores/dashboard/dashboard'
import { useAuthStore } from '@/stores/auth'

// Components
import BaseHeader from '@/components/base/BaseHeader.vue'
import { useDashboardClassroomStore } from '@/stores/dashboard/dashboardClassroom'

/*
*
*  SERVICES
*
* */
const route = useRoute()

// Data, refs
const { dashboardSelectedClassroom } = storeToRefs(useDashboardClassroomStore())
const { isAdmin, isTeacher } = storeToRefs(useAuthStore())

// Props
const props = defineProps<{
  title: string
  schoolId?: number
  classroomId?: number
}>()

const breadcrumbs = computed(() => {
  const baseBreadcrumb = {
    dashboard: {
      name: "dashboard",
      link: route.name !== "dashboard",
      title: "Tableau de bord",
      icon: "pi pi-home"
    },
    group: {
      name: "dashboard-group",
      link: route.name === "dashboard-group-progress",
      title: `Classe (${dashboardSelectedClassroom.value?.name || ""})`,
      icon: "pi pi-chevron-right"
    },
    progress: {
      name: "dashboard-group-progress",
      link: false,
      title: "Résultat du parcours",
      icon: "pi pi-chevron-right"
    }
  };

  switch (route.name) {
    case "dashboard":
      return [baseBreadcrumb.dashboard];

    case "dashboard-group":
      if (isAdmin.value) {
        return [baseBreadcrumb.dashboard, baseBreadcrumb.group];
      }
      if (!isAdmin.value && isTeacher.value) {
        baseBreadcrumb.group.link = false;
        baseBreadcrumb.group.icon = "pi pi-home";
        return [ baseBreadcrumb.group];
      }
      return [];

    case "dashboard-group-progress":
      if (isAdmin.value) {
        return [baseBreadcrumb.dashboard, baseBreadcrumb.group, baseBreadcrumb.progress];
      }
      if (!isAdmin.value && isTeacher.value) {
        baseBreadcrumb.progress.link = false;
        baseBreadcrumb.group.icon = "pi pi-home";
        return [baseBreadcrumb.group, baseBreadcrumb.progress];
      }
      return [];

    default:
      return [];
  }
});


const displayBreadcrumbs = computed(() => {
  if (route.name === 'dashboard') {
    return false
  }

  return true
})

const viewTitle = computed(() => {

  if (route.name === 'dashboard') {
    return 'Liste des classes'
  }

  if (route.name === 'dashboard-group') {

    return `Classe (${dashboardSelectedClassroom.value?.name})`
  }

  if (route.name === 'dashboard-group-progress') {
    return `Résultats du parcours`
  }

  return ''
})
</script>

<template>
  <base-header :title='viewTitle' :display-breadcrumbs='displayBreadcrumbs' :breadcrumbs='breadcrumbs' :has-widget='false' />
</template>