import type ISchoolPeriodRepository from '@/core/domain/school/period/ISchoolPeriodRepository'
import SchoolPeriod, { SchoolPeriodInput } from '@/core/domain/school/period/SchoolPeriod'
import type ApiService from '@/core/infrastructure/services/ApiService'

import {
  SchoolCreatePeriodResponseSchema,
  SchoolPeriodsListSchema
} from '@/core/infrastructure/schemas/school/period/SchoolPeriodSchema'
import { Role } from '@/assets/types/UserTypes'


export default class SchoolPeriodRepository implements ISchoolPeriodRepository {
  private apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  async fetchAllSchoolPeriods(schoolId:number): Promise<SchoolPeriod[]> {
    const response = await this.apiService.get<SchoolPeriod[]>(`admin/schools/${schoolId}/periods`);

    const parse = SchoolPeriodsListSchema.safeParse(response);

    if (parse.success) {
      return parse.data.map((schoolPeriod) => new SchoolPeriod(schoolPeriod));
    }

    return Promise.reject('Parsing error');
  }

  async fetchSchoolPeriod(): Promise<SchoolPeriod> {
    throw new Error('Method not implemented.');
  }

  async updateSchoolPeriod(): Promise<SchoolPeriod> {
    throw new Error('Method not implemented.');
  }

  async createSchoolPeriod(schoolPeriod: SchoolPeriodInput): Promise<SchoolPeriod> {
    const response = await this.apiService.post<SchoolPeriod>(`admin/schools/${schoolPeriod.schoolId}/periods`, {
      schoolId: schoolPeriod.schoolId,
      schoolYearId: schoolPeriod.schoolYearId
    });

    const parse = SchoolCreatePeriodResponseSchema.safeParse(response);

    console.log(parse);
    if (parse.success) {
      const schoolPeriodData = {
        schoolPeriodId: parse.data.schoolPeriodId,
        schoolId: parse.data.schoolId,
        schoolYear: parse.data.schoolYear,
        countStudents: parse.data.users.filter(user => user.roles.includes(Role.Values.STUDENT)).length,
        countTeachers: parse.data.users.filter(user => user.roles.includes(Role.Values.TEACHER)).length,
        countClassrooms: 0
    }
      return new SchoolPeriod(schoolPeriodData);
    }

    return Promise.reject('Parsing error');
  }
}