import { computed, ref, type Ref } from 'vue'
import { type SchoolPeriodUser } from '@/core/domain/school/user/SchoolPeriodUser'
import { UserRole } from '@/core/domain/resources/enums/UserRole'

export interface UserFilters {
  search: string
  role?: UserRole.Values
  showOnlyUsersWithoutClassroom: boolean
  showOnlyUsersWithoutGroup: boolean
}

export interface SortOption {
  label: string
  value: number
}

/**
 * Composable pour gérer le filtrage et le tri des utilisateurs
 * @param users Liste des utilisateurs à filtrer
 */
export function useSchoolUserFilters(users: Ref<SchoolPeriodUser[]>) {
  // Filtres
  const filters = ref<UserFilters>({
    search: '',
    role: undefined,
    showOnlyUsersWithoutClassroom: false,
    showOnlyUsersWithoutGroup: false
  })

  // Options de tri
  const selectedOrder = ref(1)
  const orderOptions: SortOption[] = [
    { label: 'Ordre alphabétique (A-Z)', value: 1 },
    { label: 'Ordre alphabétique (Z-A)', value: -1 }
  ]

  /**
   * Utilisateurs filtrés selon les critères
   */
  const filteredUsers = computed(() => {
    const search = filters.value.search.toLowerCase().trim()
    const role = filters.value.role
    let filteredValues = [...users.value]

    if (search) {
      filteredValues = filteredValues.filter((user) =>
        user.lastname.toLowerCase().includes(search) ||
        user.firstname.toLowerCase().includes(search)
      )
    }

    if (role) {
      filteredValues = filteredValues.filter((user) =>
        user.role.includes(role)
      )
    }

    if (filters.value.showOnlyUsersWithoutClassroom) {
      // Implémenter ce filtre lorsque les données de classe seront disponibles
      // filteredValues = filteredValues.filter((user) => !user.classrooms || user.classrooms.length === 0)
    }

    if (filters.value.showOnlyUsersWithoutGroup) {
      // Implémenter ce filtre lorsque les données de groupe seront disponibles
      // filteredValues = filteredValues.filter((user) => !user.groups || user.groups.length === 0)
    }

    return filteredValues
  })

  /**
   * Utilisateurs filtrés et triés
   */
  const sortedUsers = computed(() => {
    return [...filteredUsers.value].sort((a, b) => {
      const compareResult = a.lastname.localeCompare(b.lastname)
      return compareResult * selectedOrder.value
    })
  })

  /**
   * Réinitialise tous les filtres
   */
  function resetFilters() {
    filters.value = {
      search: '',
      role: undefined,
      showOnlyUsersWithoutClassroom: false,
      showOnlyUsersWithoutGroup: false
    }
  }

  /**
   * Vérifie si des filtres sont actifs
   */
  const hasActiveFilters = computed(() =>
    filters.value.search ||
    filters.value.role ||
    filters.value.showOnlyUsersWithoutClassroom ||
    filters.value.showOnlyUsersWithoutGroup
  )

  /**
   * Nombre d'utilisateurs après filtrage
   */
  const filteredUserCount = computed(() => filteredUsers.value.length)

  return {
    filters,
    selectedOrder,
    orderOptions,
    filteredUsers,
    sortedUsers,
    resetFilters,
    hasActiveFilters,
    filteredUserCount
  }
}