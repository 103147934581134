export namespace LearningQuizQuestionType {
  export enum Values {
    SINGLE_CHOICE = 'SINGLE_CHOICE',
    MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
    TRUE_FALSE = 'TRUE_FALSE',
    MATCHING = 'MATCHING',
    SEQUENCING = 'SEQUENCING'
  }

  export function label(questionType: Values) {
    switch (questionType) {
      case Values.SINGLE_CHOICE:
        return 'QCU'
      case Values.MULTIPLE_CHOICE:
        return 'QCM'
      case Values.TRUE_FALSE:
        return 'Vrai/Faux'
      case Values.MATCHING:
        return 'Association'
      case Values.SEQUENCING:
        return 'Réordonner'
    }
  }
}