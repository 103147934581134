import type { ISchoolClassroomRepository } from '@/core/domain/school/classroom/ISchoolClassroomRepository'
import { ClassroomDegree } from '@/core/domain/resources/enums/ClassroomDegree'
import { ClassroomType } from '@/core/domain/resources/enums/ClassroomType'
import type { SchoolClassroomInput } from '@/core/domain/school/classroom/SchoolClassroom'


export class UpdateSchoolClassroomUseCase {
  private schoolClassroomRepository: ISchoolClassroomRepository;

  constructor(schoolClassroomRepository: ISchoolClassroomRepository) {
    this.schoolClassroomRepository = schoolClassroomRepository;
  }


  async execute(
    schoolId: number,
    periodId: number,
    schoolClassroom: SchoolClassroomInput

  ) {
    return await this.schoolClassroomRepository.updateSchoolClassroom(
      schoolId,
      periodId,
      schoolClassroom
    );
  }
}