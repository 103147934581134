<script setup lang='ts'>
import DashboardHeader from '@/components/dashboard/DashboardHeader.vue'
import { useRoute, useRouter } from 'vue-router'
import { nextTick, onMounted, ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { useSchoolStore } from '@/stores/school/school'
import { useProfileStore } from '@/stores/profile'
import { useDashboardStore } from '@/stores/dashboard/dashboard'

// get props from route
const props = defineProps<{
  schoolId?: number
  classroomId?: number
}>()

const { isTeacher } = storeToRefs(useAuthStore())
const { schoolList } = storeToRefs(useSchoolStore())
const { me } = storeToRefs(useProfileStore())

const loading = ref(true)

onMounted(() => {
  // @TODO: handle default classroom after group is impletented
    // const defaultClassroom = schoolList.value[0].classrooms.find((classroom) => {
    //   const classroomMonitors = classroom.monitors.map((monitor) => monitor.identifier)
    //   if (me.value && me.value.identifier)
    //     return classroomMonitors.includes(me.value.identifier)
    // })
    //
    //
    // if (isTeacher.value && defaultClassroom) {
    //   router.push({
    //     name: 'dashboard-group',
    //     params: { schoolId: schoolList.value[0].id, groupId: defaultClassroom.id }
    //   })
    // }

    nextTick(() => {
      loading.value = false
    })
})

</script>


<template>
  <dashboard-header title='Tableau de bord' :school-id='schoolId' :classroom-id='classroomId' />
  <div
    class="col-12 p-5 pt-2 bg-bo-brand-tertiary flex-1 overflow-y-auto gap-5 flex flex-column"
  >
    <router-view v-if='!loading' />
  </div>
</template>
