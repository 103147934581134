import type { ISchoolClassroomRepository } from '@/core/domain/school/classroom/ISchoolClassroomRepository'
import type { SchoolClassroomListItem } from '@/core/domain/school/classroom/SchoolClassroom'


export class FetchAllSchoolClassroomsUseCase {
    private schoolClassroomRepository: ISchoolClassroomRepository

    constructor(schoolClassroomRepository: ISchoolClassroomRepository) {
        this.schoolClassroomRepository = schoolClassroomRepository
    }

    async execute(schoolId: number, periodId: number): Promise<SchoolClassroomListItem[]> {
        return await this.schoolClassroomRepository.fetchAllSchoolClassrooms(schoolId, periodId)
    }
}