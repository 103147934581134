// src/core/infrastructure/bootstrap/ModuleBase.ts
import DependencyContainer from '@/presentation/configuration/DependencyContainer';

export abstract class ModuleBase {
  protected registerRepository<T>(RepoClass: any, instance: T): void {
    DependencyContainer.register(RepoClass, instance);
  }

  protected registerUseCase<T>(UseCaseClass: any, instance: T): void {
    DependencyContainer.register(UseCaseClass, instance);
  }
}