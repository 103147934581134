<script lang='ts' setup>
import { default as PDivider } from 'primevue/divider'

// Composables
const router = useRouter()

import type School from '@/core/domain/school/core/School'
import { useRouter } from 'vue-router'
import BaseCard from '@/components/base/BaseCard.vue'
import { useSchoolStore } from '@/stores/school/school'

const props = defineProps<{
  school: School
}>()

const { selectSchool } = useSchoolStore()

const showSchool = () => {
  selectSchool(props.school)
  router.push({
    name: 'school',
    params: {
      schoolSlug: props.school.slug,
    },
  })
}

</script>

<template>

  <base-card :title='school.name' has-footer>

    <template #content>
      <p class='w-full'>
        {{ school.address ?? 'Adresse non reseignée' }}<br/>
        {{ school.zipCode ?? 'Non spécifié' }} {{ school.city ?? 'Non spécifié' }} ({{ school.department ?? 'Non spécifié' }})
      </p>

      <p class='w-full'>
        <i class='pi pi-home'></i>
        Académie : {{ school.schoolDistrict ?? 'Non spécifié' }}
      </p>
    </template>

    <template #footer>
      <a class='ml-auto bo-active-color cursor-pointer' @click='showSchool'>Afficher l'établissement</a>
    </template>

  </base-card>

</template>