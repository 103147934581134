<script setup lang='ts'>
import BaseBlock from '@/components/base/BaseBlock.vue'
import { SchoolInput } from '@/core/domain/school/core/School'
import { default as PInputText } from 'primevue/inputtext'
import { default as PButton } from 'primevue/button'
import { default as PInlineMessage } from 'primevue/inlinemessage'
import { computed, onMounted, ref, watch } from 'vue'
import { useSchoolStore } from '@/stores/school/school'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

// Composables
const router = useRouter()
const schoolStore = useSchoolStore()
const { schoolStoreLoading, schoolStoreError } = storeToRefs(schoolStore)

// État local
const school = ref<SchoolInput>(new SchoolInput())
const isSchoolRetrieved = ref(false)
const localErrorMessage = ref('')
const codeUAIPattern = ref(/^[0-9]{7}[A-Z]$/i)
const submittedCodeUAI = ref(false)
const emailPattern = ref(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
const isEmailValid = ref(true)
const schoolId = ref<number | null>(null)
const isLoading = ref(true) // État de chargement local

// Props
const props = defineProps<{
  schoolSlug?: string
}>()

// Computed
const hasError = computed(() => {
  return schoolStoreError.value.hasError || localErrorMessage.value !== ''
})

const errorMessage = computed(() => {
  return localErrorMessage.value || schoolStoreError.value.message
})

// État de chargement global (combinaison du chargement local et du store)
const isGlobalLoading = computed(() => {
  return isLoading.value || schoolStoreLoading.value.fetchAll ||
    (isInUpdateMode.value && schoolStoreLoading.value.update) ||
    (!isInUpdateMode.value && schoolStoreLoading.value.create)
})

const isCodeUAIValid = computed(() => {
  return codeUAIPattern.value.test(school.value.codeUai || '')
})

const isSubmittingUAI = computed(() => {
  return schoolStoreLoading.value.fetchFromDataEducation
})

const isCreatingSchool = computed(() => {
  return schoolStoreLoading.value.create
})

const isUpdatingSchool = computed(() => {
  return schoolStoreLoading.value.update
})

const canSubmitCodeUAI = computed(() => {
  return !isSubmittingUAI.value && school.value.codeUai && isCodeUAIValid.value
})

const canCreateSchool = computed(() => {
  return !isCreatingSchool.value && isSchoolRetrieved.value && isEmailValid.value
})

const canUpdateSchool = computed(() => {
  return !isUpdatingSchool.value && isSchoolRetrieved.value && isEmailValid.value
})

const isInUpdateMode = computed(() => {
  return !!props.schoolSlug && schoolId.value !== null
})

const actionButtonLabel = computed(() => {
  return isInUpdateMode.value ? "Mettre à jour l'établissement" : "Créer l'établissement"
})

const pageTitle = computed(() => {
  return isInUpdateMode.value ? "Modifier l'établissement" : "Créer un établissement"
})

// Méthodes
const validateEmail = () => {
  if (!school.value.contactEmail?.trim()) {
    isEmailValid.value = false
    return
  }

  isEmailValid.value = emailPattern.value.test(school.value.contactEmail)
}

const fetchSchool = async () => {
  submittedCodeUAI.value = true

  if (!isCodeUAIValid.value) {
    localErrorMessage.value = 'Code UAI invalide (format: 7 chiffres + 1 lettre)'
    return
  }

  // En mode création, vérifier si l'école existe déjà
  if (!isInUpdateMode.value) {
    const existingSchool = schoolStore.getSchoolByCodeUai(school.value.codeUai)
    if (existingSchool) {
      localErrorMessage.value = 'Cet établissement existe déjà dans la base de données'
      return
    }
  }

  localErrorMessage.value = ''

  try {
    const data = await schoolStore.fetchSchoolFromDataEducation(school.value.codeUai)
    school.value = data
    isSchoolRetrieved.value = true

    // Assurer que l'email est initialisé
    if (!school.value.contactEmail) {
      school.value.contactEmail = ''
    }
  } catch (err) {
    // L'erreur sera déjà gérée par le store
    localErrorMessage.value = 'Impossible de récupérer les informations de cet établissement'
  }
}

const saveSchool = async () => {
  validateEmail()

  if (!isEmailValid.value) {
    return
  }

  try {
    if (isInUpdateMode.value && schoolId.value) {
      // Mode mise à jour
      const updatedSchool = await schoolStore.updateSchool(schoolId.value, school.value)

      if (updatedSchool) {
        await router.push({
          name: 'school',
          params: {
            schoolSlug: updatedSchool.slug,
          },
        })
      }
    } else {
      // Mode création
      const newSchool = await schoolStore.createSchool(school.value)

      if (newSchool) {
        await router.push({
          name: 'school',
          params: {
            schoolSlug: newSchool.slug,
          },
        })
      }
    }
  } catch (err) {
    // L'erreur sera déjà gérée par le store
    console.error(`Erreur lors de la ${isInUpdateMode.value ? 'mise à jour' : 'création'} de l'établissement:`, err)
  }
}

const resetForm = () => {
  school.value = new SchoolInput()
  isSchoolRetrieved.value = false
  localErrorMessage.value = ''
  submittedCodeUAI.value = false
  isEmailValid.value = true

  if (isInUpdateMode.value) {
    // Recharger les données d'origine en mode mise à jour
    loadExistingSchool()
  }
}

const loadExistingSchool = async () => {
  if (!props.schoolSlug) return

  // Essayer de récupérer l'école à partir du store
  const foundSchool = schoolStore.getSchoolBySlug(props.schoolSlug)

  if (foundSchool) {
    school.value = foundSchool.toInput()
    schoolId.value = foundSchool.id
    isSchoolRetrieved.value = true
  } else {
    // Si l'école n'est pas dans le store, il faudrait implémenter
    // un fetchSchoolBySlug dans le store et l'appeler ici
    localErrorMessage.value = 'Établissement non trouvé. Veuillez réessayer.'
  }
}

// Lifecycle
onMounted(async () => {
  try {
    isLoading.value = true

    // Vérifier si on est en mode mise à jour
    if (props.schoolSlug) {
      await loadExistingSchool()
    } else {
      // En mode création, on peut juste finir le chargement
      isLoading.value = false
    }
  } catch (err) {
    console.error('Erreur lors de l\'initialisation:', err)
    localErrorMessage.value = 'Erreur lors de l\'initialisation du formulaire.'
    isLoading.value = false
  }
})

// Watchers
watch(() => school.value.contactEmail, validateEmail)
</script>

<template>
  <base-block :accordion='false' :title="pageTitle">
    <div class='flex flex-column gap-3'>
      <!-- Recherche par code UAI -->
      <div class='flex flex-column gap-2 align-items-start'>
        <p class='font-semibold'>Code établissement UAI*</p>
        <div class="flex align-items-center gap-2 w-full">
          <p-input-text
            v-model='school.codeUai'
            placeholder="ex: 0750001A"
            :class="{ 'p-invalid': submittedCodeUAI && !isCodeUAIValid }"
            :disabled="isSubmittingUAI || isSchoolRetrieved || isInUpdateMode"
            class="w-full md:w-25rem"
          />
          <p-button
            v-if="!isInUpdateMode"
            label='Valider le code établissement'
            outlined
            severity='primary'
            :loading="isSubmittingUAI"
            :disabled="isSubmittingUAI || isSchoolRetrieved || !school.codeUai"
            @click='fetchSchool'
          />
          <p-button
            v-if="isSchoolRetrieved && !isInUpdateMode"
            icon="pi pi-times"
            outlined
            severity="secondary"
            @click="resetForm"
            tooltip="Réinitialiser le formulaire"
          />
        </div>
        <small v-if="submittedCodeUAI && !isCodeUAIValid && !isInUpdateMode" class="p-error">
          Format attendu: 7 chiffres suivis d'une lettre (ex: 0750001A)
        </small>
        <p-inline-message v-if='errorMessage' severity='error'>
          {{ errorMessage }}
        </p-inline-message>
      </div>

      <!-- Informations de l'établissement -->
      <template v-if='isSchoolRetrieved'>
        <div class='flex flex-column gap-2 align-items-start'>
          <p class='font-semibold'>Nom de l'établissement</p>
          <p-input-text
            v-model='school.name'
            class="w-full md:w-30rem"
            :disabled="!isInUpdateMode"
          />
        </div>

        <div class='flex flex-column gap-2 align-items-start'>
          <p class='font-semibold'>Adresse email*</p>
          <p-input-text
            v-model='school.contactEmail'
            placeholder="Adresse email de contact"
            :class="{ 'p-invalid': !isEmailValid }"
            class="w-full md:w-30rem"
          />
          <small v-if="!isEmailValid" class="p-error">
            Veuillez saisir une adresse email valide
          </small>
        </div>

        <div class='flex flex-column gap-2 align-items-start'>
          <p class='font-semibold'>Adresse</p>
          <p-input-text
            v-model='school.address'
            class="w-full md:w-30rem"
            :disabled="!isInUpdateMode"
          />
        </div>

        <div class='flex gap-2'>
          <div class='flex flex-column gap-2 align-items-start'>
            <p class='font-semibold'>Code postal</p>
            <p-input-text
              v-model='school.zipCode'
              :disabled="!isInUpdateMode"
            />
          </div>

          <div class='flex flex-column gap-2 align-items-start'>
            <p class='font-semibold'>Ville</p>
            <p-input-text
              v-model='school.city'
              :disabled="!isInUpdateMode"
            />
          </div>
        </div>

        <div class='flex flex-column gap-2 align-items-start'>
          <p class='font-semibold'>Département</p>
          <p-input-text
            v-model='school.department'
            class="w-full md:w-30rem"
            :disabled="!isInUpdateMode"
          />
        </div>

        <div class='flex flex-column gap-2 align-items-start'>
          <p class='font-semibold'>Académie</p>
          <p-input-text
            v-model='school.schoolDistrict'
            class="w-full md:w-30rem"
            :disabled="!isInUpdateMode"
          />
        </div>

        <div class="flex gap-3 align-items-center mt-3">
          <p-button
            :label="actionButtonLabel"
            class='align-self-start'
            :loading="isCreatingSchool || isUpdatingSchool"
            :disabled="(isCreatingSchool || isUpdatingSchool) || !isEmailValid"
            @click='saveSchool'
          />

          <p-button
            v-if="isInUpdateMode"
            label="Annuler"
            outlined
            severity="secondary"
            class='align-self-start'
            :disabled="isCreatingSchool || isUpdatingSchool"
            @click="() => router.back()"
          />
        </div>
      </template>
    </div>
  </base-block>
</template>

<style scoped>
.p-inputtext:disabled {
  opacity: 0.8;
  background-color: #f8f9fa;
}

/* Styles de transition pour l'état de chargement */
.loading-transition-enter-active,
.loading-transition-leave-active {
  transition: opacity 0.3s ease;
}

.loading-transition-enter-from,
.loading-transition-leave-to {
  opacity: 0;
}

/* Animation pour le spinner */
@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.pulse-animation {
  animation: pulse 1.5s infinite ease-in-out;
}
</style>