import { z } from 'zod'
import { UserGender } from '@/core/domain/resources/enums/UserGender'
import { UserRole } from '@/core/domain/resources/enums/UserRole'
import { UserPreferencesSchema } from '@/core/infrastructure/schemas/users/preferences/UserPreferencesSchema'

/**
 * Schéma principal pour un utilisateur
 */
export const UserSchema= z.object({
  identifier: z.string().uuid(),
  firstname: z.string().min(1),
  lastname: z.string().min(1),
  nickname: z.string().optional(),
  username: z.string().min(1).optional(),
  email: z.string().email(),
  isEmailVerified: z.boolean(),
  gender: z.nativeEnum(UserGender.Values),
  roles: z.array(z.nativeEnum(UserRole.Values)).nonempty(),
  birthdate: z.string().datetime(),
  picture: z.string().optional(),
  isBlocked: z.boolean(),
  hasAnsweredTrackingConsentBanner: z.boolean(),
  hasConsentedForTracking: z.boolean(),
  isRegisterProcessDone: z.boolean(),
  preferences: UserPreferencesSchema,
  createdAt: z.string().datetime()
})

/**
 * Schéma pour une liste d'utilisateurs
 */
export const UserListSchema = z.array(UserSchema)

/**
 * Type pour l'entité utilisateur
 */
export type UserEntity = z.infer<typeof UserSchema>

/**
 * Type pour une liste d'entités utilisateur
 */
export type UserListEntity = z.infer<typeof UserListSchema>
