export namespace ClassroomDegree {
  export enum Values {
    SECONDE = "SECONDE",
    PREMIERE = "PREMIERE",
    TERMINALE = "TERMINALE",
  }

  export function label(degree: Values) {
    switch (degree) {
      case Values.SECONDE:
        return "Seconde";
      case Values.PREMIERE:
        return "Première";
      case Values.TERMINALE:
        return "Terminale";
    }
  }

  export const  options = Object.values(Values).map((role) => ({
    value: role,
    label: label(role)
  }))
}