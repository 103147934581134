<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useToast } from 'primevue/usetoast'
import DashboardClassroomAttendance from '@/components/dashboard/DashboardClassroomAttendance.vue'
import DashboardClassroomCoursesList from '@/components/dashboard/DashboardClassroomCoursesList.vue'
import DashboardClassroomGeneralMetrics from '@/components/dashboard/DashboardClassroomGeneralMetrics.vue'
import { onBeforeRouteUpdate } from 'vue-router'
import { useDashboardClassroomStore } from '@/stores/dashboard/dashboardClassroom'
import { default as PMessage } from 'primevue/message'
import { default as PSkeleton } from 'primevue/skeleton'

// STORES
const dashboardClassroomStore = useDashboardClassroomStore()

// STORE REFS
const {
  dashboardSelectedClassroom,
  dashboardClassroomStoreError: classroomError
} = storeToRefs(dashboardClassroomStore)

// LOCAL STATE
const localLoading = ref(true)
const localError = ref('')
const dataInitialized = ref(false)

// PROPS
const props = defineProps<{
  schoolId: number
  classroomId: number
}>()

// COMPOSABLES
const toast = useToast()

// COMPUTED
const hasError = computed(() =>
  (classroomError.value && classroomError.value.hasError) ||
  localError.value !== ''
)

const errorMessage = computed(() =>
  localError.value ||
  (classroomError.value?.hasError ? classroomError.value.message : '')
)

const isEmpty = computed(() =>
  !localLoading.value && !dashboardSelectedClassroom.value
)

// METHODS
/**
 * Charge les données de la classe
 */
const { setDashboardSelectedClassroomById } = dashboardClassroomStore

const loadClassroomData = async () => {
  // Si déjà initialisé, ne pas recharger
  if (dataInitialized.value) return

  try {
    localLoading.value = true
    localError.value = ''

    await setDashboardSelectedClassroomById(
      props.schoolId,
      props.classroomId
    )

    // Marquer comme initialisé après le chargement réussi
    dataInitialized.value = true
  } catch (err) {
    localError.value = err instanceof Error
      ? err.message
      : 'Impossible de charger les détails du groupe'

    toast.add({
      severity: 'error',
      summary: 'Erreur',
      detail: localError.value,
      life: 5000
    })

    console.error('Erreur lors du chargement des données de classe:', err)
  } finally {
    localLoading.value = false
  }
}

// LIFECYCLE
onMounted(async () => {
  await loadClassroomData()
})

onUnmounted(() => {
  dashboardClassroomStore.reset()
})

// ROUTE GUARDS
onBeforeRouteUpdate(async (to, from, next) => {
  if (
    to.params.classroomId !== from.params.classroomId ||
    to.params.schoolId !== from.params.schoolId
  ) {
    // Réinitialiser pour permettre le rechargement
    dataInitialized.value = false
    localLoading.value = true

    try {
      await setDashboardSelectedSchoolClassroomById(
        Number(to.params.schoolId),
        Number(to.params.classroomId)
      )
      dataInitialized.value = true
    } catch (err) {
      console.error('Erreur lors du changement de route:', err)
      localError.value = err instanceof Error
        ? err.message
        : 'Impossible de charger les détails du groupe'
    } finally {
      localLoading.value = false
    }
  }
  next()
})

// Observer les changements de props pour recharger les données si nécessaire
watch(
  [() => props.schoolId, () => props.classroomId],
  async ([newSchoolId, newClassroomId], [oldSchoolId, oldClassroomId]) => {
    if (newSchoolId !== oldSchoolId || newClassroomId !== oldClassroomId) {
      dataInitialized.value = false
      await loadClassroomData()
    }
  }
)
</script>

<template>
  <div class="dashboard-classroom">
    <!-- Message d'erreur -->
    <p-message
      v-if="hasError"
      severity="error"
      class="mb-3 w-full"
    >
      {{ errorMessage }}
    </p-message>

    <!-- État de chargement -->
    <div v-if="localLoading" class="flex flex-column gap-3">
      <!-- Skeleton pour les métriques générales -->
      <p-skeleton height="10rem" class="mb-3" />

      <!-- Skeleton pour la liste de cours -->
      <p-skeleton height="20rem" class="mb-3" />

      <!-- Skeleton pour l'assiduité -->
      <p-skeleton height="15rem" />
    </div>

    <!-- État vide -->
    <div v-else-if="isEmpty" class="flex flex-column align-items-center justify-content-center p-5">
      <i class="pi pi-users text-4xl text-500 mb-3"></i>
      <h3 class="mt-0 mb-2">Données non disponibles</h3>
      <p class="text-center text-700">
        Les informations de ce groupe ne sont pas disponibles ou n'existent pas.
      </p>
    </div>

    <!-- Contenu de la classe -->
    <template v-else>
      <dashboard-classroom-general-metrics />

      <!-- Progression du groupe -->
      <dashboard-classroom-courses-list
        :schoolId="props.schoolId"
        :classroom-id="props.classroomId"
      />

      <!-- Assiduité des élèves -->
      <dashboard-classroom-attendance
        :schoolId="props.schoolId"
        :classroom-id="props.classroomId"
      />
    </template>
  </div>
</template>