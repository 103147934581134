export namespace LearningSupportType {
  export enum Values {
    GENIALLY = 'GENIALLY'
  }

  export function label(support: Values) {
    switch (support) {
      case Values.GENIALLY:
        return 'Genially'
    }
  }
}