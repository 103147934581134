import type {
  DashboardScoreDiagnosticTaskEntity
} from '@/core/infrastructure/schemas/dashboard/DashboardScoreDiagnosticTaskSchema'
import { EvaluationRange } from '@/core/domain/resources/enums/EvaluationRange'


export class DashboardScoreDiagnosticTask {
  readonly taskId: number
  readonly taskTitle: string
  readonly taskOrder: number
  readonly diagScorePercentRounded: number
  readonly diagEvalRangeName: EvaluationRange.Values

  constructor(task: DashboardScoreDiagnosticTaskEntity) {
    this.taskId = task.taskId
    this.taskTitle = task.taskTitle
    this.taskOrder = task.taskOrder
    this.diagScorePercentRounded = task.diagScorePercentRounded
    this.diagEvalRangeName = task.diagEvalRangeName
  }
}