import { LearningEnums } from '@/assets/types/learning/enums'
import type { ILearningCourseUrlOptions } from '@/assets/types/learning/LearningCourses'

interface ILearningCourseInterface {
  id: number
  title: string
  status: LearningEnums.Status
  order: number
  name: string
  domainId?: number
}

export class LearningCourseInputDto {
  id: number
  title: string
  status: LearningEnums.Status
  order: number
  name: string
  domainId?: number

  constructor(obj: ILearningCourseInterface) {
    this.id = obj.id
    this.title = obj.title
    this.status = obj.status
    this.order = obj.order
    this.name = obj.name
    this.domainId = obj.domainId
  }

  isInputCorrect(): boolean {
    return (
      this.title.length > 0 && this.order > 0 && this.domainId !== undefined && this.name.length > 0
    )
  }
}

export class LearningCourseUrlOptions implements ILearningCourseUrlOptions {
  withModules: boolean
  withGrains: boolean
  withSupports: boolean
  withDomains: boolean
  status?: LearningEnums.Status[]

  constructor(obj: ILearningCourseUrlOptions) {
    // Handle hierarchical dependencies
    this.withDomains = obj.withDomains  // Independent from other parameters

    // If withGrains is true, withModules must also be true
    // If withSupports is true, both withGrains and withModules must be true
    this.withSupports = obj.withSupports
    this.withGrains = obj.withGrains || this.withSupports
    this.withModules = obj.withModules || this.withGrains

    this.status = obj.status
  }

  getUrlOptions(): string {
    // Handle special case when everything is false except withDomains or status
    const onlyDomainsOrStatus =
      !this.withModules &&
      !this.withGrains &&
      !this.withSupports &&
      (this.withDomains || (this.status && this.status.length > 0))

    // Build URL based on dependencies
    const params = new URLSearchParams()

    // withDomains is always added because it's independent
    params.append('withDomains', String(this.withDomains))

    // Other parameters are added according to dependency rules
    if (!onlyDomainsOrStatus) {
      params.append('withModules', String(this.withModules))

      // Only add withGrains if withModules is true
      if (this.withModules) {
        params.append('withGrains', String(this.withGrains))

        // Only add withSupports if withGrains is true
        if (this.withGrains) {
          params.append('withSupports', String(this.withSupports))
        }
      }
    }

    // Always add status if it exists
    if (this.status && this.status.length > 0) {
      params.append('status', this.status.join(','))
    }

    return `?${params.toString()}`
  }

  // Utility method to normalize options according to dependency rules
  static normalizeOptions(options: ILearningCourseUrlOptions): ILearningCourseUrlOptions {
    const normalized = { ...options }

    // Apply dependency rules
    if (normalized.withSupports) {
      normalized.withGrains = true
    }

    if (normalized.withGrains) {
      normalized.withModules = true
    }

    return normalized
  }
}



