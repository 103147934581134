import {
  SchoolClassroomInput,
  SchoolClassroomListItem
} from '@/core/domain/school/classroom/SchoolClassroom'
import type { ISchoolClassroomRepository } from '@/core/domain/school/classroom/ISchoolClassroomRepository'

export class CreateSchoolClassroomUseCase {
  private classroomRepository: ISchoolClassroomRepository;

  constructor(classroomRepository: ISchoolClassroomRepository) {
    this.classroomRepository = classroomRepository;
  }

  async execute(schoolId:number, classroom: SchoolClassroomInput): Promise<SchoolClassroomListItem> {
    return await this.classroomRepository.createSchoolClassroom(schoolId, classroom);
  }
}