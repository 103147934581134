<script setup lang="ts">
import DashboardClassroomResultsDiagnostic from '@/components/dashboard/DashboardClassroomResultsDiagnostic.vue'
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useLearningCoursesStore } from '@/stores/learning/learningCourses'
import GroupProgressTask from '@/components/dashboard/DashboardGroupProgressTask.vue'
import { useDashboardClassroomStore } from '@/stores/dashboard/dashboardClassroom'
import { useDashboardClassroomResultStore } from '@/stores/dashboard/dashboardClassroomResult'
import { useToast } from 'primevue/usetoast'
import type { LearningCourse } from '@/core/domain/learning/course/LearningCourse'
import { useDashboardStore } from '@/stores/dashboard/dashboard'

// PROPS
// Inherited props from router : schoolId, classroomId, courseId
const props = defineProps<{
  schoolId: number
  classroomId: number
  courseId: number
}>()

// STORE REFS
const dashboardClassroomResultStore = useDashboardClassroomResultStore()
const { dashboardClassroomStoreIsLoading,dashboardSelectedClassroom } = storeToRefs(useDashboardClassroomStore())
const { dashboardSelectedSchool } = storeToRefs(useDashboardStore())

// LOCAL STATE
const learningCourse = ref<LearningCourse | null>(null)
const loading = ref(true)
const error = ref<string | null>(null)

// COMPOSABLES
const toast = useToast()

// METHODS
const { fetchCourse } = useLearningCoursesStore()
const { setDashboardSelectedClassroomById } = useDashboardClassroomStore()

const loadClassroomResultsData = async () => {
  try {
    loading.value = true
    error.value = null

    console.log('Chargement des résultats de classe :' + dashboardSelectedClassroom.value)

    // S'assurer que l'école et la classe sont sélectionnées
    if (!dashboardSelectedSchool.value) {
      await setDashboardSelectedClassroomById(
        props.schoolId,
        props.classroomId
      )
    }

    // Charger les informations du parcours
    learningCourse.value = await fetchCourse(props.courseId)
  } catch (err) {
    console.error('Erreur lors du chargement des résultats de classe:', err)
    error.value = err instanceof Error
      ? err.message
      : 'Une erreur est survenue lors du chargement des données'

    toast.add({
      severity: 'error',
      summary: 'Erreur',
      detail: error.value,
      life: 5000
    })
  } finally {
    console.log(dashboardSelectedClassroom.value)
    await  nextTick(() => {
      // S'assurer que le store est mis à jour avant de changer l'état de chargement
      loading.value = false
    })
  }
}

// COMPUTED
const isLoading = computed(() => {
  return loading.value || dashboardClassroomStoreIsLoading.value
})

onMounted(async () => {

  await loadClassroomResultsData()
})

onUnmounted(() => {
  // Réinitialiser le store des résultats quand on quitte la page
  dashboardClassroomResultStore.reset()
})
</script>

<template>
  <!-- Affichage en cas d'erreur -->
  <div v-if="error" class="p-message p-message-error mb-3 w-full">
    <div class="p-message-text">{{ error }}</div>
  </div>

  <!-- Affichage en cas de chargement -->
  <div v-else-if="isLoading" class="flex flex-column gap-3">
    <div class="p-skeleton h-15rem mb-3"></div>
    <div class="p-skeleton h-20rem"></div>
  </div>

  <!-- Contenu principal -->
  <template v-else>
    <!-- Diagnostic global du groupe -->
    <dashboard-classroom-results-diagnostic
      :schoolId="props.schoolId"
      :classroomId="props.classroomId"
      :courseId="props.courseId"
    />

    <template v-if='learningCourse'>
      <!-- Progression par module -->
      <group-progress-task
        v-for="(module, i) in learningCourse.activeModules"
        :key="module.id"
        :schoolId="props.schoolId"
        :classroomId="props.classroomId"
        :courseId="props.courseId"
        :taskId="module.task.id"
        :moduleId="module.id"
        :title="`Thématique ${i + 1} : ${module.title}`"
      />
    </template>
  </template>
</template>