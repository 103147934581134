<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { default as PInputText } from 'primevue/inputtext'
import { default as PDropdown } from 'primevue/dropdown'
import { default as PButton } from 'primevue/button'
import { default as PMessage } from 'primevue/message'
import { storeToRefs } from 'pinia'
import { ClassroomType } from '@/core/domain/resources/enums/ClassroomType'
import { useSchoolClassroomStore } from '@/stores/school/schoolClassroom'
import BaseBlock from '@/components/base/BaseBlock.vue'
import { ClassroomDegree } from '@/core/domain/resources/enums/ClassroomDegree'
import { SchoolClassroomInput } from '@/core/domain/school/classroom/SchoolClassroom'

// Types
interface ValidationErrors {
  name?: string
  degree?: string
  type?: string
  global?: string
}

// Props & Emits
const props = defineProps<{
  schoolId: number
  schoolPeriodId: number
  schoolClassroom?: SchoolClassroomInput | null
  isEdit?: boolean
}>()

const emit = defineEmits<{
  close: []
  saved: [classroomId: number]
}>()

// Composables
const schoolClassroomStore = useSchoolClassroomStore()

// Store refs
const { schoolClassroomStoreLoading, schoolClassroomStoreError } = storeToRefs(schoolClassroomStore)

// Local state
const localLoading = ref(false)
const formSubmitted = ref(false)
const validationErrors = ref<ValidationErrors>({})
const formData = ref<SchoolClassroomInput>(new SchoolClassroomInput())

// Options pour les dropdowns
const degreeOptions = ClassroomDegree.options
const typeOptions = ClassroomType.options

// Computed properties
const isLoading = computed(() =>
  localLoading.value || schoolClassroomStoreLoading.value.createClassroom
)

const hasError = computed(() =>
  (schoolClassroomStoreError.value && schoolClassroomStoreError.value.hasError) ||
  Object.keys(validationErrors.value).length > 0
)

const errorMessage = computed(() => {
  if (validationErrors.value.global) return validationErrors.value.global
  if (schoolClassroomStoreError.value?.hasError) return schoolClassroomStoreError.value.message
  return ''
})

const buttonLabel = computed(() => {
  return props.isEdit ? 'Modifier la classe' : 'Créer la classe'
})

// Methods
/**
 * Valide le formulaire avant soumission
 */
function validateForm(): boolean {
  const errors: ValidationErrors = {}

  if (!formData.value.name.trim()) {
    errors.name = 'Le nom de la classe est requis'
  }

  if (!formData.value.degree) {
    errors.degree = 'Le niveau est requis'
  }

  if (!formData.value.type) {
    errors.type = 'Le type de classe est requis'
  }

  if (!formData.value.name.trim() || !formData.value.degree || !formData.value.type) {
    errors.global = 'Veuillez remplir tous les champs obligatoires'
  }

  validationErrors.value = errors
  return Object.keys(errors).length === 0
}

function handleConfirm() {
  if (props.isEdit) {
    updateClassroom()

  } else {
    createClassroom()
  }
}

/**
 * Soumet le formulaire pour créer une nouvelle classe
 */
async function createClassroom() {
  formSubmitted.value = true

  if (!validateForm() || !props.schoolPeriodId) {
    return
  }

  try {
    localLoading.value = true
    // Update formData with the selected school period ID
    formData.value.schoolPeriodId = props.schoolPeriodId

    // Appel au store pour créer la classe
    const newClassroom = await schoolClassroomStore.createSchoolClassroom(
      props.schoolId,
      formData.value
    )

    emit('saved', newClassroom.schoolClassroomId)
    emit('close')
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : 'Une erreur est survenue lors de la création de la classe'
    validationErrors.value.global = errorMessage
  } finally {
    localLoading.value = false
  }
}

/**
 * Soumet le formulaire pour mettre à jour une classe existante
 */
async function updateClassroom() {
  formSubmitted.value = true

  if (!validateForm()) {
    return
  }

  try {
    localLoading.value = true

    if (!props.schoolClassroom) {
      throw new Error('Aucune classe à mettre à jour')
    }

    // Appel au store pour mettre à jour la classe
    const updatedClassroom = await schoolClassroomStore.updateSchoolClassroom(
      props.schoolId,
      props.schoolClassroom.schoolPeriodId,
      formData.value
    )

    emit('saved', updatedClassroom.schoolClassroomId)
    emit('close')
  } catch (err) {
    console.error(err)
    const errorMessage = err instanceof Error ? err.message : 'Une erreur est survenue lors de la mise à jour de la classe'
    validationErrors.value.global = errorMessage
  } finally {
    localLoading.value = false
  }
}

/**
 * Ferme le formulaire sans sauvegarder
 */
function cancel() {
  emit('close')
}

onMounted(() => {
  // Si une classe est passée en props, on pré-remplit le formulaire
  if (props.schoolClassroom) {
    formData.value = props.schoolClassroom
  } else {
    // Sinon, on initialise le formulaire avec des valeurs par défaut
    formData.value = new SchoolClassroomInput()
  }
})
</script>

<template>
<base-block title='Créer une classe' :loading="isLoading">
    <div class="flex flex-column gap-4">
      <!-- Nom de la classe -->
      <div class="field">
        <label for="classroom-name" class="font-medium mb-2 block">Nom de la classe*</label>
        <p-input-text
          id="classroom-name"
          v-model="formData.name"
          class="w-full"
          :class="{ 'p-invalid': formSubmitted && validationErrors.name }"
          placeholder="Ex: 2nde A, Terminale S2..."
        />
        <small class="p-error" v-if="formSubmitted && validationErrors.name">
          {{ validationErrors.name }}
        </small>
      </div>

      <!-- Niveau -->
      <div class="field">
        <label for="classroom-degree" class="font-medium mb-2 block">Niveau*</label>
        <p-dropdown
          id="classroom-degree"
          v-model="formData.degree"
          :options="degreeOptions"
          optionLabel="label"
          optionValue="value"
          placeholder="Sélectionner un niveau"
          class="w-full"
          :class="{ 'p-invalid': formSubmitted && validationErrors.degree }"
        />
        <small class="p-error" v-if="formSubmitted && validationErrors.degree">
          {{ validationErrors.degree }}
        </small>
      </div>

      <!-- Type de classe -->
      <div class="field">
        <label for="classroom-type" class="font-medium mb-2 block">Type de classe*</label>
        <p-dropdown
          id="classroom-type"
          v-model="formData.type"
          :options="typeOptions"
          optionLabel="label"
          optionValue="value"
          placeholder="Sélectionner un type"
          class="w-full"
          :class="{ 'p-invalid': formSubmitted && validationErrors.type }"
        />
        <small class="p-error" v-if="formSubmitted && validationErrors.type">
          {{ validationErrors.type }}
        </small>
      </div>

      <!-- Message d'erreur global -->
      <p-message
        v-if="hasError && errorMessage"
        severity="error"
        class="w-full mb-3"
      >
        {{ errorMessage}}
      </p-message>

      <!-- Boutons d'action -->
      <div class="flex justify-content-end gap-2 mt-4">
        <p-button
          label="Annuler"
          icon="pi pi-times"
          outlined
          @click="cancel"
          :disabled="isLoading"
        />
        <p-button
          :label="buttonLabel"
          icon="pi pi-check"
          @click="handleConfirm"
          :loading="isLoading"
          :disabled="isLoading"
        />
      </div>
    </div>
</base-block>
</template>