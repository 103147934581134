import type { DashboardRepository } from '@/core/infrastructure/repositories/dashboard/DashboardRepository'
import type { DashboardScoreDiagnostic } from '@/core/domain/dashboard/DashboardScoreDiagnostic'

export class FetchDashboardClassroomScoreDiagnosticResults {
  private readonly dashboardRepository: DashboardRepository

  constructor(dashboardRepository: DashboardRepository) {
    this.dashboardRepository = dashboardRepository
  }

  async execute(
    schoolId: number,
    schoolPeriodId: number,
    classroomId: number,
    taskId: number
  ): Promise<DashboardScoreDiagnostic[]> {
    return await this.dashboardRepository.fetchDashboardScoreDiagnosticResults(
        schoolId,
        schoolPeriodId,
        classroomId,
        taskId
      )
  }
}