import { z } from 'zod'
import { LearningQuizType } from '@/core/domain/resources/enums/LearningQuiz'
import { LearningStatus } from '@/core/domain/resources/enums/LearningStatus'
import {
  type MatchingQuestionEntity,
  type MultipleChoiceQuestionEntity,
  type QuestionEntity,
  QuestionSchema, type SequencingQuestionEntity, type SingleChoiceQuestionEntity, type TrueFalseQuestionEntity
} from '@/core/infrastructure/schemas/learning/quiz/LearningQuizQuestionSchema'
import { LearningQuizQuestionType } from '@/core/domain/resources/enums/LearningQuizQuestion'
import {
  type AnswerEntity, type MatchingAnswerEntity,
  MatchingAnswerSchema,
  type MultipleChoiceAnswerEntity, type SequencingAnswerEntity,
  SequencingAnswerSchema,
  type SingleChoiceAnswerEntity, type TrueFalseAnswerEntity,
  TrueFalseAnswerSchema
} from '@/core/infrastructure/schemas/learning/quiz/LearningQuizQuestionAnswerSchema'

// Main Learning Quiz schema
export const LearningQuizSchema = z.object({
  id: z.number(),
  type: z.nativeEnum(LearningQuizType.Values),
  ready: z.boolean(),
  status: z.nativeEnum(LearningStatus.Values).optional(),
  questions: z.array(QuestionSchema)
})

// Multiple quizzes schema
export const LearningQuizListSchema = z.array(LearningQuizSchema)

// Learning Quiz entity
export type LearningQuizEntity = z.infer<typeof LearningQuizSchema>
export type LearningQuizListEntity = z.infer<typeof LearningQuizListSchema>


// Type guards for different question types
export const isSingleChoiceQuestion = (question: QuestionEntity): question is SingleChoiceQuestionEntity => {
  return question.type === LearningQuizQuestionType.Values.SINGLE_CHOICE
}

export const isMultipleChoiceQuestion = (question: QuestionEntity): question is MultipleChoiceQuestionEntity => {
  return question.type === LearningQuizQuestionType.Values.MULTIPLE_CHOICE
}

export const isTrueFalseQuestion = (question: QuestionEntity): question is TrueFalseQuestionEntity => {
  return question.type === LearningQuizQuestionType.Values.TRUE_FALSE
}

export const isMatchingQuestion = (question: QuestionEntity): question is MatchingQuestionEntity => {
  return question.type === LearningQuizQuestionType.Values.MATCHING
}

export const isSequencingQuestion = (question: QuestionEntity): question is SequencingQuestionEntity => {
  return question.type === LearningQuizQuestionType.Values.SEQUENCING
}

// Type guards for different answer types
export const isSingleChoiceAnswer = (answer: AnswerEntity): answer is SingleChoiceAnswerEntity => {
  return answer.type === LearningQuizQuestionType.Values.SINGLE_CHOICE
}

export const isMultipleChoiceAnswer = (answer: AnswerEntity): answer is MultipleChoiceAnswerEntity => {
  return answer.type === LearningQuizQuestionType.Values.MULTIPLE_CHOICE
}

export const isTrueFalseAnswer = (answer: AnswerEntity): answer is TrueFalseAnswerEntity => {
  return answer.type === LearningQuizQuestionType.Values.TRUE_FALSE
}

export const isMatchingAnswer = (answer: AnswerEntity): answer is MatchingAnswerEntity => {
  return answer.type === LearningQuizQuestionType.Values.MATCHING
}

export const isSequencingAnswer = (answer: AnswerEntity): answer is SequencingAnswerEntity => {
  return answer.type === LearningQuizQuestionType.Values.SEQUENCING
}