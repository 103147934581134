<script setup lang="ts">
// Prime components
import { default as PInputText } from 'primevue/inputtext'
import { default as PDropdown } from 'primevue/dropdown'
import { default as PButton } from 'primevue/button'
import { default as PCalendar } from 'primevue/calendar'
import { default as PMultipleSelect } from 'primevue/multiselect'

// Custom imports
import { Gender, type IUserInput } from '@/assets/types/UserTypes'
import { useGenderOptions, useRoleOptions } from '@/assets/utils/dataOptions'
import { useUserStore } from '@/stores/user'

// Vue components
import { computed, onMounted, type Ref, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import { UserRole } from '@/core/domain/resources/enums/UserRole'

const { getUserByIdentifier, createUser, updateUser } = useUserStore()
const { loading } = storeToRefs(useUserStore())
const route = useRoute()
const router = useRouter()

// DATA
const userInput: Ref<IUserInput> = ref({} as IUserInput)
const birthdateModel: Ref<null | Date> = ref(null)

const dataIsValid = computed(() => {
  return (
    !!userInput.value.firstname &&
    !!userInput.value.lastname &&
    !!userInput.value.email &&
    !!userInput.value.roles &&
    !!userInput.value.gender
  )
})

/**
 * Convert and update userInput.birthdate
 * @param value Date
 */
async function onUpdateBirthdate(value: Date) {
  userInput.value.birthdate = value.toISOString()
}

async function onSubmit(userInput: IUserInput) {
  if (route.name === 'edit-user') {
    // Update user
    await updateUser(userInput)
  } else {
    // Create user
    await createUser(userInput)
  }
}

onMounted(async () => {
  // Check if this creation or edition
  if (route.name === 'edit-user') {
    // Get user id from route params
    const userIdentifier = route.params.userIdentifier as string
    // Get user from store
    const u = await getUserByIdentifier(userIdentifier)

    if (!u) {
      // Redirect to users list
      await router.push({ name: 'users-list' })
      return
    }

    // Set user input
    userInput.value = u.getUserInputDto()
  } else {
    userInput.value.gender = Gender.Values.UNSPECIFIED
  }
})
</script>

<template>
  <div class="p-4 h-full overflow-y-auto">
    <div class="mt-7 mx-7">
      <h1 class="text-left">Créer un nouvel utilisateur</h1>
      <small class="text-color">Les champs marqués d'un * sont obligatoires.</small>

      <form class="grid mt-6" @submit.prevent="onSubmit(userInput)">
        <!--      Prénom   -->
        <div class="col-6 flex flex-column">
          <label for="firstName" class="h5 mb-2"> Prénom* </label>
          <p-input-text
            id="firstName"
            type="text"
            required
            v-model="userInput.firstname"
            autocomplete="off"
            data-form-type="other"
          />
        </div>

        <!--      Nom   -->
        <div class="col-6 flex flex-column">
          <label for="lastName" class="h5 mb-2"> Nom* </label>
          <p-input-text
            id="lastName"
            type="text"
            required
            v-model="userInput.lastname"
            autocomplete="off"
            data-form-type="other"
          />
        </div>

        <!--      Email   -->
        <div class="col-6 flex flex-column">
          <label for="email" class="h5 mb-2"> Email* </label>
          <p-input-text
            id="email"
            type="email"
            required
            v-model="userInput.email"
            autocomplete="off"
            data-form-type="other"
          />
        </div>

        <!--      Genre   -->
        <div class="col-6 flex flex-column">
          <label for="gender" class="h5 mb-2"> Genre* </label>
          <p-dropdown
            id="gender"
            v-model="userInput.gender"
            :options="useGenderOptions"
            optionLabel="name"
            optionValue="code"
            aria-required="true"
          />
        </div>

        <!--      Date de naissance   -->
        <div class="col-6 flex flex-column">
          <label for="birthDate" class="h5 mb-2"> Date de naissance </label>
          <p-calendar
            id="birthDate"
            :showIcon="true"
            :showButtonBar="true"
            locale="fr"
            dateFormat="dd/mm/yy"
            panel-class="w-10rem"
            @update:model-value="onUpdateBirthdate"
            v-model="birthdateModel"
          />
        </div>

        <!--     Rôle   -->
        <div class="col-6 flex flex-column">
          <label for="role" class="h5 mb-2"> Rôle* </label>
          <p-multiple-select
            id="role"
            v-model="userInput.roles"
            :options="UserRole.backOfficeOptions"
            optionLabel="name"
            optionValue="code"
          />
        </div>

        <div class="col-12 flex mt-6 justify-content-between">
          <p-button
            label="Annuler"
            type="button"
            severity="secondary"
            @click.prevent="$router.push({ name: 'users-list' })"
            :loading="loading"
          />
          <p-button
            label="Valider"
            type="submit"
            severity="primary"
            :disabled="!dataIsValid"
            :loading="loading"
          />
        </div>
      </form>
    </div>
  </div>
</template>
