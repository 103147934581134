import type {
  SchoolClassroomEntity, SchoolClassroomInputEntity,
  SchoolClassroomsListItemEntity
} from '@/core/infrastructure/schemas/school/classroom/SchoolClassroomSchema'
import  { SchoolPeriodUser } from '@/core/domain/school/user/SchoolPeriodUser'
import { UserRole } from '@/core/domain/resources/enums/UserRole'
import { ClassroomDegree } from '@/core/domain/resources/enums/ClassroomDegree'
import { ClassroomType } from '@/core/domain/resources/enums/ClassroomType'
import  SchoolMonitoringGroup from '@/core/domain/school/group/SchoolMonitoringGroup'

/**
 * Classroom domain class
 */
export default class SchoolClassroom {
  readonly schoolClassroomId: number;
  readonly name: string;
  readonly schoolPeriodId: number;
  readonly degree: ClassroomDegree.Values;
  readonly users: SchoolPeriodUser[];
  readonly type: ClassroomType.Values;
  readonly groups: SchoolMonitoringGroup[];

  constructor(classroom: SchoolClassroomEntity) {
    this.schoolClassroomId = classroom.schoolClassroomId;
    this.name = classroom.name;
    this.schoolPeriodId = classroom.schoolPeriodId;
    this.degree = classroom.degree;
    this.users = classroom.users.map(user => new SchoolPeriodUser(user));
    this.type = classroom.type;
    this.groups = classroom.groups.map(group => new SchoolMonitoringGroup(group));
  }

  get teachers() {
    return this.users.filter(user => user.role.includes(UserRole.Values.TEACHER));
  }

  get students() {
    return this.users.filter(user => user.role.includes(UserRole.Values.STUDENT));
  }

  get studentsWithoutGroup() {
    const studentsWithGroup = this.groups.flatMap(group => group.students);

    return this.users.filter(user => user.role.includes(UserRole.Values.STUDENT) && !studentsWithGroup.some(student => student.userIdentifier === user.userIdentifier));
  }

  toInput(): SchoolClassroomInput {
    return new SchoolClassroomInput({
      schoolClassroomId: this.schoolClassroomId,
      schoolPeriodId: this.schoolPeriodId,
      name: this.name,
      degree: this.degree,
      type: this.type,
      users: this.users
    });
  }
}

export class SchoolClassroomListItem {
  readonly schoolClassroomId: number;
  readonly name: string;
  readonly degree: ClassroomDegree.Values;
  readonly type: ClassroomType.Values;
  readonly countStudents: number;
  readonly countTeachers: number;
  readonly countGroups: number;
  readonly countStudentsWithoutGroup: number;

  constructor(classroom: SchoolClassroomsListItemEntity) {
    this.schoolClassroomId = classroom.schoolClassroomId;
    this.name = classroom.name;
    this.degree = classroom.degree;
    this.type = classroom.type;
    this.countStudents = classroom.countStudents;
    this.countTeachers = classroom.countTeachers;
    this.countGroups = classroom.countGroups;
    this.countStudentsWithoutGroup = classroom.countStudentsWithoutGroup;
  }

  get canDelete() {
    return this.countStudents === 0
  }
}

export class SchoolClassroomInput {
  schoolClassroomId: number;
  schoolPeriodId: number;
  name: string;
  degree: ClassroomDegree.Values | null;
  type: ClassroomType.Values | null;
  users?: SchoolPeriodUser[];

  constructor(schoolClassroom?: SchoolClassroomInputEntity) {
    this.schoolClassroomId = schoolClassroom?.schoolClassroomId ?? 0;
    this.schoolPeriodId = schoolClassroom?.schoolPeriodId || 0;
    this.name = schoolClassroom?.name || '';
    this.degree = schoolClassroom?.degree || null;
    this.type = schoolClassroom?.type || null;
    this.users = schoolClassroom?.users?.map(user => new SchoolPeriodUser(user)) || [];
  }

  get classroomInput() {
    return {
      schoolClassroomId: this.schoolClassroomId,
      schoolPeriodId: this.schoolPeriodId,
      name: this.name,
      degree: this.degree,
      type: this.type
    }
  }
}