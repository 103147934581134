<script setup lang='ts'>
import { default as PTable } from 'primevue/datatable'
import { default as PColumn } from 'primevue/column'
import { default as PButton } from 'primevue/button'
import { default as PTag } from 'primevue/tag'
import { type SchoolPeriodUser } from '@/core/domain/school/user/SchoolPeriodUser'
import { UserRole } from '@/core/domain/resources/enums/UserRole'

// Props
defineProps<{
  users: SchoolPeriodUser[]
  isLoading: boolean
  emptyMessage?: string
}>()

// Émits
const emit = defineEmits<{
  'edit-user': [user: SchoolPeriodUser]
}>()

/**
 * Obtient la classe CSS à utiliser pour un rôle
 * @param role Rôle de l'utilisateur
 * @returns Classe CSS correspondante
 */
function getRoleSeverity(role: UserRole.Values): string {
  switch(role) {
    case UserRole.Values.TEACHER:
      return 'info';
    case UserRole.Values.STUDENT:
      return 'success';
    case UserRole.Values.CPE:
      return 'warning';
    case UserRole.Values.PERDIR:
      return 'danger';
    default:
      return 'secondary';
  }
}
</script>

<template>
  <p-table
    :value='users'
    :paginator='true'
    :rows='10'
    :rowsPerPageOptions='[5, 10, 15, 20]'
    :loading="isLoading"
    currentPageReportTemplate="{first} à {last} sur {totalRecords} utilisateurs"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
    responsiveLayout="scroll"
    :emptyMessage="emptyMessage || 'Aucun utilisateur ne correspond à vos critères de recherche.'"
    class="w-full"
  >
    <p-column field='fullName' header='Nom et prénom' :sortable="true">
      <template #body='{ data }: { data: SchoolPeriodUser }'>
        <span>{{ data.lastname.toUpperCase() }} {{ data.firstname }}</span>
      </template>
    </p-column>

    <p-column field='role' header='Rôle'>
      <template #body='{ data }: { data: SchoolPeriodUser}'>
        <div class="flex flex-wrap gap-1">
          <p-tag
            v-for='(role, idx) in data.role'
            :key='idx'
            :value="UserRole.label(role)"
            :severity="getRoleSeverity(role)"
          />
        </div>
      </template>
    </p-column>

    <p-column field='hasSignedAgreement' header='Statut' style="width: 10rem">
      <template #body='{ data }: { data: SchoolPeriodUser}'>
        <div class="flex flex-wrap gap-1">
          <p-tag
            v-if='data.hasSignedAgreement !== undefined'
            :value='data.hasSignedAgreement ? "Signé" : "Non signé"'
            :severity='data.hasSignedAgreement ? "success" : "warning"'
          />
          <template
            v-else
          >
            -
          </template>
        </div>
      </template>
    </p-column>

    <p-column
      field='actions'
      header='Actions'
      headerClass="w-10rem"
      bodyClass="text-center"
      style="min-width: 10rem"
    >
      <template #body='{ data }: { data: SchoolPeriodUser }'>
        <div class="flex justify-content-center">
          <p-button
            size='small'
            icon='pi pi-pencil'
            @click="emit('edit-user', data)"
            text
          />
        </div>
      </template>
    </p-column>
  </p-table>
</template>
