import type { DashboardRepository } from '@/core/infrastructure/repositories/dashboard/DashboardRepository'
import type { DashboardScoreModule } from '@/core/domain/dashboard/DashboardScoreModule'

export class FetchDashboardClassroomScoreModuleResults {
  private readonly dashboardRepository: DashboardRepository

  constructor(dashboardRepository: DashboardRepository) {
    this.dashboardRepository = dashboardRepository
  }

  async execute(
    schoolId: number,
    schoolPeriodId: number,
    classroomId: number,
    taskId: number
  ): Promise<DashboardScoreModule[]> {
    return await this.dashboardRepository.fetchDashboardScoreModuleResults(
        schoolId,
        schoolPeriodId,
        classroomId,
        taskId
      )
  }
}