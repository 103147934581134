import type { ISchoolClassroomRepository } from '@/core/domain/school/classroom/ISchoolClassroomRepository'
import type SchoolClassroom from '@/core/domain/school/classroom/SchoolClassroom'

export class FetchSchoolClassroomUseCase {
  private schoolClassroomRepository: ISchoolClassroomRepository

  constructor(schoolClassroomRepository: ISchoolClassroomRepository) {
    this.schoolClassroomRepository = schoolClassroomRepository
  }

  async execute(schoolId: number, periodId: number, schoolClassroomId: number): Promise<SchoolClassroom> {
    return await this.schoolClassroomRepository.fetchSchoolClassroom(schoolId, periodId, schoolClassroomId)
  }
}