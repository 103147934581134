export namespace UserRole {
  export enum SchoolValues {
    TEACHER = 'TEACHER',
    CPE = 'CPE',
    PERDIR = 'PERDIR',
    STUDENT = 'STUDENT'
  }

  export enum BackOfficeValues {
    ADMIN = 'ADMIN',
    EDU_ENGINEER = 'EDU_ENGINEER',
    COORDINATOR = 'COORDINATOR',
    TUTOR = 'TUTOR'
  }

  export enum Values {
    ADMIN = 'ADMIN',
    EDU_ENGINEER = 'EDU_ENGINEER',
    PERDIR = 'PERDIR',
    CPE = 'CPE',
    COORDINATOR = 'COORDINATOR',
    TEACHER = 'TEACHER',
    TUTOR = 'TUTOR',
    STUDENT = 'STUDENT'
  }

  export function label(authority: Values | SchoolValues | BackOfficeValues) {
    switch (authority) {
      case Values.ADMIN:
        return 'Administrateur'
      case Values.EDU_ENGINEER:
        return 'Ing. Pédagogique'
      case Values.PERDIR:
        return 'Pers. Dir.'
      case Values.CPE:
        return 'CPE'
      case Values.COORDINATOR:
        return 'Coordinateur'
      case Values.TEACHER:
        return 'Professeur'
      case Values.TUTOR:
        return 'Tuteur'
      case Values.STUDENT:
        return 'Étudiant'
      default:
        return 'Non défini'
    }
  }

  export const options =
    Object.values(Values).map((role) => ({
      value: role,
      label: label(role)
    }))

  export const schoolOptions =
    Object.values(SchoolValues).map((role) => ({
      value: role,
      label: label(role)
    }))

  export const backOfficeOptions =
    Object.values(BackOfficeValues).map((role) => ({
      value: role,
      label: label(role)
    }))


  export function isBackOfficeRole(role: any[]): boolean {
    return (
      role.includes(BackOfficeValues.ADMIN) ||
      role.includes(BackOfficeValues.EDU_ENGINEER) ||
      role.includes(BackOfficeValues.COORDINATOR) ||
      role.includes(BackOfficeValues.TUTOR)
    )
  }

  export function isSchoolRole(role: any[]): boolean {
    return (
      role.includes(SchoolValues.TEACHER) ||
      role.includes(SchoolValues.CPE) ||
      role.includes(SchoolValues.PERDIR) ||
      role.includes(SchoolValues.STUDENT)
    )
  }
}