<script setup lang="ts">
import DashboardBaseBlock from '@/views/dashboard/blocks/DashboardBaseBlock.vue'
import { computed, onMounted, type Ref, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import type { IUser } from '@/assets/types/UserTypes'
import ThemeTableScore from '@/components/dashboard/DashboardThemeTableScore.vue'
import { default as PDataTable } from 'primevue/datatable'
import { default as PColumn } from 'primevue/column'
import { default as PDropdown } from 'primevue/dropdown'
import { default as PInputText } from 'primevue/inputtext'
import { default as PInputGroup } from 'primevue/inputgroup'
import { default as PMessage } from 'primevue/message'
import { default as PProgressSpinner } from 'primevue/progressspinner'
import PChart from 'primevue/chart'
import { FilterMatchMode } from 'primevue/api'
import ModuleTableTaskStatus from '@/components/dashboard/DashboardModuleTableTaskStatus.vue'
import type { ChartOptions } from 'chart.js'
import { EvaluationRange } from '@/assets/types/enums'
import { useToast } from 'primevue/usetoast'
import type { SchoolClassroomScoreModuleLearningGrainDto } from '@/assets/DTO/school/school.classroom.response.dto'
import { useNabooContent } from '@/assets/composables/useNabooContent'
import { useProfileStore } from '@/stores/profile'
import { useDashboardClassroomResultStore } from '@/stores/dashboard/dashboardClassroomResult'
import { useDashboardClassroomStore } from '@/stores/dashboard/dashboardClassroom'

// Import Primevue services
const toast = useToast()
const { openModuleContent } = useNabooContent()

const props = defineProps<{
  title: string
  courseId: number
  schoolId: number
  classroomId: number
  moduleId: number
  taskId: number
}>()

// STORES
const { getFullName } = storeToRefs(useProfileStore())
const dashboardClassroomResultStore = useDashboardClassroomResultStore()
const dashboardClassroomStore = useDashboardClassroomStore()
const { scoreModule, dashboardClassroomResultStoreLoading } = storeToRefs(dashboardClassroomResultStore)
const { dashboardSelectedClassroom } = storeToRefs(dashboardClassroomStore)

// LOCAL STATE
const loading = ref(false)
const error = ref<string | null>(null)
const dataInitialized = ref(false)
const tableKey = ref(0) // Pour forcer le rechargement de la table

// METHODS
const { fetchSchoolClassroomScoreModule } = dashboardClassroomResultStore
const { setDashboardSelectedClassroomById } = useDashboardClassroomStore()

/**
 * Charge les données du module
 */
const loadModuleData = async (forceRefresh = false) => {
  // Si déjà initialisé et pas d'erreur ni rechargement forcé, ne pas recharger
  if (dataInitialized.value && !error.value && !forceRefresh) return

  try {
    loading.value = true
    error.value = null

    // S'assurer que la classe est correctement sélectionnée
    if (!dashboardSelectedClassroom.value ||
      dashboardSelectedClassroom.value.schoolClassroomId !== props.classroomId) {
      await setDashboardSelectedClassroomById(
        props.schoolId,
        props.classroomId
      )
    }

    // Récupérer les données du module
    await fetchSchoolClassroomScoreModule(props.taskId)

    // Initialiser le filtre de l'enseignant
    filters.value.teacherFullname.value = teacherOptn.value?.value ?? null

    // Incrémenter la clé pour forcer le rechargement du tableau
    tableKey.value++

    // Marquer comme initialisé après le chargement réussi
    dataInitialized.value = true
  } catch (err) {
    console.error('Erreur lors du chargement des détails de la thématique:', err)
    error.value = err instanceof Error
      ? err.message
      : 'Impossible de charger les détails de la thématique'

    toast.add({
      severity: 'error',
      summary: 'Erreur',
      detail: error.value,
      life: 5000
    })
  } finally {
    loading.value = false
  }
}

// Observer les changements de props pour recharger si nécessaire
watch(
  [() => props.schoolId, () => props.classroomId, () => props.taskId, () => props.moduleId],
  async ([newSchoolId, newClassroomId, newTaskId, newModuleId], [oldSchoolId, oldClassroomId, oldTaskId, oldModuleId]) => {
    if (newSchoolId !== oldSchoolId || newClassroomId !== oldClassroomId ||
      newTaskId !== oldTaskId || newModuleId !== oldModuleId) {
      // Réinitialiser pour permettre le rechargement
      dataInitialized.value = false
      await loadModuleData(true)
    }
  }
)

onMounted(async () => {
  await loadModuleData()
})

// Source de données transformée
const dataSource = computed(() => {
  return scoreModule.value?.[props.taskId]?.map((student) => {
    return {
      ...student,
      teacherFullname: student.teacherFirstname
        ? `${student.teacherFirstname} ${student.teacherLastname}`
        : undefined
    }
  }) || []
})

// Options d'enseignants pour le filtre
const teachersOptions = computed(() => {
  const teachers = [
    ...new Set(
      dataSource.value
        ?.filter((student) => student.teacherFullname)
        ?.map((student) => student.teacherFullname)
    )
  ]?.map((teacher) => {
    return {
      label: teacher,
      value: teacher
    }
  })

  return teachers ? [{ label: 'Tous les enseignants', value: null }, ...teachers] : []
})

// Option d'enseignant par défaut basée sur l'utilisateur connecté
const teacherOptn = computed(() => {
  return teachersOptions.value.find((teacher) => teacher.value === getFullName.value)
})

// Configuration des filtres de table avec valeurs par défaut améliorées
const filters: Ref<{
  global: { value: string | null; matchMode: string }
  firstname: { value: string | null; matchMode: string }
  lastname: { value: string | null; matchMode: string }
  teacherFullname: { value: string | null; matchMode: string }
}> = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  firstname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  lastname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  teacherFullname: { value: null, matchMode: FilterMatchMode.EQUALS }
})

// Options du graphique optimisées
const chartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: true,
      callbacks: {
        // Formater les tooltips pour plus de clarté
        label: (context) => {
          const labels = ['Non commencé', 'Défi', 'Efforts à faire', 'Force'];
          const value = context.parsed.y;
          return `${labels[context.dataIndex]}: ${value} élève${value > 1 ? 's' : ''}`;
        }
      }
    },
    datalabels: {
      anchor: 'end',
      align: 'end',
      color: '#000',
      font: {
        size: 12,
        weight: 'bold'
      },
      // N'afficher les valeurs que si > 0
      formatter: (value) => value > 0 ? value : '',
      display: (context) => context.dataset.data[context.dataIndex] > 0
    }
  },
  scales: {
    x: {
      ticks: {
        color: '#495057',
        font: {
          size: 14
        }
      },
      grid: {
        display: false
      },
      barPercentage: 0.8
    },
    y: {
      beginAtZero: true,
      ticks: {
        color: '#495057',
        font: {
          size: 14
        },
        // Ajuste les intervalles en fonction du max
        stepSize: (context) => {
          const max = Math.max(...context.chart.data.datasets.flatMap(d => d.data));
          return max <= 5 ? 1 : max <= 20 ? 2 : 5;
        }
      },
      grid: {
        color: '#E5E5E5'
      }
    }
  },
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 30,
      bottom: 10
    }
  }
}

// Transformation des données pour le graphique avec gestion d'erreur améliorée
const transformDataForChart = computed(() => {
  const data = scoreModule.value?.[props.taskId] || []
  const result = {
    notStarted: 0,
    defi: 0,
    effortsAFaire: 0,
    force: 0
  }

  // Comptage des élèves par catégorie
  data.forEach((student) => {
    // Vérifier si toutes les sessions sont terminées
    const allSessionsCompleted = student.learningGrains?.every(
      grain => grain.postQuizEvalRangeName !== null
    )

    // Si toutes les sessions sont terminées, catégoriser selon moduleEvalRangeName
    if (allSessionsCompleted) {
      if (student.moduleEvalRangeName === EvaluationRange.Values.DEFI) {
        result.defi += 1
      } else if (student.moduleEvalRangeName === EvaluationRange.Values.EFFORT_A_FAIRE) {
        result.effortsAFaire += 1
      } else if (student.moduleEvalRangeName === EvaluationRange.Values.FORCE) {
        result.force += 1
      }
    } else {
      // Module en cours ou non commencé
      result.notStarted += 1
    }
  })

  return {
    labels: ['Non commencé', 'Défi', 'Efforts à faire', 'Force'],
    datasets: [
      {
        label: 'Répartition des élèves',
        backgroundColor: ['#999', '#FF6F61', '#FBA86F', '#32CD32'],
        data: [result.notStarted, result.defi, result.effortsAFaire, result.force]
      }
    ]
  }
})

// Déterminer si nous sommes en cours de chargement
const isLoading = computed(() => {
  return loading.value || dashboardClassroomResultStoreLoading.value.fetchScoreModule
})

// Déterminer s'il n'y a pas de données
const isEmpty = computed(() => {
  return !isLoading.value &&
    (!scoreModule.value?.[props.taskId] || scoreModule.value[props.taskId].length === 0)
})

// Déterminer si on peut exporter les données
const canExportData = computed(() => {
  return dataSource.value.length > 0
})

// Préparer les données pour l'export
const exportCSV = () => {
  if (!canExportData.value) return

  try {
    const exportData = dataSource.value.map(student => ({
      Prénom: student.firstname,
      Nom: student.lastname,
      Enseignant: student.teacherFullname || '-',
      'Score diagnostic (%)': student.diagScorePercentRounded,
      'Score module (%)': student.moduleScorePercentRounded,
      Variation: student.diagnosticModuleVariation
    }))

    // Convertir en CSV
    const header = Object.keys(exportData[0]).join(',')
    const rows = exportData.map(row => Object.values(row).join(','))
    const csv = [header, ...rows].join('\n')

    // Télécharger
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', `progression-${props.title.replace(/\s+/g, '-')}.csv`)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    toast.add({
      severity: 'success',
      summary: 'Export réussi',
      detail: 'Les données ont été exportées avec succès',
      life: 3000
    })
  } catch (err) {
    console.error('Erreur lors de l\'export CSV:', err)
    toast.add({
      severity: 'error',
      summary: 'Erreur d\'export',
      detail: 'Une erreur est survenue lors de l\'export des données',
      life: 5000
    })
  }
}
</script>

<template>
  <dashboard-base-block
    :title="title"
    accordion
    :key="`module-${props.moduleId}-${props.taskId}`"
    class="dashboard-progress-block"
  >
    <div class="flex flex-wrap justify-content-between align-items-center mb-3">
      <a class='bo-active-color cursor-pointer' @click='openModuleContent(moduleId, courseId)'>
        Découvrir les contenus du module <i class='pi pi-external-link' />
      </a>

      <button
        v-if="canExportData"
        class="p-button p-button-outlined p-button-sm"
        @click="exportCSV"
      >
        <i class="pi pi-download mr-1"></i> Exporter CSV
      </button>
    </div>

    <!-- Affichage en cas d'erreur -->
    <p-message
      v-if="error"
      severity="error"
      class="mb-3 w-full"
    >
      {{ error }}
    </p-message>

    <!-- Affichage en cas de chargement -->
    <div v-else-if="isLoading" class="flex align-items-center justify-content-center p-5">
      <p-progress-spinner
        style="width: 50px; height: 50px;"
        strokeWidth="5"
        fill="#EEEEEE"
        animationDuration=".5s"
      />
    </div>

    <!-- Cas où il n'y a pas de données -->
    <div
      v-else-if="isEmpty"
      class="flex flex-column align-items-center justify-content-center p-4 bg-gray-50 border-round-md"
    >
      <i class="pi pi-chart-bar text-4xl text-500 mb-3"></i>
      <h3 class="mt-0 mb-2">Aucune donnée disponible</h3>
      <p class="text-center text-700">
        Aucun résultat n'est disponible pour cette thématique actuellement.
      </p>
    </div>

    <!-- Contenu principal -->
    <div
      v-else
      class="border-1 border-bo-brand-secondary mt-4 p-4 flex flex-column gap-4 border-round-2xl col-12"
    >
      <!-- Section graphique résumé -->
      <section class="progress-summary">
        <h4 class="heading medium text-800">Répartition des élèves par intervalles de scores</h4>
        <div class="grid">
          <div class="col-12 md:col-6 lg:col-5 xl:col-4">
            <div class="chart-container" style="position: relative; height: 240px;">
              <p-chart
                class="w-full h-full"
                type="bar"
                :data="transformDataForChart"
                :options="chartOptions"
              />
            </div>
          </div>
          <div class="col-12 md:col-6 flex flex-column justify-content-center">
            <div class="stats-summary p-3 border-round-lg bg-gray-50">
              <h5 class="text-lg font-medium m-0 mb-2">Synthèse</h5>
              <ul class="list-none p-0 m-0">
                <li v-for="(count, index) in transformDataForChart.datasets[0].data" :key="index" class="mb-2 flex align-items-center">
                  <div class="stat-indicator mr-2" :style="{backgroundColor: transformDataForChart.datasets[0].backgroundColor[index]}"></div>
                  <span class="font-medium">{{ transformDataForChart.labels[index] }}:</span>
                  <span class="ml-2">{{ count }} élève{{ count > 1 ? 's' : '' }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <!-- Section détails par élève -->
      <section class="student-details">
        <h4 class="heading medium text-800">Détails par élève</h4>

        <!-- Légende -->
        <div class="bg-bo-brand-tertiary p-3 border-round-2xl grid col-12 gap-2 align-items-center mb-3">
          <h5 class="brand-heading small text-800 col-12 m-0">Légende</h5>
          <div class="flex flex-wrap gap-4">
            <div class="flex gap-2 align-items-center">
              <module-table-task-status :grain-eval-range="null" />
              <p class="m-0">Session non réalisée</p>
            </div>
            <div class="flex gap-2 align-items-center">
              <module-table-task-status :grain-eval-range="EvaluationRange.Values.FORCE" />
              <p class="m-0"><span class="font-bold">Force : </span>score &gt; 70%</p>
            </div>
            <div class="flex gap-2 align-items-center">
              <module-table-task-status :grain-eval-range="EvaluationRange.Values.EFFORT_A_FAIRE" />
              <p class="m-0"><span class="font-bold">Efforts à faire : </span>score entre 40% et 70%</p>
            </div>
            <div class="flex gap-2 align-items-center">
              <module-table-task-status :grain-eval-range="EvaluationRange.Values.DEFI" />
              <p class="m-0"><span class="font-bold">Défi : </span>score &lt; 40%</p>
            </div>
          </div>
        </div>

        <!-- Tableau de données -->
        <p-data-table
          :value="dataSource"
          dataKey="id"
          class="text-sm overflow-x-auto"
          size="small"
          :loading="isLoading"
          v-model:filters="filters"
          removableSort
          scrollable
          sortMode="multiple"
          paginator
          :rows="10"
          :rowsPerPageOptions="[5, 10, 20, 50]"
          :globalFilterFields="['firstname', 'lastname', 'teacherFullname']"
          stripedRows
          :key="tableKey"
          responsiveLayout="stack"
        >
          <!-- Header avec filtres -->
          <template #header>
            <div class="flex flex-column sm:flex-row gap-2 justify-content-between align-items-center">
              <div class="p-input-icon-left w-full sm:w-auto">
                <i class="pi pi-search" />
                <p-input-text
                  v-model="filters['global'].value"
                  placeholder="Rechercher un élève"
                  class="w-full sm:w-auto"
                />
              </div>

              <p-dropdown
                v-model="filters['teacherFullname'].value"
                :options="teachersOptions"
                optionLabel="label"
                optionValue="value"
                placeholder="Filtrer par enseignant"
                class="w-full sm:w-auto"
              />
            </div>
          </template>

          <template #empty>Aucune donnée trouvée.</template>
          <template #loading>Chargement des données...</template>

          <!-- Colonnes du tableau -->
          <p-column field="firstname" frozen :sortable="true" header="Prénom" class="w-12rem">
            <template #body="{ data }">
              <p class="m-0">{{ (data as IUser).firstname }}</p>
            </template>
          </p-column>

          <p-column field="lastname" frozen :sortable="true" header="Nom" class="w-12rem">
            <template #body="{ data }">
              <p class="m-0">{{ (data as IUser).lastname }}</p>
            </template>
          </p-column>

          <p-column field="teacherFullname" :sortable="true" header="Enseignant" class="w-12rem">
            <template #body="{ data }">
              <p class="m-0">
                {{ data.teacherFullname ? data.teacherFullname : '-' }}
              </p>
            </template>
          </p-column>

          <p-column
            field="diagScorePercentRounded"
            :sortable="true"
            header="Score diagnostic"
            class="w-12rem"
          >
            <template #body="{ data }">
              <theme-table-score
                :score="data.diagScorePercentRounded"
                :eval-range="data.diagEvalRangeName"
              />
            </template>
          </p-column>

          <p-column
            field="learningGrains"
            header="Sessions"
            class="w-12rem"
          >
            <template #body="{ data }">
              <div class="flex flex-wrap gap-2">
                <module-table-task-status
                  v-for="grain in data.learningGrains"
                  :key="grain.learningGrainId"
                  :grain="grain"
                  :grain-eval-range="grain.postQuizEvalRangeName"
                />
              </div>
            </template>
          </p-column>

          <p-column
            field="moduleScorePercentRounded"
            :sortable="true"
            header="Score module"
            class="w-12rem"
          >
            <template #body="{ data }">
              <p class="text-800 font-italic m-0" v-if="data.learningGrains.filter((grain: SchoolClassroomScoreModuleLearningGrainDto) => grain.postQuizEvalRangeName === null).length > 0">
                En cours
              </p>
              <theme-table-score
                v-else
                :score="data.moduleScorePercentRounded"
                :eval-range="data.moduleEvalRangeName"
                :progress="
                  data.diagnosticModuleVariation > 0
                    ? '+'
                    : data.diagnosticModuleVariation < 0
                      ? '-'
                      : '='
                "
              />
            </template>
          </p-column>
        </p-data-table>
      </section>
    </div>
  </dashboard-base-block>
</template>

<style scoped>
.chart-container {
  min-height: 240px;
  width: 100%;
}

.stat-indicator {
  width: 16px;
  height: 16px;
  border-radius: 3px;
}

.progress-summary, .student-details {
  margin-bottom: 1.5rem;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .chart-container {
    height: 200px;
  }
}
</style>