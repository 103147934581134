import type { IUsersRepository } from '@/core/domain/users/IUsersRepository'
import { SchoolPeriodUser, type SchoolPeriodUserInput } from '@/core/domain/school/user/SchoolPeriodUser'
import SchoolPeriodUserMapper from '@/core/infrastructure/mappers/school/SchoolPeriodUserMapper'

export default class PrepareSchoolPeriodUserEditUseCase {
  private usersRepository: IUsersRepository;

  constructor(
    usersRepository: IUsersRepository
  ) {
    this.usersRepository = usersRepository;
  }

  /**
   * Prépare les données pour le formulaire d'édition d'un utilisateur
   */
  async execute(
    schoolPeriodId: number,
    schoolPeriodUser: SchoolPeriodUser
  ): Promise<SchoolPeriodUserInput> {

    // Récupérer les détails complémentaires de l'utilisateur
    const user = await this.usersRepository.fetchUserByIdentifier(schoolPeriodUser.userIdentifier);

    // Construire l'objet d'entrée pour le formulaire
    return SchoolPeriodUserMapper.toInput(schoolPeriodUser, user, schoolPeriodId);
  }
}