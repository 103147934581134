import type ISchoolRepository from '@/core/domain/school/core/ISchoolRepository'
import type School from '@/core/domain/school/core/School'
import { SchoolMe } from '@/core/domain/school/core/School'


export default class FetchSchoolsForMeUseCase {
  private schoolRepository: ISchoolRepository;

  constructor(schoolRepository: ISchoolRepository) {
    this.schoolRepository = schoolRepository;
  }

  async execute(): Promise<SchoolMe[]> {
    return await this.schoolRepository.fetchSchoolsForMe();
  }
}