import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import DependencyContainer from '@/presentation/configuration/DependencyContainer'
import { createStoreBase } from '@/stores/resources/storeBase'
import { useToast } from 'primevue/usetoast'
import SchoolMonitoringGroup, { SchoolMonitoringGroupInput } from '@/core/domain/school/group/SchoolMonitoringGroup'
import { FetchSchoolMonitoringGroupUseCase } from '@/core/useCases/school/group/FetchSchoolMonitoringGroupUseCase'
import { CreateSchoolMonitoringGroupUseCase } from '@/core/useCases/school/group/CreateSchoolMonitoringGroupUseCase'
import {
  UpdateSchoolMonitoringGroupUsersUseCase
} from '@/core/useCases/school/group/UpdateSchoolMonitoringGroupUsersUseCase'
import { DeleteSchoolMonitoringGroupUseCase } from '@/core/useCases/school/group/DeleteSchoolMonitoringGroupUseCase'
import { useStoreEvents } from '@/stores/utils/storeEventBus'

export const useSchoolMonitoringGroupStore = defineStore('schoolMonitoringGroup', () => {
  // Base commune
  const storeBase = createStoreBase([
    'fetchGroups',
    'fetchGroup',
    'createGroup',
    'updateGroup',
    'deleteGroup'
  ])

  // Services & composables
  const toast = useToast()
  const storeEvents = useStoreEvents()

  // État
  const schoolMonitoringGroups = ref<SchoolMonitoringGroup[]>([])
  const selectedGroup = ref<SchoolMonitoringGroup | null>(null)
  const currentSchoolId = ref<number | null>(null)
  const currentSchoolPeriodId = ref<number | null>(null)
  const currentSchoolClassroomId = ref<number | null>(null)

  // Méthodes
  /**
   * Récupère les détails d'un groupe de suivi spécifique
   * @param schoolId ID de l'école
   * @param schoolPeriodId ID de la période scolaire
   * @param schoolClassroomId ID de la classe
   * @param groupId ID du groupe
   */
  async function fetchSchoolMonitoringGroup(
    schoolId: number,
    schoolPeriodId: number,
    schoolClassroomId: number,
    groupId: number
  ): Promise<SchoolMonitoringGroup> {
    try {
      storeBase.loading.value.fetchGroup = true
      storeBase.resetError()

      const response = await DependencyContainer.resolve(FetchSchoolMonitoringGroupUseCase).execute(
        schoolId,
        schoolPeriodId,
        schoolClassroomId,
        groupId
      )

      selectedGroup.value = response
      return response
    } catch (err) {
      storeBase.setError(err)
      return Promise.reject(err)
    } finally {
      storeBase.loading.value.fetchGroup = false
    }
  }

  /**
   * Crée un nouveau groupe de suivi
   * @param schoolId ID de l'école
   * @param schoolPeriodId ID de la période scolaire
   * @param schoolClassroomId ID de la classe
   */
  async function createSchoolMonitoringGroup(
    schoolId: number,
    schoolPeriodId: number,
    schoolClassroomId: number
  ): Promise<SchoolMonitoringGroup> {
    try {
      storeBase.loading.value.createGroup = true
      storeBase.resetError()

      const response = await DependencyContainer.resolve(CreateSchoolMonitoringGroupUseCase).execute(
        schoolId,
        schoolPeriodId,
        schoolClassroomId
      )

      schoolMonitoringGroups.value.push(response)
      storeBase.updateLastUpdated()

      toast.add({
        severity: 'success',
        summary: 'Succès',
        detail: 'Le groupe de suivi a été créé avec succès',
        life: 3000
      })

      return response
    } catch (err) {
      storeBase.setError(err)
      return Promise.reject(err)
    } finally {
      storeBase.loading.value.createGroup = false
    }
  }

  /**
   * Met à jour un groupe de suivi existant
   * @param schoolId ID de l'école
   * @param schoolPeriodId ID de la période scolaire
   * @param schoolClassroomId ID de la classe
   * @param schoolMonitoringGroupId ID du groupe à mettre à jour
   * @param groupData Nouvelles données du groupe
   */
  async function updateSchoolMonitoringGroup(
    schoolId: number,
    schoolPeriodId: number,
    schoolClassroomId: number,
    schoolMonitoringGroupId: number,
    groupData: SchoolMonitoringGroupInput
  ): Promise<SchoolMonitoringGroup> {
    try {
      storeBase.loading.value.updateGroup = true
      storeBase.resetError()

      const response = await DependencyContainer.resolve(UpdateSchoolMonitoringGroupUsersUseCase).execute(
        schoolId,
        schoolPeriodId,
        schoolClassroomId,
        schoolMonitoringGroupId,
        groupData
      )

      const groupToUpdate = schoolMonitoringGroups.value.find(g => g.groupId === response.groupId)
      if (groupToUpdate) {
        Object.assign(groupToUpdate, response)
      }
      storeBase.updateLastUpdated()

      // Notifier les autres stores de la mise à jour
      storeEvents.emit('group-updated', {
        schoolId,
        schoolPeriodId,
        schoolClassroomId,
        groupId: response.groupId
      })

      toast.add({
        severity: 'success',
        summary: 'Succès',
        detail: 'Le groupe de suivi a été mis à jour avec succès',
        life: 3000
      })

      return response
    } catch (err) {
      storeBase.setError(err)
      return Promise.reject(err)
    } finally {
      storeBase.loading.value.updateGroup = false
    }
  }

  /**
   * Supprime un groupe de suivi
   * @param schoolId ID de l'école
   * @param schoolPeriodId ID de la période scolaire
   * @param schoolClassroomId ID de la classe
   * @param groupId ID du groupe à supprimer
   */
  async function deleteSchoolMonitoringGroup(
    schoolId: number,
    schoolPeriodId: number,
    schoolClassroomId: number,
    groupId: number
  ): Promise<boolean> {
    try {
      storeBase.loading.value.deleteGroup = true
      storeBase.resetError()

      await DependencyContainer.resolve(DeleteSchoolMonitoringGroupUseCase).execute(
        schoolId,
        schoolPeriodId,
        schoolClassroomId,
        groupId
      )

      toast.add({
        severity: 'success',
        summary: 'Succès',
        detail: 'Le groupe de suivi a été supprimé avec succès',
        life: 3000
      })

      return true
    } catch (err) {
      storeBase.setError(err)
      return Promise.reject(err)
    } finally {
      storeBase.loading.value.deleteGroup = false
    }
  }

  /**
   * Réinitialise le store à son état initial
   */
  function reset(): void {
    schoolMonitoringGroups.value = []
    selectedGroup.value = null
    currentSchoolId.value = null
    currentSchoolPeriodId.value = null
    currentSchoolClassroomId.value = null
    storeBase.resetBase()
  }

  // Getters
  /**
   * Indique si les données doivent être rechargées
   */
  const needsFreshData = computed(() => {
    if (!storeBase.lastUpdated.value) return true

    // Considérer les données comme périmées après 5 minutes
    const fiveMinutesInMs = 5 * 60 * 1000
    return Date.now() - storeBase.lastUpdated.value.getTime() > fiveMinutesInMs
  })

  /**
   * Récupère un groupe par son ID
   */
  const getGroupById = computed(() => {
    return (id: number) => schoolMonitoringGroups.value.find(g => g.groupId === id)
  })

  /**
   * Nombre total de groupes
   */
  const groupCount = computed(() => schoolMonitoringGroups.value.length)

  return {
    // État
    schoolMonitoringGroups,
    selectedGroup,
    currentSchoolId,
    currentSchoolPeriodId,
    currentSchoolClassroomId,
    schoolMonitoringGroupStoreLoading: storeBase.loading,
    schoolMonitoringGroupStoreError: storeBase.error,
    schoolMonitoringGroupStoreLastUpdated: storeBase.lastUpdated,

    // Actions
    // fetchSchoolMonitoringGroups: fetchAllSchoolMonitoringGroups,
    fetchSchoolMonitoringGroup,
    createSchoolMonitoringGroup,
    updateSchoolMonitoringGroup,
    deleteSchoolMonitoringGroup,
    reset,

    // Getters
    needsFreshData,
    getGroupById,
    groupCount,
    schoolMonitoringGroupStoreIsLoading: storeBase.isLoading
  }
})