<script setup lang="ts">
import { computed, inject, onMounted, type Ref, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useToast } from 'primevue/usetoast'
import { default as PDropdown } from 'primevue/dropdown'
import { default as PButton } from 'primevue/button'
import { default as PMessage } from 'primevue/message'
import { default as PPickList } from 'primevue/picklist'
import { default as PProgressSpinner } from 'primevue/progressspinner'
import { useSchoolMonitoringGroupStore } from '@/stores/school/schoolMonitoringGroup'
import { useSchoolPeriodUserStore } from '@/stores/school/schoolPeriodUser'
import { UserRole } from '@/core/domain/resources/enums/UserRole'
import type { SchoolPeriodUser } from '@/core/domain/school/user/SchoolPeriodUser'
import type { DialogRefWrapper } from '@/assets/types/primevue/dialog'
import SchoolMonitoringGroup, { SchoolMonitoringGroupInput } from '@/core/domain/school/group/SchoolMonitoringGroup'
import { useSchoolClassroomStore } from '@/stores/school/schoolClassroom'
import type SchoolClassroom from '@/core/domain/school/classroom/SchoolClassroom'

// types
type DialogData = {
  schoolId: number
  schoolPeriodId: number
  schoolClassroomId: number
  groupId?: number
  groupData?: SchoolMonitoringGroup
}

interface ValidationErrors {
  name?: string
  teacher?: string
  global?: string
}

// Composables
const toast = useToast()
const schoolClassroomStore = useSchoolClassroomStore()
const schoolMonitoringGroupStore = useSchoolMonitoringGroupStore()
const schoolPeriodUserStore = useSchoolPeriodUserStore()

// Dialog ref
const dialogRef: DialogRefWrapper<DialogData> | undefined = inject('dialogRef')

// Store refs
const { selectedGroup, schoolMonitoringGroupStoreLoading, schoolMonitoringGroupStoreError } = storeToRefs(schoolMonitoringGroupStore)
const { schoolPeriodUserStoreLoading, schoolPeriodUserStoreError } = storeToRefs(schoolPeriodUserStore)

// Local state
const localLoading = ref(false)
const localError = ref('')
const formSubmitted = ref(false)
const validationErrors = ref<ValidationErrors>({})
const modelValue = ref<SchoolPeriodUser[][]>([[], []])
const sourceUsers = ref<SchoolPeriodUser[]>([])
const targetUsers = ref<SchoolPeriodUser[]>([])
const sourceFilter = ref('')
const targetFilter = ref('')
const groupName = ref('')
const selectedTeacher = ref<SchoolPeriodUser | null>(null)
const teacherOptions = ref<SchoolPeriodUser[]>([])
const schoolClassroom: Ref<SchoolClassroom | null> = ref(null)

// Computed
const isLoading = computed(() =>
  localLoading.value ||
  schoolMonitoringGroupStoreLoading.value.fetchGroup ||
  schoolPeriodUserStoreLoading.value.fetchUsers
)

const hasError = computed(() =>
  localError.value !== '' ||
  (schoolMonitoringGroupStoreError.value && schoolMonitoringGroupStoreError.value.hasError) ||
  (schoolPeriodUserStoreError.value && schoolPeriodUserStoreError.value.hasError) ||
  Object.keys(validationErrors.value).length > 0
)

const errorMessage = computed(() => {
  if (validationErrors.value.global) return validationErrors.value.global
  if (localError.value) return localError.value
  if (schoolMonitoringGroupStoreError.value?.hasError) return schoolMonitoringGroupStoreError.value.message
  if (schoolPeriodUserStoreError.value?.hasError) return schoolPeriodUserStoreError.value.message
  return ''
})

const schoolId = computed(() => dialogRef?.value.data.schoolId || 0)
const schoolPeriodId = computed(() => dialogRef?.value.data.schoolPeriodId || 0)
const schoolClassroomId = computed(() => dialogRef?.value.data.schoolClassroomId || 0)
const groupId = computed(() => dialogRef?.value.data.groupId)

const isEdit = computed(() => !!groupId.value)

const buttonLabel = computed(() => {
  return isEdit.value ? 'Modifier le groupe' : 'Créer le groupe'
})

// Titre de la dialog géré par le composant parent

// Methods
/**
 * Trie les utilisateurs par nom
 * @param users Liste des utilisateurs à trier
 * @returns Liste des utilisateurs sous forme de SchoolPeriodUser sans regroupement
 */
function sortUsersByName(users: SchoolPeriodUser[]): SchoolPeriodUser[] {
  return [...users].sort((a, b) => {
    return `${a.lastname} ${a.firstname}`.localeCompare(`${b.lastname} ${b.firstname}`);
  })
}

/**
 * Valide le formulaire avant soumission
 */
function validateForm(): boolean {
  const errors: ValidationErrors = {}

  if (!groupName.value.trim()) {
    errors.name = 'Le nom du groupe est requis'
  }

  if (!selectedTeacher.value) {
    errors.teacher = 'L\'enseignant référent est requis'
  }

  if (!groupName.value.trim() || !selectedTeacher.value) {
    errors.global = 'Veuillez remplir tous les champs obligatoires'
  }

  validationErrors.value = errors
  return Object.keys(errors).length === 0
}

/**
 * Charge les données initiales (groupe et utilisateurs)
 */
async function loadData() {
  console.log('loadData', {
    schoolId: schoolId.value,
    schoolPeriodId: schoolPeriodId.value,
    classroomId: schoolClassroomId.value,
    groupId: groupId.value
  })

  if (!schoolId.value || !schoolPeriodId.value || !schoolClassroomId.value) return

  try {
    localLoading.value = true
    localError.value = ''

    // Charger tous les utilisateurs de la période
    schoolClassroom.value = await schoolClassroomStore.fetchSchoolClassroom(
      schoolId.value,
      schoolPeriodId.value,
      schoolClassroomId.value
    )

    // Extraire les enseignants pour le dropdown
    teacherOptions.value = schoolClassroom.value.users.filter(user =>
      user.role.includes(UserRole.Values.TEACHER)
    );

    // Vérifier si on a des données du groupe passées directement
    const groupDataFromDialog = dialogRef?.value.data.groupData;

    // Si on a des données de groupe directement dans le dialog, les utiliser
    if (groupDataFromDialog) {
      // Pas besoin de charger les données du groupe depuis l'API
      if (isEdit.value) {
        groupName.value = groupDataFromDialog.name;
        selectedTeacher.value = teacherOptions.value.find(
          teacher => teacher.schoolPeriodUserId === groupDataFromDialog.teacherId
        ) || null;
      }

      // Répartir les utilisateurs avec les données fournies
      setupUserLists();
    }
    // Sinon, si c'est une modification, charger le groupe depuis l'API
    else if (isEdit.value && groupId.value) {
      await schoolMonitoringGroupStore.fetchSchoolMonitoringGroup(
        schoolId.value,
        schoolPeriodId.value,
        schoolClassroomId.value,
        groupId.value
      )

      if (selectedGroup.value) {
        groupName.value = selectedGroup.value.name;
        // Trouver l'enseignant référent
        selectedTeacher.value = teacherOptions.value.find(
          teacher => teacher.schoolPeriodUserId === selectedGroup.value?.teacherId
        ) || null;
      }

      // Répartir les utilisateurs
      setupUserLists();
    }
    // Pour une création sans données fournies
    else {
      setupUserLists();
    }
  } catch (err) {
    localError.value = err instanceof Error
      ? err.message
      : 'Erreur lors du chargement des données'

    toast.add({
      severity: 'error',
      summary: 'Erreur',
      detail: localError.value,
      life: 5000
    })
  } finally {
    localLoading.value = false
  }
}

/**
 * Configure les listes d'utilisateurs disponibles et attribués
 */
function setupUserLists() {
  // Si c'est un nouveau groupe, tous les élèves sont disponibles
  // Si c'est une modification, il faut séparer les élèves déjà attribués

  // Filtrer pour ne garder que les élèves
  const allStudents = schoolClassroom.value?.users.filter(user =>
    user.role.includes(UserRole.Values.STUDENT)
  );
  // Récupérer les élèves sans groupe
  const allStudentsWithoutGroup = schoolClassroom.value?.studentsWithoutGroup
  const allStudentWithoutGroupIds = new Set(allStudentsWithoutGroup?.map(student => student.schoolPeriodUserId))

  console.log('allStudents', allStudents)

  if (!allStudents) {
    sourceUsers.value = [];
    targetUsers.value = [];
    return;
  }

  if (isEdit.value && dialogRef?.value.data.groupData) {
    // Pour une modification, séparer les élèves déjà dans le groupe
    const groupStudentIds = new Set(dialogRef?.value.data.groupData.studentIds || []);

    console.log('groupStudentIds', groupStudentIds)

    const assignedStudents = allStudents.filter(student =>
      groupStudentIds.has(student.schoolPeriodUserId)
    );
    console.log('assignedStudents', assignedStudents)

    sourceUsers.value = allStudents.filter(student =>
      !groupStudentIds.has(student.schoolPeriodUserId) &&
      allStudentWithoutGroupIds.has(student.schoolPeriodUserId)
    );
    targetUsers.value = assignedStudents;
  } else {
    // Pour une création, tous les élèves sont disponibles
    sourceUsers.value = allStudents;
    targetUsers.value = [];
  }

  // Mettre à jour le modèle
  modelValue.value = [sourceUsers.value, targetUsers.value];
}

/**
 * Sauvegarde les modifications du groupe
 */
async function saveChanges() {
  formSubmitted.value = true;

  if (!validateForm()) {
    return;
  }

  try {
    localLoading.value = true;
    localError.value = '';

    // Créer la liste des IDs des élèves assignés (en excluant les groupHeaders)
    const studentIds = targetUsers.value
      .map(item => item.schoolPeriodUserId);

    // Créer ou mettre à jour l'objet groupe
    const groupData = new SchoolMonitoringGroupInput({
      teacherId: selectedTeacher.value?.schoolPeriodUserId,
      studentIds: studentIds
    });

    if (isEdit.value && groupId.value) {
      // Mettre à jour un groupe existant
      await schoolMonitoringGroupStore.updateSchoolMonitoringGroup(
        schoolId.value,
        schoolPeriodId.value,
        schoolClassroomId.value,
        groupId.value,
        groupData
      );
    } else {
      // Créer un nouveau groupe
      await schoolMonitoringGroupStore.createSchoolMonitoringGroup(
        schoolId.value,
        schoolPeriodId.value,
        schoolClassroomId.value
      );
    }

    // Fermer la boîte de dialogue
    dialogRef?.value.close();
  } catch (err) {
    localError.value = err instanceof Error
      ? err.message
      : 'Erreur lors de la sauvegarde du groupe';

    toast.add({
      severity: 'error',
      summary: 'Erreur',
      detail: localError.value,
      life: 5000
    });
  } finally {
    localLoading.value = false;
  }
}

/**
 * Gère le déplacement des utilisateurs entre les listes
 */
function handleMove(event: any) {
  // Récupérer les utilisateurs déplacés
  const { items, from } = event;

  if (items.length === 0) return;

  // Regrouper à nouveau les utilisateurs après le déplacement
  if (from === 'source') {
    // Les utilisateurs passent de source à target
    const remainingSourceUsers = sourceUsers.value
      .filter(item => !items.some((moved: SchoolPeriodUser) => moved.schoolPeriodUserId === item.schoolPeriodUserId))
      .map(item => item);

    const updatedTargetUsers = [...targetUsers.value.map(item => item), ...items.map((item: SchoolPeriodUser) => item)];

    sourceUsers.value = sortUsersByName(remainingSourceUsers);
    targetUsers.value = sortUsersByName(updatedTargetUsers);
  } else {
    // Les utilisateurs passent de target à source
    const remainingTargetUsers = targetUsers.value
      .filter(item => !items.some((moved: SchoolPeriodUser) => moved.schoolPeriodUserId === item.schoolPeriodUserId))
      .map(item => item);

    const updatedSourceUsers = [...sourceUsers.value.map(item => item), ...items.map((item: SchoolPeriodUser) => item)];

    targetUsers.value = sortUsersByName(remainingTargetUsers);
    sourceUsers.value = sortUsersByName(updatedSourceUsers);
  }

  // Mettre à jour le modelValue
  modelValue.value = [sourceUsers.value, targetUsers.value];
}

/**
 * Annule les modifications et ferme le composant
 */
function cancel() {
  dialogRef?.value.close()
}

/**
 * Filtre personnalisé pour la liste source
 */
function sourceFilterFunction(items: SchoolPeriodUser[]) {
  if (!sourceFilter.value.trim()) return items;

  const searchText = sourceFilter.value.toLowerCase();
  return items.filter(item =>
    item.firstname.toLowerCase().includes(searchText) ||
    item.lastname.toLowerCase().includes(searchText)
  );
}

/**
 * Filtre personnalisé pour la liste cible
 */
function targetFilterFunction(items: SchoolPeriodUser[]) {
  if (!targetFilter.value.trim()) return items;

  const searchText = targetFilter.value.toLowerCase();
  return items.filter(item =>
    item.firstname.toLowerCase().includes(searchText) ||
    item.lastname.toLowerCase().includes(searchText)
  );
}

// Lifecycle hooks
onMounted(async () => {
  await loadData()
})
</script>

<template>
  <div v-if="isLoading" class="flex justify-content-center">
    <p-progress-spinner
      :style="{ width: '50px', height: '50px' }"
      strokeWidth="8"
      fill="#ffffff"
      animationDuration=".5s"
    />
  </div>

  <div v-else>
    <!-- Message d'erreur -->
    <p-message
      v-if="hasError && errorMessage"
      severity="error"
      class="w-full mb-3"
    >
      {{ errorMessage }}
    </p-message>

    <div class="flex flex-column gap-4">

      <!-- Enseignant référent -->
      <div class="field">
        <label for="teacher-select" class="font-medium mb-2 block">Enseignant référent*</label>
        <p-dropdown
          id="teacher-select"
          v-model="selectedTeacher"
          :options="teacherOptions"
          optionLabel="lastname"
          placeholder="Sélectionner un enseignant"
          class="w-full"
          :class="{ 'p-invalid': formSubmitted && validationErrors.teacher }"
        >
          <template #value="slotProps">
            <div v-if="slotProps.value">
              {{ slotProps.value.firstname }} {{ slotProps.value.lastname }}
            </div>
            <span v-else>Sélectionner un enseignant</span>
          </template>
          <template #option="slotProps">
            {{ slotProps.option.firstname }} {{ slotProps.option.lastname }}
          </template>
        </p-dropdown>
        <small class="p-error" v-if="formSubmitted && validationErrors.teacher">
          {{ validationErrors.teacher }}
        </small>
      </div>

      <!-- Composition du groupe -->
      <div class="field">
        <label class="font-medium mb-2 block">Composition du groupe</label>
        <div class="card">
          <p-pick-list
            v-model="modelValue"
            source-filter-placeholder="Rechercher des élèves"
            target-filter-placeholder="Rechercher des élèves"
            show-source-controls
            show-target-controls
            :filter="true"
            :source-filter-value="sourceFilter"
            :target-filter-value="targetFilter"
            @filter-source-change="sourceFilter = $event.value"
            @filter-target-change="targetFilter = $event.value"
            :source-filter-method="sourceFilterFunction"
            :target-filter-method="targetFilterFunction"
            breakpoint="1400px"
            class="w-full"
            @move-to-target="handleMove({ items: $event.items, from: 'source', to: 'target' })"
            @move-all-to-target="handleMove({ items: $event.items, from: 'source', to: 'target' })"
            @move-to-source="handleMove({ items: $event.items, from: 'target', to: 'source' })"
            @move-all-to-source="handleMove({ items: $event.items, from: 'target', to: 'source' })"
          >
            <template #sourceheader>
              <div class="flex justify-content-center">
                <h5 class="my-0">Élèves disponibles</h5>
              </div>
            </template>

            <template #targetheader>
              <div class="flex justify-content-center">
                <h5 class="my-0">Élèves du groupe</h5>
              </div>
            </template>

            <template #item="slotProps: { item: SchoolPeriodUser }">
              <!-- Élément utilisateur -->
              <div class="flex flex-column w-full p-2">
                <p class="mb-0">{{ slotProps.item.firstname }} {{ slotProps.item.lastname }}</p>
              </div>
            </template>
          </p-pick-list>
        </div>
      </div>

      <!-- Boutons d'action -->
      <div class="flex justify-content-end gap-2 mt-4">
        <p-button
          label="Annuler"
          icon="pi pi-times"
          outlined
          @click="cancel"
          :disabled="isLoading"
        />
        <p-button
          :label="buttonLabel"
          icon="pi pi-check"
          @click="saveChanges"
          :loading="isLoading"
          :disabled="isLoading"
        />
      </div>
    </div>
  </div>
</template>