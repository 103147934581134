export namespace LearningQuizType {
  export enum Values {
    PRE = 'PRE',
    POST = 'POST',
    DIAG = 'DIAG'
  }

  export function label(quiz: Values) {
    switch (quiz) {
      case Values.PRE:
        return 'Pré-quiz'
      case Values.POST:
        return 'Post-quiz'
      case Values.DIAG:
        return 'Diagnostic'
    }
  }
}