import type { ISchoolClassroomRepository } from '@/core/domain/school/classroom/ISchoolClassroomRepository'


export class UpdateSchoolClassroomUsersUseCase {
  private schoolClassroomRepository: ISchoolClassroomRepository;

  constructor(schoolClassroomRepository: ISchoolClassroomRepository) {
    this.schoolClassroomRepository = schoolClassroomRepository;
  }

  async execute(
    schoolId: number,
    periodId: number,
    schoolClassroomId: number,
    users: number[]
  ) {
    return await this.schoolClassroomRepository.updateSchoolClassroomUsers(
      schoolId,
      periodId,
      schoolClassroomId,
      users
    );
  }
}