import type {
  SchoolClassroomLoginHistoryUserEntity
} from '@/core/infrastructure/schemas/school/user/SchoolClassroomLoginHistoryUserSchema'
import { SchoolClassroomLoginHistoryUser } from '@/core/domain/school/user/SchoolClassroomLoginHistoryUser'
import type {
  SchoolClassroomLoginHistoryEntity
} from '@/core/infrastructure/schemas/school/classroom/SchoolClassroomLoginHistorySchema'


/**
 * Représente l'historique de connexion d'une salle de classe regroupé par périodes d'inactivité
 */
export class SchoolClassroomLoginHistory {
  readonly lessThan7Days: SchoolClassroomLoginHistoryUser[]
  readonly between7And14Days: SchoolClassroomLoginHistoryUser[]
  readonly moreThan14Days: SchoolClassroomLoginHistoryUser[]

  constructor(schoolClassroomLoginHistory: SchoolClassroomLoginHistoryEntity) {
    this.lessThan7Days = schoolClassroomLoginHistory.lessThan7Days.map(
      user => new SchoolClassroomLoginHistoryUser(user)
    )
    this.between7And14Days = schoolClassroomLoginHistory.between7And14Days.map(
      user => new SchoolClassroomLoginHistoryUser(user)
    )
    this.moreThan14Days = schoolClassroomLoginHistory.moreThan14Days.map(
      user => new SchoolClassroomLoginHistoryUser(user)
    )
  }
}