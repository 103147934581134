import { z } from 'zod';
import { SchoolClassroomSchema } from '@/core/infrastructure/schemas/school/classroom/SchoolClassroomSchema'
import { SchoolMonitoringGroupSchema } from '@/core/infrastructure/schemas/school/group/SchoolMonitoringGroupSchema'
import { SchoolUserSchema } from '@/core/infrastructure/schemas/school/user/SchoolUserSchema'
import { SchoolYearSchema } from '@/core/infrastructure/schemas/school/year/SchoolYearSchema'
import { SchoolPeriodUserSchema } from '@/core/infrastructure/schemas/school/user/SchoolPeriodUserSchema'


// Schema for School
export const SchoolSchema = z.object({
  id: z.number(),
  codeUai: z.string(),
  name: z.string(),
  city: z.string(),
  zipCode: z.string(),
  codeInternal: z.string(),
  address: z.string().nullable(),
  department: z.string().nullable(),
  schoolDistrict: z.string().nullable(),
  contactEmail: z.string().nullable(),
});
export const SchoolsListSchema = z.array(SchoolSchema);

// Schema for SchoolMe
export const SchoolMeSchema = z.object({
  id: z.number(),
  codeUai: z.string(),
  name: z.string(),
  city: z.string(),
  zipCode: z.string(),
  codeInternal: z.string(),
  address: z.string().nullable(),
  department: z.string().nullable(),
  schoolDistrict: z.string().nullable(),
  contactEmail: z.string().nullable(),
  schoolPeriodId: z.number(),
  schoolYear: SchoolYearSchema,
  classrooms: z.array(SchoolClassroomSchema),
  users: z.array(SchoolPeriodUserSchema),
});
export const SchoolMeListSchema = z.array(SchoolMeSchema);

// Schema for SchoolInput
export const SchoolInputSchema = z.object({
  codeUai: z.string(),
  name: z.string(),
  city: z.string(),
  zipCode: z.string(),
  codeInternal: z.string().optional(),
  address: z.string().nullable(),
  department: z.string().nullable(),
  schoolDistrict: z.string().nullable(),
  email: z.string().nullable(),
})
export const SchoolInputListSchema = z.array(SchoolInputSchema);

export type SchoolEntity = z.infer<typeof SchoolSchema>;
export type SchoolMeEntity = z.infer<typeof SchoolMeSchema>;
export type SchoolInputEntity = z.infer<typeof SchoolInputSchema>;
export type SchoolsListEntity = z.infer<typeof SchoolsListSchema>;
export type SchoolMeListEntity = z.infer<typeof SchoolMeListSchema>;
