
import { z } from 'zod'
import { LearningQuizQuestionType } from '@/core/domain/resources/enums/LearningQuizQuestion'
import { FeedbacksSchema } from '@/core/infrastructure/schemas/learning/quiz/LearningQuizQuestionFeedbackSchema'
import {
  MatchingAnswerSchema,
  MultipleChoiceAnswerSchema,
  SequencingAnswerSchema,
  SingleChoiceAnswerSchema,
  TrueFalseAnswerSchema
} from '@/core/infrastructure/schemas/learning/quiz/LearningQuizQuestionAnswerSchema'

const BaseQuestionSchema = z.object({
  id: z.number(),
  label: z.string(),
  order: z.number(),
  type: z.nativeEnum(LearningQuizQuestionType.Values),
  feedbacks: FeedbacksSchema
})


// Définition spécifique pour chaque type de question avec leurs réponses fortement typées
export const SingleChoiceQuestionSchema = BaseQuestionSchema.extend({
  type: z.literal(LearningQuizQuestionType.Values.SINGLE_CHOICE),
  answers: z.array(SingleChoiceAnswerSchema)
})

export const MultipleChoiceQuestionSchema = BaseQuestionSchema.extend({
  type: z.literal(LearningQuizQuestionType.Values.MULTIPLE_CHOICE),
  answers: z.array(MultipleChoiceAnswerSchema)
})

export const TrueFalseQuestionSchema = BaseQuestionSchema.extend({
  type: z.literal(LearningQuizQuestionType.Values.TRUE_FALSE),
  answers: z.array(TrueFalseAnswerSchema)
})

export const MatchingQuestionSchema = BaseQuestionSchema.extend({
  type: z.literal(LearningQuizQuestionType.Values.MATCHING),
  answers: z.array(MatchingAnswerSchema)
})

export const SequencingQuestionSchema = BaseQuestionSchema.extend({
  type: z.literal(LearningQuizQuestionType.Values.SEQUENCING),
  answers: z.array(SequencingAnswerSchema)
})

// Question schema avec union discriminée
export const QuestionSchema = z.discriminatedUnion('type', [
  SingleChoiceQuestionSchema,
  MultipleChoiceQuestionSchema,
  TrueFalseQuestionSchema,
  MatchingQuestionSchema,
  SequencingQuestionSchema
])

export const QuestionListSchema = z.array(QuestionSchema)

export type QuestionEntity = z.infer<typeof QuestionSchema>
export type SingleChoiceQuestionEntity = z.infer<typeof SingleChoiceQuestionSchema>
export type MultipleChoiceQuestionEntity = z.infer<typeof MultipleChoiceQuestionSchema>
export type TrueFalseQuestionEntity = z.infer<typeof TrueFalseQuestionSchema>
export type MatchingQuestionEntity = z.infer<typeof MatchingQuestionSchema>
export type SequencingQuestionEntity = z.infer<typeof SequencingQuestionSchema>
export type QuestionListEntity = z.infer<typeof QuestionListSchema>