import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { type SchoolPeriodUser, SchoolPeriodUserInput } from '@/core/domain/school/user/SchoolPeriodUser'
import DependencyContainer from '@/presentation/configuration/DependencyContainer'
import FetchAllSchoolPeriodUsersUseCase from '@/core/useCases/school/user/FetchAllSchoolPeriodUsersUseCase'
import PrepareSchoolPeriodUserEditUseCase from '@/core/useCases/school/user/PrepareSchoolPeriodUserEditUseCase'
import { CreateSchoolPeriodUserUseCase } from '@/core/useCases/school/user/CreateSchoolPeriodUserUseCase'
import { UpdateSchoolPeriodUserUseCase } from '@/core/useCases/school/user/UpdateSchoolPeriodUserUseCase'
import { UserRole } from '@/core/domain/resources/enums/UserRole'
import { createStoreBase } from '@/stores/resources/storeBase'

export const useSchoolPeriodUserStore = defineStore('SchoolPeriodUser', () => {
  // BASE COMMUNE
  const baseStore = createStoreBase(['fetchUsers', 'prepareEdit', 'createUser', 'updateUser']);

  // ÉTAT
  const schoolPeriodUsers = ref<SchoolPeriodUser[]>([]);
  const currentEditUser = ref<SchoolPeriodUserInput | null>(null);
  const currentSchoolId = ref<number | null>(null);
  const currentSchoolPeriodId = ref<number | null>(null);

  // ACTIONS
  /**
   * Récupère tous les utilisateurs d'une période scolaire
   * @param schoolId Identifiant de l'école
   * @param schoolPeriodId Identifiant de la période scolaire
   * @param forceRefresh Force le rechargement des données même si déjà présentes
   */
  async function fetchAllSchoolPeriodUsersForSchool(
    schoolId: number,
    schoolPeriodId: number,
    forceRefresh = false
  ): Promise<SchoolPeriodUser[]> {
    // Éviter le rechargement inutile si les données sont déjà là pour la même période
    if (
      !forceRefresh &&
      schoolPeriodUsers.value.length > 0 &&
      currentSchoolId.value === schoolId &&
      currentSchoolPeriodId.value === schoolPeriodId &&
      baseStore.lastUpdated.value &&
      Date.now() - baseStore.lastUpdated.value.getTime() < 5 * 60 * 1000 // 5 minutes
    ) {
      return schoolPeriodUsers.value;
    }

    try {
      baseStore.loading.value.fetchUsers = true;
      baseStore.resetError();

      currentSchoolId.value = schoolId;
      currentSchoolPeriodId.value = schoolPeriodId;

      const response = await DependencyContainer.resolve(FetchAllSchoolPeriodUsersUseCase)
        .execute(schoolId, schoolPeriodId);

      schoolPeriodUsers.value = response;
      baseStore.updateLastUpdated();

      return response;
    } catch (err) {
      baseStore.setError(err);
      return Promise.reject(err);
    } finally {
      baseStore.loading.value.fetchUsers = false;
    }
  }

  /**
   * Prépare les données pour l'édition d'un utilisateur
   * @param schoolPeriodId Identifiant de la période scolaire
   * @param schoolPeriodUser Utilisateur à modifier
   */
  async function prepareSchoolPeriodUserEdit(
    schoolPeriodId: number,
    schoolPeriodUser: SchoolPeriodUser
  ): Promise<SchoolPeriodUserInput> {
    try {
      baseStore.loading.value.prepareEdit = true;
      baseStore.resetError();

      const response = await DependencyContainer.resolve(PrepareSchoolPeriodUserEditUseCase)
        .execute(schoolPeriodId, schoolPeriodUser);

      currentEditUser.value = response;
      return response;
    } catch (err) {
      baseStore.setError(err);
      return Promise.reject(err);
    } finally {
      baseStore.loading.value.prepareEdit = false;
    }
  }

  /**
   * Crée un nouvel utilisateur dans une période scolaire
   * @param schoolId Identifiant de l'école
   * @param schoolPeriodId Identifiant de la période scolaire
   * @param schoolPeriodUserInput Données de l'utilisateur
   */
  async function createSchoolPeriodUser(
    schoolId: number,
    schoolPeriodId: number,
    schoolPeriodUserInput: SchoolPeriodUserInput
  ): Promise<SchoolPeriodUser> {
    try {
      baseStore.loading.value.createUser = true;
      baseStore.resetError();

      const response = await DependencyContainer.resolve(CreateSchoolPeriodUserUseCase)
        .execute(schoolId, schoolPeriodId, schoolPeriodUserInput);

      // Ajouter l'utilisateur créé à la liste
      schoolPeriodUsers.value.push(response);

      // Réinitialiser l'utilisateur en cours d'édition
      currentEditUser.value = null;

      baseStore.showSuccessToast(
        'Utilisateur créé',
        'L\'utilisateur a bien été ajouté à la période scolaire.'
      );

      baseStore.updateLastUpdated();
      return response;
    } catch (err) {
      baseStore.setError(err);
      return Promise.reject(err);
    } finally {
      baseStore.loading.value.createUser = false;
    }
  }

  /**
   * Met à jour un utilisateur existant dans une période scolaire
   * @param schoolId Identifiant de l'école
   * @param schoolPeriodId Identifiant de la période scolaire
   * @param schoolPeriodUserInput Données mises à jour de l'utilisateur
   */
  async function updateSchoolPeriodUser(
    schoolId: number,
    schoolPeriodId: number,
    schoolPeriodUserInput: SchoolPeriodUserInput
  ): Promise<SchoolPeriodUser> {
    try {
      baseStore.loading.value.updateUser = true;
      baseStore.resetError();

      const response = await DependencyContainer.resolve(UpdateSchoolPeriodUserUseCase)
        .execute(schoolId, schoolPeriodId, schoolPeriodUserInput);

      // Mettre à jour l'utilisateur dans la liste (et non l'ajouter)
      const index = schoolPeriodUsers.value.findIndex(
        user => user.userIdentifier === schoolPeriodUserInput.userIdentifier
      );

      if (index !== -1) {
        schoolPeriodUsers.value[index] = response;
      } else {
        // Si l'utilisateur n'existe pas encore dans la liste, l'ajouter
        schoolPeriodUsers.value.push(response);
      }

      // Réinitialiser l'utilisateur en cours d'édition
      currentEditUser.value = null;

      baseStore.showSuccessToast(
        'Utilisateur mis à jour',
        'L\'utilisateur a bien été mis à jour dans la période scolaire.'
      );

      baseStore.updateLastUpdated();
      return response;
    } catch (err) {
      baseStore.setError(err);
      return Promise.reject(err);
    } finally {
      baseStore.loading.value.updateUser = false;
    }
  }

  /**
   * Réinitialise l'utilisateur en cours d'édition
   */
  function resetCurrentEditUser(): void {
    currentEditUser.value = null;
  }

  /**
   * Réinitialise le store à son état initial
   */
  function reset(): void {
    schoolPeriodUsers.value = [];
    currentEditUser.value = null;
    currentSchoolId.value = null;
    currentSchoolPeriodId.value = null;
    baseStore.resetBase();
  }

  // GETTERS
  /**
   * Filtre les utilisateurs par rôle
   */
  const getUsersByRole = computed(() => {
    return (role: UserRole.Values): SchoolPeriodUser[] => {
      return schoolPeriodUsers.value.filter(user =>
        user.role.includes(role)
      );
    };
  });

  /**
   * Récupère un utilisateur par son identifiant
   */
  const getUserByIdentifier = computed(() => {
    return (userIdentifier: string): SchoolPeriodUser | undefined => {
      return schoolPeriodUsers.value.find(user =>
        user.userIdentifier === userIdentifier
      );
    };
  });

  /**
   * Liste des enseignants
   */
  const teachers = computed(() =>
    schoolPeriodUsers.value.filter(user =>
      user.role.includes(UserRole.Values.TEACHER)
    )
  );

  /**
   * Liste des étudiants
   */
  const students = computed(() =>
    schoolPeriodUsers.value.filter(user =>
      user.role.includes(UserRole.Values.STUDENT)
    )
  );

  /**
   * Indique si les données doivent être rechargées
   */
  const needsFreshData = computed(() => {
    if (!baseStore.lastUpdated.value) return true;

    // Considérer les données comme périmées après 5 minutes
    const fiveMinutesInMs = 5 * 60 * 1000;
    return Date.now() - baseStore.lastUpdated.value.getTime() > fiveMinutesInMs;
  });

  /**
   * Nombre total d'utilisateurs
   */
  const totalUsers = computed(() => schoolPeriodUsers.value.length);

  return {
    // État
    schoolPeriodUsers,
    currentEditUser,
    currentSchoolId,
    currentSchoolPeriodId,
    schoolPeriodUserStoreLoading: baseStore.loading,
    schoolPeriodUserStoreError: baseStore.error,
    schoolPeriodUserStoreLastUpdated: baseStore.lastUpdated,

    // Actions
    fetchAllSchoolPeriodUsersForSchool,
    prepareSchoolPeriodUserEdit,
    createSchoolPeriodUser,
    updateSchoolPeriodUser,
    resetCurrentEditUser,
    reset,

    // Getters
    getUsersByRole,
    getUserByIdentifier,
    teachers,
    students,
    needsFreshData,
    totalUsers,
    schoolPeriodUserStoreIsLoading: baseStore.isLoading
  };
});