import { defineStore } from 'pinia'
import { computed, ref, type Ref } from 'vue'
import type { SchoolClassroomLoginHistory } from '@/core/domain/school/classroom/SchoolClassroomLoginHistory'
import DependencyContainer from '@/presentation/configuration/DependencyContainer'
import FetchSchoolClassroomLoginHistoryUseCase
  from '@/core/useCases/dashboard/FetchSchoolClassroomLoginHistoryUseCase'
import { createStoreBase } from '@/stores/resources/storeBase'
import type SchoolClassroom from '@/core/domain/school/classroom/SchoolClassroom'
import { useDashboardStore } from '@/stores/dashboard/dashboard'

export const useDashboardClassroomStore = defineStore('dashboardClassroom', () => {
  // BASE COMMUNE
  const baseStore = createStoreBase(['fetchLoginHistory', 'setClassroom']);

  // ÉTAT
  const dashboardClassroomLoginHistory: Ref<SchoolClassroomLoginHistory | null> = ref(null);
  const dashboardSelectedClassroom: Ref<SchoolClassroom | null> = ref(null);
  const contextSchoolId: Ref<number | null> = ref(null);
  const contextPeriodId: Ref<number | null> = ref(null);

  // ACTIONS
  /**
   * Récupère l'historique de connexion pour la classe sélectionnée
   * @param schoolId ID de l'école
   * @param periodId ID de la période
   * @param forceRefresh Force le rechargement des données même si elles sont récentes
   */
  async function fetchDashboardClassroomLoginHistory(
    forceRefresh = false
  ): Promise<SchoolClassroomLoginHistory | null> {
    // Éviter le rechargement inutile si les données sont déjà là et récentes
    if (
      !forceRefresh &&
      dashboardClassroomLoginHistory.value &&
      baseStore.lastUpdated.value &&
      Date.now() - baseStore.lastUpdated.value.getTime() < 5 * 60 * 1000 // 5 minutes
    ) {
      return dashboardClassroomLoginHistory.value;
    }

    try {
      baseStore.loading.value.fetchLoginHistory = true;
      baseStore.resetError();

      const schoolClassroomId = dashboardSelectedClassroom.value?.schoolClassroomId;
      const schoolId = contextSchoolId.value;
      const periodId = contextPeriodId.value;

      if (!schoolClassroomId || !schoolId || !periodId) {
        return Promise.reject(new Error('Informations manquantes pour récupérer l\'historique de connexion'));
      }

      const result = await DependencyContainer.resolve(FetchSchoolClassroomLoginHistoryUseCase)
        .execute(schoolClassroomId, schoolId, periodId);

      if (result) {
        dashboardClassroomLoginHistory.value = result;
        baseStore.lastUpdated.value = new Date();
      }

      return result;
    } catch (err) {
      baseStore.setError(err);
      console.error('Erreur lors de la récupération de l\'historique de connexion:', err);
      return null;
    } finally {
      baseStore.loading.value.fetchLoginHistory = false;
    }
  }

  /**
   * Définit la classe sélectionnée dans le dashboard et met à jour le contexte
   * @param classroom Classe à sélectionner
   * @param schoolId ID de l'école associée
   * @param periodId ID de la période associée
   */
  function setDashboardSelectedClassroom(
    classroom: SchoolClassroom,
    schoolId: number,
    periodId: number
  ): void {
    // Vérifier si la classe est déjà sélectionnée
    if (dashboardSelectedClassroom.value?.schoolClassroomId === classroom.schoolClassroomId) {
      return;
    }

    try {
      baseStore.loading.value.setClassroom = true;
      baseStore.resetError();

      dashboardSelectedClassroom.value = classroom;
      contextSchoolId.value = schoolId;
      contextPeriodId.value = periodId;
      baseStore.lastUpdated.value = new Date();
    } catch (err) {
      baseStore.setError(err);
      console.error('Erreur lors de la définition de la classe sélectionnée:', err);
    } finally {
      baseStore.loading.value.setClassroom = false;
    }
  }

  /**
   * Définit la classe sélectionnée dans le dashboard par son ID
   * @param schoolId ID de l'école
   * @param classroomId ID de la classe
   * @returns Promise qui se résout quand l'opération est terminée
   */
  async function setDashboardSelectedClassroomById(
    schoolId: number,
    classroomId: number
  ): Promise<void> {
    // Si la classe est déjà sélectionnée, ne rien faire
    if (
      dashboardSelectedClassroom.value?.schoolClassroomId === classroomId &&
      contextSchoolId.value === schoolId
    ) {
      console.log('Classe déjà sélectionnée, aucune mise à jour nécessaire');
      return Promise.resolve();
    }

    try {
      baseStore.loading.value.setClassroom = true;
      baseStore.resetError();

      // Obtenir le dashboard store
      const dashboardStore = useDashboardStore();

      // Définir l'école sélectionnée par ID dans le dashboard store
      await dashboardStore.setDashboardSelectedSchoolById(schoolId);

      // Récupérer l'école sélectionnée
      const selectedSchool = dashboardStore.dashboardSelectedSchool;
      if (!selectedSchool) {
        throw new Error(`École avec l'ID ${schoolId} non trouvée`);
      }

      // Trouver la classe par ID
      const classroom = selectedSchool.classrooms.find(
        c => c.schoolClassroomId === classroomId
      );

      if (classroom) {
        dashboardSelectedClassroom.value = classroom;
        contextSchoolId.value = schoolId;
        contextPeriodId.value = selectedSchool.schoolPeriodId;
        baseStore.lastUpdated.value = new Date();
        return Promise.resolve();
      } else {
        throw new Error(`Classe avec l'ID ${classroomId} non trouvée`);
      }
    } catch (err) {
      baseStore.setError(err);
      console.error('Erreur lors de la définition de la classe sélectionnée:', err);
      return Promise.reject(err);
    } finally {
      baseStore.loading.value.setClassroom = false;
    }
  }

  /**
   * Réinitialise l'état du store
   */
  function reset(): void {
    dashboardClassroomLoginHistory.value = null;
    dashboardSelectedClassroom.value = null;
    contextSchoolId.value = null;
    contextPeriodId.value = null;
    baseStore.lastUpdated.value = null;
    baseStore.resetError();

    baseStore.loading.value = {
      fetchLoginHistory: false,
      setClassroom: false
    };
  }

  // GETTERS

  return {
    // État
    dashboardSelectedClassroom,
    dashboardClassroomLoginHistory,
    dashboardClassroomStoreLoading: baseStore.loading,
    dashboardClassroomStoreError: baseStore.error,
    baseStoreLastUpdated: baseStore.lastUpdated,
    contextSchoolId,
    contextPeriodId,

    // Actions
    setDashboardSelectedClassroom,
    setDashboardSelectedClassroomById,
    fetchDashboardClassroomLoginHistory,
    reset,

    // Getters
    dashboardClassroomStoreIsLoading: baseStore.isLoading,
    needsFreshData: baseStore.shouldRefreshData,
  };
});