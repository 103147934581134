<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import type School from '@/core/domain/school/core/School'
import { useSchoolPeriodStore } from '@/stores/school/schoolPeriod'
import { useToast } from 'primevue/usetoast'
import BaseBlock from '@/components/base/BaseBlock.vue'
import { default as PButton } from 'primevue/button'
import { default as PDropdown } from 'primevue/dropdown'
import { default as PTable } from 'primevue/datatable'
import { default as PColumn } from 'primevue/column'
import { default as PMessage } from 'primevue/message'
import { default as PProgressSpinner } from 'primevue/progressspinner'
import { default as PTag } from 'primevue/tag'
import { useSchoolClassroomStore } from '@/stores/school/schoolClassroom'
import SchoolClassroomGroups from '@/components/schools/SchoolClassroomGroups.vue'
import {
  SchoolClassroomInput,
  SchoolClassroomListItem
} from '@/core/domain/school/classroom/SchoolClassroom'
import { ClassroomDegree } from '@/core/domain/resources/enums/ClassroomDegree'
import SchoolClassroomsUpdate from '@/components/schools/SchoolClassroomsUpdate.vue'
import { useDialog } from 'primevue/usedialog'
import SchoolClassroomUpdateUsers from '@/components/schools/SchoolClassroomUpdateUsers.vue'
import { useConfirm } from 'primevue/useconfirm'

// Types
interface ClassroomFilter {
  level: string | null
}

// Props
const props = defineProps<{
  school: School
}>()

// Composables
const toast = useToast()
const confirm = useConfirm()
const schoolPeriodStore = useSchoolPeriodStore()
const schoolClassroomStore = useSchoolClassroomStore()
const dialog = useDialog()

// Store refs
const { selectedSchoolPeriod } = storeToRefs(schoolPeriodStore)
const {
  schoolClassrooms,
  schoolClassroomStoreLoading,
  schoolClassroomStoreError,
  needsFreshData,
  expandedClassroomIds,
  expandingClassrooms,
} = storeToRefs(schoolClassroomStore)

// Local state
const localLoading = ref(false)
const localError = ref('')
const filters = ref<ClassroomFilter>({
  level: null
})
const localClassroom = ref<SchoolClassroomInput | null>(null)
const isEditing = ref({
  classroomCreate: false,
  classroomUpdate: false,
  groupCreate: false,
  groupUpdate: false
})

// Computed
const isLoading = computed(() =>
  localLoading.value || schoolClassroomStoreLoading.value.fetchClassrooms
)

const hasError = computed(() =>
  localError.value !== '' ||
  (schoolClassroomStoreError.value && schoolClassroomStoreError.value.hasError)
)

const errorMessage = computed(() =>
  localError.value ||
  (schoolClassroomStoreError.value?.hasError ? schoolClassroomStoreError.value.message : '')
)

const filteredClassrooms = computed(() => {
  if (!filters.value.level) return schoolClassrooms.value

  return schoolClassrooms.value.filter(classroom =>
    classroom.degree === filters.value.level
  )
})

const hasClassrooms = computed(() => schoolClassrooms.value.length > 0)

const expandedRows = computed(() => {
  // Convertir le tableau d'IDs en objet pour PrimeVue
  return expandedClassroomIds.value.reduce((obj, id) => {
    obj[id] = true
    return obj
  }, {} as Record<number, boolean>)
})

// Methods
/**
 * Charge les classes de l'école pour la période sélectionnée
 * @param forceRefresh Force le rechargement des données même si déjà présentes
 */
async function loadClassrooms(forceRefresh = false) {
  if (!selectedSchoolPeriod.value || !props.school?.id) return

  // Éviter les rechargements inutiles si les données sont déjà chargées
  if (
    !forceRefresh &&
    schoolClassrooms.value.length > 0 &&
    !needsFreshData.value
  ) {
    return
  }

  try {
    localLoading.value = true
    localError.value = ''

    await schoolClassroomStore.fetchSchoolClassrooms(
      props.school.id,
      selectedSchoolPeriod.value.schoolPeriodId,
      forceRefresh
    )
  } catch (err) {
    localError.value = err instanceof Error
      ? err.message
      : 'Erreur lors du chargement des classes'

    toast.add({
      severity: 'error',
      summary: 'Erreur',
      detail: localError.value,
      life: 5000
    })

    console.error('Erreur lors du chargement des classes:', err)
  } finally {
    localLoading.value = false
  }
}

/**
 * Gère l'événement d'expansion d'une ligne
 * @param event Événement d'expansion contenant les données de la ligne
 */
async function handleRowExpand(event: { data: SchoolClassroomListItem }) {
  const classroomId = event.data.schoolClassroomId
  schoolClassroomStore.setClassroomExpanded(classroomId, true)

  if (!props.school?.id || !selectedSchoolPeriod.value) return

  try {
    await schoolClassroomStore.loadClassroomDetails(
      props.school.id,
      selectedSchoolPeriod.value.schoolPeriodId,
      classroomId
    )
  } catch (err) {
    // Gérer l'erreur...
  }
}

/**
 * Gère l'événement de fermeture d'une ligne
 * @param event Événement de fermeture contenant les données de la ligne
 */
function handleRowCollapse(event: { data: SchoolClassroomListItem }) {
  const classroomId = event.data.schoolClassroomId
  schoolClassroomStore.handleRowCollapse(classroomId)
}

/**
 * Ouvre le formulaire de création d'une classe
 */
function openCreateClassroomForm() {
  isEditing.value.classroomCreate = true
}

/**
 * Gère la fermeture du formulaire de création
 */
function handleUpdateClassroomClose() {
  Object.assign(isEditing.value, {
    classroomCreate: false,
    classroomUpdate: false,
    groupCreate: false,
    groupUpdate: false
  })
  localClassroom.value = null
}

/**
 * Gère la création d'une nouvelle classe
 */
function handleClassroomSaved() {
  isEditing.value.classroomCreate = false

  // Recharger les classes pour afficher la nouvelle
  loadClassrooms(true)
}

/**
 * Ouvre la page de détail d'une classe
 * @param classroom Identifiant de la classe
 */
function openClassroomDetails(classroom: SchoolClassroomListItem) {
  dialog.open(SchoolClassroomUpdateUsers, {
    props: {
      header: `Gérer les membres de la classe ${classroom.name}`,
      dismissableMask: true,
      draggable: false,
      modal: true,
      style: {
        width: '75vw',
      },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
    },
    data: {
      schoolClassroomId: classroom.schoolClassroomId,
      schoolId: props.school.id,
      schoolPeriodId: selectedSchoolPeriod?.value?.schoolPeriodId || 0,
    }
  })
}

/**
 * Ouvre le formulaire de modification d'une classe
 * @param classroomId Identifiant de la classe
 */
async function openEditClassroomForm(classroomId: number) {
  if (!selectedSchoolPeriod.value || !props.school?.id) return

  const classroomDetails = await schoolClassroomStore.fetchSchoolClassroom(
    props.school.id,
    selectedSchoolPeriod.value.schoolPeriodId,
    classroomId
  )

  localClassroom.value = classroomDetails.toInput()

  isEditing.value.classroomUpdate = true
}

/**
 * Confirme la suppression d'une classe
 * @param classroomId Identifiant de la classe
 */
function confirmDeleteClassroom(classroomId: number) {
  confirm.require({
    message: 'Êtes-vous sûr de vouloir supprimer cette classe ?',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: 'Oui',
    rejectLabel: 'Non',
    accept: async () => {
      try {
        await schoolClassroomStore.deleteSchoolClassroom(
          props.school.id,
          selectedSchoolPeriod.value?.schoolPeriodId || 0,
          classroomId
        )
        await loadClassrooms(true)
      } catch (err) {
        const message = err instanceof Error
          ? err.message
          : 'Erreur lors de la suppression de la classe'

        toast.add({
          severity: 'error',
          summary: 'Erreur',
          detail: message,
          life: 5000
        })
      }
    }
  })
}

// Watchers
watch(selectedSchoolPeriod, async (newValue) => {
  if (newValue) {
    await loadClassrooms(true)
  } else {
    // Réinitialiser les données lorsqu'aucune période n'est sélectionnée
    schoolClassroomStore.reset()
  }
})

// Lifecycle hooks
onMounted(async () => {
  if (selectedSchoolPeriod.value) {
    await loadClassrooms()
  }
})

</script>

<template>
  <school-classrooms-update
    v-if="(isEditing.classroomCreate || isEditing.classroomUpdate) && selectedSchoolPeriod"
    :school-period-id='selectedSchoolPeriod.schoolPeriodId'
    :school-id='school.id'
    @close="handleUpdateClassroomClose"
    @saved="handleClassroomSaved"
    :school-classroom="isEditing.classroomUpdate ? localClassroom : undefined"
    :is-edit="isEditing.classroomUpdate"
  />

  <base-block
    v-else
    :accordion="false" title="CLASSES">
    <!-- Message quand aucune période n'est sélectionnée -->
    <p-message
      v-if="!selectedSchoolPeriod"
      severity="info"
      class="w-full mb-3"
    >
      Veuillez sélectionner une période scolaire pour gérer les classes.
    </p-message>

    <!-- Contenu principal -->
    <div v-else class="flex align-items-start flex-column gap-3 w-full">
      <!-- Message d'erreur -->
      <p-message
        v-if="hasError"
        severity="error"
        :text="errorMessage"
        class="w-full mb-2"
      />

      <!-- Actions et filtres -->
      <div class="flex justify-content-between align-items-center w-full">
        <p-button
          label="Créer une classe"
          icon="pi pi-plus"
          severity="primary"
          size="small"
          @click="openCreateClassroomForm"
          :disabled="isLoading"
        />

        <div v-if="isLoading" class="flex align-items-center">
          <p-progress-spinner style="width: 1.5rem; height: 1.5rem" />
          <span class="ml-2">Chargement...</span>
        </div>

        <div v-else-if="hasClassrooms" class="flex align-items-center gap-2">
          <p-button
            icon="pi pi-refresh"
            text
            size="small"
            @click="() => loadClassrooms(true)"
            tooltip="Actualiser"
          />
        </div>
      </div>

      <!-- Filtres -->
      <div class="flex gap-2 w-full mb-3" v-if="hasClassrooms">
        <div class="flex flex-column gap-2">
          <label for="level-filter" class="font-semibold">Niveau</label>
          <p-dropdown
            id="level-filter"
            v-model="filters.level"
            :options="ClassroomDegree.options"
            optionLabel="label"
            optionValue="value"
            placeholder="Tous les niveaux"
          />
        </div>
      </div>

      <!-- État vide -->
      <div
        v-if="!isLoading && !hasClassrooms"
        class="flex flex-column align-items-center justify-content-center p-5 w-full"
      >
        <i class="pi pi-book text-4xl text-500 mb-3"></i>
        <h3 class="mt-0 mb-2">Aucune classe</h3>
        <p class="text-center text-700">
          Cette période scolaire ne contient pas encore de classes.
          Commencez par ajouter une classe avec le bouton ci-dessus.
        </p>
      </div>

      <!-- Tableau des classes -->
      <div v-else-if="hasClassrooms" class="w-full">
        <p-table
          :value="filteredClassrooms"
          :expandedRows="expandedRows"
          @row-expand="handleRowExpand"
          @row-collapse="handleRowCollapse"
          dataKey="schoolClassroomId"
          :paginator="true"
          :rows="10"
          :rowsPerPageOptions="[5, 10, 15, 20]"
          :loading="isLoading"
          currentPageReportTemplate="{first} à {last} sur {totalRecords} classes"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
          responsiveLayout="scroll"
          emptyMessage="Aucune classe ne correspond à vos critères de recherche."
          class="w-full"
        >
          <template #empty>
            <div class="text-center p-4">
              Aucune classe ne correspond à vos critères de recherche.
            </div>
          </template>

          <template #loading>
            <div class="text-center p-4">
              <p-progress-spinner />
              <div>Chargement des classes...</div>
            </div>
          </template>

          <!-- Template d'expansion pour les groupes -->
          <template #expansion="slotProps: {data: SchoolClassroomListItem}">
            <school-classroom-groups
              :classroom="expandingClassrooms.get(slotProps.data.schoolClassroomId)?.details || null"
              :loading="expandingClassrooms.get(slotProps.data.schoolClassroomId)?.isLoading || false"
              :school-id="props.school.id"
              :school-period-id="selectedSchoolPeriod?.schoolPeriodId || 0"
            />
          </template>

          <!-- Colonne d'expansion -->
          <p-column expander style="width: 3rem" />

          <!-- Colonne classe -->
          <p-column field="name" header="Classe">
            <template #body="{ data }">
              {{ data.name }}
            </template>
          </p-column>

          <!-- Colonne niveau -->
          <p-column field="degree" header="Niveau">
            <template #body="{ data }: { data: SchoolClassroomListItem }">
              {{ ClassroomDegree.label(data.degree) }}
            </template>
          </p-column>

          <!-- Colonne nombre d'élèves -->
          <p-column field="userCount" header="Élèves" style="width: 8rem">
            <template #body="{ data }: { data: SchoolClassroomListItem }">
              <div class="text-center">
                {{ data.countStudents || 0 }}
              </div>
            </template>
          </p-column>

          <!-- Colonne nombre d'enseignants -->
          <p-column header="Enseignants" style="width: 8rem">
            <template #body="{ data }: { data: SchoolClassroomListItem }">
              <div class="text-center">
                {{ data.countTeachers || 0 }}
              </div>
            </template>
          </p-column>

          <!-- Colonne nombre de groupes -->
          <p-column header="Groupes" style="width: 15rem">
            <template #body="{ data }: { data: SchoolClassroomListItem }">
              <div class="flex gap-1">
                {{ data.countGroups || 0 }}
                <p-tag v-if="data.countStudentsWithoutGroup > 0" severity="warning" class="ml-2">
                  {{ data.countStudentsWithoutGroup }} élèves sans groupe
                </p-tag>
              </div>
            </template>
          </p-column>

          <!-- Colonne actions -->
          <p-column header="Actions" style="width: 10rem">
            <template #body="{ data }: { data: SchoolClassroomListItem }">
              <div class="flex justify-content-center gap-2">
                <p-button
                  icon="pi pi-users"
                  text
                  size="small"
                  @click="openClassroomDetails(data)"
                  tooltip="Gérer les membres"
                />
                <p-button
                  icon="pi pi-pencil"
                  text
                  size="small"
                  @click="openEditClassroomForm(data.schoolClassroomId)"
                  tooltip="Modifier"
                />
                <p-button
                  icon="pi pi-trash"
                  text
                  size="small"
                  severity="danger"
                  :disabled='!data.canDelete'
                  @click="confirmDeleteClassroom(data.schoolClassroomId)"
                  tooltip="Supprimer"
                />
              </div>
            </template>
          </p-column>
        </p-table>
      </div>
    </div>
  </base-block>
</template>