import type { SchoolMonitoringGroupInput } from '@/core/domain/school/group/SchoolMonitoringGroup'
import type { ISchoolMonitoringGroupRepository } from '@/core/domain/school/group/ISchoolMonitoringGroupRepository'

export class UpdateSchoolMonitoringGroupUsersUseCase {
  private schoolMonitoringGroupRepository: ISchoolMonitoringGroupRepository;

  constructor(schoolMonitoringGroupRepository: ISchoolMonitoringGroupRepository) {
    this.schoolMonitoringGroupRepository = schoolMonitoringGroupRepository;
  }

  async execute(
    schoolId: number,
    schoolPeriodId: number,
    schoolClassroomId: number,
    schoolMonitoringGroupId: number,
    groupInput: SchoolMonitoringGroupInput
  ) {
    return await this.schoolMonitoringGroupRepository.updateSchoolMonitoringGroupUsers(
      schoolId,
      schoolPeriodId,
      schoolClassroomId,
      schoolMonitoringGroupId,
      groupInput
    );
  }
}