import type { SchoolYearEntity } from '@/core/infrastructure/schemas/school/year/SchoolYearSchema'
import { z } from 'zod'

/**
 * User domain class
 */
export default class SchoolYear {
  readonly schoolYearId: number
  readonly startDate: string
  readonly endDate: string
  readonly name: string
  readonly isCurrent: boolean

  constructor(year: SchoolYearEntity) {
    this.schoolYearId = year.schoolYearId
    this.startDate = year.startDate
    this.endDate = year.endDate
    this.name = year.name
    this.isCurrent = year.isCurrent
  }
}