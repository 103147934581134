<script setup lang='ts'>
import { default as PDropdown } from 'primevue/dropdown'
import { default as PInputGroup } from 'primevue/inputgroup'
import { default as PInputGroupAddon } from 'primevue/inputgroupaddon'
import { default as PInputText } from 'primevue/inputtext'
import { UserRole } from '@/core/domain/resources/enums/UserRole'
import { type UserFilters, type SortOption } from '@/composables/school/users/useSchoolPeriodUsersFilters'

// Props
const props = defineProps<{
  modelValue: UserFilters
  selectedOrder: number
  orderOptions: SortOption[]
}>()

// Émits
const emit = defineEmits<{
  'update:modelValue': [value: UserFilters]
  'update:selectedOrder': [value: number]
}>()

// Méthodes
function updateFilters(key: keyof UserFilters, value: any) {
  emit('update:modelValue', {
    ...props.modelValue,
    [key]: value
  })
}

function updateOrder(value: number) {
  emit('update:selectedOrder', value)
}
</script>

<template>
  <div class='flex gap-3 w-full flex-wrap'>
    <div class='flex flex-column gap-2 flex-grow-1'>
      <!-- Filtre par texte -->
      <label for="user-search" class='font-semibold'>Recherche</label>
      <p-input-group>
        <p-input-group-addon>
          <i class="pi pi-search" />
        </p-input-group-addon>
        <p-input-text
          id="user-search"
          placeholder='Rechercher un utilisateur'
          :value="modelValue.search"
          @input="e => updateFilters('search', e.target.value)"
        />
      </p-input-group>
    </div>

    <div class='flex flex-column gap-2 flex-grow-1'>
      <!-- Filtre par rôle -->
      <label for="role-filter" class='font-semibold'>Rôle</label>
      <p-dropdown
        id="role-filter"
        :model-value="modelValue.role"
        @update:model-value="value => updateFilters('role', value)"
        :options='UserRole.schoolOptions'
        optionLabel='label'
        optionValue='value'
        placeholder='Tous les rôles'
        class='h-full'
      />
    </div>

    <div class='flex flex-column gap-2 min-w-10rem ml-auto'>
      <!-- Trier par ordre alphabétique -->
      <label for="sort-order" class='font-semibold'>Trier par</label>
      <p-dropdown
        id="sort-order"
        :model-value="selectedOrder"
        @update:model-value="updateOrder"
        :options='orderOptions'
        optionLabel='label'
        optionValue='value'
      />
    </div>
  </div>
</template>
