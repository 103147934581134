<script setup lang="ts">
import { useNavigation } from '@/composables/navigation/useNavigation'

// Types
interface MenuItemProps {
  item: {
    name: string;
    link: string;
    icon?: string;
    group?: string;
    children?: Array<{
      name: string;
      classrooms: Array<{
        name: string;
        link: string;
        params: Record<string, number>;
      }>
    }>;
  };
  isTeacher: boolean;
  isFullyOpened: boolean;
  isSelected: boolean;
}

// Props
const props = defineProps<MenuItemProps>()

// Navigation
const { processClick, isClassroomActive } = useNavigation()
</script>

<template>
  <div
    class=" flex align-items-start relative"
    :class="[
      { 'has-children': item.children && item.children.length > 0 }
    ]"
  >
    <div class='w-full'>
      <div class='flex'>
        <i
          class="left-0 pi flex justify-content-center align-items-center p-1"
          :class="[item.icon, isSelected ? 'selected' : '',]"
          @click="processClick(item.link)"
          v-tooltip.right="!isFullyOpened ? item.name : undefined"
        />
        <h4
          v-if='isFullyOpened'
          class='brand-heading small flex align-items-center uppercase ml-2'
          :class='[{"cursor-pointer" : !isTeacher}, isSelected ? "selected" : "",]'
          @click="processClick(item.link)"
        >
          {{ item.name }}
        </h4>
      </div>

      <!-- Sous-menu déplié -->
      <template v-if='isFullyOpened'>
        <div
          v-if='item.children && item.children.length > 0'
          class='flex flex-column gap-2 text ml-4 mt-2'
        >
          <template v-for='child in item.children' :key='`child_${child.name}`'>
            <template v-if='!isTeacher'>
              <h5
                class='pl-2 brand-heading small uppercase white-space-nowrap text-overflow-ellipsis w-full overflow-hidden pl-1'
                :title='child.name'
              >
                {{child.name}}
              </h5>
            </template>
            <template v-for='classroom in child.classrooms' :key='`classroom_${classroom.name}`'>
              <h6
                @click='processClick(classroom.link, classroom.params)'
                class='pl-2 brand-heading small-regular uppercase cursor-pointer pl-1'
                :class='{ "selected": isClassroomActive(classroom.params) }'
              >
                {{ classroom.name }}
              </h6>
            </template>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.selected {
  color: var(--blue-400);
  font-weight: 700
}
</style>