import { defineStore, storeToRefs } from 'pinia'
import { computed, type Ref, ref } from 'vue'
import type { SchoolMe } from '@/core/domain/school/core/School'
import { useSchoolStore } from '@/stores/school/school'
import { createStoreBase } from '@/stores/resources/storeBase'

export const useDashboardStore = defineStore('dashboard', () => {
  // BASE COMMUNE
  const baseStore = createStoreBase(['setSchool']);

  // STORES
  const schoolStore = useSchoolStore();
  const { schoolList, schoolStoreIsLoading } = storeToRefs(schoolStore);

  // ÉTAT
  const dashboardSelectedSchool: Ref<SchoolMe | null> = ref(null);

  // ACTIONS
  /**
   * Définit l'école sélectionnée dans le dashboard
   * @param school École à sélectionner
   */
  function setDashboardSelectedSchool(school: SchoolMe): void {
    try {
      // Si l'école est déjà sélectionnée, ne rien faire
      if (dashboardSelectedSchool.value?.id === school.id) {
        return;
      }

      baseStore.loading.value.setSchool = true;
      baseStore.resetError();

      dashboardSelectedSchool.value = school;
      baseStore.lastUpdated.value = new Date();
    } catch (err) {
      baseStore.setError(err);
      console.error('Erreur lors de la définition de l\'école sélectionnée:', err);
    } finally {
      baseStore.loading.value.setSchool = false;
    }
  }

  /**
   * Définit l'école sélectionnée dans le dashboard par son ID
   * @param schoolId ID de l'école
   * @returns Promise qui se résout quand l'opération est terminée
   */
  async function setDashboardSelectedSchoolById(schoolId: number): Promise<void> {
    try {
      // Si l'école est déjà sélectionnée, ne rien faire
      if (dashboardSelectedSchool.value?.id === schoolId) {
        return Promise.resolve();
      }

      baseStore.loading.value.setSchool = true;
      baseStore.resetError();

      const school = schoolList.value.find(school => school.id === schoolId);
      if (school) {
        dashboardSelectedSchool.value = school;
        baseStore.lastUpdated.value = new Date();
        return Promise.resolve();
      } else {
        const error = new Error(`École avec l'ID ${schoolId} non trouvée`);
        baseStore.setError(error);
        return Promise.reject(error);
      }
    } catch (err) {
      baseStore.setError(err);
      console.error('Erreur lors de la définition de l\'école par ID:', err);
      return Promise.reject(err);
    } finally {
      baseStore.loading.value.setSchool = false;
    }
  }

  /**
   * Réinitialise l'état du store
   */
  function reset(): void {
    dashboardSelectedSchool.value = null;
    baseStore.lastUpdated.value = null;
    baseStore.resetError();

    baseStore.loading.value = {
      setSchool: false
    };
  }

  // GETTERS
  /**
   * Liste des classes de l'école sélectionnée
   */
  const selectedSchoolClassrooms = computed(() =>
    dashboardSelectedSchool.value?.classrooms || []
  );

  /**
   * Fonction pour récupérer une classe par son ID
   */
  const getClassroomById = computed(() => {
    return (classroomId: number) => {
      if (!dashboardSelectedSchool.value) return null;

      return dashboardSelectedSchool.value.classrooms.find(
        classroom => classroom.schoolClassroomId === classroomId
      ) || null;
    };
  });

  return {
    // État
    dashboardSchoolList: schoolList,
    dashboardSelectedSchool,
    dashboardStoreLoading: baseStore.loading,
    dashboardStoreError: baseStore.error,
    dashboardStoreLastUpdated: baseStore.lastUpdated,

    // Actions
    setDashboardSelectedSchool,
    setDashboardSelectedSchoolById,
    reset,

    // Getters
    dashboardStoreIsLoading: baseStore.isLoading,
    selectedSchoolClassrooms,
    getClassroomById
  };
});