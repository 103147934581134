import type {
  SchoolClassroomLoginHistoryUserEntity
} from '@/core/infrastructure/schemas/school/user/SchoolClassroomLoginHistoryUserSchema'

export class SchoolClassroomLoginHistoryUser {
  readonly identifier: string
  readonly firstname: string
  readonly lastname: string
  readonly lastLogin: Date

  constructor(schoolClassroomLoginHistoryUser: SchoolClassroomLoginHistoryUserEntity) {
    this.identifier = schoolClassroomLoginHistoryUser.identifier
    this.firstname = schoolClassroomLoginHistoryUser.firstname
    this.lastname = schoolClassroomLoginHistoryUser.lastname
    this.lastLogin = new Date(schoolClassroomLoginHistoryUser.lastLogin)
  }

  /**
   * Retourne le nom complet de l'utilisateur (prénom + nom)
   */
  get fullName(): string {
    return `${this.firstname} ${this.lastname}`
  }

  /**
   * Calcule le nombre de jours depuis la dernière connexion
   */
  getDaysSinceLastLogin(): number {
    const currentDate = new Date()
    const timeDifference = currentDate.getTime() - this.lastLogin.getTime()
    return Math.floor(timeDifference / (1000 * 60 * 60 * 24))
  }
}