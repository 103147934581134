import { z } from 'zod'
import {
  DashboardScoreDiagnosticTaskSchema
} from '@/core/infrastructure/schemas/dashboard/DashboardScoreDiagnosticTaskSchema'

export const DashboardScoreDiagnosticSchema = z.object({
  identifier: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  periodId: z.number(),
  schoolYearName: z.string(),
  classroomId: z.number(),
  groupId: z.number().nullable(),
  groupName: z.string().nullable(),
  teacherId: z.number().nullable(),
  teacherFirstname: z.string().nullable(),
  teacherLastname: z.string().nullable(),
  tasks: z.array(DashboardScoreDiagnosticTaskSchema)
})

export const DashboardScoreDiagnosticListSchema = z.array(DashboardScoreDiagnosticSchema)

export type DashboardScoreDiagnosticEntity = z.infer<typeof DashboardScoreDiagnosticSchema>