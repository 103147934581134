<script setup lang="ts">
// PROPS
import { useToast } from 'primevue/usetoast'
import { useNabooContentStore } from '@/stores/content/nabooContent'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'
import DashboardBaseBlock from '@/views/dashboard/blocks/DashboardBaseBlock.vue'
import NabooContentModuleCard from '@/views/nabooContent/blocks/NabooContentModuleCard.vue'
import { NabooContentLearningGrainDto } from '@/assets/DTO/nabooContent/nabooContent.dto'
import { default as PTag } from 'primevue/tag'

const props = defineProps<{
  courseId: number
  moduleId: number
}>()

// SERVICES
const toast = useToast()

// STORES
const { fetchNabooContentModule } = useNabooContentStore()
const { currentLearningModule } = storeToRefs(useNabooContentStore())

onMounted(async () => {
  try {
    await fetchNabooContentModule(props.courseId, props.moduleId)
  } catch (error) {
    if (error instanceof Error) {
      toast.add({ severity: 'error', summary: 'Erreur', detail: error.message })
    } else {
      toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: 'Une erreur est survenue, merci de contacter le support'
      })
    }
  }
})
</script>

<template>
  <div class="col-12 grid grid-nogutter p-5 py-3 border-bottom-1 border-300">
    <div class="col justify-content-start flex flex-column gap-2">
      <router-link
        :to="{ name: 'naboo-content-list' }"
        class="flex align-items-center gap-2 w-full text-color-secondary no-underline"
        >{{ '< Revenir à la liste des parcours' }}
      </router-link>
      <h3 class="uppercase text-left">Contenu de module : {{ currentLearningModule?.title }}</h3>
    </div>
  </div>

  <div
    class="col-12 p-5 bg-bo-brand-tertiary flex-grow-1 overflow-y-auto gap-5 flex flex-column h-full"
  >
    <dashboard-base-block
      v-for="grain in currentLearningModule?.learningGrains"
      :key="`grain_${grain.id}`"
      :accordion="true"
      :data="grain"
    >
      <template #title="{ data }">
        <div
          class="flex flex-row flex-initial flex-wrap justify-content-start align-content-center align-items-center gap-2"
        >
          <h3 class="flex-auto brand-color uppercase vertical-align-middle">
            {{ (data as NabooContentLearningGrainDto).title }}
          </h3>
          <p-tag severity="warning" class="flex-1 text-xs min-w-max align-items-center">
            Tâche {{ (data as NabooContentLearningGrainDto).order.toString()?.padStart(3, '0') }}
          </p-tag>
        </div>
      </template>
      <div class="flex flex-wrap gap-3 justify-content-start">
        <naboo-content-module-card
          v-for="child in grain.children"
          :key="`card_${child.id}_${child.order}`"
          :children="child"
          :grain="grain as NabooContentLearningGrainDto"
        />
      </div>
    </dashboard-base-block>
  </div>
</template>
