import type {
  SchoolEntity,
  SchoolInputEntity,
  SchoolMeEntity
} from '@/core/infrastructure/schemas/school/core/SchoolSchema'
import SchoolClassroom from '@/core/domain/school/classroom/SchoolClassroom'
import slugify from 'slugify'
import { SchoolPeriodUser } from '@/core/domain/school/user/SchoolPeriodUser'
import SchoolYear from '@/core/domain/school/year/SchoolYear'

/**
 * School domain class
 */

export default class School {
   readonly id: number;
   readonly name: string;
   readonly codeUai: string;
   readonly address: string | null;
   readonly city: string;
   readonly zipCode: string;
   readonly codeInternal: string;
   readonly department: string | null;
   readonly schoolDistrict: string | null;
   readonly contactEmail: string | null;
   readonly slug: string;

  constructor(school: SchoolEntity) {
    this.id = school.id;
    this.name = school.name;
    this.codeUai = school.codeUai;
    this.address = school.address;
    this.city = school.city;
    this.zipCode = school.zipCode;
    this.codeInternal = school.codeInternal;
    this.department = school.department;
    this.schoolDistrict = school.schoolDistrict;
    this.contactEmail = school.contactEmail;
    this.slug = slugify(school.name, { lower: true, strict: true, locale: 'fr', remove: /[*+~.()'"!:@]/g });
  }

  get school() {
    return {
      id: this.id,
      name: this.name,
      codeUai: this.codeUai,
      address: this.address,
      city: this.city,
      zipCode: this.zipCode,
      codeInternal: this.codeInternal,
      department: this.department,
      schoolDistrict: this.schoolDistrict,
      contactEmail: this.contactEmail,
    };
  }

  toInput () {
    return new SchoolInput({
      name: this.name,
      codeUai: this.codeUai,
      address: this.address,
      city: this.city,
      zipCode: this.zipCode,
      department: this.department,
      schoolDistrict: this.schoolDistrict,
      email: this.contactEmail,
    });
  }
}

export class SchoolMe extends School {
  readonly classrooms: SchoolClassroom[];
  readonly users: SchoolPeriodUser[];
  readonly schoolYear: SchoolYear;
  readonly schoolPeriodId: number;

  constructor(school: SchoolMeEntity) {
    super(school);
    this.classrooms = school.classrooms.map(classroom => new SchoolClassroom(classroom));
    this.users = school.users.map(user => new SchoolPeriodUser(user));
    this.schoolYear = new SchoolYear(school.schoolYear);
    this.schoolPeriodId = school.schoolPeriodId;
  }

  get school() {
    return {
      id: this.id,
      name: this.name,
      codeUai: this.codeUai,
      address: this.address,
      city: this.city,
      zipCode: this.zipCode,
      codeInternal: this.codeInternal,
      department: this.department,
      schoolDistrict: this.schoolDistrict,
      contactEmail: this.contactEmail,
    };
  }
}

export class SchoolInput {
  name: string;
  codeUai: string;
  address: string | null;
  city: string;
  zipCode: string;
  codeInternal: string;
  department: string | null;
  schoolDistrict: string | null;
  contactEmail: string | null;

  constructor(schoolInput?: SchoolInputEntity) {
    this.name = schoolInput?.name || '';
    this.codeUai = schoolInput?.codeUai || '';
    this.address = schoolInput?.address || null;
    this.city = schoolInput?.city || '';
    this.zipCode = schoolInput?.zipCode || '';
    this.codeInternal = schoolInput?.codeInternal || '';
    this.department = schoolInput?.department || null;
    this.schoolDistrict = schoolInput?.schoolDistrict || null;
    this.contactEmail = schoolInput?.email|| null;
  }

  get schoolInput () {
    return{
      name: this.name,
      codeUai: this.codeUai,
      address: this.address,
      city: this.city,
      zipCode: this.zipCode,
      codeInternal: this.codeInternal,
      department: this.department,
      schoolDistrict: this.schoolDistrict,
      contactEmail: this.contactEmail,
    }
  }
}