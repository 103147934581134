import type { DashboardScoreModuleEntity } from '@/core/infrastructure/schemas/dashboard/DashboardScoreModuleSchema'
import { DashboardScoreModuleGrain } from '@/core/domain/dashboard/DashboardScoreModuleGrain'


export class DashboardScoreModule {
  readonly identifier: string
  readonly firstname: string
  readonly lastname: string
  readonly teacherFirstname: string
  readonly teacherLastname: string
  readonly taskId: number
  readonly taskTitle: string
  readonly taskOrder: number
  readonly diagScorePercentRounded: number
  readonly diagEvalRangeName: string
  readonly moduleScorePercentRounded: number
  readonly moduleEvalRangeName: string
  readonly diagnosticModuleVariation: number
  readonly learningGrains: DashboardScoreModuleGrain[]


  constructor(DashboardModule: DashboardScoreModuleEntity) {
    this.identifier = DashboardModule.identifier
    this.firstname = DashboardModule.firstname
    this.lastname = DashboardModule.lastname
    this.teacherFirstname = DashboardModule.teacherFirstname
    this.teacherLastname = DashboardModule.teacherLastname
    this.taskId = DashboardModule.taskId
    this.taskTitle = DashboardModule.taskTitle
    this.taskOrder = DashboardModule.taskOrder
    this.diagScorePercentRounded = DashboardModule.diagScorePercentRounded
    this.diagEvalRangeName = DashboardModule.diagEvalRangeName
    this.moduleScorePercentRounded = DashboardModule.moduleScorePercentRounded
    this.moduleEvalRangeName = DashboardModule.moduleEvalRangeName
    this.diagnosticModuleVariation = DashboardModule.diagnosticModuleVariation
    this.learningGrains = DashboardModule.learningGrains.map((grain) => {
      return new DashboardScoreModuleGrain(grain)
    })
  }
}