import { LearningQuizType } from '@/core/domain/resources/enums/LearningQuiz'
import { LearningStatus } from '@/core/domain/resources/enums/LearningStatus'
import {
  MatchingQuestion,
  MultipleChoiceQuestion,
  type Question, SequencingQuestion,
  SingleChoiceQuestion, TrueFalseQuestion
} from '@/core/domain/learning/quiz/LearningQuizQuestion'
import {
  isMatchingQuestion,
  isMultipleChoiceQuestion, isSequencingQuestion,
  isSingleChoiceQuestion, isTrueFalseQuestion,
  type LearningQuizEntity
} from '@/core/infrastructure/schemas/learning/quiz/LearningQuizSchema'

export class LearningQuiz {
  id: number
  type: LearningQuizType.Values
  ready: boolean
  status?: LearningStatus.Values
  questions: Question[]

  constructor(quiz: LearningQuizEntity) {
    this.id = quiz.id
    this.type = quiz.type
    this.ready = quiz.ready
    this.status = quiz.status

    // Convertir les questions en leurs types spécifiques
    this.questions = quiz.questions.map(question => {
      if (isSingleChoiceQuestion(question)) {
        return new SingleChoiceQuestion(question)
      } else if (isMultipleChoiceQuestion(question)) {
        return new MultipleChoiceQuestion(question)
      } else if (isTrueFalseQuestion(question)) {
        return new TrueFalseQuestion(question)
      } else if (isMatchingQuestion(question)) {
        return new MatchingQuestion(question)
      } else if (isSequencingQuestion(question)) {
        return new SequencingQuestion(question)
      } else {
        throw new Error(`Type de question non pris en charge`)
      }
    })
  }

  // Retourne le libellé du type de quiz
  getTypeLabel(): string {
    return LearningQuizType.label(this.type)
  }

  // Vérifie si c'est un pré-quiz
  isPreQuiz(): boolean {
    return this.type === LearningQuizType.Values.PRE
  }

  // Vérifie si c'est un post-quiz
  isPostQuiz(): boolean {
    return this.type === LearningQuizType.Values.POST
  }

  // Vérifie si c'est un quiz de diagnostic
  isDiagQuiz(): boolean {
    return this.type === LearningQuizType.Values.DIAG
  }

  // Vérifie si le quiz est valide (toutes les questions sont valides)
  isValid(): boolean {
    return this.questions.length > 0 && this.questions.every(question => question.isValid())
  }

  // Retourne le score maximum possible
  getMaxScore(): number {
    return this.questions.length
  }
}