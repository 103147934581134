import { LearningQuizQuestionType } from '@/core/domain/resources/enums/LearningQuizQuestion'
import {
  ChoiceAnswer, MatchingAnswer,
  type QuestionAnswer, SequencingAnswer,
  TrueFalseAnswer
} from '@/core/domain/learning/quiz/LearningQuizQuestionAnswer'
import type { QuestionEntity } from '@/core/infrastructure/schemas/learning/quiz/LearningQuizQuestionSchema'
import {
  isMatchingAnswer,
  isMultipleChoiceAnswer, isSequencingAnswer,
  isSingleChoiceAnswer, isTrueFalseAnswer
} from '@/core/infrastructure/schemas/learning/quiz/LearningQuizSchema'
import type { ContentItemEntity } from '@/core/infrastructure/schemas/learning/quiz/LearningQuizQuestionAnswerSchema'

export abstract class Question {
  id: number
  label: string
  order: number
  type: LearningQuizQuestionType.Values
  feedbacks: { correct: string; wrong: string }
  answers: QuestionAnswer[]

  protected constructor(question: QuestionEntity) {
    this.id = question.id
    this.label = question.label
    this.order = question.order
    this.type = question.type
    this.feedbacks = question.feedbacks
    this.answers = []  // Sera rempli par les classes dérivées
  }

  isValid(): boolean {
    return !!this.label &&
      !!this.type &&
      this.answers.length > 0 &&
      this.answers.every(answer => answer.isValid())
  }
}

// Classes pour les différents types de questions
export class SingleChoiceQuestion extends Question {
  answers: ChoiceAnswer[]

  constructor(question: Extract<QuestionEntity, { type: LearningQuizQuestionType.Values.SINGLE_CHOICE}>) {
    super(question)
    this.answers = question.answers
      .filter(isSingleChoiceAnswer)
      .map(answer => new ChoiceAnswer(answer))
  }

  getCorrectAnswer(): ChoiceAnswer | undefined {
    return this.answers.find(answer => answer.isCorrect)
  }
}

export class MultipleChoiceQuestion extends Question {
  answers: ChoiceAnswer[]

  constructor(question: Extract<QuestionEntity, { type: LearningQuizQuestionType.Values.MULTIPLE_CHOICE }>) {
    super(question)
    this.answers = question.answers
      .filter(isMultipleChoiceAnswer)
      .map(answer => new ChoiceAnswer(answer))
  }

  getCorrectAnswers(): ChoiceAnswer[] {
    return this.answers.filter(answer => answer.isCorrect)
  }
}

export class TrueFalseQuestion extends Question {
  answers: TrueFalseAnswer[]

  constructor(question: Extract<QuestionEntity, { type: LearningQuizQuestionType.Values.TRUE_FALSE }>) {
    super(question)
    this.answers = question.answers
      .filter(isTrueFalseAnswer)
      .map(answer => new TrueFalseAnswer(answer))
  }

  getCorrectAnswer(): TrueFalseAnswer | undefined {
    return this.answers.find(answer => answer.isCorrect)
  }
}

export class MatchingQuestion extends Question {
  answers: MatchingAnswer[]

  constructor(question: Extract<QuestionEntity, { type: LearningQuizQuestionType.Values.MATCHING }>) {
    super(question)
    this.answers = question.answers
      .filter(isMatchingAnswer)
      .map(answer => new MatchingAnswer(answer))
  }

  getPairs(): { left: ContentItemEntity; right: ContentItemEntity }[] {
    return this.answers.map(answer => ({
      left: answer.left,
      right: answer.right
    }))
  }
}

export class SequencingQuestion extends Question {
  answers: SequencingAnswer[]

  constructor(question: Extract<QuestionEntity, { type: LearningQuizQuestionType.Values.SEQUENCING }>) {
    super(question)
    this.answers = question.answers
      .filter(isSequencingAnswer)
      .map(answer => new SequencingAnswer(answer))
  }

  getOrderedItems(): ContentItemEntity[] {
    return [...this.answers]
      .sort((a, b) => a.order - b.order)
      .map(answer => answer.data)
  }
}
