export namespace LearningStatus {
  export enum Values {
    DRAFT = 'DRAFT',
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED',
    DELETED = 'DELETED'
  }

  export function label(status: Values) {
    switch (status) {
      case Values.DRAFT:
        return 'Brouillon'
      case Values.ACTIVE:
        return 'Actif'
      case Values.ARCHIVED:
        return 'Archivé'
      case Values.DELETED:
        return 'Supprimé'
    }
  }
}