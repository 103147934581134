import z from 'zod';
import { UserRole } from '@/core/domain/resources/enums/UserRole'
import { UserGender } from '@/core/domain/resources/enums/UserGender'

export const SchoolPeriodUserSchema = z.object({
  schoolPeriodUserId: z.number(),
  userIdentifier: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  role: z.array(z.nativeEnum(UserRole.Values)),
  hasSignedAgreement: z.boolean().optional(),
})

export const SchoolPeriodUserListSchema = z.array(SchoolPeriodUserSchema);

export const SchoolPeriodUserInputSchema = z.object({
  userIdentifier: z.string().uuid().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  birthdate: z.string(),
  roles: z.array(z.nativeEnum(UserRole.SchoolValues)),
  gender: z.nativeEnum(UserGender.Values),
  schoolPeriodId: z.number(),
  hasSignedAgreement: z.boolean().optional(),
})


export type SchoolPeriodUserEntity = z.infer<typeof SchoolPeriodUserSchema>;
export type SchoolPeriodUserListEntity = z.infer<typeof SchoolPeriodUserListSchema>;
export type SchoolPeriodUserInputEntity = z.infer<typeof SchoolPeriodUserInputSchema>;