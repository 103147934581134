<script setup lang='ts'>
import BaseBlock from '@/components/base/BaseBlock.vue'
import { default as PInputText } from 'primevue/inputtext'
import { default as PRadioButton } from 'primevue/radiobutton'
import { default as PButton } from 'primevue/button'
import { default as PCalendar } from 'primevue/calendar'
import { default as PMessage } from 'primevue/message'
import type School from '@/core/domain/school/core/School'
import { computed, onMounted, ref } from 'vue'
import type { SchoolPeriodUserInput } from '@/core/domain/school/user/SchoolPeriodUser'
import { UserRole } from '@/core/domain/resources/enums/UserRole'
import { UserGender } from '@/core/domain/resources/enums/UserGender'
import { useSchoolPeriodUserStore } from '@/stores/school/schoolPeriodUser'
import { storeToRefs } from 'pinia'
import { useSchoolPeriodStore } from '@/stores/school/schoolPeriod'
import { useToast } from 'primevue/usetoast'

// Types
interface ValidationErrors {
  firstname?: string
  lastname?: string
  email?: string
  gender?: string
  roles?: string
  global?: string
}

// Props, Model et Émits
const model = defineModel<SchoolPeriodUserInput>("modelValue", {required: true})

const props = defineProps<{
  school: School
  isCreate: boolean
}>()

const emit = defineEmits(['close'])

// Composables
const toast = useToast()
const schoolPeriodUserStore = useSchoolPeriodUserStore()
const schoolPeriodStore = useSchoolPeriodStore()

// Store refs
const { 
  schoolPeriodUserStoreLoading: loading, 
  schoolPeriodUserStoreError: error 
} = storeToRefs(schoolPeriodUserStore)
const { selectedSchoolPeriod } = storeToRefs(schoolPeriodStore)

// Local state
const formSubmitted = ref(false)
const validationErrors = ref<ValidationErrors>({})
const localLoading = ref(false)

// Computed
const title = computed(() => 
  props.isCreate ? 'Nouvel utilisateur' : 'Modifier un utilisateur'
)

const confirmButtonLabel = computed(() => 
  props.isCreate ? 'Créer l\'utilisateur' : 'Modifier l\'utilisateur'
)

const isSubmitting = computed(() => 
  loading.value.createUser || loading.value.updateUser || localLoading.value
)

const hasError = computed(() => 
  error.value.hasError || Object.keys(validationErrors.value).length > 0
)

const errorMessage = computed(() => {
  if (error.value.hasError) return error.value.message
  if (validationErrors.value.global) return validationErrors.value.global
  return ''
})

// Méthodes
/**
 * Valide le formulaire avant soumission
 * @returns true si le formulaire est valide, false sinon
 */
function validateForm(): boolean {
  const errors: ValidationErrors = {}

  // Validation de base
  if (!model.value.firstname?.trim()) {
    errors.firstname = 'Le prénom est requis'
  }

  if (!model.value.lastname?.trim()) {
    errors.lastname = 'Le nom est requis'
  }

  if (!model.value.email?.trim()) {
    errors.email = 'L\'email est requis'
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(model.value.email)) {
    errors.email = 'L\'email n\'est pas valide'
  }

  if (!model.value.gender) {
    errors.gender = 'Le genre est requis'
  }

  if (!model.value.roles?.length) {
    errors.roles = 'Au moins un rôle est requis'
  }

  validationErrors.value = errors
  return Object.keys(errors).length === 0
}

/**
 * Soumet le formulaire pour création ou mise à jour
 */
async function confirm() {
  formSubmitted.value = true

  if (!validateForm()) {
    return
  }

  if (!selectedSchoolPeriod.value) {
    validationErrors.value.global = 'Aucune période scolaire sélectionnée'
    return
  }

  // Mettre à jour schoolPeriodId
  model.value.schoolPeriodId = selectedSchoolPeriod.value.schoolPeriodId

  try {
    localLoading.value = true

    if (props.isCreate) {
      await schoolPeriodUserStore.createSchoolPeriodUser(
        props.school.id,
        selectedSchoolPeriod.value.schoolPeriodId,
        model.value
      )
    } else {
      await schoolPeriodUserStore.updateSchoolPeriodUser(
        props.school.id,
        selectedSchoolPeriod.value.schoolPeriodId,
        model.value
      )
    }

    emit('close')
  } catch (err) {
    const message = err instanceof Error 
      ? err.message 
      : 'Une erreur est survenue lors de la sauvegarde'
    
    toast.add({
      severity: 'error',
      summary: 'Erreur',
      detail: message,
      life: 5000
    })
    
    console.error('Erreur lors de la sauvegarde:', err)
  } finally {
    localLoading.value = false
  }
}


/**
 * Ferme le formulaire sans sauvegarder
 */
function cancel() {
  emit('close')
}

// Lifecycle hooks
onMounted(() => {
  // S'assurer que schoolPeriodId est défini
  if (!model.value.schoolPeriodId && selectedSchoolPeriod.value) {
    model.value.schoolPeriodId = selectedSchoolPeriod.value.schoolPeriodId
  }
})
</script>

<template>
  <base-block :accordion='false' :title='title'>
    <div class='flex flex-column gap-4'>
      <!-- Section identification -->
      <div>
        <h6 class='heading medium mb-3'>Identification</h6>
        <div class='flex flex-column gap-4'>
          <!-- Prénom -->
          <div class='flex flex-column'>
            <label for="firstname">Prénom*</label>
            <p-input-text
              id="firstname"
              v-model='model.firstname'
              :class="{ 'p-invalid': formSubmitted && validationErrors.firstname }"
              aria-describedby="firstname-error"
            />
            <small id="firstname-error" class="p-error" v-if="formSubmitted && validationErrors.firstname">
              {{ validationErrors.firstname }}
            </small>
          </div>

          <!-- Nom -->
          <div class='flex flex-column'>
            <label for="lastname">Nom*</label>
            <p-input-text
              id="lastname"
              v-model='model.lastname'
              :class="{ 'p-invalid': formSubmitted && validationErrors.lastname }"
              aria-describedby="lastname-error"
            />
            <small id="lastname-error" class="p-error" v-if="formSubmitted && validationErrors.lastname">
              {{ validationErrors.lastname }}
            </small>
          </div>

          <!-- Genre -->
          <div class='flex flex-column'>
            <label>Genre*</label>
            <div class='flex flex-column gap-2'>
              <div class='flex gap-2' v-for='gender in Object.values(UserGender.Values)' :key='gender'>
                <p-radio-button :input-id="gender" :value='gender' v-model='model.gender' :aria-labelledby="`gender-label-${gender}`" />
                <label :id="`gender-label-${gender}`" :for='gender'>{{ UserGender.label(gender) }}</label>
              </div>
            </div>
            <small class="p-error" v-if="formSubmitted && validationErrors.gender">
              {{ validationErrors.gender }}
            </small>
          </div>

          <!-- Email -->
          <div class='flex flex-column'>
            <label for="email">Adresse email*</label>
            <p-input-text
              id="email"
              v-model='model.email'
              :class="{ 'p-invalid': formSubmitted && validationErrors.email }"
              aria-describedby="email-error"
            />
            <small id="email-error" class="p-error" v-if="formSubmitted && validationErrors.email">
              {{ validationErrors.email }}
            </small>
          </div>

          <!-- Date de naissance -->
          <div class='flex flex-column'>
            <label for="birthdate">Date de naissance</label>
            <p-calendar 
              id="birthdate"
              v-model='model.birthdateDate' 
              dateFormat="dd/mm/yy"
              :showIcon="true"
              inputId="birthdate"
            />
          </div>
        </div>
      </div>

      <!-- Section rôles -->
      <div>
        <h6 class='heading medium mb-3'>Rôles et autorisations</h6>
        <div class='flex flex-column gap-4'>
          <!-- Rôle -->
          <div class='flex flex-column'>
            <label>Rôle*</label>
            <div class='flex flex-column gap-2'>
              <div class='flex gap-2' v-for='roleValue in Object.values(UserRole.SchoolValues)' :key='roleValue'>
                <p-radio-button :input-id="roleValue" :value='roleValue' v-model='model.role' :aria-labelledby="`role-label-${roleValue}`" />
                <label :id="`role-label-${roleValue}`" :for='roleValue'>{{ UserRole.label(roleValue) }}</label>
              </div>
            </div>
            <small class="p-error" v-if="formSubmitted && validationErrors.roles">
              {{ validationErrors.roles }}
            </small>
          </div>

          <!-- Accord utilisateur (seulement pour étudiants) -->
          <div class='flex flex-column' v-if='model.role === UserRole.SchoolValues.STUDENT'>
            <label>Accord utilisateur*</label>
            <div class='flex flex-column gap-2'>
              <div class='flex gap-2'>
                <p-radio-button 
                  input-id="agreement-no" 
                  :value='false' 
                  v-model='model.hasSignedAgreement'
                  aria-labelledby="agreement-no-label"
                />
                <label id="agreement-no-label" for='agreement-no'>Non signé (l'utilisateur sera exclu des résultats)</label>
              </div>

              <div class='flex gap-2'>
                <p-radio-button 
                  input-id="agreement-yes" 
                  :value='true' 
                  v-model='model.hasSignedAgreement'
                  aria-labelledby="agreement-yes-label"
                />
                <label id="agreement-yes-label" for='agreement-yes'>Signé</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Message d'erreur global -->
      <p-message
        severity="error"
        v-if="hasError && errorMessage"
      >
        {{ errorMessage }}
      </p-message>

      <!-- Actions -->
      <div class="flex gap-3 justify-content-end">
        <p-button
          label="Annuler"
          severity="secondary"
          outlined
          icon="pi pi-times"
          @click="cancel"
          :disabled="isSubmitting"
        />
        <p-button
          severity='primary'
          :label='confirmButtonLabel'
          icon="pi pi-check"
          @click='confirm'
          :loading="isSubmitting"
          :disabled="isSubmitting"
        />
      </div>
    </div>
  </base-block>
</template>