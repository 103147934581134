import type { ISchoolMonitoringGroupRepository } from '@/core/domain/school/group/ISchoolMonitoringGroupRepository'
import type SchoolMonitoringGroup from '@/core/domain/school/group/SchoolMonitoringGroup'


export class CreateSchoolMonitoringGroupUseCase {
  private schoolMonitoringGroupRepository: ISchoolMonitoringGroupRepository;

  constructor(schoolMonitoringGroupRepository: ISchoolMonitoringGroupRepository) {
    this.schoolMonitoringGroupRepository = schoolMonitoringGroupRepository;
  }

  async execute(
    schoolId: number,
    schoolPeriodId: number,
    schoolClassroomId: number,
  ): Promise<SchoolMonitoringGroup> {
    return this.schoolMonitoringGroupRepository.createSchoolMonitoringGroup(schoolId, schoolPeriodId, schoolClassroomId);
  }
}