import type ISchoolPeriodRepository from '@/core/domain/school/period/ISchoolPeriodRepository'
import SchoolPeriod, { type SchoolPeriodInput } from '@/core/domain/school/period/SchoolPeriod'

export default class CreateSchoolPeriodUseCase {
  schoolPeriodRepository: ISchoolPeriodRepository

  constructor(schoolPeriodRepository: ISchoolPeriodRepository) {
    this.schoolPeriodRepository = schoolPeriodRepository;
  }

  async execute(schoolPeriod: SchoolPeriodInput): Promise<SchoolPeriod> {
    return await this.schoolPeriodRepository.createSchoolPeriod(schoolPeriod);
  }
}