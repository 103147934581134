import { z } from 'zod';

// Schéma pour un utilisateur individuel
export const SchoolClassroomLoginHistoryUserSchema = z.object({
  identifier: z.string().uuid(),
  firstname: z.string(),
  lastname: z.string(),
  lastLogin: z.string().datetime().optional()
});

export type  SchoolClassroomLoginHistoryUserEntity = z.infer<typeof SchoolClassroomLoginHistoryUserSchema>;