import School, { SchoolInput } from '@/core/domain/school/core/School'
import type ISchoolRepository from '@/core/domain/school/core/ISchoolRepository'

export class UpdateSchoolUseCase {
  private schoolRepository: ISchoolRepository;

  constructor(schoolRepository: ISchoolRepository) {
    this.schoolRepository = schoolRepository;
  }

  /**
   * @description Update a school
   * @param {number} schoolId - The id of the school to update
   * @param {SchoolInput} school - The school to update
   * @returns {Promise<School>} The updated school
   */
  async execute(schoolId: number, school: SchoolInput): Promise<School> {
    return await this.schoolRepository.updateSchool(schoolId, school);
  }
}