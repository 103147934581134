<script setup lang='ts'>
import BaseBlock from '@/components/base/BaseBlock.vue'
import { default as PMessage } from 'primevue/message'
import { default as PButton } from 'primevue/button'
import { default as PProgressSpinner } from 'primevue/progressspinner'
import { useSchoolPeriodUserStore } from '@/stores/school/schoolPeriodUser'
import type School from '@/core/domain/school/core/School'
import { computed, onMounted, type Ref, ref, watch } from 'vue'
import { useSchoolPeriodStore } from '@/stores/school/schoolPeriod'
import { storeToRefs } from 'pinia'
import { type SchoolPeriodUser, SchoolPeriodUserInput } from '@/core/domain/school/user/SchoolPeriodUser'
import SchoolPeriodUsersFilters from '@/components/schools/SchoolPeriodUsersFilters.vue'
import SchoolPeriodUsersTable from '@/components/schools/SchoolPeriodUsersTable.vue'

import { useToast } from 'primevue/usetoast'
import { useSchoolUserFilters } from '@/composables/school/users/useSchoolPeriodUsersFilters'
import SchoolPeriodUsersUpdate from '@/components/schools/SchoolPeriodUsersUpdate.vue'

interface EditUser {
  open: boolean
  user: SchoolPeriodUserInput | null
  isCreation: boolean
}

// Composables
const toast = useToast()
const schoolPeriodUserStore = useSchoolPeriodUserStore()
const schoolPeriodStore = useSchoolPeriodStore()

// Store refs
const {
  schoolPeriodUsers,
  schoolPeriodUserStoreIsLoading,
  schoolPeriodUserStoreError,
  needsFreshData
} = storeToRefs(schoolPeriodUserStore)
const { selectedSchoolPeriod } = storeToRefs(schoolPeriodStore)

// Local state
const localLoading = ref(false)
const localError = ref('')

// Filtrage des utilisateurs via le composable
const {
  filters,
  selectedOrder,
  orderOptions,
  sortedUsers,
  resetFilters,
  hasActiveFilters,
  filteredUserCount
} = useSchoolUserFilters(schoolPeriodUsers)

// État d'édition
const editUser: Ref<EditUser> = ref({
  open: false,
  user: null,
  isCreation: false
})

// Props
const props = defineProps<{
  school: School
}>()

// Computed
const isLoading = computed(() =>
  localLoading.value || schoolPeriodUserStoreIsLoading.value
)

const hasError = computed(() =>
  localError.value !== '' || (schoolPeriodUserStoreError.value && schoolPeriodUserStoreError.value.hasError)
)

const errorMessage = computed(() =>
  localError.value || (schoolPeriodUserStoreError.value?.hasError ? schoolPeriodUserStoreError.value.message : '')
)

const userCount = computed(() => schoolPeriodUsers.value.length)
const hasUsers = computed(() => userCount.value > 0)
const canAddUser = computed(() =>
  !isLoading.value && selectedSchoolPeriod.value !== null && props.school?.id
)

// Methods
/**
 * Charge les utilisateurs de la période scolaire
 * @param forceRefresh Force le rechargement des données même si déjà présentes
 */
async function loadUsers(forceRefresh = false) {
  if (!selectedSchoolPeriod.value || !props.school?.id) return

  // Éviter les rechargements inutiles si les données sont déjà chargées
  if (
    !forceRefresh &&
    schoolPeriodUsers.value.length > 0 &&
    !needsFreshData.value
  ) {
    return
  }

  try {
    localLoading.value = true
    localError.value = ''

    await schoolPeriodUserStore.fetchAllSchoolPeriodUsersForSchool(
      props.school.id,
      selectedSchoolPeriod.value.schoolPeriodId,
      forceRefresh
    )
  } catch (err) {
    localError.value = err instanceof Error
      ? err.message
      : 'Erreur lors du chargement des utilisateurs'

    toast.add({
      severity: 'error',
      summary: 'Erreur',
      detail: localError.value,
      life: 5000
    })

    console.error('Erreur lors du chargement des utilisateurs:', err)
  } finally {
    localLoading.value = false
  }
}

/**
 * Prépare l'édition d'un utilisateur
 * @param user Utilisateur à modifier (ou undefined pour création)
 */
async function startUserEdit(user?: SchoolPeriodUser) {
  if (!selectedSchoolPeriod.value) return

  try {
    localLoading.value = true
    localError.value = ''

    if (user) {
      // Mode édition
      editUser.value.user = await schoolPeriodUserStore.prepareSchoolPeriodUserEdit(
        selectedSchoolPeriod.value.schoolPeriodId,
        user
      )
      editUser.value.isCreation = false
    } else {
      // Mode création
      editUser.value.user = new SchoolPeriodUserInput()
      editUser.value.user.schoolPeriodId = selectedSchoolPeriod.value.schoolPeriodId
      editUser.value.isCreation = true
    }

    editUser.value.open = true
  } catch (err) {
    localError.value = err instanceof Error
      ? err.message
      : 'Erreur lors de la préparation de l\'édition'

    toast.add({
      severity: 'error',
      summary: 'Erreur',
      detail: localError.value,
      life: 5000
    })

    console.error('Erreur lors de la préparation de l\'édition:', err)
  } finally {
    localLoading.value = false
  }
}

/**
 * Ferme l'éditeur d'utilisateur
 */
function closeUserEdit() {
  editUser.value.open = false
  editUser.value.user = null

  // Recharger les utilisateurs pour refléter les changements
  loadUsers(true)
}

// Watchers
watch(selectedSchoolPeriod, async (newValue) => {
  if (newValue) {
    await loadUsers(true)
  } else {
    // Réinitialiser les données lorsqu'aucune période n'est sélectionnée
    schoolPeriodUserStore.reset()
  }
})

// Lifecycle hooks
onMounted(async () => {
  if (selectedSchoolPeriod.value) {
    await loadUsers(true)
  }
})
</script>

<template>
  <!-- Modal d'édition d'utilisateur -->
  <school-period-users-update
    v-if='editUser.open && editUser.user'
    :is-create='editUser.isCreation'
    :school='school'
    v-model='editUser.user'
    @close='closeUserEdit()'
  />

  <!-- Liste des utilisateurs -->
  <base-block :accordion='false' title="Utilisateurs liés à l'établissement" v-else>
    <!-- Message quand aucune période n'est sélectionnée -->
    <p-message
      v-if='!selectedSchoolPeriod'
      severity="info"
      class="w-full mb-3"
    >
      Veuillez sélectionner une période scolaire pour gérer les utilisateurs.
    </p-message>

    <!-- Contenu principal -->
    <div v-else class='flex align-items-start flex-column gap-3 w-full'>
      <!-- Message d'erreur -->
      <p-message
        v-if="hasError"
        severity="error"
        :text="errorMessage"
        class="w-full mb-2"
      />

      <!-- En-tête avec bouton d'ajout et compteur -->
      <div class="flex justify-content-between align-items-center w-full">
        <p-button
          label='Ajouter un utilisateur'
          icon='pi pi-plus'
          severity='primary'
          size='small'
          @click='startUserEdit()'
          :disabled="!canAddUser"
        />

        <div v-if="isLoading" class="flex align-items-center">
          <p-progress-spinner style="width: 1.5rem; height: 1.5rem" />
          <span class="ml-2">Chargement...</span>
        </div>

        <div v-else-if="hasUsers" class="flex align-items-center gap-2">
          <span class="text-sm text-500">
            {{ filteredUserCount }} utilisateur(s) {{ hasActiveFilters ? 'trouvé(s)' : 'au total' }}
          </span>

          <p-button
            v-if="hasActiveFilters"
            icon="pi pi-filter-slash"
            text
            size="small"
            @click="resetFilters"
            tooltip="Réinitialiser les filtres"
          />

          <p-button
            icon="pi pi-refresh"
            text
            size="small"
            @click="() => loadUsers(true)"
            tooltip="Actualiser"
          />
        </div>
      </div>

      <!-- Filtres -->
      <school-period-users-filters
        v-if="hasUsers"
        v-model="filters"
        :selected-order="selectedOrder"
        :order-options="orderOptions"
        @update:selected-order="value => selectedOrder = value"
      />

      <!-- État vide -->
      <div
        v-if="!isLoading && !hasUsers"
        class="flex flex-column align-items-center justify-content-center p-5 w-full"
      >
        <i class="pi pi-users text-4xl text-500 mb-3"></i>
        <h3 class="mt-0 mb-2">Aucun utilisateur</h3>
        <p class="text-center text-700">
          Cette période scolaire ne contient pas encore d'utilisateurs.
          Commencez par ajouter un utilisateur avec le bouton ci-dessus.
        </p>
      </div>

      <!-- Tableau des utilisateurs -->
      <school-period-users-table
        v-else-if="hasUsers"
        :users="sortedUsers"
        :is-loading="isLoading"
        @edit-user="startUserEdit"
      />
    </div>
  </base-block>
</template>
