import type { ILearningCourseUrlOptions } from '@/core/domain/learning/course/ILearningCourseUrlOptions'
import type ApiService from '@/core/infrastructure/services/ApiService'
import { LearningCourse } from '@/core/domain/learning/course/LearningCourse'
import { LearningCourseUrlOptions } from '@/core/domain/learning/course/LearningCourseUrlOptions'
import {
  LearningCourseListSchema,
  LearningCourseSchema
} from '@/core/infrastructure/schemas/learning/course/LearningCourseSchema'
import type { ILearningCourseRepository } from '@/core/domain/learning/course/ILearningCourseRepository'

export class LearningCourseRepository implements ILearningCourseRepository{
  private apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  /**
   * Fetches learning courses with the specified options
   * The response structure varies based on the provided parameters:
   * - With modules, grains, supports: returns courses with their complete hierarchy
   * - With domains: returns courses with domain information
   * - With status filter: returns courses matching the specified status
   */
  async fetchAllLearningCourses(learningCourseOptions?: ILearningCourseUrlOptions): Promise<LearningCourse[]> {
    console.log('fetchAllLearningCourses');
    let url = '/learning/courses';

    // Generate URL options if provided
    if (learningCourseOptions) {
      // Normalize options to ensure proper dependencies
      const normalizedOptions = LearningCourseUrlOptions.normalizeOptions(learningCourseOptions);
      const options = new LearningCourseUrlOptions(normalizedOptions).getUrlOptions();
      url += options;
    }

    try {
      // Fetch the data with the constructed URL
      const res = await this.apiService.get(url);

      // Parse the response
      const parse = LearningCourseListSchema.safeParse(res);
      console.log(parse);

      if (parse.success) {
        return parse.data.map((learningCourse) => new LearningCourse(learningCourse));
      }

      return Promise.reject('Parsing Error: Unable to parse learning courses data');
    } catch (error) {
      return Promise.reject(`Error fetching learning courses: ${error}`);
    }
  }

  /**
   * Fetches a single learning course by ID
   * @param id The ID of the learning course to fetch
   * @param options Optional parameters to customize the returned data
   */
  async fetchLearningCourse(id: number, options?: ILearningCourseUrlOptions): Promise<LearningCourse> {
    console.log('fetchLearningCourse', id);

    if (!id) {
      return Promise.reject('Error: Learning course ID is required');
    }

    let url = `/learning/courses/${id}`;

    // Generate URL options if provided
    if (options) {
      // Normalize options to ensure proper dependencies
      const normalizedOptions = LearningCourseUrlOptions.normalizeOptions(options);
      const urlOptions = new LearningCourseUrlOptions(normalizedOptions).getUrlOptions();
      url += urlOptions;
    }

    try {
      // Fetch the data with the constructed URL
      const res = await this.apiService.get(url);

      // Parse the response
      const parse = LearningCourseSchema.safeParse(res);

      if (parse.success) {
        return new LearningCourse(parse.data);
      }

      return Promise.reject('Parsing Error: Unable to parse learning course data');
    } catch (error) {
      return Promise.reject(`Error fetching learning course: ${error}`);
    }
  }
}