import type { ISchoolClassroomRepository } from '@/core/domain/school/classroom/ISchoolClassroomRepository'
import type { SchoolClassroomLoginHistory } from '@/core/domain/school/classroom/SchoolClassroomLoginHistory'

export default class FetchSchoolClassroomLoginHistoryUseCase {
  private schoolClassroomRepository: ISchoolClassroomRepository

  constructor(schoolClassroomRepository: ISchoolClassroomRepository) {
    this.schoolClassroomRepository = schoolClassroomRepository
  }

  async execute(
    schoolClassroomId: number,
    schoolId: number,
    schoolPeriodId: number
  ): Promise<SchoolClassroomLoginHistory> {
    return await this.schoolClassroomRepository.fetchSchoolClassroomLoginHistory(
      schoolClassroomId,
      schoolId,
      schoolPeriodId
    )
  }
}