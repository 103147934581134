import { z } from 'zod'
import { LearningStatus } from '@/core/domain/resources/enums/LearningStatus'
import { LearningQuizSchema } from '@/core/infrastructure/schemas/learning/quiz/LearningQuizSchema'
import { LearningSupportListSchema } from '@/core/infrastructure/schemas/learning/support/LearningSupportSchema'

// Schema de base pour un grain d'apprentissage
export const LearningGrainSchema = z.object({
  id: z.number(),
  title: z.string(),
  status: z.nativeEnum(LearningStatus.Values),
  points: z.number(),
  order: z.number(),
  // Les quiz sont optionnels car ils peuvent ne pas exister encore
  quizPre: LearningQuizSchema.optional().nullable(),
  quizPost: LearningQuizSchema.optional().nullable(),
  // Les supports sont toujours présents, même comme tableau vide
  learningSupports: LearningSupportListSchema.default([])
})

// Liste de grains
export const LearningGrainListSchema = z.array(LearningGrainSchema)

// Entrée pour la création ou la mise à jour d'un grain
export const LearningGrainInputSchema = z.object({
  id: z.number(),
  title: z.string(),
  status: z.nativeEnum(LearningStatus.Values),
  points: z.number(),
  order: z.number(),
  // Les supports sont optionnels pour l'entrée
  learningSupports: LearningSupportListSchema.optional()
})

// Types dérivés des schemas
export type LearningGrainEntity = z.infer<typeof LearningGrainSchema>
export type LearningGrainListEntity = z.infer<typeof LearningGrainListSchema>
export type LearningGrainInputEntity = z.infer<typeof LearningGrainInputSchema>