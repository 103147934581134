import {
  type SchoolMonitoringGroupEntity, type SchoolMonitoringGroupInputEntity,
  SchoolMonitoringGroupInputSchema
} from '@/core/infrastructure/schemas/school/group/SchoolMonitoringGroupSchema'
import { SchoolPeriodUser } from '@/core/domain/school/user/SchoolPeriodUser'

/**
 * MonitoringGroup domain class
 */
export default class SchoolMonitoringGroup {
  readonly groupId: number;
  readonly name: string;
  readonly schoolClassroomId: number;
  readonly teacher?: SchoolPeriodUser;
  readonly students: SchoolPeriodUser[]

  constructor(group: SchoolMonitoringGroupEntity) {
    this.groupId = group.groupId;
    this.schoolClassroomId = group.schoolClassroomId;
    this.name = group.name;
    this.teacher = group.teacher ? new SchoolPeriodUser(group.teacher) : undefined;
    this.students = group.students.map(student => new SchoolPeriodUser(student));
  }

  get studentIds(): number[] {
    return this.students.map(student => student.schoolPeriodUserId);
  }

  get teacherId(): number {
    return this.teacher ? this.teacher.schoolPeriodUserId : -1;
  }

  toInput(): SchoolMonitoringGroupInput {
    return new SchoolMonitoringGroupInput({
      teacherId: this.teacherId,
      studentIds: this.studentIds
    });
  }
}

export class SchoolMonitoringGroupInput {
  readonly teacherId?: number;
  readonly studentIds: number[]

  constructor(group: SchoolMonitoringGroupInputEntity) {
    this.teacherId = group.teacherId;
    this.studentIds = group.studentIds;
  }
}