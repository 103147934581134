import { z } from 'zod'
import { LearningQuizQuestionType } from '@/core/domain/resources/enums/LearningQuizQuestion'

const ContentItemSchema = z.object({
  type: z.string(),
  value: z.string()
})

// Base answer schema
const BaseAnswerSchema = z.object({
  type: z.nativeEnum(LearningQuizQuestionType.Values)
})

// MCQ/UCQ Answer schema
export const SingleChoiceAnswerSchema = BaseAnswerSchema.extend({
  type: z.literal(LearningQuizQuestionType.Values.SINGLE_CHOICE),
  label: z.string(),
  isCorrect: z.boolean()
})

export const MultipleChoiceAnswerSchema = BaseAnswerSchema.extend({
  type: z.literal(LearningQuizQuestionType.Values.MULTIPLE_CHOICE),
  label: z.string(),
  isCorrect: z.boolean()
})

// True/False Answer schema
export const TrueFalseAnswerSchema = BaseAnswerSchema.extend({
  type: z.literal(LearningQuizQuestionType.Values.TRUE_FALSE),
  label: z.string(),
  isCorrect: z.boolean()
})

export const MatchingAnswerSchema = BaseAnswerSchema.extend({
  type: z.literal(LearningQuizQuestionType.Values.MATCHING),
  left: ContentItemSchema,
  right: ContentItemSchema
})

export const SequencingAnswerSchema = BaseAnswerSchema.extend({
  type: z.literal(LearningQuizQuestionType.Values.SEQUENCING),
  data: ContentItemSchema,
  order: z.number()
})

export const QuestionAnswerSchema = z.discriminatedUnion('type', [
  SingleChoiceAnswerSchema,
  MultipleChoiceAnswerSchema,
  TrueFalseAnswerSchema,
  MatchingAnswerSchema,
  SequencingAnswerSchema
])

export type AnswerEntity = z.infer<typeof QuestionAnswerSchema>
export type SingleChoiceAnswerEntity = z.infer<typeof SingleChoiceAnswerSchema>
export type MultipleChoiceAnswerEntity = z.infer<typeof MultipleChoiceAnswerSchema>
export type TrueFalseAnswerEntity = z.infer<typeof TrueFalseAnswerSchema>
export type MatchingAnswerEntity = z.infer<typeof MatchingAnswerSchema>
export type SequencingAnswerEntity = z.infer<typeof SequencingAnswerSchema>
export type ContentItemEntity = z.infer<typeof ContentItemSchema>