import { ref, computed } from 'vue'
import { NabooError } from '@/assets/classes/Error'
import { useToast } from 'primevue/usetoast'

// Types communs
export type LoadingState = {
  [key: string]: boolean;
};

export type ErrorState = {
  hasError: boolean;
  message: string;
  code?: string;
  details?: any;
};

/**
 * Crée une base commune pour les stores Pinia
 * @param initialLoadingKeys Clés initiales pour l'état de chargement
 */
export function createStoreBase(initialLoadingKeys: string[] = []) {
  // SERVICES
  const toast = useToast();

  // Création de l'état de chargement initial
  const initialLoadingState: LoadingState = {};
  initialLoadingKeys.forEach(key => {
    initialLoadingState[key] = false;
  });

  // État
  const loading = ref<LoadingState>({ ...initialLoadingState });
  const error = ref<ErrorState>({
    hasError: false,
    message: '',
    code: undefined,
    details: undefined
  });
  const lastUpdated = ref<Date | null>(null);

  // Méthodes
  /**
   * Réinitialise l'état d'erreur
   */
  function resetError(): void {
    error.value = {
      hasError: false,
      message: '',
      code: undefined,
      details: undefined
    };
  }

  /**
   * Configure l'état d'erreur
   */
  function setError(err: any): void {
    error.value.hasError = true;

    if (err instanceof NabooError) {
      error.value.message = err.message;
      // Récupérer le code et les détails si disponibles
      if (err['code']) error.value.code = err['code'];
      if (err['message']) error.value.details = err['message'];
    } else if (err instanceof Error){
      error.value.message = err.message;
    } else {
      error.value.message = 'Une erreur inconnue est survenue';
    }
  }

  function showSuccessToast(summary: string, detail: string): void {
    toast.add({
      severity: 'success',
      summary,
      detail,
      life: 5000
    });
  }

  /**
   * Met à jour la date de dernière mise à jour
   */
  function updateLastUpdated(): void {
    lastUpdated.value = new Date();
  }

  /**
   * Vérifie si les données ont besoin d'être rafraîchies
   * @param maxAgeMinutes Âge maximum des données en minutes
   */
  function shouldRefreshData(maxAgeMinutes: number = 5): boolean {
    if (!lastUpdated.value) return true;

    const maxAgeMs = maxAgeMinutes * 60 * 1000;
    return Date.now() - lastUpdated.value.getTime() > maxAgeMs;
  }

  /**
   * Vérifie si un état de chargement est actif
   */
  const isLoading = computed(() =>
    Object.values(loading.value).some(Boolean)
  );

  /**
   * Réinitialise l'état du store
   */
  function resetBase(): void {
    // Réinitialiser l'état de chargement
    Object.keys(loading.value).forEach(key => {
      loading.value[key] = false;
    });

    resetError();
    lastUpdated.value = null;
  }

  return {
    // État
    loading,
    error,
    lastUpdated,

    // Méthodes
    resetError,
    setError,
    updateLastUpdated,
    shouldRefreshData,
    resetBase,
    showSuccessToast,

    // Getters
    isLoading
  };
}