import { z } from 'zod';
import { SchoolPeriodUserSchema } from '@/core/infrastructure/schemas/school/user/SchoolPeriodUserSchema'

export const SchoolMonitoringGroupSchema = z.object({
  groupId: z.number(),
  schoolClassroomId: z.number(),
  name: z.string(),
  teacher: SchoolPeriodUserSchema.optional(),
  students: z.array(SchoolPeriodUserSchema),
})

export const SchoolMonitoringGroupInputSchema = z.object({
  teacherId: z.number().optional(),
  studentIds: z.array(z.number()),
})


export type SchoolMonitoringGroupEntity = z.infer<typeof SchoolMonitoringGroupSchema>;
export type SchoolMonitoringGroupInputEntity = z.infer<typeof SchoolMonitoringGroupInputSchema>;