import { defineStore, storeToRefs } from 'pinia'
import { useDashboardStore } from '@/stores/dashboard/dashboard'
import { useDashboardClassroomStore } from '@/stores/dashboard/dashboardClassroom'
import { computed, ref, type Ref } from 'vue'
import { createStoreBase } from '@/stores/resources/storeBase'
import DependencyContainer from '@/presentation/configuration/DependencyContainer'

import { FetchDashboardClassroomScoreModuleResults } from '@/core/useCases/dashboard/FetchDashboardClassroomScoreModuleResults'
import { FetchDashboardClassroomScoreDiagnosticResults } from '@/core/useCases/dashboard/FetchDashboardClassroomScoreDiagnosticResults'
import type { DashboardScoreModule } from '@/core/domain/dashboard/DashboardScoreModule'
import type { DashboardScoreDiagnostic } from '@/core/domain/dashboard/DashboardScoreDiagnostic'

export const useDashboardClassroomResultStore = defineStore('dashboardClassroomResult', () => {
  // BASE COMMUNE
  const baseStore = createStoreBase(['fetchScoreDiagnostic', 'fetchScoreModule']);

  // STORES
  const dashboardStore = useDashboardStore()
  const dashboardClassroomStore = useDashboardClassroomStore()

  const { dashboardSelectedSchool } = storeToRefs(dashboardStore)
  const { dashboardSelectedClassroom } = storeToRefs(dashboardClassroomStore)

  // ÉTAT
  const scoreDiagnostic: Ref<DashboardScoreDiagnostic[]> = ref([])
  const scoreModule: Ref<Record<number, DashboardScoreModule[]>> = ref({})

  // ACTIONS
  /**
   * Récupère les résultats du diagnostic pour une classe
   * @param courseId ID du parcours
   */
  async function fetchSchoolClassroomScoreDiagnostic(
    courseId: number
  ): Promise<DashboardScoreDiagnostic[] | null> {
    try {
      baseStore.loading.value.fetchScoreDiagnostic = true
      baseStore.resetError()

      // Vérifier si les données sont déjà chargées et récentes
      if (
        baseStore.lastUpdated.value &&
        Date.now() - baseStore.lastUpdated.value.getTime() < 5 * 60 * 1000 && // 5 minutes
        scoreDiagnostic.value.length > 0
      ) {
        return scoreDiagnostic.value
      }

      console.log('Fetching score diagnostic:', dashboardSelectedClassroom.value)
      const classroomId = dashboardSelectedClassroom.value?.schoolClassroomId
      const schoolId = dashboardSelectedSchool.value?.id
      const periodId = dashboardSelectedClassroom.value?.schoolPeriodId

      if (!schoolId || !classroomId || !courseId || !periodId) {
        return Promise.reject(new Error(`Informations manquantes pour récupérer les scores du diagnostic. SchoolId: ${schoolId}, schoolPeriodId: ${periodId}, classroomId: ${classroomId}, courseId: ${courseId} ` ))
      }

      const result = await DependencyContainer.resolve(FetchDashboardClassroomScoreDiagnosticResults).execute(
        schoolId,
        periodId,
        classroomId,
        courseId
      )

      if (result) {
        scoreDiagnostic.value = result
        baseStore.lastUpdated.value = new Date()
      }

      return result
    } catch (err) {
      baseStore.setError(err)
      console.error('Erreur lors de la récupération des scores de diagnostic:', err)
      return null
    } finally {
      baseStore.loading.value.fetchScoreDiagnostic = false
    }
  }

  /**
   * Récupère les résultats du module pour une classe
   * @param taskId ID de la tâche
   */
  async function fetchSchoolClassroomScoreModule(
    taskId: number
  ): Promise<DashboardScoreModule[] | null> {
    try {
      baseStore.loading.value.fetchScoreModule = true
      baseStore.resetError()

      // Vérifier si les données sont déjà chargées et récentes
      if (
        baseStore.lastUpdated.value &&
        Date.now() - baseStore.lastUpdated.value.getTime() < 5 * 60 * 1000 && // 5 minutes
        scoreModule.value[taskId]?.length > 0
      ) {
        return scoreModule.value[taskId]
      }

      const classroomId = dashboardSelectedClassroom.value?.schoolClassroomId
      const schoolId = dashboardSelectedSchool.value?.id
      const periodId = dashboardSelectedClassroom.value?.schoolPeriodId

      if (!schoolId || !classroomId || !taskId || !periodId) {
        return Promise.reject(new Error('Informations manquantes pour récupérer les scores du module'))
      }

      const result = await DependencyContainer.resolve(FetchDashboardClassroomScoreModuleResults).execute(
        schoolId,
        periodId,
        classroomId,
        taskId,
      )

      if (result) {
        scoreModule.value = {
          ...scoreModule.value,
          [taskId]: result
        }
        baseStore.lastUpdated.value = new Date()
      }

      return result
    } catch (err) {
      baseStore.setError(err)
      console.error('Erreur lors de la récupération des scores du module:', err)
      return null
    } finally {
      baseStore.loading.value.fetchScoreModule = false
    }
  }

  /**
   * Réinitialise l'état du store
   */
  function reset(): void {
    scoreDiagnostic.value = []
    scoreModule.value = {}
    baseStore.lastUpdated.value = null
    baseStore.resetError()

    baseStore.loading.value = {
      fetchScoreDiagnostic: false,
      fetchScoreModule: false
    }
  }

  // GETTERS
  /**
   * Indique si des données sont en cours de chargement
   */
  const isLoading = computed(() =>
    Object.values(baseStore.loading.value).some(Boolean)
  )

  /**
   * Indique si les données doivent être rechargées
   */
  const needsFreshData = computed(() => {
    if (!baseStore.lastUpdated.value) return true

    // Considérer les données comme périmées après 5 minutes
    const fiveMinutesInMs = 5 * 60 * 1000
    return Date.now() - baseStore.lastUpdated.value.getTime() > fiveMinutesInMs
  })

  return {
    // État
    scoreDiagnostic,
    scoreModule,
    dashboardClassroomResultStoreLoading: baseStore.loading,
    dashboardClassroomResultStoreError: baseStore.error,
    baseStoreLastUpdated: baseStore.lastUpdated,

    // Actions
    fetchSchoolClassroomScoreDiagnostic,
    fetchSchoolClassroomScoreModule,
    reset,

    // Getters
    dashboardClassroomResultStoreIsLoading: isLoading,
    needsFreshData
  }
})