import type { ISchoolClassroomRepository } from '@/core/domain/school/classroom/ISchoolClassroomRepository'
import { SchoolClassroomLoginHistory } from '@/core/domain/school/classroom/SchoolClassroomLoginHistory'
import type ApiService from '@/core/infrastructure/services/ApiService'
import { SchoolClassroomLoginHistorySchema } from '@/core/infrastructure/schemas/school/classroom/SchoolClassroomLoginHistorySchema'
import SchoolClassroom, {
  SchoolClassroomInput,
  SchoolClassroomListItem
} from '@/core/domain/school/classroom/SchoolClassroom'
import {
  SchoolClassroomSchema,
  SchoolClassroomsListISchema, SchoolClassroomsListItemSchema
} from '@/core/infrastructure/schemas/school/classroom/SchoolClassroomSchema'

export default class SchoolClassroomRepository implements ISchoolClassroomRepository {
  private apiService: ApiService

  constructor(apiService: ApiService) {
    this.apiService = apiService
  }

  /**
   * Récupère une classe pour une période scolaire donnée
   * @param schoolClassroomId Identifiant de la classe
   * @param schoolId Identifiant de l'école
   * @param schoolPeriodId Identifiant de la période scolaire
   * @returns Classe de l'école
   */
  async fetchSchoolClassroom(schoolId: number, schoolPeriodId: number, schoolClassroomId: number): Promise<SchoolClassroom> {
    const response = await this.apiService.get<SchoolClassroom>(`admin/schools/${schoolId}/periods/${schoolPeriodId}/classrooms/${schoolClassroomId}`)

    const parse = SchoolClassroomSchema.safeParse(response)

    if (parse.success) {
      return new SchoolClassroom(parse.data)
    }

    return Promise.reject('Parsing error :' + parse.error)
  }

  /**
   * Récupère l'ensemble des classes d'une école pour une période scolaire donnée
   * @param schoolId Identifiant de l'école
   * @param schoolPeriodId Identifiant de la période scolaire
   * @returns Liste des classes de l'école
   */
  async fetchAllSchoolClassrooms(schoolId: number, schoolPeriodId: number): Promise<SchoolClassroomListItem[]> {
    const response = await this.apiService.get<SchoolClassroom[]>(`admin/schools/${schoolId}/periods/${schoolPeriodId}/classrooms`)

    const parse = SchoolClassroomsListISchema.safeParse(response)

    if (parse.success) {
      return parse.data.map((classroom) => new SchoolClassroomListItem(classroom))
    }

    return Promise.reject('Parsing error :' + parse.error)
  }

  /**
   * Crée une classe
   * @param schoolId Identifiant de l'école
   * @param schoolClassroom Classe à créer
   * @returns Classe créée
   */
  async createSchoolClassroom(schoolId: number, schoolClassroom: SchoolClassroomInput): Promise<SchoolClassroomListItem> {
    const response = await this.apiService.post<SchoolClassroomListItem>(`admin/schools/${schoolId}/periods/${schoolClassroom.schoolPeriodId}/classrooms`, schoolClassroom)

    const parse = SchoolClassroomsListItemSchema.safeParse(response)

    if (parse.success) {
      return new SchoolClassroomListItem(parse.data)
    }

    return Promise.reject('Parsing error :' + parse.error)
  }


  /**
   * Met à jour le détail d'une classe
   * @param schoolId Identifiant de l'école
   * @param periodId Identifiant de la période scolaire
   * @param schoolClassroom Input de la classe
   * @returns Classe mise à jour
   */
  async updateSchoolClassroom(schoolId: number, periodId: number, schoolClassroom: SchoolClassroomInput): Promise<SchoolClassroomListItem> {
    const response = await this.apiService.put<SchoolClassroom>(`admin/schools/${schoolId}/periods/${periodId}/classrooms/${schoolClassroom.schoolClassroomId}`, schoolClassroom)

    const parse = SchoolClassroomsListItemSchema.safeParse(response)

    console.log('parse', parse)

    if (parse.success) {
      return new SchoolClassroomListItem(parse.data)
    }

    return Promise.reject('Parsing error :' + parse.error)
  }


  /**
   * Met à jour les utilisateurs d'une classe
   * @param schoolId Identifiant de l'école
   * @param periodId Identifiant de la période scolaire
   * @param schoolClassroomId Identifiant de la classe
   * @param users Identifiant des utilisateurs
   * @returns Classe mise à jour
   */
  async updateSchoolClassroomUsers(
    schoolId: number,
    periodId: number,
    schoolClassroomId: number,
    users: number[]): Promise<SchoolClassroomListItem> {


    const response = await this.apiService.put<SchoolClassroomListItem>(
      `admin/schools/${schoolId}/periods/${periodId}/classrooms/${schoolClassroomId}/users`,
      { classroomId: schoolClassroomId, users }
    )

    const parse = SchoolClassroomsListItemSchema.safeParse(response)

    if (parse.success) {
      return new SchoolClassroomListItem(parse.data)
    }

    return Promise.reject('Parsing error :' + parse.error)
  }

  /**
   * Récupère l'historique de connexion d'une classe pour une période scolaire donnée
   * @param schoolClassroomId Identifiant de la classe
   * @param schoolId Identifiant de l'école
   * @param schoolPeriodId Identifiant de la période scolaire
   * @returns Historique de connexion des élèves de la classe
   */
  async fetchSchoolClassroomLoginHistory(
    schoolClassroomId: number,
    schoolId: number,
    schoolPeriodId: number
  ): Promise<SchoolClassroomLoginHistory> {
    const response = await this.apiService.get<SchoolClassroomLoginHistory>(
      `admin/schools/${schoolId}/periods/${schoolPeriodId}/classrooms/${schoolClassroomId}/login-history`
    )

    const parse = SchoolClassroomLoginHistorySchema.safeParse(response)

    if (parse.success) {
      return new SchoolClassroomLoginHistory(parse.data)
    }

    console.error(parse.error)
    return Promise.reject('Parsing error')
  }


  /**
   * Supprime une classe
   * @param schoolId Identifiant de l'école
   * @param periodId Identifiant de la période scolaire
   * @param schoolClassroomId Identifiant de la classe
   */
  async deleteSchoolClassroom(schoolId: number, periodId: number, schoolClassroomId: number): Promise<void> {
    await this.apiService.delete(`admin/schools/${schoolId}/periods/${periodId}/classrooms/${schoolClassroomId}`)
  }
}