export namespace ClassroomType {
  export enum Values {
    PROJECT = 'PROJECT',
    CONTROL = 'CONTROL',
  }

  export function label(classroomType: Values) {
    switch (classroomType) {
      case Values.PROJECT:
        return 'Projet'
      case Values.CONTROL:
        return 'Contrôle'
    }
  }

  export const options = Object.values(Values).map((type) => ({
    value: type,
    label: label(type)
  }))
}