<script lang='ts' setup>
import { default as PButton } from 'primevue/button';
import { default as PTag } from 'primevue/tag';
import { default as PBadge } from 'primevue/badge';
import { default as PCard } from 'primevue/card';
import { default as PDivider } from 'primevue/divider';
import type SchoolPeriod from '@/core/domain/school/period/SchoolPeriod';
import { DateTime } from "luxon";
import type SchoolYear from '@/core/domain/school/year/SchoolYear';
import type School from '@/core/domain/school/core/School';
import { computed, ref } from 'vue';

const emit = defineEmits(['create-school-period']);

const props = defineProps<{
  schoolPeriod?: SchoolPeriod;
  schoolPeriods?: SchoolPeriod[];
  schoolYear: SchoolYear;
  school?: School;
  highlighted?: boolean;
}>();

const isHovered = ref(false);

// COMPUTED
const isPeriodAssociated = computed(() => {
  return props.schoolPeriods?.some((period) => period.schoolYear.schoolYearId === props.schoolYear.schoolYearId);
});

const formattedStartDate = computed(() => {
  try {
    return DateTime.fromISO(props.schoolYear.startDate).toLocaleString(DateTime.DATE_MED);
  } catch (e) {
    return 'Date invalide';
  }
});

const formattedEndDate = computed(() => {
  try {
    return DateTime.fromISO(props.schoolYear.endDate).toLocaleString(DateTime.DATE_MED);
  } catch (e) {
    return 'Date invalide';
  }
});

const statusInfo = computed(() => {
  type Status = 'Active' | 'A venir' | 'Terminée';
  type StatusMap = Record<Status, { severity: string; icon: string }>;
  const status: string = getYearStatus();

  const statusMap: StatusMap = {
    'Active': {
      severity: 'success',
      icon: 'pi-calendar-plus'
    },
    'A venir': {
      severity: 'info',
      icon: 'pi-calendar-minus'
    },
    'Terminée': {
      severity: 'secondary',
      icon: 'pi-calendar-times'
    }
  };

  return {
    text: status,
    severity: statusMap[status]?.severity || 'info',
    icon: statusMap[status]?.icon || 'pi-calendar'
  };
});

const cardClass = computed(() => {
  return {
    'border-1 border-round-xl p-3 h-full': true,
    'border-primary': props.highlighted,
    'border-bo-brand-secondary': !isHovered.value && !props.highlighted
  };
});

// METHODS
const createSchoolPeriod = () => {
  emit('create-school-period', props.schoolYear);
};

const getYearStatus = () => {
  try {
    const now = DateTime.now();
    const startDate = DateTime.fromISO(props.schoolYear.startDate);
    const endDate = DateTime.fromISO(props.schoolYear.endDate);

    if (now < startDate) {
      return 'A venir';
    }

    if (now > endDate) {
      return 'Terminée';
    }

    return 'Active';
  } catch (e) {
    return 'Indéterminé';
  }
};
</script>

<template>
  <div
    :class="cardClass"
  >
    <!-- En-tête avec nom et statut -->
    <div class=" align-items-center justify-content-between mb-2">
      <h4 class="text-lg font-medium m-0 text-truncate" :title="schoolYear.name">
        <i :class="['pi mr-2', statusInfo.icon]"></i>
        {{ schoolYear.name ?? 'Non Nommé' }}
      </h4>
      <p-tag
        class='mt-3'
        :value="statusInfo.text"
        :severity="statusInfo.severity"
      />
    </div>

    <!-- Période de dates -->
    <div class="mb-3 mt-3">
      <div class="flex align-items-center gap-1 text-600">
        <span class='text-sm'>du {{ formattedStartDate }} au {{ formattedEndDate }}</span>
      </div>
    </div>

    <!-- Contenu lié à la période -->
    <div v-if="schoolPeriod" class="flex flex-column gap-3">
      <div class="flex align-items-center gap-2">
        <i class="pi pi-users text-primary"></i>
        <span>{{ schoolPeriod.countStudents }} élèves</span>
      </div>

      <div class="flex align-items-center gap-2">
        <i class="pi pi-user text-primary"></i>
        <span>{{ schoolPeriod.countTeachers }} enseignants</span>
      </div>

      <div class="flex align-items-center gap-2">
        <i class="pi pi-th-large text-primary"></i>
        <span>{{ schoolPeriod.countClassrooms }} classes</span>
      </div>
    </div>

    <!-- Bouton d'association -->
    <div v-else class="flex justify-content-start mt-3">
      <p-button
        label="Associer"
        size='small'
        :severity="isPeriodAssociated ? 'secondary' : 'primary'"
        :outlined="isPeriodAssociated"
        :disabled="isPeriodAssociated"
        @click="createSchoolPeriod"
        v-tooltip="isPeriodAssociated ? 'La période est déjà associée' : 'Associer cette année scolaire à l\'établissement'"
      />
    </div>
  </div>
</template>

<style scoped>
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
}
</style>