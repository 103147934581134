import type { ILearningCourseUrlOptions } from '@/core/domain/learning/course/ILearningCourseUrlOptions'
import type { ILearningCourseRepository } from '@/core/domain/learning/course/ILearningCourseRepository'
import type { LearningCourse } from '@/core/domain/learning/course/LearningCourse'

export class FetchLearningCourseUseCase {
  private learningCourseRepository: ILearningCourseRepository;

  constructor(learningCourseRepository: ILearningCourseRepository) {
    this.learningCourseRepository = learningCourseRepository;
  }

  async execute(id: number, options?: ILearningCourseUrlOptions): Promise<LearningCourse> {
    return this.learningCourseRepository.fetchLearningCourse(id, options)
  }
}