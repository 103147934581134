import type { LearningSupportEntity } from '@/core/infrastructure/schemas/learning/support/LearningSupportSchema'
import { LearningSupportType } from '@/core/domain/resources/enums/LearningSupport'

export class LearningSupport {
  id: number
  type: LearningSupportType.Values
  url: string
  estimatedTime: number
  order: number

  constructor(support: LearningSupportEntity) {
    this.id = support.id
    this.type = support.type
    this.url = support.url
    this.estimatedTime = support.estimatedTime
    this.order = support.order
  }

  // Obtenir le libellé du type de support
  getTypeLabel(): string {
    return LearningSupportType.label(this.type)
  }

  // Vérifier si le support est de type Genially
  isGenially(): boolean {
    return this.type === LearningSupportType.Values.GENIALLY
  }

  // Formatter le temps estimé en format heures:minutes
  getFormattedEstimatedTime(): string {
    const hours = Math.floor(this.estimatedTime / 60)
    const minutes = this.estimatedTime % 60

    if (hours > 0) {
      return `${hours}h${minutes > 0 ? ` ${minutes}min` : ''}`
    } else {
      return `${minutes}min`
    }
  }

  // Convertir en format API pour les requêtes
  toApiFormat(){
    return {
      id: this.id,
      type: this.type,
      url: this.url,
      estimatedTime: this.estimatedTime,
      order: this.order,
    }
  }
}

// Fonction factory pour créer un support
export function createLearningSupport(support: LearningSupportEntity): LearningSupport {
  return new LearningSupport(support)
}