import { User } from '@/core/domain/users/User'
import type { IUsersRepository } from '@/core/domain/users/IUsersRepository'
import type ApiService from '@/core/infrastructure/services/ApiService'
import { UserSchema } from '@/core/infrastructure/schemas/users/core/UserSchema'

export class UsersRepository implements IUsersRepository {
  private apiService: ApiService

  constructor(apiService: ApiService) {
    this.apiService = apiService
  }

  async fetchAllUsers(): Promise<User[]> {
    throw new Error('Method not implemented.');
  }

  async fetchUserByIdentifier(userIdentifier: string): Promise<User> {

    const response = await this.apiService.get<User>(
      `admin/users/${userIdentifier}`
    )

    const parse = UserSchema.safeParse(response)

    if (parse.success) {
      return new User(parse.data)
    }

    return Promise.reject('Parsing error : ' + parse.error)
  }

  async createUser(): Promise<User> {
    throw new Error('Method not implemented.');
  }

  async updateUser(): Promise<User> {
    throw new Error('Method not implemented.');
  }
}