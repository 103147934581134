<script setup lang="ts">
import { computed, inject, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useToast } from 'primevue/usetoast'
import { default as PButton } from 'primevue/button'
import { default as PMessage } from 'primevue/message'
import { default as PPickList } from 'primevue/picklist'
import { default as PProgressSpinner } from 'primevue/progressspinner'
import { useSchoolClassroomStore } from '@/stores/school/schoolClassroom'
import { useSchoolPeriodUserStore } from '@/stores/school/schoolPeriodUser'
import { UserRole } from '@/core/domain/resources/enums/UserRole'
import type { SchoolPeriodUser } from '@/core/domain/school/user/SchoolPeriodUser'
import type { DialogRefWrapper } from '@/assets/types/primevue/dialog'

// types
type DialogData = {
  schoolId: number
  schoolPeriodId: number
  schoolClassroomId: number
}

type GroupedUser = {
  user: SchoolPeriodUser
  groupHeader?: string
}

// Composables
const toast = useToast()
const schoolClassroomStore = useSchoolClassroomStore()
const schoolPeriodUserStore = useSchoolPeriodUserStore()

// Dialog ref
const dialogRef: DialogRefWrapper<DialogData> | undefined = inject('dialogRef')

// Store refs
const { selectedClassroom, schoolClassroomStoreLoading, schoolClassroomStoreError } = storeToRefs(schoolClassroomStore)
const { schoolPeriodUsers, schoolPeriodUserStoreLoading, schoolPeriodUserStoreError } = storeToRefs(schoolPeriodUserStore)

// Local state
const localLoading = ref(false)
const localError = ref('')
const modelValue = ref<GroupedUser[][]>([[], []])
const sourceUsers = ref<GroupedUser[]>([])
const targetUsers = ref<GroupedUser[]>([])
const sourceFilter = ref('')
const targetFilter = ref('')

// Computed
const isLoading = computed(() =>
  localLoading.value ||
  schoolClassroomStoreLoading.value.fetchClassroom ||
  schoolPeriodUserStoreLoading.value.fetchUsers
)

const hasError = computed(() =>
  localError.value !== '' ||
  (schoolClassroomStoreError.value && schoolClassroomStoreError.value.hasError) ||
  (schoolPeriodUserStoreError.value && schoolPeriodUserStoreError.value.hasError)
)

const errorMessage = computed(() => {
  if (localError.value) return localError.value
  if (schoolClassroomStoreError.value?.hasError) return schoolClassroomStoreError.value.message
  if (schoolPeriodUserStoreError.value?.hasError) return schoolPeriodUserStoreError.value.message
  return ''
})

const schoolId = computed(() => dialogRef?.value.data.schoolId || 0)
const schoolPeriodId = computed(() => dialogRef?.value.data.schoolPeriodId || 0)
const schoolClassroomId = computed(() => dialogRef?.value.data.schoolClassroomId || 0)

// Methods
/**
 * Regroupe les utilisateurs par leur premier rôle
 */
function groupUsersByRole(users: SchoolPeriodUser[]): GroupedUser[] {
  // Tri des utilisateurs par rôle puis par nom
  const sortedUsers = [...users].sort((a, b) => {
    // D'abord par premier rôle (supposons que le rôle principal est le premier de la liste)
    const roleA = a.role[0] || '';
    const roleB = b.role[0] || '';
    if (roleA !== roleB) {
      return roleA.localeCompare(roleB);
    }
    // Ensuite par nom
    return `${a.lastname} ${a.firstname}`.localeCompare(`${b.lastname} ${b.firstname}`);
  });

  // Créer un tableau avec des en-têtes de groupe
  const groupedUsers: GroupedUser[] = [];
  let currentRole = '';

  sortedUsers.forEach(user => {
    const userRole = user.role[0] || '';
    // Si nous passons à un nouveau rôle, ajouter un en-tête
    if (currentRole !== userRole) {
      currentRole = userRole;
      groupedUsers.push({
        user: user,
        groupHeader: UserRole.label(userRole)
      });
    }
    groupedUsers.push({ user });
  });

  return groupedUsers;
}

/**
 * Charge les données initiales (classe et utilisateurs)
 */
async function loadData() {
  console.log('loadData', {
    schoolId: schoolId.value,
    schoolPeriodId: schoolPeriodId.value,
    classroomId: schoolClassroomId.value
  })
  if (!schoolId.value || !schoolPeriodId.value || !schoolClassroomId.value) return

  try {
    localLoading.value = true
    localError.value = ''

    // Charger la classe pour obtenir les utilisateurs déjà attribués
    await schoolClassroomStore.fetchSchoolClassroom(
      schoolId.value,
      schoolPeriodId.value,
      schoolClassroomId.value
    )

    // Charger tous les utilisateurs de la période
    await schoolPeriodUserStore.fetchAllSchoolPeriodUsersForSchool(
      schoolId.value,
      schoolPeriodId.value,
      true
    )

    // Répartir les utilisateurs entre disponibles et attribués
    setupUserLists()
  } catch (err) {
    localError.value = err instanceof Error
      ? err.message
      : 'Erreur lors du chargement des données'

    toast.add({
      severity: 'error',
      summary: 'Erreur',
      detail: localError.value,
      life: 5000
    })
  } finally {
    localLoading.value = false
  }
}

/**
 * Configure les listes d'utilisateurs disponibles et attribués
 */
function setupUserLists() {
  if (!selectedClassroom.value) return

  const classroomUserIds = new Set(
    selectedClassroom.value.users.map(user => user.schoolPeriodUserId)
  )

  // Séparer les utilisateurs
  const assignedUsers = schoolPeriodUsers.value.filter(user =>
    classroomUserIds.has(user.schoolPeriodUserId)
  )

  const availableUsers = schoolPeriodUsers.value.filter(user =>
    !classroomUserIds.has(user.schoolPeriodUserId)
  )

  // Grouper les utilisateurs par rôle
  sourceUsers.value = groupUsersByRole(availableUsers)
  targetUsers.value = groupUsersByRole(assignedUsers)

  // Affecter les tableaux au modelValue
  modelValue.value = [sourceUsers.value, targetUsers.value]
}

/**
 * Sauvegarde les modifications des utilisateurs de la classe
 */
async function saveChanges() {
  try {
    localLoading.value = true
    localError.value = ''

    // Créer la liste des IDs des utilisateurs assignés (en excluant les groupHeaders)
    const userIds = targetUsers.value
      .filter(item => !item.groupHeader)
      .map(item => item.user.schoolPeriodUserId)

    // Appeler l'API pour mettre à jour les utilisateurs de la classe
    await schoolClassroomStore.updateSchoolClassroomUsers(
      schoolId.value,
      schoolPeriodId.value,
      schoolClassroomId.value,
      userIds
    )

    dialogRef?.value.close()
  } catch (err) {
    localError.value = err instanceof Error
      ? err.message
      : 'Erreur lors de la sauvegarde des utilisateurs'
  } finally {
    localLoading.value = false
  }
}

/**
 * Gère le déplacement des utilisateurs entre les listes
 */
function handleMove(event: any) {
  // Récupérer les utilisateurs déplacés
  const { items, from } = event;

  // Filtrer pour exclure les en-têtes de groupe
  const actualUsers = items.filter((item: GroupedUser) => !item.groupHeader);

  if (actualUsers.length === 0) return;

  // Regrouper à nouveau les utilisateurs après le déplacement
  if (from === 'source') {
    // Les utilisateurs passent de source à target
    const remainingSourceUsers = sourceUsers.value
      .filter(item => !item.groupHeader && !items.some((moved: GroupedUser) => moved.user.schoolPeriodUserId === item.user.schoolPeriodUserId))
      .map(item => item.user);

    const updatedTargetUsers = [...targetUsers.value.filter(item => !item.groupHeader).map(item => item.user), ...actualUsers.map((item: GroupedUser) => item.user)];

    sourceUsers.value = groupUsersByRole(remainingSourceUsers);
    targetUsers.value = groupUsersByRole(updatedTargetUsers);
  } else {
    // Les utilisateurs passent de target à source
    const remainingTargetUsers = targetUsers.value
      .filter(item => !item.groupHeader && !items.some((moved: GroupedUser) => moved.user.schoolPeriodUserId === item.user.schoolPeriodUserId))
      .map(item => item.user);

    const updatedSourceUsers = [...sourceUsers.value.filter(item => !item.groupHeader).map(item => item.user), ...actualUsers.map((item: GroupedUser) => item.user)];

    targetUsers.value = groupUsersByRole(remainingTargetUsers);
    sourceUsers.value = groupUsersByRole(updatedSourceUsers);
  }

  // Mettre à jour le modelValue
  modelValue.value = [sourceUsers.value, targetUsers.value];
}

/**
 * Annule les modifications et ferme le composant
 */
function cancel() {
  dialogRef?.value.close()
}

/**
 * Filtre personnalisé pour la liste source
 */
function sourceFilterFunction(items: GroupedUser[]) {
  if (!sourceFilter.value.trim()) return items;

  const searchText = sourceFilter.value.toLowerCase();
  return items.filter(item =>
    item.groupHeader || // Toujours garder les en-têtes de groupe
    item.user.firstname.toLowerCase().includes(searchText) ||
    item.user.lastname.toLowerCase().includes(searchText)
  );
}

/**
 * Filtre personnalisé pour la liste cible
 */
function targetFilterFunction(items: GroupedUser[]) {
  if (!targetFilter.value.trim()) return items;

  const searchText = targetFilter.value.toLowerCase();
  return items.filter(item =>
    item.groupHeader || // Toujours garder les en-têtes de groupe
    item.user.firstname.toLowerCase().includes(searchText) ||
    item.user.lastname.toLowerCase().includes(searchText)
  );
}

// Lifecycle hooks
onMounted(async () => {
  await loadData()
})
</script>

<template>
  <div v-if="isLoading" class="flex justify-content-center">
    <p-progress-spinner
      :style="{ width: '50px', height: '50px' }"
      strokeWidth="8"
      fill="#ffffff"
      animationDuration=".5s"
    />
  </div>

  <div v-else>
    <!-- Message d'erreur -->
    <p-message
      v-if="hasError"
      severity="error"
      :text="errorMessage"
      class="w-full mb-3"
    >
      {{ errorMessage }}
    </p-message>

    <div class="card">
      <p-pick-list
        v-model="modelValue"
        source-filter-placeholder="Rechercher des utilisateurs"
        target-filter-placeholder="Rechercher des utilisateurs"
        show-source-controls
        show-target-controls
        :filter="true"
        :source-filter-value="sourceFilter"
        :target-filter-value="targetFilter"
        @filter-source-change="sourceFilter = $event.value"
        @filter-target-change="targetFilter = $event.value"
        :source-filter-method="sourceFilterFunction"
        :target-filter-method="targetFilterFunction"
        breakpoint="1400px"
        class="w-full"
        @move-to-target="handleMove({ items: $event.items, from: 'source', to: 'target' })"
        @move-all-to-target="handleMove({ items: $event.items, from: 'source', to: 'target' })"
        @move-to-source="handleMove({ items: $event.items, from: 'target', to: 'source' })"
        @move-all-to-source="handleMove({ items: $event.items, from: 'target', to: 'source' })"
      >
        <template #sourceheader>
          <div class="flex justify-content-center">
            <h5 class="my-0">Utilisateurs disponibles</h5>
          </div>
        </template>

        <template #targetheader>
          <div class="flex justify-content-center">
            <h5 class="my-0">Utilisateurs attribués</h5>
          </div>
        </template>

        <template #item="slotProps: {item: GroupedUser, index: number}">
          <!-- En-tête de groupe -->
          <div v-if="slotProps.item.groupHeader" class="w-full">
            <p class='font-bold'>{{ slotProps.item.groupHeader }}</p>
          </div>
          <!-- Élément utilisateur -->
          <div v-else class="flex flex-column w-full ">
            <p>{{ slotProps.item.user.firstname }} {{ slotProps.item.user.lastname }}</p>
          </div>
        </template>
      </p-pick-list>
    </div>

    <!-- Boutons d'action -->
    <div class="flex justify-content-end gap-2 mt-4">
      <p-button
        label="Annuler"
        icon="pi pi-times"
        outlined
        @click="cancel"
        :disabled="isLoading"
      />
      <p-button
        label="Enregistrer la classe"
        icon="pi pi-check"
        @click="saveChanges"
        :loading="isLoading"
        :disabled="isLoading"
      />
    </div>
  </div>
</template>