<script setup lang='ts'>
import BaseBlock from '@/components/base/BaseBlock.vue'
import { default as PButton } from 'primevue/button'
import { default as PProgressSpinner } from 'primevue/progressspinner'
import { default as PMessage } from 'primevue/message'
import { default as PTag } from 'primevue/tag'
import { default as PSkeletonCard } from 'primevue/skeleton'
import { computed, onMounted, ref, watch } from 'vue'
import type School from '@/core/domain/school/core/School'
import SchoolPeriodsItem from '@/components/schools/SchoolPeriodsItem.vue'
import SchoolPeriodCreate from '@/components/schools/SchoolPeriodCreate.vue'
import { useDialog } from 'primevue/usedialog'
import { storeToRefs } from 'pinia'
import { useSchoolPeriodStore } from '@/stores/school/schoolPeriod'

// COMPOSABLES
const dialog = useDialog()
const schoolPeriodStore = useSchoolPeriodStore()

// STORE REFS
const {
  schoolPeriods,
  needsFreshData,
  schoolPeriodStoreError,
  schoolPeriodStoreIsLoading
} = storeToRefs(schoolPeriodStore)

// LOCAL STATE
const localLoading = ref(false)
const localError = ref('')

// PROPS
const props = defineProps<{
  school: School
}>()

// COMPUTED
const isEmpty = computed(() => !localLoading.value && schoolPeriods.value.length === 0)

const isLoading = computed(() =>
  localLoading.value || schoolPeriodStoreIsLoading.value
)

const errorMessage = computed(() => {
  if (localError.value) return localError.value
  if (schoolPeriodStoreError.value?.hasError) return schoolPeriodStoreError.value.message
  return ''
})

const currentPeriod = computed(() =>
  schoolPeriods.value.find(period => period.schoolYear.isCurrent)
)


// METHODS
const createSchoolPeriod = () => {
  const dialogRef = dialog.open(SchoolPeriodCreate, {
    props: {
      header: 'Associer une période scolaire',
      dismissableMask: true,
      draggable: false,
      modal: true,
      style: {
        width: '75vw',
      },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
    },
    data: {
      school: props.school,
      schoolPeriods: schoolPeriods.value
    }
  })
}

/**
 * Charge les périodes scolaires
 * @param forceRefresh Force le rafraîchissement des données
 */
const fetchSchoolPeriods = async (forceRefresh = false) => {
  if (!props.school?.id) return

  try {
    localLoading.value = true
    localError.value = ''

    await schoolPeriodStore.fetchAllSchoolPeriodsForSchool(props.school.id, forceRefresh)
  } catch (err) {
    localError.value = err instanceof Error ? err.message : 'Erreur lors du chargement des périodes'
    console.error('Erreur lors du chargement des périodes scolaires:', err)
  } finally {
    localLoading.value = false
  }
}

// LIFECYCLE
onMounted(async () => {
  // Si les données sont périmées ou inexistantes, les recharger
  await fetchSchoolPeriods(needsFreshData.value)
})

// WATCHERS
watch(() => props.school?.id, async (newId, oldId) => {
  if (newId !== oldId && newId) {
    await fetchSchoolPeriods(true)
  }
})
</script>

<template>
  <base-block :accordion='false' title='Périodes scolaires'>
    <div class="flex gap-2 w-full">
      <p-button
        label='Associer à une année scolaire'
        icon='pi pi-plus'
        severity='primary'
        size='small'
        :disabled="isLoading"
        @click='createSchoolPeriod'
      />
      <p-button
        icon="pi pi-refresh"
        text
        rounded
        severity="secondary"
        :disabled="isLoading"
        @click="fetchSchoolPeriods(true)"
        v-tooltip.top="'Rafraîchir les données'"
      />
    </div>

    <!-- Message d'erreur -->
    <p-message
      v-if="errorMessage"
      severity="error"
      class="mb-3 w-full"
    >
      {{ errorMessage }}
    </p-message>

    <!-- État de chargement -->
    <div v-if="isLoading" class="grid w-full">
      <div class="col-12 md:col-6 lg:col-3" v-for="i in 4" :key="i">
        <p-skeleton-card class="h-10rem" />
      </div>
    </div>

    <!-- Pas de périodes -->
    <div v-else-if="isEmpty" class="flex flex-column align-items-center gap-3 py-5 w-full">
      <i class="pi pi-calendar text-4xl text-500"></i>
      <p>Aucune période scolaire associée à cet établissement.</p>
      <p>Commencez par associer une année scolaire à l'établissement.</p>
    </div>

    <!-- Liste des périodes -->
    <div v-else class='w-full'>
      <!-- Périodes par ordre chronologique -->
      <div class="grid w-full">
        <div
          class="col-12 md:col-6 lg:col-3"
          v-for="(schoolPeriod, index) in schoolPeriods"
          :key="schoolPeriod.schoolPeriodId || index"
        >
          <school-periods-item
            :school-period="schoolPeriod"
            :school-year="schoolPeriod.schoolYear"
          />
        </div>
      </div>
    </div>
  </base-block>
</template>
