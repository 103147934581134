import type ISchoolYearRepository from '@/core/domain/school/year/ISchoolYearRepository'
import type ApiService from '@/core/infrastructure/services/ApiService'
import SchoolYear from '@/core/domain/school/year/SchoolYear'
import { SchoolYearListSchema } from '@/core/infrastructure/schemas/school/year/SchoolYearSchema'


export default class SchoolYearRepository implements ISchoolYearRepository {
  private apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  async fetchSchoolYear(schoolYearId: number): Promise<SchoolYear> {
    throw new Error('Method not implemented.');
  }

  async fetchAllSchoolYears(): Promise<SchoolYear[]> {
    const res = await this.apiService.get<SchoolYear[]>(`admin/school-years`);

    // Parse the response
    const parse = SchoolYearListSchema.safeParse(res);

    // Check if the response was successful
    if (parse.success) {
      return parse.data.map((schoolYear) => new SchoolYear(schoolYear));
    }

    // Return an error
    return Promise.reject('Parsing error');
  }

  async updateSchoolYear(): Promise<SchoolYear> {
    throw new Error('Method not implemented.');
  }
}