// SchoolPeriodUserMapper.ts
import { SchoolPeriodUser, SchoolPeriodUserInput } from '@/core/domain/school/user/SchoolPeriodUser'
import { User } from '@/core/domain/users/User'
import { UserRole } from '@/core/domain/resources/enums/UserRole'

export default class SchoolPeriodUserMapper {
  /**
   * Convertit les données d'un utilisateur et d'un SchoolPeriodUser en SchoolPeriodUserInput
   * @param schoolPeriodUser Données de l'utilisateur dans le contexte d'une période scolaire
   * @param user Données complètes de l'utilisateur
   * @param schoolPeriodId Identifiant de la période scolaire
   * @returns Un objet SchoolPeriodUserInput prêt pour le formulaire
   */
  static toInput(
    schoolPeriodUser: SchoolPeriodUser,
    user: User,
    schoolPeriodId: number
  ): SchoolPeriodUserInput {
    const input = new SchoolPeriodUserInput();

    function convertRole(role: UserRole.Values): UserRole.SchoolValues {
      switch (role) {
        case UserRole.Values.PERDIR:
          return UserRole.SchoolValues.PERDIR;
        case UserRole.Values.TEACHER:
          return UserRole.SchoolValues.TEACHER;
        case UserRole.Values.STUDENT:
          return UserRole.SchoolValues.STUDENT;
        case UserRole.Values.CPE:
          return UserRole.SchoolValues.CPE;
        default:
          throw new Error(`Role ${role} is not a school role`);
      }
    }

    // Données de base de SchoolPeriodUser
    input.userIdentifier = schoolPeriodUser.userIdentifier;
    input.firstname = schoolPeriodUser.firstname;
    input.lastname = schoolPeriodUser.lastname;

    // Données supplémentaires de User
    input.email = user.email;
    input.gender = user.gender
    input.birthdate = user.birthdate.toISOString();
    input.schoolPeriodId = schoolPeriodId;

    // Conversion des rôles
    input.roles = schoolPeriodUser.role.map(convertRole);

    // Ajout de la propriété hasSignedAgreement pour les étudiants
    input.hasSignedAgreement = input.role === UserRole.SchoolValues.STUDENT ? schoolPeriodUser.hasSignedAgreement : undefined;

    return input;
  }
}