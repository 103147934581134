import { Gender, Role } from '@/assets/types/UserTypes'
import type {
  SchoolPeriodUserEntity,
  SchoolPeriodUserInputEntity
} from '@/core/infrastructure/schemas/school/user/SchoolPeriodUserSchema'
import { UserRole } from '@/core/domain/resources/enums/UserRole'
import { DateTime } from 'luxon'

export class SchoolPeriodUser {
  readonly schoolPeriodUserId: number
  readonly userIdentifier: string
  readonly firstname: string
  readonly lastname: string
  readonly role: UserRole.Values[]
  hasSignedAgreement?: boolean

  constructor(schoolPeriodUser: SchoolPeriodUserEntity) {
    this.schoolPeriodUserId = schoolPeriodUser.schoolPeriodUserId
    this.userIdentifier = schoolPeriodUser.userIdentifier
    this.firstname = schoolPeriodUser.firstname
    this.lastname = schoolPeriodUser.lastname
    this.role = schoolPeriodUser.role
    this.hasSignedAgreement = schoolPeriodUser.hasSignedAgreement
  }

  get fullName() {
    return `${this.firstname} ${this.lastname}`
  }
}

export class SchoolPeriodUserInput {
  userIdentifier?: string
  firstname: string = ''
  lastname: string = ''
  email: string = ''
  gender: Gender.Values | null = null
  roles: Role.SchoolValues[] = []
  birthdate: string = DateTime.now().toISODate()
  schoolPeriodId: number = 0
  hasSignedAgreement?: boolean

  constructor(schoolPeriodUserInput?: SchoolPeriodUserInputEntity) {
    if (schoolPeriodUserInput) {
      this.userIdentifier = schoolPeriodUserInput.userIdentifier
      this.firstname = schoolPeriodUserInput.firstname
      this.lastname = schoolPeriodUserInput.lastname
      this.email = schoolPeriodUserInput.email
      this.gender = schoolPeriodUserInput.gender
      this.roles = schoolPeriodUserInput.roles
      this.birthdate = schoolPeriodUserInput.birthdate
      this.schoolPeriodId = schoolPeriodUserInput.schoolPeriodId
      this.hasSignedAgreement = schoolPeriodUserInput.hasSignedAgreement
    }
  }

  get birthdateDate() {
    return new Date(this.birthdate)
  }

  set birthdateDate(date: Date) {
    this.birthdate = DateTime.fromJSDate(date).toISO() ?? ''
  }

  get role() {
    return this.roles[0]

  }

  set role(role: Role.SchoolValues) {
    this.roles = [role]
    if (role !== Role.SchoolValues.STUDENT) {
      this.hasSignedAgreement = undefined
    }
  }
}