

// Classe pour la task associée à un module
import { LearningQuiz } from '@/core/domain/learning/quiz/LearningQuiz'
import type {
  LearningModuleEntity, LearningModuleInputEntity,
  ModuleTaskEntity
} from '@/core/infrastructure/schemas/learning/module/LearningModuleSchema'
import { LearningStatus } from '@/core/domain/resources/enums/LearningStatus'
import { LearningGrain } from '@/core/domain/learning/grain/LearningGrain'

export class ModuleTask {
  id: number
  title: string
  description?: string
  quiz?: LearningQuiz | null

  constructor(task: ModuleTaskEntity) {
    this.id = task.id
    this.title = task.title

    if (task.quiz) {
      this.quiz = new LearningQuiz(task.quiz)
    } else {
      this.quiz = null
    }
  }
}

export class LearningModule {
  id: number
  title: string
  status: LearningStatus.Values
  order: number
  task: ModuleTask
  learningCourseId: number
  learningCourseName: string
  learningGrains: LearningGrain[]
  createdBy?: string
  createdAt?: Date
  modifiedBy?: string
  modifiedAt?: Date

  constructor(module: LearningModuleEntity) {
    this.id = module.id
    this.title = module.title
    this.status = module.status
    this.order = module.order

      this.task = new ModuleTask(module.task)


    this.learningCourseId = module.learningCourseId
    this.learningCourseName = module.learningCourseName

    // Conversion des grains
    this.learningGrains = module.learningGrains.map(grain =>
      new LearningGrain(grain)
    )

    this.createdBy = module.createdBy

    // Conversion des dates si elles existent
    if (module.createdAt) {
      this.createdAt = new Date(module.createdAt)
    }

    this.modifiedBy = module.modifiedBy

    if (module.modifiedAt) {
      this.modifiedAt = new Date(module.modifiedAt)
    }
  }

  // Retourne le nombre de grains du module
  grainsCount(): number {
    return this.learningGrains.length
  }

  // Retourne les grains actifs du module
  activeGrains(): LearningGrain[] {
    return this.learningGrains.filter(grain =>
      grain.status === LearningStatus.Values.ACTIVE
    )
  }

  // Obtenir les grains triés par ordre
  getSortedGrains(): LearningGrain[] {
    return [...this.learningGrains].sort((a, b) => a.order - b.order)
  }

  // Créer un objet d'entrée pour l'API
  createLearningModuleInput(): LearningModuleInputEntity {
    return {
      id: this.id,
      title: this.title,
      status: this.status,
      order: this.order,
      taskId: this.task?.id,
      learningGrains: this.learningGrains.map(grain => grain.toInputFormat())
    }
  }

  // Convertir vers le format d'API pour la mise à jour
  toApiFormat(): Record<string, any> {
    return {
      id: this.id,
      title: this.title,
      status: this.status,
      order: this.order,
      taskId: this.task?.id,
      learningCourseId: this.learningCourseId,
      learningGrains: this.learningGrains.map(grain => grain.toApiFormat())
    }
  }
}