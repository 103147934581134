<script setup lang="ts">
import { useSchoolStore } from '@/stores/school/school'
import { computed, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import DashboardSchoolListItem from '@/components/dashboard/DashboardSchoolListItem.vue'
import { useDashboardStore } from '@/stores/dashboard/dashboard'
import { default as PMessage } from 'primevue/message'
import { default as PSkeleton } from 'primevue/skeleton'

// STORES
const schoolStore = useSchoolStore()
const dashboardStore = useDashboardStore()

// STORE REFS
const { schoolStoreIsLoading, schoolStoreError } = storeToRefs(schoolStore)
const { dashboardSchoolList } = storeToRefs(dashboardStore)

// LOCAL STATE
const localLoading = ref(false)
const localError = ref('')

// COMPUTED
const isLoading = computed(() =>
  localLoading.value || schoolStoreIsLoading.value
)

const isEmpty = computed(() =>
  !isLoading.value && dashboardSchoolList.value.length === 0
)

const hasError = computed(() =>
  (schoolStoreError.value && schoolStoreError.value.hasError) || localError.value !== ''
)

const errorMessage = computed(() =>
  localError.value || (schoolStoreError.value?.hasError ? schoolStoreError.value.message : '')
)

// METHODS
/**
 * Charge les écoles pour l'utilisateur courant
 */
const loadSchools = async () => {
  try {
    localLoading.value = true
    localError.value = ''

    await schoolStore.fetchSchoolsForMe()
  } catch (err) {
    localError.value = err instanceof Error
      ? err.message
      : 'Erreur lors du chargement des établissements'
    console.error('Erreur lors du chargement des écoles:', err)
  } finally {
    localLoading.value = false
  }
}

// LIFECYCLE
onMounted(async () => {
  await loadSchools()
})
</script>

<template>
  <div class="dashboard-school-list">
    <!-- Message d'erreur -->
    <p-message
      v-if="hasError"
      severity="error"
      class="mb-3 w-full"
    >
      {{ errorMessage }}
    </p-message>

    <!-- État de chargement -->
    <div v-if="isLoading" class="flex flex-column gap-2">
      <p-skeleton v-for="i in 3" :key="i" class="mb-2" height="6rem" />
    </div>

    <!-- État vide -->
    <div v-else-if="isEmpty" class="flex flex-column align-items-center justify-content-center p-5">
      <i class="pi pi-building text-4xl text-500 mb-3"></i>
      <h3 class="mt-0 mb-2">Aucun établissement</h3>
      <p class="text-center text-700">
        Vous n'avez pas encore d'établissements associés.
      </p>
    </div>

    <!-- Liste des établissements -->
    <div class='flex flex-column gap-3' v-else>
      <dashboard-school-list-item
        v-for="school in dashboardSchoolList"
        :key="`school_${school.id}`"
        :school="school"
      />
    </div>
  </div>
</template>