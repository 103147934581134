export namespace EvaluationRange {
  export enum Values {
    DEFI = "DEFI",
    EFFORT_A_FAIRE = "EFFORT_A_FAIRE",
    FORCE = "FORCE",
  }

  export function label(evaluation: Values) {
    switch (evaluation) {
      case Values.DEFI:
        return 'Défi'
      case Values.EFFORT_A_FAIRE:
        return 'Effort à faire'
      case Values.FORCE:
        return 'Force'
    }
  }
}