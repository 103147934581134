import type ISchoolPeriodRepository from '@/core/domain/school/period/ISchoolPeriodRepository'
import type SchoolPeriod from '@/core/domain/school/period/SchoolPeriod'


export default class FetchAllSchoolPeriodsUseCase {
  private schoolPeriodRepository: ISchoolPeriodRepository;

  constructor(schoolPeriodRepository: ISchoolPeriodRepository) {
    this.schoolPeriodRepository = schoolPeriodRepository;
  }

  async execute(schoolId: number): Promise<SchoolPeriod[]> {
    return await this.schoolPeriodRepository.fetchAllSchoolPeriods(schoolId);
  }
}