import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { createNabooAdminRoutes } from '@/router/routes'
import { useAuthStore } from '@/stores/auth'
import { useSchoolStore } from '@/stores/school/school'
import { Role } from '@/assets/types/UserTypes'

export function useNavigation() {
  // Composables
  const route = useRoute()
  const router = useRouter()
  const authStore = useAuthStore()
  const schoolStore = useSchoolStore()

  // Store refs
  const { getRoles } = storeToRefs(authStore)
  const { schoolList } = storeToRefs(schoolStore)

  // Routes
  const routes = createNabooAdminRoutes()

  /**
   * Collecte récursivement les rôles requis d'une route
   */
  function collectRolesFromRoute(node: any): Role.Values[] {
    const rolesSet = new Set<Role.Values>();

    function traverse(currentNode: any) {
      if (currentNode?.meta?.roles && Array.isArray(currentNode.meta.roles)) {
        currentNode.meta.roles.forEach((role: Role.Values | null | undefined) => {
          if (role) {
            rolesSet.add(role);
          }
        });
      }

      if (currentNode?.children && Array.isArray(currentNode.children)) {
        currentNode.children.forEach((child: any) => traverse(child));
      }
    }

    traverse(node);
    return Array.from(rolesSet);
  }

  /**
   * Gère la navigation vers une route
   */
  async function processClick(link: string, params?: Record<string, number>) {
    try {
      if (link === '/') {
        await router.replace({ path: '/' });
        return;
      }

      if (link === route.name && params) {
        await router.push({ name: link, params });
        return;
      }

      await router.push({ name: link, params });
    } catch (err) {
      console.error('Erreur lors de la navigation:', err);
    }
  }

  /**
   * Vérifie si une classe est active
   */
  function isClassroomActive(classroomParams: any): boolean {
    return (
      route.name === 'dashboard-group' &&
      Number(route.params.classroomId) === classroomParams.classroomId
    );
  }

  /**
   * Groupe de menu actif
   */
  const currentGroup = computed(() =>
    route?.meta?.group ?? 'home'
  )

  /**
   * Éléments du menu filtrés selon les rôles de l'utilisateur
   */
  const menuItems = computed(() => {
    return routes
      .filter(it => it.name !== 'NotFound')
      .map(it => {
        const routeRoles = collectRolesFromRoute(it)
        let children;

        if (routeRoles.length > 0 && !routeRoles.some(role => getRoles.value.includes(role))) {
          return null;
        }

        const link = 'name' in it ? it.name : (it?.children?.length > 0 ? it.children[0].name : '');

        if (link === 'dashboard') {
          children = schoolList.value.map(school => ({
            name: school.name,
            classrooms: school.classrooms.map(classroom => ({
              name: classroom.name,
              link: 'dashboard-group',
              params: {
                schoolId: school.id,
                classroomId: classroom.schoolClassroomId,
              },
            }))
          }));
        }

        return {
          name: it.meta?.menuTitle || '',
          link: link || '',
          icon: it.meta?.icon,
          group: it.meta?.group,
          children
        };
      })
      .filter((it) => it !== null);
  })

  return {
    menuItems,
    currentGroup,
    processClick,
    isClassroomActive
  }
}