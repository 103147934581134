import type { ISchoolClassroomRepository } from '@/core/domain/school/classroom/ISchoolClassroomRepository'

export class DeleteSchoolClassroomUseCase {
  private schoolClassroomRepository: ISchoolClassroomRepository;

  constructor(schoolClassroomRepository: ISchoolClassroomRepository) {
    this.schoolClassroomRepository = schoolClassroomRepository;
  }

  async execute(schoolId: number, periodId: number, schoolClassroomId: number) {
    return await this.schoolClassroomRepository.deleteSchoolClassroom(
      schoolId,
      periodId,
      schoolClassroomId
    );
  }
}