import type SchoolMonitoringGroup from '@/core/domain/school/group/SchoolMonitoringGroup'
import type { ISchoolMonitoringGroupRepository } from '@/core/domain/school/group/ISchoolMonitoringGroupRepository'

export class FetchSchoolMonitoringGroupUseCase {
  private schoolMonitoringGroupRepository: ISchoolMonitoringGroupRepository;

  constructor(schoolMonitoringGroupRepository: ISchoolMonitoringGroupRepository) {
    this.schoolMonitoringGroupRepository = schoolMonitoringGroupRepository;
  }

  async execute(
    schoolId: number,
    schoolPeriodId: number,
    schoolClassroomId: number,
    groupId: number
  ): Promise<SchoolMonitoringGroup> {
    return this.schoolMonitoringGroupRepository.fetchSchoolMonitoringGroup(schoolId, schoolPeriodId, schoolClassroomId, groupId);
  }
}